import axios from "axios";

export const ServiceApi = axios.create({
  // baseURL: "https://testdev.coralpay.com/CoralMailService/api/",
  // baseURL: "https://204.8.207.123:5000/MessageServices/api/",
  baseURL: "https://testdev.coralpay.com:5000/MessageServices/api/",
  // baseURL: "http://localhost:5000/api/",
  headers: {
    "Content-Type": "application/json",
    // accept: "application/json",
    "ApiKey": process.env.REACT_APP_APIKEY,
    // "Access-Control-Allow-Origin": "*"
  },
});

export const routes = {
  // ContactUs: "tobiEmailService",
  // ContactUs: "SendEmail",
  ContactUs: "sendemail",
};
