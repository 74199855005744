import wallet from "../src/assets/icons/wallet.svg"
import chart from "../src/assets/chart.svg"
//import chart2 from "../src/assets/icons/chartOrange.svg"
import card from "../src/assets/card.svg"
import card2 from "../src/assets/icons/paylinksg.svg"
import phone from "../src/assets/phone-with-car.svg"
import dash from "../src/assets/icons/dash.svg"
import bank from "../src/assets/icons/bankBlue.svg"
import nqrbc from "../src/assets/icons/nqrbc.png"
import finpaysvg from "../src/assets/icons/finpaysvg.svg"
import icons8iphone from "../src/assets/icons/Cgateicon.svg"
import cpg from "../src/assets/icons/cpg.svg"
import gate from "../src/assets/Group 6514.svg"
import ts from "../src/assets/icons/ts.svg"
import finpay from "../src/assets/icons/fin.svg"
import bs from "../src/assets/icons/bap.svg"
import nqr from "../src/assets/icons/nqr.svg"
import paylink from "../src/assets/icons/paylink.svg"
import paywithbank from "../src/assets/icons/pw.svg"
import instantpay from "../src/assets/icons/ins.svg"
import digipos from "../src/assets/icons/digipos.svg"



const  payload =   [
       {
            topTitle: "CoralPay Payment Gateway",
            content: "Our Payment Gateway aggregates all our payment solutions in one place, " +
                "enabling customers to have various payment options at merchant’s checkout point.",
            bottomTitle: cpg,
            imgBorderColor:"#22AC00",
            imgIcon:card,
            titleColor:"#22AC00",
           link:"/coralpay-payment-gateway",
           backIcon:""
        },
         {
            topTitle: "C’Gate USSD",
            content: "C’Gate innovative payment solution has successfully incorporated the Unstructured Supplementary " +
                "Service Data (USSD) payments on hardware and Soft PoS, e-commerce websites....",
            bottomTitle: gate,
             imgBorderColor:"#FF7A00",
             imgIcon: icons8iphone,
             titleColor:"#FF7A00",
             link:"/cgateussd",
             backIcon:""
        },
        {
            topTitle: "Transaction Switching\n" +
                "& Processing",
            content: "We offer transaction switching and processing services to financial institutions, " +
                "mobile money operators and other relevant partners.",
            bottomTitle: ts,
            imgBorderColor:"#7D3E64",
            imgIcon:chart,
            titleColor:"#7D3E64",
            link:"/transaction-switching-and-processing",
            backIcon:""
        },
        {
            topTitle: "DigiPOS",
            content: "DigiPOS is a software-based solution which transforms a regular smartphone or other Commercial " +
                "Off-The-Shelf (COTS) devices, into a contactless payment terminal. ",
            bottomTitle: digipos,
            imgBorderColor:"rgb(255, 122, 0)",
            imgIcon:phone,
            titleColor:"rgb(255, 122, 0)",
            link:"/digipos",
            backIcon:""
        },
        // {
        //     topTitle: "FinPay",
        //     content: "Offer your customers a highly secure and convenient way of making payments with biometrics.",
        //     bottomTitle: finpay,
        //     imgBorderColor:"#FF0000",
        //     imgIcon:finpaysvg,
        //     titleColor:"#FF0000",
        //     link:"/finpay",
        //     backIcon:""
        // },
        {
            topTitle: "Billers Aggregation Platform\n",
            content: "Make bills payments more convenient for your customers. Bring the billers right into their devices" +
                " with our Billers Aggregation Platform.",
            bottomTitle:bs,
            imgBorderColor:"#FF0099",
            imgIcon:dash,
            titleColor:"#FF0099",
            link:"/billers-aggregation-platform",
            backIcon:""
        },
        {
            topTitle: "NQR",
            content: "CoralPay has incorporated NQR as a channel for businesses to receive payments. " +
                "With NQR, customers can scan a QR code on merchant’s",
            bottomTitle: nqr,
            imgBorderColor:"#0038FF",
            imgIcon:nqrbc,
            titleColor:"#0038FF",
            link:"/nqr",
            backIcon:""
        },
        {
            topTitle: "CoralPay Instant Payment",
            content: "C’Gate InstantPay (CIP) interbank transfer solution enables businesses and individuals perform " +
                "instant money transfers, name enquiry, fraud monitoring etc. ",
            bottomTitle: instantpay,
            imgBorderColor:"#8F00FF",
            imgIcon:wallet,
            titleColor:"#8F00FF",
            link:"/instant-payment",
            backIcon:""
        },
        {
            topTitle: "Payment Link",
            content: "With Payment Link, CoralPay empowers social media merchants and other online merchants without a website.",
            bottomTitle: paylink,
            imgBorderColor:"#9EAC00",
            imgIcon:card2,
            titleColor:"#9EAC00",
            link:"/payment-link",
            backIcon:""
        },
        {
            topTitle: "Pay With Bank Transfer",
            content: "Pay with Bank Transfer is a secure way to make payment with account number, " +
                "which ensures that best practices are observed while making payments through bank transfers.",
            bottomTitle: paywithbank,
            imgBorderColor:"#0038FF",
            imgIcon:bank,
            titleColor:"#0038FF",
            link:"/payment-with-transfer",
            backIcon:""
        },
        
    ]

 export default payload


