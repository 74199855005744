import React from "react";
import {useState} from "react";
import logo from "../assets/logo1.svg"
import{NavLink} from "react-router-dom";


const Header = () => {
    const [showMobileNav, setShowMobileNav] = useState(false)
    const [showMobileNavDropdown, setShowMobileNavDropdown] = useState(false)
    const dropMobileNav = () => {
        setShowMobileNav(!showMobileNav)
        setShowMobileNavDropdown(false)
    }
    const dropMobileDowNav = () => {setShowMobileNavDropdown(!showMobileNavDropdown)}


    return (
        <>
            <div className="navWrapper">
                    <div className="logo-area">
                       <NavLink className="logo-link" to={'/'}> <img src={logo}/> </NavLink>
                    </div>
                    <div className="mobile-nav-icon" onClick={() => dropMobileNav()}>&#9776;</div>
                    <div className="top-menu" id="">
                        <NavLink  to="/" activeClassName="active">Home</NavLink>
                        <a className="products-services dropdown-toggle">Products and Services <span style={{fontWeight:900}}> </span>
                            <ul className="dropdown">
                                <li><a href="/coralpay-payment-gateway">CoralPay Payment Gateway</a></li>
                                <li><a href="/cgateussd">C’Gate USSD</a></li>
                                <li><a href="/transaction-switching-and-processing">Transaction Switching & Processing</a></li>
                                <li><a href="/digipos">DigiPOS</a></li>
                                {/* <li><a href="/finpay">FInPay</a></li> */}
                                <li><a href="/billers-aggregation-platform">Billers Aggregation Platform</a></li>
                                <li><a href="/nqr">NQR</a></li>
                                <li><a href="/payment-link">Payment Link</a></li>
                                <li><a href="/payment-with-transfer">Pay With Bank Transfer</a></li>
                                <li><a href="/instant-payment">CoralPay Instant Payment</a></li>
                            </ul>
                        </a>
                        <NavLink  to="/developer" activeClassName="active">Developers</NavLink>
                        <NavLink to="/about-us" activeClassName="active">About Us</NavLink>
                        <NavLink to="/contact-us" activeClassName="active">Contact Us</NavLink>
                        <a href="https://blog.coralpay.com/" activeClassName="active">Blog</a>

                        {/*<a href="javascript:void(0);" style="font-size:15px;" className="icon"*/}
                        {/*   onClick="myFunction()">&#9776;</a>*/}
                    </div>
                    {/*<div className="btn-wrapper">*/}
                    {/*     <a href="tel:+23470026725729"> <Button message={"Call Us Now"} bgColor={"#7B3F63"}/></a>*/}
                    {/*</div>*/}
            </div>
            {showMobileNav ?
                <div className="mobile-nav">
                    <a href="/">Home</a>
                    <a className="products-services" onClick={() => dropMobileDowNav()}>Products and Services <span style={{fontWeight:900}}>  </span></a>
                    {showMobileNavDropdown ?
                    <ul className="dropdown-mobile">
                        <li><a href="/coralpay-payment-gateway">CoralPay Payment Gateway</a></li>
                        <li><a href="/cgateussd">C’Gate USSD</a></li>
                        <li><a href="/transaction-switching-and-processing">Transaction Switching & Processing</a></li>
                        <li><a href="/digipos">DigiPOS</a></li>
                        {/* <li><a href="/finpay">FInPay</a></li> */}
                        <li><a href="/billers-aggregation-platform">Billers Aggregation Platform</a></li>
                        <li><a href="/nqr">NQR</a></li>
                        <li><a href="/payment-link">Payment Link</a></li>
                        <li><a href="/payment-with-transfer">Pay With Bank Transfer</a></li>
                        <li><a href="/instant-payment">CoralPay Instant Payment</a></li>
                    </ul>
                    : null
                    }
                    <a href="/developer">Developers</a>
                    <a href="/about-us">About Us</a>
                    <a href="/contact-us">Contact Us</a>
                    <a href="https://blog.coralpay.com/">Blog</a>
                </div>
                : null
            }
        </>
    )

}
export default Header