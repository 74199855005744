import React from "react"
import guy from "../assets/guy.svg";

const CookiePolicyScreen = () => {
    return(
        <div>
            <div className="blog-header" style={{backgroundImage:`url(${guy})`}}>
                <div className="blog-wrapper-overlay blog">
                    <h3>Cookie Policy</h3>
                </div>
            </div>

            <div className="isms-policy">
                <div>
                    <h3>Introduction</h3>
                    <p>Our site uses cookies to distinguish you from other users of our site. This helps us to provide you with a good experience
                        when you visit, navigate, access, use or browse our site and also allows us to improve our site, services or protect our
                        interest.</p>
                    <p>Upon access to our platform/site or use of our services, content, features, technologies or functions offered on our
                        website, platforms or visit any of our offices for official or non-official purposes (collectively the “CoralPay Services”), or
                        continuing to visit, navigate or use this platform, you acknowledge and confirm the acceptance and consent to this cookie
                        policy. This policy also governs the use of CoralPay Services and collaboration projects by our users and stakeholders,
                        unless otherwise agreed through a valid written agreement.</p>
                    <p>We may amend this policy at any time by posting a revised version on our platforms or site. The revised version will be
                        effective after publication.</p>
                    <p>If you have any questions about this policy, including any inquiries, please contact us by sending an email to
                        Info@coralpay.com .</p>

                    <h3>Scope</h3>

                    <p>A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer. Cookies
                        contain information that is transferred to your computer&#39;s hard drive.</p>

                    <p>We use cookies, such as (by way of example only):</p>

                    <ul>
                        <li><b>Strictly necessary cookies.</b> These are cookies that are required for the operation of our site. They include, for
                            example, cookies that enable you to log into secure areas of our site, perform a transaction or payment.</li>
                        <li><b>Analytical or performance cookies.</b> These allow us to recognise and count the number of visitors/users and to see
                            how visitors/users move around our site when they are using it. This helps us to improve the way our site works, for
                            example, by ensuring that visitors/users are finding what they are looking for easily.</li>
                        <li><b>Functionality cookies.</b> These are used to recognise you when you return to our site. This enables us to personalise
                            our content for you, greet you by name and remember your preferences (for example, your choice of language or
                            region).</li>
                        <li><b>Targeting cookies.</b> These cookies record your visit to our site, the pages you have visited and the links you have
                            followed. We will use this information to make our site, communications and or promotions displayed on it more
                            relevant to your interests. We may also share this information with third parties for this purpose.</li>
                    </ul>

                    <p><b>Other cookies.</b> This cookie helps us to estimate our user/usage size, and or pattern. Store information about your
                        preferences, and so allow us to customise our site and to provide you with offers that are targeted to your individual
                        interests. Speed up your searches. Recognise you when you return to our site. Allow you to use our site in a way that
                        makes your user experience more convenient. If you register with us or complete our online forms, we will use cookies to
                        remember your details or personal data during your current visit, and any future visits provided the cookie was not deleted
                        in the interim. For more information on how we use your personal data kindly click on url link.</p>

                    <p>Please note that third parties may also use cookies, over which we have no control. These named third parties may
                        include, for example, advertising/marketing networks and providers of external services like web traffic analysis services.</p>

                    <p>Kindly note that, if you use your browser settings to block all cookies (including essential cookies) you may not be able to
                        access all or parts of our site.</p>

                </div>
            </div>
        </div>
    )
}

export default CookiePolicyScreen