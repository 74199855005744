import React from "react";
import blogPayload from "../blogContent"
import Button from "./Button";

const Card2 = () => {
        const card2Component = blogPayload.map((i, index) => {
            return (
                <div>
                    <div className="card2">
                        <div className="card-img2" style={{backgroundImage: `url(${i.image})`}}></div>
                        <div style={{
                            paddingLeft: '15%',
                            paddingRight: '10%',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            height: '250px',
                            flexDirection: 'column',
                            justifyContent: 'space-evenly'
                        }}>
                            <div className="pl-3 content mt-4">

                                <h3>{i.title}</h3>

                                <p>{i.content} <span> <b>Read More</b> </span></p>
                            </div>
                            <div style={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-around',
                                alignItems: "center",
                                marginTop: "20px"
                            }}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <img src={i.profilePics} alt='back'/>
                                    <span className="ml-3 blog-profile-span">{i.user}</span>
                                </div>
                                <span className="blog-time">{i.date}</span>
                            </div>
                        </div>
                    </div>
                    <div className="circle-wrapper">
                        <div className="circle">1</div>
                    </div>
                </div>
            )

        })

         if(blogPayload.length >= 1)
             return [card2Component]
        else{
            return(
                <div style={{width:"100%",display:'flex',alignItems:'center',justifyContent:'center'}}>
                    <h1 style={{fontSize:'30px'}}>No Post Found </h1>
                </div>
            )
         }


}

export default Card2