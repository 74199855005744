import React from 'react'
import guy from "../assets/guy.svg";

const IsmsPolicyStatement = () => {
    return (
        <div>
            <div className="blog-header" style={{backgroundImage:`url(${guy})`}}>
                <div className="blog-wrapper-overlay blog">
                    <h3 className='small'>ISMS Policy Statement</h3>
                </div>
            </div>
            <div className="isms-policy">
            <div>
                <h3>Purpose and Objectives</h3>
                <p>CoralPay is committed to the integrity of her information and implements measures through the establishment,
                    implementation, maintenance and continual improvement of an information security program to protect the
                    organization’s information.
                </p>
                <h3>Scope</h3>
                <p>Coralpay’s Information Security Policy is to provide a security framework that will ensure the protection of Information
                    from unauthorized access, loss or damage while supporting the open, information-sharing needs of our partners or
                    users..
                </p>
                <h3>Application</h3>
                <p>Any external party dealing with Coralpay no matter what her status is, such as employee, customer, contractor, or consultant (by way of example only) (“user”), must comply with our Information Security Policy. All users shall comply
                    with this policy and exercise a duty of care based on best practices in relation to the storage, processing, and or
                    transmission of our information and information systems.</p>

                <p>Upon access to our information and or information systems, you acknowledge and confirm the acceptance and consent to this policy.</p>
                <p>We may amend this policy at any time by updating with a revised version on our platforms or site. The revised version will be effective after publication.</p>
            </div>
            <div>
                <h3>Policy</h3>
                <p>This information security policy states management’s commitment and establishes the primary framework and
                    foundation from which all our information security related policies are framed.
                </p>
                <p>Coralpay’s Top Management is committed to implementing Information Security Management (“ISMS”) based on
                    International best practices and international standard ISO 27001:2013. We will further comply with applicable legal,
                    regulatory and contractual requirements related to information security in our services and operations.
                </p>
                <p>We welcome interested parties’ comments on the enforcement of the policy and the policy itself.</p>
            </div>
        </div>
        </div>

    )
}

export default IsmsPolicyStatement