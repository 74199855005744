import React from "react"
import Subscribe from "../../components/Subscribe";
import Photo3 from "../../assets/photo3.svg"
import photo4 from "../../assets/photo4.jpg"
import "../../App.css"
import learnMore from "../../assets/learnmore.svg";
import {Link} from "react-router-dom";
import Route from "../../ReRoute";

const InstantPaymentScreen = () => {

    return(
        <div>
            <div className="blog-header" style={{backgroundImage:`url(${Photo3})`}}>
                <div className="blog-wrapper-overlay">
                    <h3 style={{width:696}}>CoralPay Instant <span style={{color:'#F7EF8A'}}>Payment</span></h3>
                </div>
            </div>
            <br /><br /><br /><br />
            <div className="product">
                <div className="product-one">
                    <div className="accordion" id="accordionExample">
                        <Link to="/coralpay-payment-gateway" >
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingOne">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Coralpay Payment Gateway
                                    </button>
                                </h2>
                            </div>
                        </Link>

                        <Link to="/cgateussd">
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingOne">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        CGateUssd
                                    </button>
                                </h2>
                            </div>
                        </Link>
                        <Link to="/transaction-switching-and-processing">
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingTwo">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo(null)" aria-expanded="false" aria-controls="collapseTwo">
                                        Transaction Switching & Processing
                                    </button>
                                </h2>
                            </div>
                        </Link>

                        <Link to="/payment-with-transfer">
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree(null)" aria-expanded="false"
                                            aria-controls="collapseThree">
                                        Pay With Bank Transfer
                                    </button>
                                </h2>
                            </div></Link>
                        <Link to="/nqr" >
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree(null)" aria-expanded="false"
                                            aria-controls="collapseThree">
                                        NQR
                                    </button>
                                </h2>
                            </div></Link>
                        <Link to="/digipos">
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingFour">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseFour" aria-expanded="false"
                                            aria-controls="collapseFour">
                                        DigiPOS
                                    </button>
                                </h2>
                            </div>
                        </Link>
                        <Link to="/billers-aggregation-platform">
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree">
                                        Billers Aggregation Platform
                                    </button>
                                </h2>
                            </div>
                        </Link>
                        <Link to="/finpay">
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingSix">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseSix" aria-expanded="false"
                                            aria-controls="collapseSix">
                                        FinPay
                                    </button>
                                </h2>
                            </div>
                        </Link>
                        <Link to="/payment-link">
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingSeven">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseSeven" aria-expanded="false"
                                            aria-controls="collapseSeven">
                                        Payment Link
                                    </button>
                                </h2>
                            </div>
                        </Link>
                        <Link to="/instant-payment">
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingEight">
                                    <button className="accordion-button dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseEight" aria-expanded="false"
                                            aria-controls="collapseEight">
                                        Coralpay Instant Payment
                                    </button>
                                </h2>
                                <div id="collapseEight" className="accordion-collapse show" aria-labelledby="headingEight"
                                     data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <div className="biller-one-option-mobile">
                                            <div className="biller-one-app-option" style={{backgroundImage:`url(${photo4})`}}></div>
                                        </div>
                                        <p>
                                            C’Gate InstantPay (CIP) interbank transfer solution enables businesses and individuals perform instant money transfers, name enquiry, fraud monitoring etc.
                                            CIP also opens opportunities to bank subsidiaries across the continent to send/receive and disburse international transfers directly into the accounts at MFBs and wallets of MMO’s, cashable at Agency Banking Locations.
                                        </p>
                                        <br />
                                        <br />
                                        <span onClick={() => Route("https://blog.coralpay.com/the-payment-options-that-will-keep-your-business-ahead/")}><img src={learnMore} alt='learn more btn'/></span>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="biller-one">
                    <div className="biller-one-app" style={{backgroundImage:`url(${photo4})`}}></div>
                </div>
            </div>
            <Subscribe />
        </div>
    )
}


export default InstantPaymentScreen