// import logo from './logo.svg';
import "./App.css";
import HomeScreen from "./view/HomeScreen";
import AboutScreen from "./view/AboutScreen";
import ContactScreen from "./view/ContactScreen";
import BlogScreen from "./view/BlogScreen";
import Header from "./components/Header";
import Footer from "./components/Footer";

// Product and Service Screen
import DigiPos from "./view/products_and_services/DigiPos";
// import FinPayScreen from "./view/products_and_services/FinPayScreen";
import PaymentGatewayScreen from "./view/products_and_services/PaymentGatewayScreen";
import BillersAggePlat from "./view/products_and_services/BillersAggePlat";
import PaymentLinkScreen from "./view/products_and_services/PaymentLinkScreen";
import InstantPaymentScreen from "./view/products_and_services/InstantPaymentScreen";
import CGateUssd from "./view/products_and_services/CGateUssd";
import TransactionSwitching from "./view/products_and_services/TransactionSwitching";
import PayWithTransfer from "./view/products_and_services/PayWithTransfer";
import Nqr from "./view/products_and_services/Nqr";

// FAQs Screen
import FAQs from "./view/FAQs";

//Developers Screen
import DeveloperHomeScreen from "./view/developers/HomeScreen";
import DeveloperHeader from "./components/DeveloperHeader";
import ResponseCode from "./view/developers/ResponseCode";
import CGateUssdDocumentationScreen from "./view/developers/CGATE/CGateUssdDocumentationScreen";
import TransactionPaymentNotification from "./view/developers/TransactionPaymentNotification";
import AccountNumber from "./view/developers/AccountNumber";
import TransactionQuery from "./view/developers/TransactionQuery";
import CoralpayVasDocumentation from "./view/developers/VAS/CoralpayVasDocumentation";
import CoralpayCardApiDocumentation from "./view/developers/CardApi/CoralpayCardApiDocumentation";
import CoralpayPaymentGatewayDocumentation from "./view/developers/Verge/CoralpayPaymentGatewayDocumentation";
import PaywithBankTransferDocumentation from "./view/developers/BankLink/PaywithBankTransferDocumentation";
import FastChannelDocumentation from "./view/developers/FastChannel/FastChannelDocumentation";
import CIPDocumentation from "./view/developers/CIP/CIPDocumentation";
import AccountConnectDocumentation from "./view/developers/AccountConnect/AccountConnectDocumentation";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  HashRouter,
} from "react-router-dom";
import React, { useEffect } from "react";

// Privacy
import PrivacyScreen from "./view/PrivacyScreen";
import IsmsPolicyStatement from "./view/IsmsPolicyStatement";
import ITsmsPolicyStatement from "./view/ITsmsPolicyStatement";
import TermsAndConditions from "./view/TermsAndConditions";
import AcceptableUsePolicy from "./view/AcceptableUsePolicy";
import CookiePolicyScreen from "./view/CookiePolicyScreen";

// Page not found
import PageNotFound from "./view/PageNotFound";

// import { browserName} from "react-device-detect";

function Layout() {
  useEffect(() => {
    if (window.innerWidth <= 1601) {
      document.body.style.zoom = "90%";
      console.log(window.innerWidth);
    } else if (window.outerWidth >= 1685) {
      document.body.style.zoom = "80%";
      console.log(window.outerWidth);
    } else {
      document.body.style.zoom = "100%";
      console.log(window.innerWidth);
    }
  });
  return (
    <div className="App">
      <Router>
        {window.location.pathname === "/developer/docs" ? (
          <DeveloperHeader />
        ) : (
          <Header />
        )}

        <Routes>
          <Route path="/" exact element={<HomeScreen />}></Route>
          <Route path="/about-us" element={<AboutScreen />}></Route>
          <Route path="/contact-us" element={<ContactScreen />}></Route>
          <Route path="/blog" element={<BlogScreen />}></Route>

          {/* Product and Service Route */}
          <Route
            path="/coralpay-payment-gateway"
            element={<PaymentGatewayScreen />}
          ></Route>
          <Route
            path="/billers-aggregation-platform"
            element={<BillersAggePlat />}
          ></Route>
          <Route path="/digipos" element={<DigiPos />}></Route>
          {/* <Route path="/finpay"  element={<FinPayScreen />}></Route> */}
          <Route path="/payment-link" element={<PaymentLinkScreen />}></Route>
          <Route
            path="/instant-payment"
            element={<InstantPaymentScreen />}
          ></Route>
          <Route path="/cgateussd" element={<CGateUssd />}></Route>
          <Route path="/nqr" element={<Nqr />}></Route>
          <Route
            path="/payment-with-transfer"
            element={<PayWithTransfer />}
          ></Route>
          <Route
            path="/transaction-switching-and-processing"
            element={<TransactionSwitching />}
          ></Route>

          {/* Developer Route */}
          <Route path="/developer" element={<DeveloperHomeScreen />}></Route>
          <Route
            path="/developer/docs/response-code"
            element={<ResponseCode />}
          ></Route>

          {/* Documentation Route */}
          <Route
            path="/developer/docs/cgateussd"
            element={<CGateUssdDocumentationScreen />}
          ></Route>
          <Route
            path="/developer/docs/payment-notification"
            element={<TransactionPaymentNotification />}
          ></Route>
          <Route
            path="/developer/docs/account-number"
            element={<AccountNumber />}
          ></Route>
          <Route
            path="/developer/docs/transaction-query"
            element={<TransactionQuery />}
          ></Route>
          <Route
            path="/developer/docs/pay-with-bank-transfer"
            element={<PaywithBankTransferDocumentation />}
          ></Route>
          <Route
            path="/developer/docs/payment-gateway"
            element={<CoralpayPaymentGatewayDocumentation />}
          ></Route>
          <Route
            path="/developer/docs/card-api"
            element={<CoralpayCardApiDocumentation />}
          ></Route>
          <Route
            path="/developer/docs/vas"
            element={<CoralpayVasDocumentation />}
          ></Route>
          <Route
            path="/developer/docs/fast-channel"
            element={<FastChannelDocumentation />}
          ></Route>
          <Route
            path="/developer/docs/cip"
            element={<CIPDocumentation />}
          ></Route>
          <Route
            path="/developer/docs/account-connect"
            element={<AccountConnectDocumentation />}
          ></Route>

          {/* Privacy and statement Route */}
          <Route path="/privacy-statement/" element={<PrivacyScreen />}></Route>
          <Route
            path="/isms-policy-statement/"
            element={<IsmsPolicyStatement />}
          ></Route>
          <Route
            path="/itsms-policy-statement/"
            element={<ITsmsPolicyStatement />}
          ></Route>
          <Route
            path="/terms-conditions/"
            element={<TermsAndConditions />}
          ></Route>
          <Route
            path="/acceptable-use-policy/"
            element={<AcceptableUsePolicy />}
          ></Route>
          <Route
            path="/cookie-policy/"
            element={<CookiePolicyScreen />}
          ></Route>

          {/* Page Not Found*/}
          <Route path="/*" exact element={<PageNotFound />}></Route>

          {/* FAQs */}
          <Route path="/frequently-asked-questions/" element={<FAQs />}></Route>
        </Routes>

        <Footer />
      </Router>
    </div>
  );
}

export default Layout;
