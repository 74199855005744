import React from "react"
import {Link} from "react-router-dom";

const ProductLinks = () => {
    return  <div className="accordion" id="accordionExample">
        <Link to="/cgateussd" >
            <div className="accordion-item mb-3">
                <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        C’Gate USSD
                    </button>
                </h2>
            </div></Link>
        <Link to="/transaction-switching-and-processing">
            <div className="accordion-item mb-3">
                <h2 className="accordion-header" id="headingTwo">
                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        Transaction Switching & Processing
                    </button>
                </h2>
            </div></Link>
        <Link to="/payment-with-transfer">
            <div className="accordion-item mb-3">
                <h2 className="accordion-header" id="headingThree">
                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseThree" aria-expanded="false"
                            aria-controls="collapseThree">
                        Pay With Bank Transfer
                    </button>
                </h2>
            </div></Link>

        <Link to="/nqr">
            <div className="accordion-item mb-3">
                <h2 className="accordion-header" id="headingThree">
                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseThree" aria-expanded="false"
                            aria-controls="collapseThree">
                        NQR
                    </button>
                </h2>
            </div>
        </Link>
        <Link to="/digipos">
            <div className="accordion-item mb-3">
                <h2 className="accordion-header" id="headingThree">
                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseThree" aria-expanded="false"
                            aria-controls="collapseThree">
                        DigiPOS
                    </button>
                </h2>
            </div>
        </Link>
        <Link to="/billers-aggregation-platform">
            <div className="accordion-item mb-3">
                <h2 className="accordion-header" id="headingThree">
                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseThree" aria-expanded="false"
                            aria-controls="collapseThree">
                        Billers Aggregation Platform
                    </button>
                </h2>
            </div>
        </Link>
        <Link to="/finpay">
            <div className="accordion-item mb-3">
                <h2 className="accordion-header" id="headingThree">
                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseThree" aria-expanded="false"
                            aria-controls="collapseThree">
                        FinPay
                    </button>
                </h2>
            </div>
        </Link>
        <Link to="/payment-link">
            <div className="accordion-item mb-3">
                <h2 className="accordion-header" id="headingThree">
                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseThree" aria-expanded="false"
                            aria-controls="collapseThree">
                        Payment Link
                    </button>
                </h2>
            </div>
        </Link>
        <Link to="/instant-payment">
            <div className="accordion-item mb-3">
                <h2 className="accordion-header" id="headingThree">
                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseThree" aria-expanded="false"
                            aria-controls="collapseThree">
                        CoralPay Instant Payment
                    </button>
                </h2>
            </div>
        </Link>

    </div>
}

export default ProductLinks