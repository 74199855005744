import {
  Box,
  Button,
  Grid2,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import {
  darcula,
  a11yDark,
} from "react-syntax-highlighter/dist/esm/styles/prism";
import DotNetContent from "./dotnet/DotNetContent";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import { scrollToTop } from "../../../components/helper";
// import JavascriptContent from "./javascript/JavascriptContent";
// import Dotnetcontent from "./dotnet/Dotnetcontent";
// import JavaContent from "./java";

const FastChannelDocumentation = () => {
  const [javascript, setJavascript] = useState(false);
  const [dotnet, setDotnet] = useState(true);
  const [ceeSharp, setCeeSharp] = useState(false);
  const navigate = useNavigate();

  const apiSpec = useRef(null);
  const snippets = useRef(null);

  const scrollToSection = (ref) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleJavascriptContent = () => {
    setJavascript((prev) => !prev);
    setDotnet(false);
    setCeeSharp(false);
  };
  const handleDotnetContent = () => {
    setJavascript(false);
    setDotnet((prev) => !prev);
    setCeeSharp(false);
  };
  const handleCeeSharpContent = () => {
    setJavascript(false);
    setDotnet(false);
    setCeeSharp((prev) => !prev);
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  const scriptTag = `
  https://testdev.coralpay.com:5000/FastChannel/api/Authentication
  `;

  const authPayload = `
      {
      "Username": "SkaiKru",
      "Password": "#1ooGr@0ndar$"
    }
  `;
  const authResponse = `
{
  "responseHeader": {
    "responseCode": "00",
    "responseMessage": "Successful"
  },
  "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdG",
  "key": "2092ba6b-742b-4df1-af34-7def3bc004c1",
  "expiryDate": "2024-03-10T10:15:06.8791956+01:00"
}
  `;
  const singlePostUrl = `
  https://testdev.coralpay.com:5000/FastChannel/api/SinglePost
  `;

  const singlePostRequestPayload = `
{
  "traceId": "100992921107213",
  "signature": "81a657c554b654f54441a776c",
  "timestamp": "1602211123",
  "timeStamp": 1694660027,
  "transactionDetails": {
    "creditAccount": "1780004070",
    "creditAccountName": "Test Name",
    "creditBankCode": "999998",
    "narration": "Test narration",
    "amount": 3000.0
  }
}
`;
  const singlePostResponsePayload = `
{
  "responseHeader": {
    "responseCode": "00",
    "responseMessage": "Success"
  },
  "traceId": "1002222222222220008",
  "batchId": "20230914035415758080",
  "amountDebited": 3010.75,
  "transactionAmount": 3000,
  "transactionCharge": 10.0,
  "vat": 0.75
}
`;

  const batchPostUrl = `
https://testdev.coralpay.com:5000/FastChannel/api/BatchPost
`;

  const batchPostRequestPayload = `
{
  "traceId": "1009929211072",
  "totalAmount": 6000.0,
  "signature": "0928uymd0dkq445553222pl9",
  "timestamp": "1602211123",
  "transactionList": [
    {
      "creditAccount": "1234567890",
      "creditAccountName": "Test Name",
      "creditBankCode": "737",
      "narration": "Monthly payment",
      "amount": 2000.0,
      "itemTraceId": "10102929291"
    },
    {
      "creditAccount": "1234567891",
      "creditAccountName": "Test Name",
      "creditBankCode": "966",
      "narration": "Monthly payment",
      "amount": 3000.0,
      "itemTraceId": "10102929292"
    },
    {
      "creditAccount": "1234567898",
      "creditAccountName": "Test Name",
      "creditBankCode": "737",
      "narration": "Monthly payment",
      "amount": 1000.0,
      "itemTraceId": "10102929299"
    }
  ]
}

`;

  const batchPostResponsePayload = `
{
  "responseHeader": {
    "responseCode": "00",
    "responseMessage": "Successful"
  },
  "batchId": "BCT112234553",
  "traceId": "1009929211072",
  "amountDebited": 6060.0,
  "transactionAmount": 6000.0,
  "transactionCharge": 60.0
}
`;

  const nameEnquiryUrl = `
https://testdev.coralpay.com:5000/FastChannel/api/NameEnquiry
`;

  const nameEnquiryRequestPayload = `
{
  "traceId": "2024041134453533211",
  "signature": "1ac221676bc2a33837ac33baa22313",
  "timeStamp": 1689798324,
  "enquiryDetails": {
    "bankCode": "737",
    "accountNumber": "0114547784"
  }
}

`;

  const nameEnquiryResponsePayload = `
{
  "responseHeader": {
    "responseCode": "00",
    "responseMessage": "Successful"
  },
  "traceId": "2024041134453533211",
  "accountName": "Chinedu Musa Ayo",
  "accountNumber": "0114547784",
  "bankCode": "737"
}
`;

  const callBackUrl = `
https://testdev.coralpay.com:5000/FastChannel/api/BatchPost
`;

  const callBackRequestPayload = `
{
  "ResponseCode": "00",
  "ResponseMessage": "Successful",
  "CreditAccount": "1780004070",
  "CreditAccountName": "Nathan Sola",
  "CreditBankCode": "999998",
  "Narration": "Payment for cable",
  "Amount": 8000.0,
  "Charge": 10.0,
  "ItemBatchId": "202201160550030256707",
  "ItemTraceId": "10102929298",
  "TraceId": "1000000100003",
  "MerchantId": "MS1000001"
}

`;

  const callBackResponsePayload = `
{
  "responseCode": "00",
  "responseMessage": "Successful"
}
`;

  const invokeUrl = `
  https://testdev.coralpay.com/VergeTest/api/v1/Invokepayment
  `;
  const invokePaymentRequestPayload = `
    { 
      "RequestHeader": 
      { "MerchantId": "10990MTE0992", 
        "TimeStamp": "0123456789", 
        "Signature": "0928uymd0dkq445553222pl9"
      }, 
      "Customer": 
     { 
        "Email": "babavoss@see.com", 
        "Name": "Baba Voss", 
        "Phone": "002200302092" 
      }, 
      "Customization": 
      { 
      "LogoUrl": "http://sampleurl.com", 
       "Title": "Watermarks Limited", 
       "Description": "Service Payment"
      }, 
      "MetaData": 
        { 
          "Data1": "sample string 1", 
          "Data2": "sample string 2", 
          "Data3": "sample string 3" 
        }, 
         "TraceId": "9900990285", 
         "Amount": 2000.00, 
         "Currency": "NGN", 
         "FeeBearer": "M", 
         "ReturnUrl": "http://samplereturnurl.com/status" 
  
        }
  `;

  const invokePaymentResponsePayload = `{
      "ResponseHeader": {
      "ResponseCode": "00",
      "ResponseMessage": "Successful",
      "TimeStamp": 0123456789,
      "Signature": "120dksl3l3lld303e3k3mjmf36k6j3h"
      },
      "TransactionId": "029918891988381",
      "TraceId": "9900990285",
      "PayPageLink": "https://coralpay.ng/payment/00998833",
      "MetaData": {
      "Data1": "sample string 1",
      "Data2": "sample string 2",
      "Data3": "sample string 3"
      
      }
  }
      `;

  const transactionQueryUrl = `
      https://testdev.coralpay.com:5000/FastChannel/api/TransactionQuery
      `;

  const transactionQueryRequestPayload = `
{
  "traceId": "20240411344535332122",
  "itemTraceId": "10102929292",
  "signature": "c2aa3461676bc2a33837ac33baa22313",
  "timeStamp": 165478546
}
      `;
  const transactionQueryResponsePayload = `
{
  "responseHeader": {
    "merchantId": "string",
    "batchId": " BCT112234543",
    "responseCode": "00",
    "responseMessage": "Successful",
    "amountDebited": 6060.0,
    "traceId": "20240411344535332122",
    "transactionAmount": 6000.0,
    "transactionCharge": 60.0
  },
  "transactionList": [
    {
      "responseCode": "00",
      "responseMessage": "Successful",
      "itemTraceId": "ITM202400111233",
      "creditAccount": "1001245789",
      "creditAccountName": "Dayo Smith",
      "creditBankCode": "string",
      "narration": "Weekly Payment",
      "amount": 6000.0,
      "charge": 60.0
    }
  ]
}
      `;

  return (
    <div>
      <div className="developer-wrapper">
        <div className="developer-wrapper-overlay">
          <div className="developer-wrapper-overlay-inner">
            <h3>FAST CHANNEL</h3>
            <p>
              FastChannel is designed to facilitate single and batch transfer of
              funds from a designated account to other account(s) in a very fast
              and seamless approach. The service also gets the status of the
              posted transactions.
            </p>
            <br />
            <br />
            {/*<div style={{display:'flex'}} >*/}
            {/*    <Button message="API Dashboard" bgColor="#7B3F63" bgText="#FFFFFF" />*/}
            {/*    <Button  message="Download Document" bgColor="#EFEFEF" bgText="#3A3A3A"/>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
      <div className="doc-div-parent-doc">
        <div
          className="docNav"
          style={{ paddingTop: "2rem", position: "sticky", top: "0" }}
        >
          {/* <h3>Documentation</h3> */}
          <Box sx={{ paddingBottom: "2rem" }}>
            <Button
              sx={{
                backgroundColor: "#083857",
                fontWeight: "550",
                color: "#fff",
              }}
              onClick={() => navigate("/developer#services")}
            >
              BACK TO DEVELOPER'S HOMEPAGE
            </Button>
          </Box>
          <nav>
            <List
              sx={{
                border: "1px solid #083857",
                borderRadius: "5px",
              }}
            >
              <ListItem disablePadding>
                {/* <a href="#apiSpec"> */}
                  <ListItemButton onClick={() => scrollToSection(apiSpec)}>
                    <ListItemIcon>{/* <InboxIcon /> */}</ListItemIcon>
                    <ListItemText primary="API Specifications" />
                  </ListItemButton>
                {/* </a> */}
              </ListItem>
              <ListItem disablePadding>
                {/* <a href="#snippets"> */}
                  <ListItemButton onClick={() => scrollToSection(snippets)}>
                    <ListItemIcon>{/* <DraftsIcon /> */}</ListItemIcon>
                    <ListItemText primary="Libraries & Code Snippets" />
                  </ListItemButton>
                {/* </a> */}
              </ListItem>
            </List>
          </nav>
          {/* <ul>
              <a href={"/developer/docs/cgateussd"}>
                {" "}
                <li>C’Gate USSD</li>
              </a>
              <a href={"/developer/docs/pay-with-bank-transfer"}>
                {" "}
                <li>Pay With Bank Transfer</li>
              </a>
              <a href={"/developer/docs/payment-gateway"}>
                {" "}
                <li>CoralPay Payment Gateway</li>
              </a>
              <a href={"/developer/docs/vas"}>
                {" "}
                <li>CoralPay VAS</li>
              </a>
              <a href={"/developer/docs/card-api"}>
                {" "}
                <li>CoralPay Card APIs</li>
              </a>
            </ul> */}
        </div>

        <div className="doc-div-doc" ref={apiSpec}>
          <React.Fragment id="apiSpec">
            <h3>Introduction</h3>

            <p className="mt-2 small">
              This document is a technical and integration document for the
              FastChannel Service. It provides guidance, and is a template
              material which is intended to assist the relevant stakeholders to
              easily integrate seamlessly.
            </p>
            <p className="mt-2 small">
              FastChannel is designed to facilitate single and batch transfer of
              funds from a designated account to other account(s) in a very fast
              and seamless approach. The service also gets the status of the
              posted transactions.
            </p>
            <h3>API Specification</h3>
            <p>
              Merchants are required to be registered on CoralPay’s platform and
              the credentials to call the API operations will be shared.
            </p>
            <p>
              FastChannel uses the JWT authentication method to authenticate
              users calling the API, and a Key which is used to generate the
              Signature value for every service call.
            </p>

            <h3>Authentication</h3>

            <p>
              Merchants are required to call the Authentication operation using
              the credentials provided by CORALPAY during registration. The
              Response from this operation includes the Token for bearer token
              authentication for other operations and a Key to calculate the
              signature to maintain the data integrity.
            </p>
            <p>
              Note that the Key changes every time this operation is called, so
              we advise this operation to be called less often
            </p>

            <br />

            <table style={{ width: "910px" }}>
              <tr>
                <td>Url</td>
                <td>
                  <SyntaxHighlighter language="javascript" style={a11yDark}>
                    {scriptTag}
                  </SyntaxHighlighter>
                </td>
              </tr>
              <tr>
                <td>Verb</td>
                <td>Post</td>
              </tr>
              <tr>
                <td>Content Type</td>
                <td>Json</td>
              </tr>
              <tr>
                <td>Authentication</td>
                <td>Basic Username : Password</td>
              </tr>
              <tr>
                <td>Request Payload</td>
                <td>
                  {" "}
                  <SyntaxHighlighter language="javascript" style={a11yDark}>
                    {authPayload}
                  </SyntaxHighlighter>
                </td>
              </tr>
              <tr>
                <td>Response Payload</td>
                <td>
                  <SyntaxHighlighter language="javascript" style={a11yDark}>
                    {authResponse}
                  </SyntaxHighlighter>
                </td>
              </tr>
            </table>
            <br />
            <span>
              <b>Properties Description</b>
            </span>
            <table style={{ width: "910px" }}>
              <tr>
                <th>Properties</th>
                <th>Description</th>
                <th>Optional/Mandatory</th>
              </tr>
              <tr>
                <td>Username</td>
                <td>
                  This is a unique username assigned to the merchant. Datatype:
                  String.
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <td>Password</td>
                <td>
                  The is the Corresponding password to the Username. Datatype:
                  String
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <th>ResponseHeader</th>
              </tr>
              <tr>
                <td>ResponseCode</td>
                <td>This is the status code for the transaction.</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>ResponseMessage</td>
                <td>This is the status code message for the transaction</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>Token</td>
                <td>
                  The is the generated token used for the bearer token
                  authentication type. Datatype: String
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <td>Key</td>
                <td>
                  This is used to generate the signature value on operation
                  call. Datatype: String
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <td>ExpiryDate</td>
                <td>
                  This is the expiry date of the token generated. Datatype:
                  String
                </td>
                <td>YES</td>
              </tr>
            </table>
            <br />

            <h3>Single Post</h3>
            <p>
              This is the operation to push a single credit. This is an instant
              payment, and the source bank account will be debited immediately,
              and the accounts passed in the request will be credited
              subsequently in real-time.
            </p>
            <p>
              <b>
                Note: No callback will be sent for this operation. Please
                requery if the response code is not definite.
              </b>
            </p>
            <table style={{ width: "910px" }}>
              <tr>
                <td>Url</td>
                <td>
                  <SyntaxHighlighter language="javascript" style={a11yDark}>
                    {singlePostUrl}
                  </SyntaxHighlighter>
                </td>
              </tr>
              <tr>
                <td>Verb</td>
                <td>Post</td>
              </tr>
              <tr>
                <td>Content Type</td>
                <td>Json</td>
              </tr>
              <tr>
                <td>Authentication</td>
                <td>
                  Bearer Token Authentication type. See <a href="#d"> here</a>
                </td>
              </tr>
              <tr>
                <td>Request Payload</td>
                <td>
                  {" "}
                  <SyntaxHighlighter language="javascript" style={a11yDark}>
                    {singlePostRequestPayload}
                  </SyntaxHighlighter>
                </td>
              </tr>
              <tr>
                <td>Response Payload</td>
                <td>
                  <SyntaxHighlighter language="javascript" style={a11yDark}>
                    {singlePostResponsePayload}
                  </SyntaxHighlighter>
                </td>
              </tr>
            </table>
            <br />
            <span>
              <b>Properties Description</b>
            </span>
            <table style={{ width: "910px" }}>
              <tr>
                <th>Properties</th>
                <th>Description</th>
                <th>Type</th>
                <th>Mandatory</th>
              </tr>
              <tr>
                <th>RequestHeader</th>
              </tr>
              <tr>
                <td>TraceId</td>
                <td>
                  This is the unique identifier of the transaction from the
                  merchant’s end.
                </td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>TimeStamp</td>
                <td>Timestamp: UnixTimeSeconds Datatype: long</td>
                <td>Long</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>Signature</td>
                <td>
                  This value is generated to maintain the integrity of the
                  payload. Note that a new Timestamp must be generated to
                  calculate the signature every API call. Sha256(MerchantId
                  +TraceId+ TimeStamp + Key).
                </td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <th>TransactionDetails</th>
              </tr>
              <tr>
                <td>CreditAccount</td>
                <td>This is the account number to be credited</td>
                <td>String</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>CreditAccountName</td>
                <td>This is the name of the account to be credited</td>
                <td>String</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>CreditBankCode</td>
                <td>This is the bankcode of the account to be credited</td>
                <td>String</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>Narration</td>
                <td>
                  This is a description of the payment. Narration length should
                  be maximum of 80 characters.
                </td>
                <td>String</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>Amount</td>
                <td>This is the amount the account is to be credited.</td>
                <td>Decimal</td>
                <td>NO</td>
              </tr>

              <tr>
                <th>Responseheader</th>
              </tr>
              <tr>
                <td>ResponseCode</td>
                <td>
                  This is the status code for the transaction. "00" means
                  beneficiary credit is successful.
                </td>
                <td>Object</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>ResponseMessage</td>
                <td>This is the status code message for the transaction.</td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>BatchId</td>
                <td>
                  This is the unique identifier of the batch of transactions,
                  generated by CoralPay.
                </td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>AmountDebited</td>
                <td>
                  This is the total amount the source account is debited. It
                  includes the transaction amount and the transaction charge
                </td>
                <td>Decimal</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>TransactionAmount</td>
                <td>
                  {" "}
                  This is the total amount credited to the destination accounts
                  passed in the request.
                </td>
                <td>Decimal</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>TransactionCharge</td>
                <td>
                  This is the total amount of charges for the batch payment.
                </td>
                <td>Decimal</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>Vat</td>
                <td>This is the charge for VAT.</td>
                <td>Decimal</td>
                <td>YES</td>
              </tr>
            </table>
            <br />
            <h3>Batch Post</h3>
            <p>
              This is the operation to push credit to multiple bank accounts.
              The source bank account will be debited immediately, and the
              accounts passed in the request will be credited subsequently near
              real-time.
            </p>
            <table style={{ width: "910px" }}>
              <tr>
                <td>Url</td>
                <td>
                  <SyntaxHighlighter language="javascript" style={a11yDark}>
                    {batchPostUrl}
                  </SyntaxHighlighter>
                </td>
              </tr>
              <tr>
                <td>Verb</td>
                <td>POST</td>
              </tr>
              <tr>
                <td>Content Type</td>
                <td>application/json</td>
              </tr>
              <tr>
                <td>Authentication</td>
                <td>
                  Bearer Token Authentication type. See <a href="#d"> here</a>
                </td>
              </tr>
              <tr>
                <td>Request Payload</td>
                <td>
                  {" "}
                  <SyntaxHighlighter language="javascript" style={a11yDark}>
                    {batchPostRequestPayload}
                  </SyntaxHighlighter>
                </td>
              </tr>
              <tr>
                <td>Response Payload</td>
                <td>
                  <SyntaxHighlighter language="javascript" style={a11yDark}>
                    {batchPostResponsePayload}
                  </SyntaxHighlighter>
                </td>
              </tr>
            </table>
            <br />
            <span>
              <b>Properties Description</b>
            </span>
            <table style={{ width: "910px" }}>
              <tr>
                <th>Properties</th>
                <th>Description</th>
                <th>Type</th>
                <th>Mandatory</th>
              </tr>
              <tr>
                <th>RequestHeader</th>
              </tr>
              <tr>
                <td>TraceId</td>
                <td>
                  This is the unique identifier of the transaction from the
                  merchant’s end.
                </td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>TotalAmount</td>
                <td>
                  This is the total amount to be debited from the source
                  account.
                </td>
                <td>Decimal</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>TimeStamp</td>
                <td>Timestamp: UnixTimeSeconds Datatype: long</td>
                <td>Long</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>Signature</td>
                <td>
                  This value is generated to maintain the integrity of the
                  payload. Note that a new Timestamp must be generated to
                  calculate the signature every API call. Sha256(MerchantId
                  +TraceId+ TimeStamp + Key).
                </td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <th>TransactionList</th>
              </tr>
              <tr>
                <td>CreditAccount</td>
                <td>This is the account number to be credited</td>
                <td>String</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>CreditAccountName</td>
                <td>This is the name of the account to be credited</td>
                <td>String</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>CreditBankCode</td>
                <td>This is the bankcode of the account to be credited</td>
                <td>String</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>Narration</td>
                <td>
                  This is a description of the payment. Narration length should
                  be maximum of 80 characters.
                </td>
                <td>String</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>Amount</td>
                <td>This is the amount the account is to be credited.</td>
                <td>Decimal</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>ItemTraceId</td>
                <td>
                  This is the unique identifier of the credit request generated
                  by the merchant. It is unique for each payment request in a
                  batch.
                </td>
                <td>String</td>
                <td>YES</td>
              </tr>

              <tr>
                <th>Responseheader</th>
              </tr>
              <tr>
                <td>ResponseCode</td>
                <td>
                  This is the status code for the transaction. "00" means
                  beneficiary credit is successful.
                </td>
                <td>Object</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>ResponseMessage</td>
                <td>This is the status code message for the transaction.</td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>BatchId</td>
                <td>
                  This is the unique identifier of the batch of transactions,
                  generated by CoralPay.
                </td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>AmountDebited</td>
                <td>
                  This is the total amount the source account is debited. It
                  includes the transaction amount and the transaction charge
                </td>
                <td>Decimal</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>TransactionAmount</td>
                <td>
                  {" "}
                  This is the total amount credited to the destination accounts
                  passed in the request.
                </td>
                <td>Decimal</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>TransactionCharge</td>
                <td>
                  This is the total amount of charges for the batch payment.
                </td>
                <td>Decimal</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>Vat</td>
                <td>This is the charge for VAT.</td>
                <td>Decimal</td>
                <td>YES</td>
              </tr>
            </table>
            <br />
            <h3>Name Enquiry</h3>
            <p>
              This is the operation to push credit to multiple bank accounts.
              The source bank account will be debited immediately, and the
              accounts passed in the request will be credited subsequently in
              real-time.
            </p>
            <table style={{ width: "910px" }}>
              <tr>
                <td>Url</td>
                <td>
                  <SyntaxHighlighter language="javascript" style={a11yDark}>
                    {nameEnquiryUrl}
                  </SyntaxHighlighter>
                </td>
              </tr>
              <tr>
                <td>Verb</td>
                <td>POST</td>
              </tr>
              <tr>
                <td>Content Type</td>
                <td>application/json</td>
              </tr>
              <tr>
                <td>Authentication</td>
                <td>
                  Bearer Token Authentication type. See <a href="#d"> here</a>
                </td>
              </tr>
              <tr>
                <td>Request Payload</td>
                <td>
                  {" "}
                  <SyntaxHighlighter language="javascript" style={a11yDark}>
                    {nameEnquiryRequestPayload}
                  </SyntaxHighlighter>
                </td>
              </tr>
              <tr>
                <td>Response Payload</td>
                <td>
                  <SyntaxHighlighter language="javascript" style={a11yDark}>
                    {nameEnquiryResponsePayload}
                  </SyntaxHighlighter>
                </td>
              </tr>
            </table>
            <br />
            <span>
              <b>Properties Description</b>
            </span>
            <table style={{ width: "910px" }}>
              <tr>
                <th>Properties</th>
                <th>Description</th>
                <th>Type</th>
                <th>Mandatory</th>
              </tr>
              <tr>
                <td>TraceId</td>
                <td>
                  This is the unique identifier of the transaction from the
                  merchant’s end.
                </td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>TimeStamp</td>
                <td>Timestamp: UnixTimeSeconds Datatype: long</td>
                <td>Long</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>Signature</td>
                <td>
                  This value is generated to maintain the integrity of the
                  payload. Note that a new Timestamp must be generated to
                  calculate the signature every API call. Sha256(MerchantId
                  +TraceId+ TimeStamp + Key).
                </td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <th>EnquiryDetails</th>
              </tr>
              <tr>
                <td>BankCode</td>
                <td>This is the bank code of the bank account</td>
                <td>String</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>AccountNumber</td>
                <td>This is the account number</td>
                <td>String</td>
                <td>NO</td>
              </tr>
              <tr>
                <th>Responseheader</th>
              </tr>
              <tr>
                <td>ResponseCode</td>
                <td>
                  This is the status code for the request. "00" means the
                  request was successful.
                </td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>ResponseMessage</td>
                <td>This is the status code message for the request.</td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>AccountName</td>
                <td>
                  This is the name tied to the account number in the request.
                </td>
                <td>String</td>
                <td>YES</td>
              </tr>
            </table>
            <br />
            <h3>Callback</h3>
            <p>
              This operation is to be created by the merchant to receive real
              time payment notification; we expect to get Http response 200 upon
              posting the notification. There will be a repush of the
              notification 6 times within an hour until http response 200 is
              received.
            </p>
            <table style={{ width: "910px" }}>
              <tr>
                <td>Url</td>
                <td>To be provided by the merchant</td>
              </tr>
              <tr>
                <td>Verb</td>
                <td>POST</td>
              </tr>
              <tr>
                <td>Content Type</td>
                <td>application/json</td>
              </tr>
              <tr>
                <td>Authentication</td>
                <td>
                  Basic Auth: using username and password provided by CORALPAY
                </td>
              </tr>
              <tr>
                <td>Request Payload</td>
                <td>
                  {" "}
                  <SyntaxHighlighter language="javascript" style={a11yDark}>
                    {callBackRequestPayload}
                  </SyntaxHighlighter>
                </td>
              </tr>
              <tr>
                <td>Response Payload</td>
                <td>
                  <SyntaxHighlighter language="javascript" style={a11yDark}>
                    {callBackResponsePayload}
                  </SyntaxHighlighter>
                </td>
              </tr>
            </table>
            <br />
            <h3>Transaction Query</h3>
            <p>
              This operation can be called to get details of transactions of not
              more than 3 months.
            </p>
            <table style={{ width: "910px" }}>
              <tr>
                <td>Url</td>
                <td>
                  <SyntaxHighlighter language="javascript" style={a11yDark}>
                    {transactionQueryUrl}
                  </SyntaxHighlighter>
                </td>
              </tr>
              <tr>
                <td>Verb</td>
                <td>POST</td>
              </tr>
              <tr>
                <td>Content Type</td>
                <td>application/json</td>
              </tr>
              <tr>
                <td>Authentication</td>
                <td>Bearer Token Authentication type.</td>
              </tr>
              <tr>
                <td>Request Payload</td>
                <td>
                  {" "}
                  <SyntaxHighlighter language="javascript" style={a11yDark}>
                    {transactionQueryRequestPayload}
                  </SyntaxHighlighter>
                </td>
              </tr>
              <tr>
                <td>Response Payload</td>
                <td>
                  <SyntaxHighlighter language="javascript" style={a11yDark}>
                    {transactionQueryResponsePayload}
                  </SyntaxHighlighter>
                </td>
              </tr>
            </table>
            <br />
            <span>
              <b>Properties Description</b>
            </span>
            <table style={{ width: "910px" }}>
              <tr>
                <th>Properties</th>
                <th>Description</th>
                <th>Type</th>
                <th>Mandatory</th>
              </tr>
              <tr>
                <td>TraceId</td>
                <td>
                  This is the unique identifier of the transaction from the
                  merchant’s end.
                </td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>ItemTraceId</td>
                <td>
                  This is used to query the transaction of a particular item in
                  a batch.
                </td>
                <td>String</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>TimeStamp</td>
                <td>Timestamp: UnixTimeSeconds Datatype: long</td>
                <td>Long</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>Signature</td>
                <td>
                  This value is generated to maintain the integrity of the
                  payload. Note that a new Timestamp must be generated to
                  calculate the signature every API call. Sha256(MerchantId
                  +TraceId+ TimeStamp + Key).
                </td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <th>Responseheader</th>
              </tr>
              <tr>
                <td>ResponseCode</td>
                <td>This is the status code for the transaction.</td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>ResponseMessage</td>
                <td>This is the status code message for the transaction.</td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>BatchId</td>
                <td>
                  This is the unique identifier of the batch of transactions,
                  generated by CoralPay.
                </td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>MerchantId</td>
                <td></td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>AmountDebited</td>
                <td>
                  This is the total amount the source account is debited. It
                  includes the transaction amount and the transaction charge
                </td>
                <td>Decimal</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>TransactionAmount</td>
                <td>
                  This is the total amount credited to the destination accounts
                  passed in the request.
                </td>
                <td>Decimal</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>TransactionCharge</td>
                <td>
                  This is the total amount of charges for the batch payment.
                </td>
                <td>Decimal</td>
                <td>YES</td>
              </tr>
              <tr>
                <th>TransactionList</th>
              </tr>
              <tr>
                <td>CreditAccount</td>
                <td>This is the account number to be credited</td>
                <td>String</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>CreditAccountName</td>
                <td>This is the name of the account to be credited</td>
                <td>String</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>CreditBankCode</td>
                <td>This is the bankcode of the account to be credited</td>
                <td>String</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>Narration</td>
                <td>
                  This is a description of the payment. Narration length should
                  be maximum of 80 characters.
                </td>
                <td>String</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>Amount</td>
                <td>This is the amount the account is to be credited.</td>
                <td>Decimal</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>Charge</td>
                <td>This is the charge for the credit request in the batch.</td>
                <td>Decimal</td>
                <td>YES</td>
              </tr>
            </table>

            <br />
            <span>
              <b>Error Codes</b>
            </span>
            <table style={{ width: "910px" }}>
              <tr>
                <td>Codes</td>
                <td>Description</td>
              </tr>
              <tr>
                <td>00</td>
                <td>Successful</td>
              </tr>
              <tr>
                <td>01</td>
                <td>
                  Failed Transaction: Description in the ResponseMessage, e.g.
                  Insufficient fund,
                </td>
              </tr>
              <tr>
                <td>03</td>
                <td>Invalid Sender</td>
              </tr>
              <tr>
                <td>05</td>
                <td>Do not honor</td>
              </tr>
              <tr>
                <td>06</td>
                <td>Invalid or missing parameter</td>
              </tr>
              <tr>
                <td>07</td>
                <td>Invalid account</td>
              </tr>
              <tr>
                <td>08</td>
                <td>Account name mismatch</td>
              </tr>
              <tr>
                <td>09</td>
                <td>Pending transaction</td>
              </tr>
              <tr>
                <td>12</td>
                <td>Invalid transaction</td>
              </tr>
              <tr>
                <td>13</td>
                <td>Invalid amount</td>
              </tr>
              <tr>
                <td>14</td>
                <td>Invalid batch number</td>
              </tr>
              <tr>
                <td>15</td>
                <td>Invalid session or record ID</td>
              </tr>
              <tr>
                <td>16</td>
                <td>Transaction is being processed</td>
              </tr>
              <tr>
                <td>17</td>
                <td>Invalid Channel</td>
              </tr>
              <tr>
                <td>18</td>
                <td>Wrong method call</td>
              </tr>
              <tr>
                <td>21</td>
                <td>No action taken</td>
              </tr>
              <tr>
                <td>22</td>
                <td>No definite response yet</td>
              </tr>
              <tr>
                <td>25</td>
                <td>Unable to locate record</td>
              </tr>
              <tr>
                <td>26</td>
                <td>Duplicate record</td>
              </tr>
              <tr>
                <td>30</td>
                <td>Format error</td>
              </tr>
              <tr>
                <td>34</td>
                <td>Suspected fraud</td>
              </tr>
              <tr>
                <td>35</td>
                <td>Contact sending bank</td>
              </tr>
              <tr>
                <td>51</td>
                <td>Insufficient fund</td>
              </tr>
              <tr>
                <td>57</td>
                <td>Transaction not permitted to sender</td>
              </tr>
              <tr>
                <td>58</td>
                <td>Transaction not permitted on channel</td>
              </tr>
              <tr>
                <td>61</td>
                <td>Transfer limit Exceeded</td>
              </tr>
              <tr>
                <td>63</td>
                <td>Security violation</td>
              </tr>
              <tr>
                <td>65</td>
                <td>Exceeds withdrawal frequency</td>
              </tr>
              <tr>
                <td>68</td>
                <td>Response received too late</td>
              </tr>
              <tr>
                <td>69</td>
                <td>Unsuccessful Account/Amount block</td>
              </tr>
              <tr>
                <td>70</td>
                <td>Unsuccessful Account/Amount unblock</td>
              </tr>
              <tr>
                <td>71</td>
                <td>Empty Mandate Reference Number</td>
              </tr>
              <tr>
                <td>91</td>
                <td>Beneficiary Bank not available</td>
              </tr>
              <tr>
                <td>92</td>
                <td>Routing error</td>
              </tr>
              <tr>
                <td>94</td>
                <td>Duplicate transaction</td>
              </tr>
              <tr>
                <td>96</td>
                <td>General Error or System Challenge</td>
              </tr>
              <tr>
                <td>97</td>
                <td>Timeout waiting for response from destination</td>
              </tr>
              <tr>
                <td>A1</td>
                <td>Client disabled</td>
              </tr>
              <tr>
                <td>A2</td>
                <td>Not found</td>
              </tr>
              <tr>
                <td>A3</td>
                <td>Expired</td>
              </tr>
              <tr>
                <td>A4</td>
                <td>Empty value</td>
              </tr>
              <tr>
                <td>A5</td>
                <td>Invalid value</td>
              </tr>
              <tr>
                <td>A6</td>
                <td>Invalid data provided</td>
              </tr>
              <tr>
                <td>A7</td>
                <td>Remote IP not permitted</td>
              </tr>
              <tr>
                <td>A8</td>
                <td>Invalid client ID</td>
              </tr>
              <tr>
                <td>A9</td>
                <td>Unable to process request, please try again</td>
              </tr>
              <tr>
                <td>B0</td>
                <td>Mandate Bank mismatch</td>
              </tr>
            </table>
          </React.Fragment>

          <br />

          <hr />

          <Box sx={{ width: "100%", paddingTop: "2rem" }} ref={snippets}>
            <Grid2
              container
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Grid2>
                <Typography
                  sx={{
                    color: "#7f3d64",
                    fontSize: "1.2em",
                    fontWeight: "800",
                  }}
                >
                  Libraries & Code Snippets
                </Typography>
              </Grid2>
              <Grid2 sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button onClick={scrollToTop} sx={{ color: "#7f3d64" }}>
                  Back to the top{" "}
                  <KeyboardDoubleArrowUpIcon
                    sx={{ fontSize: 30, color: "#7f3d64" }}
                  />
                </Button>
              </Grid2>
            </Grid2>

            <Grid2
              container
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "1.5rem 0 1.5rem 0",
              }}
            >
              {/* <div style={{ marginRight: "1rem" }}>
                <button
                  style={{
                    border: javascript ? "none" : "1px solid #242424",
                    borderRadius: "10px",
                    fontWeight: 550,
                    padding: "1rem",
                    color: javascript ? "#fff" : "#242424",
                    backgroundColor: javascript ? "#242424" : "#fff",
                  }}
                  onClick={handleJavascriptContent}
                >
                  JAVASCRIPT
                </button>
              </div> */}
              <div style={{ marginRight: "1rem" }}>
                <button
                  style={{
                    border: dotnet ? "none" : "1px solid #242424",
                    borderRadius: "10px",
                    fontWeight: 550,
                    padding: "1rem",
                    color: dotnet ? "#fff" : "#242424",
                    backgroundColor: dotnet ? "#242424" : "#fff",
                  }}
                  onClick={handleDotnetContent}
                >
                  .NET
                </button>
              </div>
              {/* <div>
                <button
                  style={{
                    border: ceeSharp ? "none" : "1px solid #242424",
                    borderRadius: "10px",
                    fontWeight: 550,
                    padding: "1rem",
                    color: ceeSharp ? "#fff" : "#242424",
                    backgroundColor: ceeSharp ? "#242424" : "#fff",
                  }}
                  onClick={handleCeeSharpContent}
                >
                  JAVA
                </button>
              </div> */}
            </Grid2>

            <div>
              {/* {javascript && <JavascriptContent />} */}
              {dotnet && <DotNetContent />}
              {/* {ceeSharp && <JavaContent />} */}
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default FastChannelDocumentation;
