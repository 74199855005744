import React from "react";
import footer1 from "../assets/footer1.svg"
import footer2 from "../assets/footer2.svg"
import phone from "../assets/icons/telephone.svg"
import emailPlain from "../assets/icons/email-plain.svg"
import insta from "../assets/insta.svg"
import you from "../assets/youtube.svg"
import twi from "../assets/twi.svg"
import face from "../assets/face.svg"
import linked from "../assets/linkedin.svg"
import footerIcon from "../assets/footerIcon.jpg"


const Footer = () => {
    return(
        <footer className="footer sm:p-6 sm:justify-center sm:flex">
            <div className="footer-inner">
                <div className="md:flex md:justify-between sm:justify-center">
                   <div className="footer-inner-item" style={{padding:10}}>
                        <h2 className="text-sm font-semibold text-gray-400  footer-head" style={{marginBottom:20}}>CoralPay Technology (Nigeria) Limited</h2>
                       <h3 className="mb-6" style={{fontSize:16}}>Licensed by the Central Bank of Nigeria.</h3>
                        <span className="mb-6">
                            Our vision is to be the customer’s<br /> first
                            choice provider of payments<br /> and
                            payment-centric services and solutions
                            in Nigeria and the West African sub-region.
                        </span>
                        <div className="footer-social">
                            <a href="https://www.linkedin.com/company/18968866/admin/" target="_blank" rel="noreferrer" > <img src={linked} alt="socialM" className="social-link"/></a>
                         <a href="https://www.instagram.com/coralpaytech/" className="social-link " target="_blank" rel="noreferrer" > <img src={insta} alt="socialM"/></a>
                          <a href="https://www.youtube.com/watch?v=fQGC2grb2PM" className="social-link" target="_blank" rel="noreferrer" > <img src={you} alt="socialM"/></a>
                         <a href="https://twitter.com/CoralPayTech?t=kQ1bA2C_XJ4wkjHhOLLN8g&s=09" className="social-link" target="_blank" rel="noreferrer" > <img src={twi} alt="socialM"/></a>
                          <a href="https://web.facebook.com/CoralPay/?_rdc=1&_rdr" className="social-link" target="_blank" rel="noreferrer" > <img src={face} alt="socialM"/></a>
                        </div>
                </div>
                    <div className="footer-inner-item" style={{padding:10}}>
                        <h2 className="mb-6 text-sm font-semibold text-gray-400  footer-head" style={{marginBottom:40}}>Quick Links</h2>
                        <ul className="text-gray-300 footer-paragraph">
                            <li className="mb-4">
                                <a href="/about-us/" className="hover:underline">About us</a>
                            </li>
                            <li className="mb-4">
                                <a href="/developer/" className="hover:underline">For Developers</a>
                            </li>
                            <li className="mb-4">
                                <a href="/frequently-asked-questions/" className="hover:underline">FAQ's</a>
                            </li>
                            <li className="mb-4">
                                <a href="/contact-us/" className="hover:underline">Contact Us</a>
                            </li>
                        </ul>
                    </div>
                    <div className="footer-inner-item" style={{padding:10}}>
                        <h2 className="mb-6 text-sm font-semibold text-gray-400  footer-head" style={{marginBottom:40}}>Legal</h2>
                        <ul className="text-gray-300 footer-paragraph">
                            <li className="mb-4">
                                <a href="/privacy-statement/" className="hover:underline">Privacy Statement</a>
                            </li>
                            <li className="mb-4">
                                <a href="/acceptable-use-policy/"className="hover:underline">Acceptable Use Policy</a>
                            </li>
                            <li className="mb-4">
                                <a href="/cookie-policy" className="hover:underline">Cookie Policy</a>
                            </li>
                            <li className="mb-4">
                                <a href="/terms-conditions/"className="hover:underline">Terms & Conditions</a>
                            </li>
                            <li className="mb-4">
                                <a href="/isms-policy-statement/" className="hover:underline">ISMS Policy Statement</a>
                            </li>
                            <li className="mb-4">
                                <a href="/itsms-policy-statement/" className="hover:underline">ITSMS Policy Statement</a>
                            </li>
                            <li className="mb-4">
                                <a href="https://blog.coralpay.com/wp-content/uploads/2022/10/CP_ISO-22301_5_Business-Continuity-Policy_V1.0.pdf" className="hover:underline">BCMS Policy Statement</a>
                            </li>
                            <li className="mb-4" style={{display:'flex'}}>
                                <img src={footerIcon}  alt="footer" className="footer-icon" />
                                <img src={footer1}  alt="footer"/>
                                <img src={footer2}  alt="footer"/>
                            </li>
                        </ul>
                    </div>
                    <div className="footer-inner-item" style={{padding:10}}>
                        <h2 className="mb-6 text-sm font-semibold text-gray-400  footer-head" style={{marginBottom:40}}>Contacts</h2>
                        <div>
                            
                             <div className="mb-3 email-footer">
                                 <img src={emailPlain} alt="email-plain"/>
                                 <p style={{paddingLeft:'1%'}} className="small">customersupport@coralpay.com</p>
                             </div>
                             <div className="mb-3 email-footer">
                                 <img src={phone} alt="mobile"/>
                                 <p style={{paddingLeft:'1%'}} className="small">+234(0)700-CORALPAY<br></br>(700-2672-5729)<br></br>(020-1888-7512)</p>
                             </div>
                        </div>
                    </div>
                </div>
            </div>
            
       </footer>

    )
}

export default Footer