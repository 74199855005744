import { Box } from "@mui/material";
import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import {
  darcula,
  a11yDark,
} from "react-syntax-highlighter/dist/esm/styles/prism";
import {
  authenticateCode,
  bankLinkCode,
  createStaticAccountFunction,
  initializeVergClass,
  invokePaymentFunction,
  staticTransactionQuery,
  transactionQueryFunction,
} from "./syntax";

const DotNetContent = () => {
  const newHeaderClass = {
    fontWeight: "600",
    fontSize: "1.2em",
    color: "#7F3D64",
  };
  const newHeaderClass2 = {
    fontWeight: "600",
    // fontSize: "1.2em",
    color: "#7F3D64",
  };
  return (
    <>
      <h3 style={newHeaderClass}>Prerequisites</h3>
      <ul>
        <li>1. .NET environment (e.g., .NET Core or .NET Framework).</li>
        <li>2. Access to BankLink API credentials. username, and password.</li>
        <li>3. Familiarity with HTTP requests and C#.</li>
      </ul>
      <div style={{ padding: "1rem 0 1rem 0" }}>
        <hr />
      </div>

      <div style={{ padding: "1rem 0 1rem 0" }}>
        <h3
          style={newHeaderClass}
        >
          1. Setting Up BankLink Services
        </h3>
      </div>
      <div style={{ padding: "1rem 0 1rem 0" }}>
        <h5
          style={newHeaderClass2}
        >
          1.1 Installation
        </h5>
        <p style={{ padding: "1rem 0 1rem 0" }}>
          The BankLink class provides the core functionality for interacting
          with the BankLink API.
        </p>

        <h5
          style={newHeaderClass2}
        >
          1.2 Initializing BankLink Services Class
        </h5>
        <p style={{ padding: "1rem 0 1rem 0" }}>
          You can initialize the BankLinkServices class by passing the base URL
          of the BankLink API and an authentication token.
        </p>
        <SyntaxHighlighter
          language="javascript"
          style={a11yDark}
          className="react-syntax-highlighter"
        >
          {initializeVergClass}
        </SyntaxHighlighter>
      </div>

      <h3
        style={newHeaderClass}
      >
        2. Authentication
      </h3>
      <br />
      <SyntaxHighlighter
        language="javascript"
        style={a11yDark}
        className="react-syntax-highlighter"
      >
        {authenticateCode}
      </SyntaxHighlighter>
      <br />
      <h3
        style={{
          fontWeight: "600",
          fontSize: "1.2em",
          color: "#7F3D64",
        }}
      >
        3. Get List Of Banks
      </h3>
      <br />
      <SyntaxHighlighter
        language="javascript"
        style={a11yDark}
        className="react-syntax-highlighter"
      >
        {bankLinkCode}
      </SyntaxHighlighter>
      <br />
      <h3
        style={{
          fontWeight: "600",
          fontSize: "1.2em",
          color: "#7F3D64",
        }}
      >
        4. Static Account Creation
      </h3>
      <br />
      <SyntaxHighlighter
        language="javascript"
        style={a11yDark}
        className="react-syntax-highlighter"
      >
        {createStaticAccountFunction}
      </SyntaxHighlighter>
      <br />
      <h3
        style={{
          fontWeight: "600",
          fontSize: "1.2em",
          color: "#7F3D64",
        }}
      >
        4. Invoke Payment
      </h3>
      <br />
      <SyntaxHighlighter
        language="javascript"
        style={a11yDark}
        className="react-syntax-highlighter"
      >
        {invokePaymentFunction}
      </SyntaxHighlighter>
      <br />
      <h3
        style={{
          fontWeight: "600",
          fontSize: "1.2em",
          color: "#7F3D64",
        }}
      >
        4. Transaction Query{" "}
      </h3>
      <br />
      <SyntaxHighlighter
        language="javascript"
        style={a11yDark}
        className="react-syntax-highlighter"
      >
        {transactionQueryFunction}
      </SyntaxHighlighter>
      <br />
      <h3
        style={{
          fontWeight: "600",
          fontSize: "1.2em",
          color: "#7F3D64",
        }}
      >
        4. Static Transaction Query{" "}
      </h3>
      <br />
      <SyntaxHighlighter
        language="javascript"
        style={a11yDark}
        className="react-syntax-highlighter"
      >
        {staticTransactionQuery}
      </SyntaxHighlighter>
    </>
  );
};

export default DotNetContent;
