import React, { useEffect, useState } from "react";
import "../App.css";
import contact from "../assets/contact-woman.png";
// import Button from "../components/Button";
import {
  Alert,
  Box,
  Button,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import contactimg from "../assets/contactimg.png";
import circle_dot2 from "../assets/circle_dot2.svg";
import { Formik, Form, useFormik } from "formik";
import axios from "axios";
import { SendEmail } from "../api";
import { scrollToTop } from "../components/helper";
import * as yup from "yup";

const ContactScreen = () => {
  const [data, setData] = useState({
    To: "customersupport@coralpay.com",
    ToName: "Customer support",
    From: "",
    FromName: "",
    Subject: "",
    Body: "",
    TermsAndCondition: false,
  });

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const [err, setErr] = useState({});
  const [msg, setMsg] = useState("");
  const [typeOfSeverity, setTypeOfSeverity] = useState("");

  const handleChange = (e) => {
    const { name, type, value, checked } = e.target;
    const updatedValue = type === "checkbox" ? checked : value;

    setData((prevData) => ({
      ...prevData,
      [name]: updatedValue,
    }));
  };

  const validationSchema = () => {
    const { From, FromName, Body, Subject, TermsAndCondition } = data;

    const error = {};

    const isValidEmail = (email) => {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    if (From === "") {
      error.From = "This field is required";
    } else if (!isValidEmail(From)) {
      error.From = "Please enter a valid email address";
    }
    if (FromName === "") {
      error.FromName = "This field is required";
    }
    if (Body === "") {
      error.Body = "This field is required";
    }
    if (Subject === "") {
      error.Subject = "This field is required";
    }
    if (!TermsAndCondition) {
      error.TermsAndCondition =
        "You must accept to CoralPay Terms and Condition before proceeding";
    }

    return error;
  };

  const doSubmit = async () => {
    if (data.TermsAndCondition) {
      const payload = {
        To: "customersupport@coralpay.com",
        ToName: "Customer support",
        From: data.From,
        FromName: data.FromName,
        Subject: data.Subject,
        Body: data.Body,
      };
      try {
        setLoading(true);
        const response = await SendEmail(payload);
        if (response.data.responseCode === "00") {
          setData({
            ...data,
            From: "",
            FromName: "",
            Subject: "",
            Body: "",
            TermsAndCondition: false,
          });
          // alert(response.data.responseMessage);
          setTypeOfSeverity("success");
          setOpen(true);
          setMsg("Your details has been successfully submitted!");
        } else {
          // alert(response.data.responseMessage);
          setOpen(true);
          setTypeOfSeverity("failed");
          setMsg(response.data.responseMessage);
        }
      } catch (err) {
        // alert(err.message);
        setTypeOfSeverity("error");
        setMsg(err.message);
      } finally {
        setLoading(false);
      }
    } else {
      alert(
        "You must accept to CoralPay Terms and Condition before proceeding"
      );
    }
  };

  const handleCheckErr = () => {
    const checkErr = validationSchema();
    setErr(checkErr);

    if (Object.values(checkErr).length > 0) return;

    setErr({});
    doSubmit();
  };

  //   function doSubmit(values) {
  //     if (isChecked) {
  //       setLoading(true);
  //       axios({
  //         method: "post",
  //         // url: 'https://testdev.coralpay.com/CoralMailService/api/SendEmail',
  //         url: "https://204.8.207.123:5000/MessageServices/api/sendemail",
  //         // headers:{
  //         //     "ApiKey":"maZXhN8sfK3Py0hmTaXKy3tZv"
  //         // },
  //         data: values,
  //       })
  //         .then((response) => {
  //           setLoading(false);
  //           let responseData = response.data;
  //           if (responseData.responseCode === "00") {
  //             alert(responseData.responseMessage);
  //           } else {
  //             alert(responseData.responseMessage);
  //           }
  //         })
  //         .catch((e) => {
  //           setLoading(false);
  //           alert(e.message);
  //         });
  //     } else {
  //       alert(
  //         "You must accept to CoralPay Terms and Condition before proceeding"
  //       );
  //     }
  //   }

  const [loading, setLoading] = React.useState(false);
  const [isChecked, setIsChecked] = React.useState(false);

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div>
      <img src={circle_dot2} className="circle_dot2" alt="#" />
      <div
        className="blog-header"
        style={{ backgroundImage: `url(${contact})` }}
      >
        <div className="blog-wrapper-overlay">
          <h3>
            We Are Glad to{" "}
            <span style={{ color: "#F7EF8A" }}>Hear From You</span>
          </h3>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <div>
        <h3 className="recentpost">Leave Us A Message</h3>
        {/* <Formik initialValues={data} onSubmit={(values) => doSubmit(values)}>
          {(props) => ( */}
        <form className="form-contact">
          <div>
            <Stack>
              <textarea
                className="subscribe-input-message"
                onChange={handleChange}
                // onBlur={handleBlur}
                value={data.Body}
                name={"Body"}
                style={{ border: err.Body ? "1px solid red" : "" }}
              />
              {err.Body && (
                <p
                  style={{
                    fontSize: "0.8em",
                    color: "red",
                    marginTop: "-2rem",
                  }}
                >
                  {err.Body}
                </p>
              )}
            </Stack>
          </div>
          <Box
            sx={{
              width: "100%",
              display: { lg: "flex", md: "flex", sm: "", xs: "" },
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: { lg: "50%", md: "50%", sm: "100%", xs: "100%" },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Stack sx={{ padding: "2rem" }}>
                {/* <input
                  type="text"
                  className="subscribe-input"
                  placeholder="Enter the Subject"
                  required
                  onChange={handleChange}
                  // onBlur={handleBlur}
                  value={data.Subject}
                  name="Subject"
                  style={{ width: "80%" }}
                /> */}
                <TextField
                  name="Subject"
                  type="text"
                  placeholder="Enter the Subject"
                  onChange={handleChange}
                  value={data.Subject}
                  error={err.Subject}
                />
                {err.Subject && (
                  <Typography
                    sx={{
                      fontSize: "0.8em",
                      color: "red",
                    }}
                  >
                    {err.Subject}
                  </Typography>
                )}
                <br />
                <TextField
                  name="FromName"
                  type="text"
                  placeholder="Enter Your Name"
                  onChange={handleChange}
                  value={data.FromName}
                  error={err.FromName}
                />
                {err.FromName && (
                  <Typography
                    style={{
                      fontSize: "0.8em",
                      color: "red",
                    }}
                  >
                    {err.FromName}
                  </Typography>
                )}
                <br />
                <TextField
                  name="From"
                  type="email"
                  placeholder="Enter Your Email Address"
                  onChange={handleChange}
                  value={data.From}
                  error={err.From}
                />
                {err.From && (
                  <Typography
                    style={{
                      fontSize: "0.8em",
                      color: "red",
                    }}
                  >
                    {err.From}
                  </Typography>
                )}
                <br />
                {/* <input
                  type="text"
                  className="subscribe-input"
                  placeholder="Enter Your Name"
                  required
                  onChange={handleChange}
                  // onBlur={handleBlur}
                  value={data.FromName}
                  name="FromName"
                  style={{ width: "80%" }}
                /> */}
                {/* <input
                  type="email"
                  className="subscribe-input"
                  placeholder="Enter Your Email Address"
                  required
                  onChange={handleChange}
                  // onBlur={props.handleBlur}
                  value={data.From}
                  name="From"
                  style={{ width: "80%" }}
                /> */}
                <div className="text-center" style={{ width: "100%" }}>
                  <p className="contact-content" style={{ width: "100%" }}>
                    {/* <input
                    type="checkbox"
                    onChange={handleChange}
                    name="TermsAndCondition"
                    value={data.TermsAndCondition}
                  />{" "} */}
                    <input
                      type="checkbox"
                      onChange={handleChange}
                      name="TermsAndCondition"
                      checked={data.TermsAndCondition} // Controlled via state
                    />
                    By checking this, you agree to our{" "}
                    <span style={{ color: "#7D3E64" }}>
                      <a href="/terms-conditions/"> Terms</a>
                    </span>{" "}
                    and{" "}
                    <span style={{ color: "#7D3E64" }}>
                      <a href="/privacy-statement/"> Privacy Policy</a>
                    </span>
                  </p>
                  {err.TermsAndCondition && (
                    <Typography
                      style={{
                        fontSize: "0.8em",
                        color: "#fff",
                        background: "#419BEF",
                        padding: "0.5rem 1rem 0.5rem 1rem",
                        borderRadius: "10px",
                        fontWeight: "550",
                      }}
                    >
                      {err.TermsAndCondition}
                    </Typography>
                  )}
                  <br />
                  <Button
                    sx={{
                      backgroundColor: "#7B3F63",
                      color: "#fff",
                      borderRadius: "15px",
                      width: "200px",
                    }}
                    onClick={handleCheckErr}
                  >
                    Submit
                  </Button>
                  {/* <Button message="Submit" bgColor="#7B3F63" loading={loading} /> */}
                </div>
              </Stack>
            </Box>
            <Box
              sx={{
                width: { lg: "50%", md: "50%", sm: "100%", xs: "100%" },
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img src={contactimg} alt="hello" width={500} height={500} />
            </Box>
          </Box>
        </form>
        {/* //   )}
        // </Formik> */}
      </div>
      {/*<div className="contact-us-woman">*/}
      {/*    <img src={contactimg} alt="hello" />*/}
      {/*</div>*/}
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity={typeOfSeverity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {msg}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ContactScreen;
