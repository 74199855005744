export const initializeVergClass = `
string baseUrl = ""https://testdev.coralpay.com:5000/GwApi"";  // Replace with actual Verge API base URL
string authToken = "your_auth_token";  // You will receive this after authentication

VergeServices vergeService = new VergeServices(baseUrl, authToken);
`;
export const authenticationRequestStructure = `
public class AuthenticationRequest
{
    public string username { get; set; }
    public string password { get; set; }
    public string terminalId { get; set; }
}

`;
export const authenticationResponseStructure = `
public class AuthenticationResponse
{
    public string token { get; set; }
    public string key { get; set; }
    public string status { get; set; }
}

`;

export const baseUrlText = `
const baseUrl = "https://testdev.coralpay.com:5000/GwApi"; //replace with the actual verge base URL
`;

export const authenticateCode = `
AuthenticationRequest authRequest = new AuthenticationRequest
{
    username = "your_username",
    password = "your_password",
    terminalId = "your_terminal_id"
};

AuthenticationResponse authResponse = await vergeService.Authentication(authRequest);

if (authResponse.status != "Failed")
{
    Console.WriteLine($"Authentication Token: {authResponse.token}");
}
else
{
    Console.WriteLine("Authentication failed");
}
`;

export const invokePaymentRequest = `
public class PaymentRequest
{
    public RequestHeader requestHeader { get; set; }
    public Customer customer { get; set; }
    public Customization customization { get; set; }
    public object metaData { get; set; }
    public string traceId { get; set; }
    public string productId { get; set; }
    public decimal amount { get; set; }
    public string currency { get; set; }
    public string feeBearer { get; set; }
    public string returnUrl { get; set; }
    public Settlement settlement { get; set; }
}

`;

export const invokePaymentFunction = `
// First, generate the signature and timestamp
SignatureResponse signatureResponse = vergeService.GetSignature(new SignatureRequest
{
    merchantId = "your_merchant_id",
    traceId = "unique_trace_id",
    key = "your_secret_key",
    shaType = SHA.SHA256
});

 
        // Create a new PaymentRequest object
        PaymentRequest paymentRequest = new PaymentRequest
        {
            requestHeader = new RequestHeader
            {
                merchantId = "123456",
                terminalId = "78910",
                timeStamp = DateTimeOffset.UtcNow.ToUnixTimeSeconds(),
                signature = "generated_signature"
            },
            customer = new Customer
            {
                email = "john.doe@example.com",
                name = "John Doe",
                phone = "+1234567890",
                tokenUserId = "TOKEN_USER_001"
            },
            customization = new Customization
            {
                logoUrl = "https://example.com/logo.png",
                title = "Last Kingdom Limited",
                description = "Uthred Chariot"
            },
            metaData = new
            {
                orderId = "ORD12345",
                description = "Payment for order ORD12345"
            },
            traceId = "TRC123456789",
            productId = "PROD001",
            amount = 150.75,
            currency = "NGN",
            feeBearer = "M",
            returnUrl = "https://example.com/payment/return",
            settlement = new Settlement
            {
                settlementType = "DYNAMIC",
                splitCode = "SPLIT_CODE_001",
                settlementInstruction = new List<Accounts>
                {
                    new Accounts
                    {
                        AccountId = 101,
                        Amount = 75.50
                    },
                    new Accounts
                    {
                        AccountId = 102,
                        Amount = 75.25
                    }
                }
            }
        };

  


PaymentResponse paymentResponse = await vergeService.InvokePayment(paymentRequest);

if (paymentResponse.responseHeader.responseCode == "00")
{
    Console.WriteLine($"Payment Page Link: {paymentResponse.payPageLink}");
}
else
{
    Console.WriteLine($"Error: {paymentResponse.responseHeader.responseMessage}");
}
`;

export const sampleResponse = `
const response= {
"token": "your_auth_token",
"key": "your_encryption_key",
"status": "Success"
}
`;

export const sampleInvokePaymentResponse = `
const response = {
"responseHeader": {
"responseCode": "00",
"responseMessage": "Success",
"timeStamp": 1609459200,
"signature": "response_signature"
},
"transactionId": "transaction_id",
"traceId": "trace_id",
"payPageLink": "https://pay.verge.com/payment-page",
"metaData": {}
}
`;

export const sampleRequestForTransactionQuery = `
public class TransactionQueryRequest
{
    public RequestHeader requestHeader { get; set; }
    public string traceId { get; set; }
}
`;

export const transactionQueryFunction = `
SignatureResponse signatureResponse = vergeService.GetSignature(new SignatureRequest
{
    merchantId = "your_merchant_id",
    traceId = "unique_trace_id",
    key = "your_secret_key",
    shaType = SHA.SHA256
});

TransactionQueryRequest queryRequest = new TransactionQueryRequest
{
    requestHeader = new RequestHeader
    {
        merchantId = "your_merchant_id",
        terminalId = "your_terminal_id",
        timeStamp = signatureResponse.timeStamp,  // Use generated timestamp
        signature = signatureResponse.signature   // Use generated signature
    },
    traceId = "unique_trace_id"
};

TransactionQueryResponse queryResponse = await vergeService.TransactionQuery(queryRequest);

if (queryResponse.responseCode == "00")
{
    Console.WriteLine("Transaction successful");
}
else
{
    Console.WriteLine($"Error: {queryResponse.responseMessage}");
}
`;

export const tsqSampleResponsePayload = `
const response = {
"responseCode": "00",
"responseMessage": "Transaction successful",
"amount": 100.00,
"currency": "USD",
"traceId": "trace_id",
"transactionId": "transaction_id",
"refunds": []
}
`;

export const sampleRequestForGetAccountDetails = `
        // Create an instance of GetAccountRequest
        var getAccountRequest = new GetAccountRequest
        {
            requestHeader = new RequestHeader
            {
            merchantId = "123456",
            terminalId = "78910",
            timeStamp = signatureResponse.timeStamp,
            signature = signatureResponse.signature 
           }
           traceId = Guid.NewGuid().ToString() // Generates a unique trace ID
        };
`;

export const getAccountDetailsFunction = `
SignatureResponse signatureResponse = vergeService.GetSignature(new SignatureRequest
{
    merchantId = "your_merchant_id",
    traceId = "unique_trace_id",
    key = "your_secret_key",
    shaType = SHA.SHA256
});

GetAccountRequest accountRequest = new GetAccountRequest
{
    requestHeader = new RequestHeader
    {
        merchantId = "your_merchant_id",
        terminalId = "your_terminal_id",
        timeStamp = signatureResponse.timeStamp,  // Use generated timestamp
        signature = signatureResponse.signature   // Use generated signature
    },
    traceId = "unique_trace_id"
};

GetAccountResponse accountResponse = await vergeService.GetAccountDetails(accountRequest);

if (accountResponse.responseCode == "00")
{
    Console.WriteLine("Account details retrieved successfully");
}
else
{
    Console.WriteLine($"Error: {accountResponse.responseMessage}");
}
`;

export const accountDetailsResponse = `
const response = {
"accountDetails": [
{
    "bankCode": "123",
    "accountNumber": "1234567890",
    "accountId": 22
},
{
    "bankCode": "456",
    "accountNumber": "1000002001",
    "accountId": 33
}
],
"traceId": "trace_id",
"timeStamp": 1726751419,
"signature": "signature",
"responseCode": "00",
"responseMessage": "Successful"
}
`;

export const sampleRequestForSignatureGeneration = `
public class SignatureRequest
{
    public string merchantId { get; set; }
    public string traceId { get; set; }
    public string key { get; set; }
    public SHA shaType { get; set; } // SHA256
}

`;

export const signatureGenerationFunction = `
SignatureRequest signatureRequest = new SignatureRequest
{
    merchantId = "your_merchant_id",
    traceId = "unique_trace_id",
    key = "your_secret_key",  // You get this from the Verge dashboard
    shaType = SHA.SHA256
};

SignatureResponse signatureResponse = vergeService.GetSignature(signatureRequest);

long timeStamp = signatureResponse.timeStamp;
string signature = signatureResponse.signature;

Console.WriteLine($"Generated Signature: {signature}");
Console.WriteLine($"Timestamp: {timeStamp}");
`;

export const sampleSignatureResponse = `
const response = {
"timeStamp": 1609459200,
"signature": "generated_signature"
}
`;

export const sampleErrorHandling = `
PaymentResponse paymentResponse = await vergeService.InvokePayment(paymentRequest);

if (paymentResponse.responseHeader.responseCode != "00")
{
    Console.WriteLine($"Error: {paymentResponse.responseHeader.responseMessage}");
}
else
{
    Console.WriteLine("Payment initiated successfully");
}

`;
