const blogPayload = [
    // {
    //     title:'CoralPay Payment Gateway',
    //     content:'elit.Lorem ipsum dolor sit amet, consecLorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet',
    //     profilePics:'',
    //     user:'CoralPay',
    //     date:'02 Jan, 22 | 2:00PM',
    //     image:"../../blogimagesomething.svg"
    // }
]

export default  blogPayload