import React from "react";

const Table = () => {
    return(
       <div>
           <table style={{border: '13px solid #7E3D64'}}>
               <tr style={{border: '3px solid #7E3D64'}}>
                   <th>Description</th>
                   <th style={{border: '3px solid #7E3D64'}}>Field</th>
                   <th style={{border: '3px solid #7E3D64'}}>Description</th>
                   <th style={{border: '3px solid #7E3D64'}}>Optional/Mandatory</th>
               </tr>
               <tr style={{border: '3px solid #7E3D64'}}>
                   <td></td>
                   <td style={{border: '3px solid #7E3D64'}} >Client Id</td>
                   <td style={{border: '3px solid #7E3D64'}} >The Id of the<br/> merchant issued by<br/> CoralPay after<br/> profiling</td>
                   <td style={{border: '3px solid #7E3D64'}} >Mandatory</td>
               </tr>
               <tr style={{border: '3px solid #7E3D64'}}>
                   <td></td>
                   <td style={{border: '3px solid #7E3D64'}} >Request Type</td>
                   <td style={{border: '3px solid #7E3D64'}}>The type of request<br/> the merchant/client<br/> is making. Bank <br/> Transfer by default</td>
                   <td style={{border: '3px solid #7E3D64'}}>Mandatory</td>
               </tr>
               <tr style={{border: '3px solid #7E3D64'}}>
                   <td ></td>
                   <td style={{border: '3px solid #7E3D64'}} >Customer Name</td>
                   <td style={{border: '3px solid #7E3D64'}}>The desired name <br/> the merchant wants <br/> to display to the <br/> payer at the time of <br/> transfer</td>
                   <td style={{border: '3px solid #7E3D64'}}>Mandatory</td>
               </tr>
               <tr style={{border: '3px solid #7E3D64'}}>
                   <td></td>
                   <td style={{border: '3px solid #7E3D64'}} >Reference Number</td>
                   <td style={{border: '3px solid #7E3D64'}}>A unique transaction <br/> reference number</td>
                   <td style={{border: '3px solid #7E3D64'}}>Mandatory</td>
               </tr>
               <tr style={{border: '3px solid #7E3D64'}}>
                   <td ></td>
                   <td style={{border: '3px solid #7E3D64'}}>Transaction Amount</td>
                   <td style={{border: '3px solid #7E3D64'}}>The Amount of <br/> money the customer <br/> is trying to pay</td>
                   <td style={{border: '3px solid #7E3D64'}}>Optional for <br/> Wallet Funding<br/>Mandatory for Payment</td>
               </tr>
               <tr style={{border: '3px solid #7E3D64'}}>
                   <td ></td>
                   <td style={{border: '3px solid #7E3D64'}} >Response Code</td>
                   <td style={{border: '3px solid #7E3D64'}}>The final response <br/> code of the operation</td>
                   <td style={{border: '3px solid #7E3D64'}}>Mandatory</td>
               </tr>
               <tr style={{border: '3px solid #7E3D64'}}>
                   <td ></td>
                   <td style={{border: '3px solid #7E3D64'}} >Response Message</td>
                   <td style={{border: '3px solid #7E3D64'}}>The final response <br/> message of the operation</td>
                   <td style={{border: '3px solid #7E3D64'}}>Mandatory</td>
               </tr>
               <tr style={{border: '3px solid #7E3D64'}}>
                   <td ></td>
                   <td style={{border: '3px solid #7E3D64'}} >Account Name</td>
                   <td style={{border: '3px solid #7E3D64'}}>The name of the <br/> Dynamic Account</td>
                   <td style={{border: '3px solid #7E3D64'}}>Mandatory</td>
               </tr>
               <tr style={{border: '3px solid #7E3D64'}}>
                   <td ></td>
                   <td style={{border: '3px solid #7E3D64'}} >Account Number</td>
                   <td style={{border: '3px solid #7E3D64'}}>The created <br/> Dynamic Account <br/> Number for this <br/> request. NOTE: This <br/> account is <br/> deactivated after
                       <br/>a fixed interval of time <br/> or after a transaction has been <br/> done on the <br/> account.</td>
                   <td style={{border: '3px solid #7E3D64'}}>Mandatory</td>
               </tr>
               <tr style={{border: '3px solid #7E3D64'}}>
                   <td ></td>
                   <td style={{border: '3px solid #7E3D64'}} >Operation Reference</td>
                   <td style={{border: '3px solid #7E3D64'}}>The unique reference of the transaction on CoralPay's system.</td>
                   <td style={{border: '3px solid #7E3D64'}}>Mandatory</td>
               </tr>
           </table>
       </div>
    )
}

export default Table