import React from "react"
import DocumentationLink from "../../components/DocumentationLink";
import accountNumber from "../../assets/icons/account-number.svg"
import table1 from "../../assets/table1.svg"
import table2 from "../../assets/table2.png"
import Table from "../../components/Table";


const AccountNumber = () => {
    return(
        <div style={{background:'#F9F9FB',width:'100%'}}>
        <div className="flex-section">
            <DocumentationLink/>
            <div>
                <div className="doc-content account-number">
                    <h3>Account Number</h3>
                    <p>This operation is for merchants to generate a Dynamic Account Number for their customers
                        to make payments. These payments can either be to fund wallets or pay for services. </p>
                    <div className="doc-content-inner">
                        <img src={accountNumber} alt="responseCode"  className="doc-content-inner-img"/>
                    </div>
                </div>
            </div>

        </div>
            <div className="doc-div-parent">
                <div className="doc-div">
                   <Table />
                </div>
            </div>
    </div>

    )
}

export default AccountNumber