import React from "react";
import cgate from "../../assets/cgate.svg";
import coralpayment1 from "../../assets/coralpaypaymentgateway1.svg";
import ussd from "../../assets/CGateUSSD.svg";
import Subscribe from "../../components/Subscribe";
import {Link} from "react-router-dom"
import learnMore from "../../assets/learnmore.svg"

const CGateUssd = () => {
    return (
        <div>
            <div className="about-header" style={{backgroundImage:`url(${cgate})`}}>
                <h3>C’Gate <span style={{color:'#F7EF8A'}}>USSD</span></h3>
            </div>
            {/*<br /><br /><br />*/}
            <div className="product">
                <div className="product-one">
                    <div className="accordion" id="accordionExample">
                        <Link to="/coralpay-payment-gateway">
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingOne">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Coralpay Payment Gateway
                                    </button>
                                </h2>
                            </div>
                        </Link>

                        <div className="accordion-item mb-3">
                             <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button dropdown-btn" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    C'Gate USSD
                                </button>
                             </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show"
                                 aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className="biller-one-option-mobile">
                                        <div className="biller-one-app-option" style={{backgroundImage:`url(${coralpayment1})`}}></div>
                                    </div>
                                    <p>C’Gate innovative payment solution has successfully incorporated the Unstructured Supplementary Service Data (USSD) payments on hardware and Soft PoS, e-commerce websites,
                                        chatbots and USSD driven menus, allowing customers to use a unique code to pay for goods and services in the most safe and convenient way.
                                        C’Gate USSD can also be used for Merchant collections, Charity donations, Religious Bodies collections, Wallet funding, Gaming and Lottery,
                                        Schools, Government collections etc.</p>
                                        <br /><br />
                                      <p> C’Gate USSD straight dial solution caters for merchants requesting for a payment solution without requiring physical terminals, or internet connectivity.</p>
                                        <br /><br />
                                        <br /><br />
                                     <a href="https://blog.coralpay.com/the-payment-options-that-will-keep-your-business-ahead/"><img src={learnMore} alt='learn more btn'/></a>

                                </div>
                            </div>
                        </div>
                     <Link to="/transaction-switching-and-processing">
                         <div className="accordion-item mb-3">
                            <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseTwo(null)" aria-expanded="false" aria-controls="collapseTwo">
                                    Transaction Switching & Processing
                                </button>
                            </h2>
                        </div>
                     </Link>
                        <Link to="/payment-with-transfer">
                            <div className="accordion-item mb-3">
                            <h2 className="accordion-header" id="headingThree">
                                <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree(null)" aria-expanded="false"
                                        aria-controls="collapseThree">
                                    Pay With Bank Transfer
                                </button>
                            </h2>
                        </div></Link>
                        <Link to="/nqr" >
                            <div className="accordion-item mb-3">
                            <h2 className="accordion-header" id="headingThree">
                                <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree(null)" aria-expanded="false"
                                        aria-controls="collapseThree">
                                    NQR
                                </button>
                            </h2>
                        </div></Link>
                        <Link to="/digipos">
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree">
                                        DigiPOS
                                    </button>
                                </h2>
                            </div>
                        </Link>
                        <Link to="/billers-aggregation-platform">
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree">
                                        Billers Aggregation Platform
                                    </button>
                                </h2>
                            </div>
                        </Link>
                        <Link to="/finpay">
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree">
                                        FinPay
                                    </button>
                                </h2>
                            </div>
                        </Link>
                        <Link to="/payment-link">
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree">
                                        Payment Link
                                    </button>
                                </h2>
                            </div>
                        </Link>
                        <Link to="/instant-payment">
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree">
                                        Coralpay Instant Payment
                                    </button>
                                </h2>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="biller-one-option-desk">
                    <div className="biller-one-app-option" style={{backgroundImage:`url(${coralpayment1})`}}></div>
                </div>
            </div>
            <div className="about-inner-img-option" style={{backgroundImage:`url(${ussd})`}}></div>
            <Subscribe />
        </div>
    )
}

export default CGateUssd