export const initializeVergClass = `
string baseUrl = "http://sandbox1.coralpay.com:8080/account-connect/api/payment";  // Replace with actual AccountConnect API base URL
string merchantId = "your_merchant_Id"; 
string secretKey = "your_secret_Key"; 

AccountConnectServices service = new AccountConnectServices(baseUrl, merchantId, secretKey);


`;
export const getProcessorResponseStructure = `
 public class GetProcessorResponse
 {
     public ResponseHeader responseHeader { get; set; }
     public List<Processor> processors { get; set; }
 }
 public class Processor
 {
     public string processorName { get; set; }
     public string processorCode { get; set; }
     public string processorLogo { get; set; }
     public bool isActive { get; set; }
 }


`;
export const callGetProcessorServices = `
GetProcessorResponse response = await service.GetProcessors();

if (response.responseHeader.responseMessage == "00" )
{
    Console.WriteLine("GetProcessorResponse : GetProcessors successful");
}
else
{
    Console.WriteLine($"GetProcessorResponse : {response.responseHeader.responseMessage}");
}


`;

export const authenticationResponseStructure = `
public class AuthenticationResponse
{
    public ResponseHeader responseHeader { get; set; }
    public string token { get; set; }
    public string key { get; set; }
    public DateTime expiryDate { get; set; }
}

`;

export const baseUrlText = `
const baseUrl = "https://testdev.coralpay.com:5000/GwApi"; //replace with the actual verge base URL
`;

export const paymentRequestStructure = `
 public class PaymentRequest
 {
     public decimal amount { get; set; }
     public string processorCode { get; set; }
     public string phoneNumber { get; set; }
     public string payId { get; set; }
     public string description { get; set; }
     public string returnUrl {get; set;}
     public RequestHeader requestHeader { get; set; }
 }

 public class RequestHeader
 {
     public string merchantId { get; set; }
     public string traceId { get; set; }
 }


`;

export const paymentResponseStructure = `
 public class PaymentResponse
{
    public paymentResponseHeader responseHeader { get; set; }
    public DateTime expiryDate { get; set; }
    public string transactionId { get; set; }
    public string channelTranId { get; set; }
    public string processorCode { get; set; }
    public string processorName { get; set; }
    public decimal amount { get; set; }
    public string paymentLink { get; set; }
    public string description { get; set; }
}

public class paymentResponseHeader
{
    public string traceId { get; set; }
    public string merchantId { get; set; }
    public string responseCode { get; set; }
    public string responseMessage { get; set; }
}


`;

export const singlePostResponseStructure = `
 public class SinglePostResponse
 {
     public ResponseHeader responseHeader { get; set; }
     public string traceId { get; set; }
     public string batchId { get; set; }
     public decimal amountDebited { get; set; }
     public int transactionAmount { get; set; }
     public decimal transactionCharge { get; set; }
     public decimal vat { get; set; }
  }



`;

export const transactionQueryRequestStructure = `
public class TransactionQueryRequest
{
    public RequestHeader requestHeader { get; set; }
    public string traceId { get; set; }
}

`;
export const transactionQueryResponseStructure = `
public class TransactionQueryResponse
{
   public string transactionId { get; set; }
   public decimal amount { get; set; }
   public ResponseHeader responseHeader { get; set; }
   public DateTime createdDate { get; set; }
   public string traceId { get; set; }
   public string merchantId { get; set; }
   public DateTime paymentDate { get; set; }
   public string processorCode { get; set; }
   public string description { get; set; }
}


`;
export const transactionQuery = `
TransactionQueryRequest queryRequest = new TransactionQueryRequest
{
    merchantId = "your_merchant_id",
    traceId = "unique_trace_id"
};

TransactionQueryResponse queryResponse = await service.TransactionQuery(queryRequest);

if (queryResponse.responseHeader.responseCode == "00")
{
    Console.WriteLine("Transaction successful");
}
else
{
    Console.WriteLine($"Error: {queryResponse.responseHeader.responseMessage}");
}

`;

export const generatePaymentLink = `
 // Create a new PaymentRequest object
 var requestHeader = new RequestHeader
 {
    merchantId = "your_merchant_id",
    traceId = "unique_trace_id",
 };
 PaymentRequest paymentRequest = new PaymentRequest
 {
    amount = 1500.75, 
    processorCode = "PROC001", 
    phoneNumber = "1234567890", 
    payId = "PAY12345", 
    returnUrl = "wwww.ceepay.com,
    description = "Payment for invoice #123", 
    requestHeader = requestHeader
 };

PaymentResponse paymentResponse = await service.InvokePayment(paymentRequest);

if (paymentResponse.responseHeader.responseCode == "00")
{
    Console.WriteLine($"PaymentLink: {paymentResponse.paymentLink}");
}
else
{
    Console.WriteLine($"Error: {paymentResponse.responseHeader.responseMessage}");
}

`;

export const batchPostRequestStructure = `
    public class BatchPostRequest
    {
        public string traceId { get; set; }
        public decimal totalAmount { get; set; }
        public string signature { get; set; }
        public long timestamp { get; set; }
        public List<TransactionList> transactionList { get; set; }
    }
    public class TransactionList
    {
        public string creditAccount { get; set; }
        public string creditAccountName { get; set; }
        public string creditBankCode { get; set; }
        public string narration { get; set; }
        public decimal amount { get; set; }
        public string itemTraceId { get; set; }
    }


`;
export const batchPostResponseStructure = `

   public class BatchPostResponse
   {
        public ResponseHeader responseHeader { get; set; }
        public string batchId { get; set; }
        public string traceId { get; set; }
        public decimal amountDebited { get; set; }
        public decimal transactionAmount { get; set; }
        public decimal transactionCharge { get; set; }
   }

`;

export const batchPost = `
// First, generate the signature and timestamp
SignatureResponse signatureResponse = FastChannelService.GetSignature(new SignatureRequest
{
    merchantId = "your_merchant_id",
    traceId = "unique_trace_id", 
    key = "generated_key",
    shaType = SHA.SHA512
});

// Create a new BatchPostRequest object
BatchPostRequest postRequest = new BatchPostRequest
{
     traceId = "12789987", // traceId used for signature request
     totalAmount = 55500.00,
     timeStamp = signatureResponse.timeStamp,
     signature = signatureResponse.signature, 
     transactionList = new List<TransactionList>
     {
        new TransactionList
        { 
           creditAccount = "0092309678",
           creditAccountName = "Christiana Isreal",
           creditBankCode = "890998",
           narration = "string",
           amount: 35500.00,
           itemTraceId = "92872929291"
         },
         new TransactionList
         { 
            creditAccount = "0092345612",
            creditAccountName = "Precious James",
            creditBankCode = "929068",
            narration = "string",
            amount: 20000.00,
            itemTraceId = "10102929092"
          }
       }
  };
 
BatchPostResponse postResponse = await service.BatchPost(postRequest);

if (postResponse.responseHeader.responseCode == "00")
{
    Console.WriteLine("Transfer successful");
}
else
{
    Console.WriteLine($"Error: {paymentResponse.responseHeader.responseMessage}");
}

`;

export const nameEnquiryRequestStructure = `
public class NameEnquiryRequest
{
    public string traceId { get; set; }
    public string signature { get; set; }
    public long timeStamp { get; set; }
    public EnquiryDetails enquiryDetails { get; set; }
}

public class EnquiryDetails
{
    public string bankCode { get; set; }
    public string accountNumber { get; set; }
}

`;

export const nameEnquiryResponseStructure = `
public class NameEnquiryResponse
{
   public ResponseHeader responseHeader { get; set; }
   public string traceId { get; set; }
   public string accountName { get; set; }
   public string accountNumber { get; set; }
   public string bankCode { get; set; }
}


`;

export const nameEnquiry = `
SignatureResponse signatureResponse = FastChannelService.GetSignature(new SignatureRequest
{
   merchantId = "your_merchant_id",
   traceId = "unique_trace_id",
   key = "generated_key",
   shaType = SHA.SHA512
});

NameEnquiryRequest enquiryRequest = new NameEnquiryRequest
{
   traceId = "78998710", // traceId used for signature request
   timeStamp = signatureResponse.timeStamp,
   signature = signatureResponse.signature, 
   enquiryDetails = new EnquiryDetails
   {
      accountNumber = "7379712014",
      bankCode = "090100"
   }
};

NameEnquiryResponse enquiryResponse = await service.NameEnquiry(enquiryRequest);

if (enquiryResponse.responseHeader.responseCode == "00")
{
    Console.WriteLine("Name enquiry successful");
}
else
{
    Console.WriteLine($"Error: {enquiryResponse.responseHeader.responseMessage}");
}


`;

export const sampleInvokePaymentResponse = `
const response = {
"responseHeader": {
"responseCode": "00",
"responseMessage": "Success",
"timeStamp": 1609459200,
"signature": "response_signature"
},
"transactionId": "transaction_id",
"traceId": "trace_id",
"payPageLink": "https://pay.verge.com/payment-page",
"metaData": {}
}
`;

export const sampleRequestForTransactionQuery = `
public class TransactionQueryRequest
{
   public string traceId { get; set; }
   public string itemTraceId { get; set; }
   public string signature { get; set; }
   public long timeStamp { get; set; }
}

`;

export const sampleResponseForTransactionQuery = `
public class TransactionQueryResponse
{
   public TransactionQueryResponseHeader responseHeader { get; set; }
   public List<TransList> transactionList { get; set; }
}
public class TransactionQueryResponseHeader
{
   public string merchantId { get; set; }
   public string batchId { get; set; }
   public string responseCode { get; set; }
   public string responseMessage { get; set; }
   public decimal amountDebited { get; set; }
   public string traceId { get; set; }
   public decimal transactionAmount { get; set; }
   public decimal transactionCharge { get; set; }
}
public class TransList
{
   public string responseCode { get; set; }
   public string responseMessage { get; set; }
   public string itemTraceId { get; set; }
   public string creditAccount { get; set; }
   public string creditAccountName { get; set; }
   public string creditBankCode { get; set; }
   public string narration { get; set; }
   public decimal amount { get; set; }
   public decimal charge { get; set; }
   public string SessionId { get; set; }
}


`;

export const transactionQueryFunction = `
SignatureResponse signatureResponse = FastChannelService.GetSignature(new SignatureRequest
{
   merchantId = "your_merchant_id",
   traceId = "unique_trace_id",
   key = "generated_key",
   shaType = SHA.SHA512
});

TransactionQueryRequest queryRequest = new TransactionQueryRequest
{
   traceId = "78998710", // traceId used for signature request
   timeStamp = signatureResponse.timeStamp,
   signature = signatureResponse.signature, 
   itemTraceId = 92872929291 // TraceId of a particular item in a batch.
};

TransactionQueryResponse queryResponse = await service.TransactionQuery(queryRequest);

if (queryResponse.responseHeader.responseCode == "00")
{
    Console.WriteLine("Transaction queried successfully");
}
else
{
    Console.WriteLine($"Error: {queryResponse.responseHeader.responseMessage}");
}


`;

export const staticTransactionQuery = `
     public async Task<StaticTranQueryResponse> StaticTranQuery(StaticTranQueryRequest Request)
     {
         var JsonRequest = JsonSerializer.Serialize(Request);

         var Content = new StringContent(JsonRequest, Encoding.UTF8, "application/json");

         var HttpResponse = await _httpClient.PostAsync($"{_baseUrl}/api/StaticTransactionQuery", Content);

         if (HttpResponse.IsSuccessStatusCode)
         {
             var stringResponse = await HttpResponse.Content.ReadAsStringAsync();
             var ResponseObj = JsonSerializer.Deserialize<StaticTranQueryResponse>(stringResponse);

             return ResponseObj;
         }
         else
         {
             return new StaticTranQueryResponse
             {
                 responseHeader = new Models.ResponseHeader
                 {
                     responseCode = "96",
                     responseMessage = !string.IsNullOrEmpty(HttpResponse.ReasonPhrase) ? HttpResponse.ReasonPhrase : "System Challenge"
                 }
             };
         }

     }
`;

export const sampleRequestForGetAccountDetails = `
        // Create an instance of GetAccountRequest
        var getAccountRequest = new GetAccountRequest
        {
            requestHeader = new RequestHeader
            {
            merchantId = "123456",
            terminalId = "78910",
            timeStamp = signatureResponse.timeStamp,
            signature = signatureResponse.signature 
           }
           traceId = Guid.NewGuid().ToString() // Generates a unique trace ID
        };
`;

export const getAccountDetailsFunction = `
SignatureResponse signatureResponse = vergeService.GetSignature(new SignatureRequest
{
    merchantId = "your_merchant_id",
    traceId = "unique_trace_id",
    key = "your_secret_key",
    shaType = SHA.SHA256
});

GetAccountRequest accountRequest = new GetAccountRequest
{
    requestHeader = new RequestHeader
    {
        merchantId = "your_merchant_id",
        terminalId = "your_terminal_id",
        timeStamp = signatureResponse.timeStamp,  // Use generated timestamp
        signature = signatureResponse.signature   // Use generated signature
    },
    traceId = "unique_trace_id"
};

GetAccountResponse accountResponse = await vergeService.GetAccountDetails(accountRequest);

if (accountResponse.responseCode == "00")
{
    Console.WriteLine("Account details retrieved successfully");
}
else
{
    Console.WriteLine($"Error: {accountResponse.responseMessage}");
}
`;

export const accountDetailsResponse = `
const response = {
"accountDetails": [
{
    "bankCode": "123",
    "accountNumber": "1234567890",
    "accountId": 22
},
{
    "bankCode": "456",
    "accountNumber": "1000002001",
    "accountId": 33
}
],
"traceId": "trace_id",
"timeStamp": 1726751419,
"signature": "signature",
"responseCode": "00",
"responseMessage": "Successful"
}
`;

export const sampleRequestForSignatureGeneration = `
public class SignatureRequest
{
    public string merchantId { get; set; }
    public string traceId { get; set; }
    public string key { get; set; }
    public SHA shaType { get; set; } // SHA256 or SHA512
}

`;
export const sampleResponseForSignatureGeneration = `
public class SignatureResponse
{
    public long timeStamp { get; set; }
    public string signature { get; set; }
}

`;

export const signatureGenerationFunction = `
SignatureRequest signatureRequest = new SignatureRequest
{
    merchantId = "your_merchant_id",
    traceId = "unique_trace_id",
    key = "your_secret_key",  // You get this from the Auth Response
    shaType = SHA.SHA512
};

SignatureResponse signatureResponse = FastChannelService.GetSignature(signatureRequest);

long timeStamp = signatureResponse.timeStamp;
string signature = signatureResponse.signature;

Console.WriteLine($"Generated Signature: {signature}");
Console.WriteLine($"Generated Timestamp: {timeStamp}");

`;

export const sampleSignatureResponse = `
public class SignatureResponse
{
    public long timeStamp { get; set; }
    public string signature { get; set; }
}
`;

export const sampleErrorHandling = `
PaymentResponse paymentResponse = await vergeService.InvokePayment(paymentRequest);

if (paymentResponse.responseHeader.responseCode != "00")
{
    Console.WriteLine($"Error: {paymentResponse.responseHeader.responseMessage}");
}
else
{
    Console.WriteLine("Payment initiated successfully");
}

`;
