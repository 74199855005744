import React, { useEffect, useRef, useState } from "react";
// import Button from "../../components/Button";
import { NavLink, useNavigate } from "react-router-dom";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import {
  darcula,
  a11yDark,
} from "react-syntax-highlighter/dist/esm/styles/prism";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Button,
  Grid2,
  Typography,
} from "@mui/material";

import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import { scrollToTop } from "../../../components/helper";

const CoralpayCardApiDocumentation = () => {
  const [javascript, setJavascript] = useState(false);
  const [dotnet, setDotnet] = useState(true);
  const [ceeSharp, setCeeSharp] = useState(false);
  const navigate = useNavigate();

  const apiSpec = useRef(null);
  const snippets = useRef(null);

  const scrollToSection = (ref) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleJavascriptContent = () => {
    setJavascript((prev) => !prev);
    setDotnet(false);
    setCeeSharp(false);
  };
  const handleDotnetContent = () => {
    setJavascript(false);
    setDotnet((prev) => !prev);
    setCeeSharp(false);
  };
  const handleCeeSharpContent = () => {
    setJavascript(false);
    setDotnet(false);
    setCeeSharp((prev) => !prev);
  };

  useEffect(() => {
    scrollToTop();
  },[]);

  const firstRequestPayload = `
  {
  "merchantId": "2058DE016809207",
  "merchantRef": "546255421116667",
  "amount": 100,
  "callBackUrlSuccess": "http://25.58.102.5/callback/successful",
  "callBackUrlFailed": "http://25.58.102.5/callback/failed",
  "isTokenize": 0
}
  `;
  const firstResponsePayload = `
{
  "responseCode": "0000",
  "responsemessage": "Successful",
  "navigatingUrl": "https://cnptest.coralpay.com:9443//octoweb/payment/quickpay/eyJhbGciOiJIUzI1NiJ9.
  eyJzdWIiOiJFMTU2 RjYzNjA3OTFGMDdDOUIxNUI1QzZFM0M0MDU0R
   EE0MUQxQjJGRTZCMDA1NzM3RUEyRkQ1ODI1Qk 
   QyOUJEIiwiaXNzIjoiT2N0b3BsdXMiLCJtaWQiOiI1M 
   iIsInR5cCI6ImFjY2VzcyIsImV4cCI6MTYzMjE2MzA3M 
   CwiaWF0IjoxNjMyMTUyMjcwLCJqdGkiOiJmZjJiMTY 
   3Yy02YWYzLTQzZWMtYjYwOC1kN2Y1YjRmZWEyNT 
   cifQ.Ltf_wBV_dpImwx6A2Phuzm3R7ImX- vbYL7wzejmdJv4"
}

  `;

  const transactionQueryRequestPayload = `
{
  "merchantId": "123456789012345",
  "tnxId": "210000000014580620210222192017111132"
}
  `;
  const transactionQueryResponsePayload = `
{
  "merchantId": "123456789012345",
  "merchantRef": "7589645286780",
  "transactionId": "9484775775MDC",
  "amount": "30.50",
  "retrievalRef": "045287651157",
  "responseCode": "0000",
  "responseMsg": "Approved"
}

  `;

  const refundRequestPayload = `
{
  "merchantId": "20580000000000X",
  "tnxId": "210000000014580620210222192017111132",
  "amount": 30.50
}
  `;

  const refundResponsePayload = `
{
  "merchantId": "123456789012345",
  "merchantRef": "7589645286780",
  "transactionId": "9484775775MDC",
  "amount": "30.50",
  "retrievalRef": "045287651157",
  "responseCode": "0000",
  "responseMsg": "Successful"
}
  `;

  return (
    <div>
      <div className="developer-wrapper">
        <div className="developer-wrapper-overlay">
          <div className="developer-wrapper-overlay-inner">
            <h3>Card API Documentation</h3>
            <p className="mt-2 small">
              This document is a Technical Design and integration document of
              the OctoPlus Custompay API Interface. It provides guidance and
              template material which is intended to assist the relevant
              stakeholders to easily integrate to the OctoPlus CustomPay
              Interface for the processing Card Transaction
            </p>
            <br />
            <br />
            {/*<div style={{display:'flex'}} >*/}
            {/*    <Button message="API Dashboard" bgColor="#7B3F63" bgText="#FFFFFF" />*/}
            {/*    <Button  message="Download Document" bgColor="#EFEFEF" bgText="#3A3A3A"/>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>

      <div className="doc-div-parent-doc">
        <div className="docNav">
          {/* <h3>Documentation</h3> */}
          {/* <ul>
            <a href={"/developer/docs/cgateussd"}>
              {" "}
              <li>C’Gate USSD</li>
            </a>
            <a href={"/developer/docs/pay-with-bank-transfer"}>
              {" "}
              <li>Pay With Bank Transfer</li>
            </a>
            <a href={"/developer/docs/payment-gateway"}>
              {" "}
              <li>CoralPay Payment Gateway</li>
            </a>
            <a href={"/developer/docs/vas"}>
              {" "}
              <li>CoralPay VAS</li>
            </a>
            <a href={"/developer/docs/card-api"}>
              {" "}
              <li>CoralPay Card APIs</li>
            </a>
          </ul> */}
          <Box sx={{ padding: "2rem 0 2rem 0" }}>
            <Button
              sx={{
                backgroundColor: "#083857",
                fontWeight: "550",
                color: "#fff",
              }}
              onClick={() => navigate("/developer#services")}
            >
              BACK TO DEVELOPER'S HOMEPAGE
            </Button>
          </Box>
          <nav>
            <List
              sx={{
                border: "1px solid #083857",
                borderRadius: "5px",
              }}
            >
              <ListItem disablePadding>
                {/* <a href="#apiSpec"> */}
                  <ListItemButton onClick={() => scrollToSection(apiSpec)}>
                    <ListItemIcon>{/* <InboxIcon /> */}</ListItemIcon>
                    <ListItemText primary="API Specifications" />
                  </ListItemButton>
                {/* </a> */}
              </ListItem>
              <ListItem disablePadding>
                {/* <a href="#snippets"> */}
                  <ListItemButton onClick={() => scrollToSection(snippets)}>
                    <ListItemIcon>{/* <DraftsIcon /> */}</ListItemIcon>
                    <ListItemText primary="Libraries & Code Snippets" />
                  </ListItemButton>
                {/* </a> */}
              </ListItem>
            </List>
          </nav>
        </div>

        <div className="doc-div-doc" ref={apiSpec}>
          <h3>BASIS OF THIS DOCUMENT</h3>

          <p>
            The OctoPlus Payment Gateway is a secure interface designed by
            CoralPay for card processing and has the capabilities to process
            payment on behalf of merchant who wants to accept card from their
            interface: -.
          </p>

          <h3>OCTOPLUS MERCHANT PAY USE CASE</h3>
          <p>
            User Scenario for accepting card transaction on the OctoPlus
            merchant pay
          </p>
          <span>Pre-Condition</span>
          <ul
            style={{
              listStyle: "order",
              marginTop: "5px",
              marginLeft: "3%",
              marginBottom: "3%",
            }}
          >
            <li>
              Merchant Information has been profiled on OctoPlus Web Pay Gateway
            </li>
            <li>
              Merchant callback url for successful and failed payment is called
              if available
            </li>
            <li>Customer has a valid MasterCard, Visa or Verve</li>
          </ul>

          <span>Post Condition</span>
          <ul
            style={{
              listStyle: "order",
              marginTop: "5px",
              marginLeft: "3%",
              marginBottom: "3%",
            }}
          >
            <li>
              Customers card is debited and transaction is redirected to
              merchant callback url
            </li>
          </ul>

          <span>Use Case</span>
          <ul
            style={{
              listStyle: "order",
              marginTop: "5px",
              marginLeft: "3%",
              marginBottom: "3%",
            }}
          >
            <li>
              Customer browses merchant portal and add product or services to
              cart
            </li>
            <li>Customer checkout payment</li>
            <li>Merchant call the request payment API</li>
            <li>Merchant Redirect to the send OctoPlus Payment Gate</li>
            <li>Customer enters his card information,CVV and expiry date</li>
            <li>OctoPlus Gateway redirect to 3D Secure Page</li>
            <li>Customer enters his detail on the 3D Secure page</li>
            <li>
              OctoPlus Service verify customer card and performs 3D secure.
              Customer may be prompted to enrol for 3Dsecure/VbV by the issuer
              if not currently enrolled
            </li>
            <li>
              Upon successful 3D secure, OctoPlus gateway debit customer and
              notifies merchant callback url if availabel.
            </li>
          </ul>

          <br />

          <table style={{ width: "910px" }}>
            <tr>
              <td>url</td>
              {/* <td>
                https://cnptest.coralpay.com:9443/octoweb/cnp/requestPayment
              </td> */}
              <td>
                <SyntaxHighlighter language="javascript" style={a11yDark}>
                  {`https://cnptest.coralpay.com:9443/octoweb/cnp/requestPayment`}
                </SyntaxHighlighter>
              </td>
            </tr>
            <tr>
              <td>Verb</td>
              <td>Post</td>
            </tr>
            <tr>
              <td>Content Type</td>
              <td>Json</td>
            </tr>
            <tr>
              <td>JSON Object</td>
              <td>
                <SyntaxHighlighter language="javascript" style={a11yDark}>
                  {firstRequestPayload}
                </SyntaxHighlighter>
              </td>
            </tr>
            <tr>
              <td>Request Data</td>
              <td> Authorization Basic clientSecretKey</td>
            </tr>
            <tr>
              <td>Response Data</td>
              <td>
                <SyntaxHighlighter language="javascript" style={a11yDark}>
                  {firstResponsePayload}
                </SyntaxHighlighter>
              </td>
            </tr>
          </table>
          <br />
          <p
            style={{
              textAlign: "center",
              fontWeight: "800",
              textDecoration: "underline",
            }}
          >
            Request
          </p>
          <table style={{ width: "910px" }}>
            <tr>
              <td>merchantId</td>
              <td>
                This is a unique merchantId that will be sent to the merchant
              </td>
              <td>Mandatory</td>
            </tr>
            <tr>
              <td>merchantRef</td>
              <td>
                This is a unique merchant ref generate by merchant to identify
                the transaction.
              </td>
              <td>Mandatory</td>
            </tr>
            <tr>
              <td>amount</td>
              <td>The transaction amount.</td>
              <td>Mandatory</td>
            </tr>
            <tr>
              <td>callBackUrlSuccess</td>
              <td>
                This is the url that will be called after a successful payment
              </td>
              <td>Mandatory</td>
            </tr>
            <tr>
              <td>callBackUrlFailed</td>
              <td>
                This is the url that will be called after a successful payment
              </td>
            </tr>
            <tr>
              <td>isTokenize</td>
              <td>This is to indicate if card should be tokenize</td>
              <td>Optional</td>
            </tr>
          </table>
          <br />

          <p
            style={{
              textAlign: "center",
              fontWeight: "800",
              textDecoration: "underline",
            }}
          >
            Response
          </p>
          <table style={{ width: "910px" }}>
            <tr>
              <td>responseCode</td>
              <td>
                This is the response code for the request 0000 or 00 is
                successful
              </td>
              <td>Mandatory</td>
            </tr>
            <tr>
              <td>responsemessage</td>
              <td>This is the narration of the response</td>
              <td>Mandatory</td>
            </tr>
            <tr>
              <td>navigatingUrl</td>
              <td>
                For a successful transaction, The merchant will redirect to the
                url for customer to make payment
              </td>
              <td>Mandatory</td>
            </tr>
          </table>

          <p style={{ fontWeight: "800" }}>
            Upon redirect to the payment navigatingUrl, the payment page is
            displayed for customer to enter his card detail
          </p>

          <h3>WEB HOOK OR CALLBACK URL:</h3>
          <p>
            The merchant can also provide a web hook or callback url. The
            OctoPlus merchant pay will call the web hook url upon completion of
            transaction. The OctoPlus merchant post this information to the web
            hook or callback url
          </p>

          <table style={{ width: "910px" }}>
            <tr>
              <td></td>
              <th style={{ textAlign: "center" }}>
                Field Description (Merchant Callback)
              </th>
              <td></td>
            </tr>
            <tr></tr>
            <tr>
              <td></td>
              <th style={{ textAlign: "center" }}>Field Description</th>
              <td></td>
            </tr>
            <tr>
              <td>responseCode</td>
              <td>The response code of the request</td>
              <td>Mandatory</td>
            </tr>
            <tr>
              <td>token</td>
              <td>The tokenize request for performing transaction</td>
              <td>
                Optional: only available if Successful and if tokenize is 1
              </td>
            </tr>
            <tr>
              <td> transactionRef</td>
              <td>The reference for the transaction</td>
              <td>Optional</td>
            </tr>
            <tr>
              <td>retrievalReferen ce</td>
              <td>The retrieval reference sent by the issuer</td>
              <td>Optional, available if transaction is successful</td>
            </tr>
            <tr>
              <td>responseMsg</td>
              <td>Response message</td>
              <td></td>
            </tr>
            <tr>
              <td>tnxId</td>
              <td>
                Transaction Id generated by octoplus portal and this is value
                that will be passed for refund or when quering for the status of
                the transaction
              </td>
              <td>Optional, available if transaction is successful</td>
            </tr>
          </table>
          <br />

          <h3>Querying Transaction for WEB Transaction</h3>
          <p>
            Merchant can re-query WEB transaction to get the status on the
            transaction and the transaction amount approved by the switch
          </p>

          <br />
          <table style={{ width: "910px" }}>
            <tr>
              <td>url</td>
              {/* <td>
                https://cnptest.coralpay.com:9443/octoweb/payment/
                queryTransaction
              </td> */}
              <td>
                <SyntaxHighlighter language="javascript" style={a11yDark}>
                  {`https://cnptest.coralpay.com:9443/octoweb/payment/queryTransaction`}
                </SyntaxHighlighter>
              </td>
            </tr>
            <tr>
              <td>Verb</td>
              <td>Post</td>
            </tr>
            <tr>
              <td>Content Type</td>
              <td>Json</td>
            </tr>
            <tr>
              <td>JSON Object</td>
              <td>
                <SyntaxHighlighter language="javascript" style={a11yDark}>
                  {transactionQueryRequestPayload}
                </SyntaxHighlighter>
              </td>
            </tr>
            <tr>
              <td>Request Data</td>
              <td> Authorization Basic clientSecretKey</td>
            </tr>
            <tr>
              <td>Response Data</td>
              <td>
                <SyntaxHighlighter language="javascript" style={a11yDark}>
                  {transactionQueryResponsePayload}
                </SyntaxHighlighter>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                Please note the responseMsg can either be Approved, Reversed,
                Refund. A transaction is approved when responseCode is either
                0000 or 0002 and responseMsg is not (Refund, Reversed)
              </td>
            </tr>
          </table>
          <br />
          <h3>Refund WEB Transaction</h3>
          <p>
            This is the ability for web merchant to refund a successful
            transaction when there is a dispute
          </p>

          <br />
          <table style={{ width: "910px" }}>
            <tr>
              <td>url</td>
              {/* <td>https://cnptest.coralpay.com:9443/octoweb/payment/refund</td> */}
              <td>
                <SyntaxHighlighter language="javascript" style={a11yDark}>
                  {`https://cnptest.coralpay.com:9443/octoweb/payment/refund`}
                </SyntaxHighlighter>
              </td>
            </tr>
            <tr>
              <td>Verb</td>
              <td>Post</td>
            </tr>
            <tr>
              <td>Content Type</td>
              <td>Json</td>
            </tr>
            <tr>
              <td>JSON Object</td>
              <td>
                <SyntaxHighlighter language="javascript" style={a11yDark}>
                  {refundRequestPayload}
                </SyntaxHighlighter>
              </td>
            </tr>
            <tr>
              <td>Request Data</td>
              <td> Authorization Basic clientSecretKey</td>
            </tr>
            <tr>
              <td>Response Data</td>
              <td>
                <SyntaxHighlighter language="javascript" style={a11yDark}>
                  {refundResponsePayload}
                </SyntaxHighlighter>
              </td>
            </tr>
          </table>

          <Box sx={{ width: "100%", paddingTop: "2rem" }} ref={snippets}>
            <Grid2
              container
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Grid2>
                <Typography
                  sx={{
                    color: "#7f3d64",
                    fontSize: "1.2em",
                    fontWeight: "800",
                  }}
                >
                  Libraries & Code Snippets
                </Typography>
              </Grid2>
              <Grid2 sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button onClick={scrollToTop} sx={{ color: "#7f3d64" }}>
                  Back to the top{" "}
                  <KeyboardDoubleArrowUpIcon
                    sx={{ fontSize: 30, color: "#7f3d64" }}
                  />
                </Button>
              </Grid2>
            </Grid2>

            <Grid2
              container
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "1.5rem 0 1.5rem 0",
              }}
            >
              {/* <div style={{ marginRight: "1rem" }}>
                <button
                  style={{
                    border: javascript ? "none" : "1px solid #242424",
                    borderRadius: "10px",
                    fontWeight: 550,
                    padding: "1rem",
                    color: javascript ? "#fff" : "#242424",
                    backgroundColor: javascript ? "#242424" : "#fff",
                  }}
                  onClick={handleJavascriptContent}
                >
                  JAVASCRIPT
                </button>
              </div> */}
              {/* <div style={{ marginRight: "1rem" }}>
                <button
                  style={{
                    border: dotnet ? "none" : "1px solid #242424",
                    borderRadius: "10px",
                    fontWeight: 550,
                    padding: "1rem",
                    color: dotnet ? "#fff" : "#242424",
                    backgroundColor: dotnet ? "#242424" : "#fff",
                  }}
                  onClick={handleDotnetContent}
                >
                  .NET
                </button>
              </div> */}
              {/* <div>
                <button
                  style={{
                    border: ceeSharp ? "none" : "1px solid #242424",
                    borderRadius: "10px",
                    fontWeight: 550,
                    padding: "1rem",
                    color: ceeSharp ? "#fff" : "#242424",
                    backgroundColor: ceeSharp ? "#242424" : "#fff",
                  }}
                  onClick={handleCeeSharpContent}
                >
                  JAVA
                </button>
              </div> */}
            </Grid2>

            <div>
              {/* {javascript && <JavascriptContent />} */}
              {/* {dotnet && <DotnetContent />} */}
              {/* {ceeSharp && <JavaContent />} */}
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default CoralpayCardApiDocumentation;
