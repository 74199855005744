import React, { useState } from 'react';

const Accordion = ({ title, content }) => {
    const [isActive, setIsActive] = useState(false);

    return (
        <div className="accordion-item" style={{marginBottom:'30px'}}>
            <div style={styles.accordion} onClick={() => setIsActive(!isActive)}>
                <div style={styles.title}>{title}</div>
                {/*<div>{isActive ? '-' : '+'}</div>*/}
            </div>
            {isActive && <div style={styles.accordion_content}>{content}</div>}
        </div>
    );
};

const styles = ({
    accordion:{
        alignItems:'center',
        padding:'.5%',
        width:window.innerWidth < 1000 ? "100%" : "80%",
        transition:'.6s ease'

    },
    title:{
        fontSize:window.innerWidth < 1000 ? '12px':'14px',
        textAlign:'left',
        padding:'10px',
        fontWeight:'500',
        fontFamily:'montserrat',
    },
    accordion_content:{
        padding:'3%',
        maxWidth:"100%",
        fontSize:window.innerWidth < 1000 ? '12px':'14px',
    }

})

export default Accordion;