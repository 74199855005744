export const scriptTag = `
<script src="https://testdev.coralpay.com:5000/vergelibrary/verge.js"></script>
`;
export const codeString = `
const requestBody = {
Username : "SkaiKru",
Password : "#1ooGr@0ndar$"
}`;

export const baseUrlText = `
const baseUrl = "https://testdev.coralpay.com:5000/GwApi";
`;

export const authenticateCode = `
function authenticate() {

//To begin using the Verge library, initialize the VergeServices class by providing the API base URL.
const service = new VergeServices(baseUrl);
//create the request body that will be passed as an argument to the authenticate service.
const authRequest = {
username: "your_username",
password: "your_password",
terminalId: "your_terminal_id", // Optional
};

//vergeService authentication
service
.authentication(authRequest)
.then((authResponse) => {
// console.log("Authentication Response:", authResponse);
apiResponse = authResponse;
})
.catch((err) => console.error("Authentication Error:", err));
}
`;

export const invokePaymentRequest = `
const paymentRequest = {
requestHeader: {
merchantId: "123456MT03939",
terminalId: "78910",
timeStamp: 1234567890,
signature: "signature_here"
},
customer: {
email: "john.doe@example.com",
name: "John Doe",
phone: "+1234567890",
tokenUserId: "TOKEN_USER_001"
},
customization: {
logoUrl: "https://example.com/logo.png",
title: "Payment for Order #ORD001",
description: "This is the payment for your order #ORD001."
},
metaData: {
orderId: "ORD12345",
description: "Payment for order ORD12345"
},
traceId: "TRC123456789",
productId: "PROD001",
amount: 150.75,
currency: "NGN",
feeBearer: "M",
returnUrl: "https://example.com/payment/return",
settlement: {
settlementType: "Dynamic",
splitCode: "SPLIT_CODE_001",
settlementInstruction: [
{
accountId: 101,
amount: 75.50
},
{
accountId: 102,
amount: 75.25
}
]
}
};
`;

export const invokePaymentFunction = `
async function invoke() {
//To begin using the Verge library, initialize the VergeServices class by providing the API base URL.
const service = await new VergeServices(baseUrl, apiResponse?.token);

//Create the request body for signature generation. This request body will be passed as an argument into the getSignature service.
const signatureRequest = {
merchantId: formData.merchantId,
traceId: formData.traceId,
key: apiResponse.key,
shaType: "SHA256",
};

//Initiate an API call to the getSignature service from verge and pass the signature request as an argument. The getSignature service returns an object with two values (timeStamp and signature).
const signatureResponse = await service.getSignature(signatureRequest);

const invokePaymentRequestBody = {
requestHeader: {
merchantId: "123456MT03939",
terminalId: "78910",
timeStamp: signatureResponse.timeStamp,  //pass the value for timeStamp  gotten from the signature response above.
signature: signatureResponse.signature  //pass the value for signature gotten from the signature response above.
},
customer: {
email: "john.doe@example.com",
name: "John Doe",
phone: "+1234567890",
tokenUserId: "TOKEN_USER_001"
},
customization: {
logoUrl: "https://example.com/logo.png",
title: "Payment for Order #ORD001",
description: "This is the payment for your order #ORD001."
},
metaData: {
orderId: "ORD12345",
description: "Payment for order ORD12345"
},
traceId: "TRC123456789",
productId: "PROD001",
amount: 150.75,
currency: "NGN",
feeBearer: "M",
returnUrl: "https://example.com/payment/return",
settlement: {
settlementType: "Dynamic",
splitCode: "SPLIT_CODE_001",
settlementInstruction: [
{
accountId: 101,
amount: 75.50
},
{
accountId: 102,
amount: 75.25
}
]
}
};

//initiate the invoke payment API by passing in the request body.
service
.invokePayment(invokePaymentRequestBody)
.then((invokeResponse) => {
console.log("Payment Response:", invokeResponse?.payPageLink);
})
.catch((err) => {
console.error("Payment Error:", err);
});
}
`;

export const sampleResponse = `
const response= {
"token": "your_auth_token",
"key": "your_encryption_key",
"status": "Success"
}
`;

export const sampleInvokePaymentResponse = `
const response = {
"responseHeader": {
"responseCode": "00",
"responseMessage": "Success",
"timeStamp": 1609459200,
"signature": "response_signature"
},
"transactionId": "transaction_id",
"traceId": "trace_id",
"payPageLink": "https://pay.verge.com/payment-page",
"metaData": {}
}
`;

export const sampleRequestForTransactionQuery = `
const tsqRequestBody = {
requestHeader: {
merchantId: 'your_merchant_id',
terminalId: 'your_terminal_id',
timeStamp: 'timestamp',
signature: 'your_signature'
},
traceId: 'unique_trace_id'
};
`;

export const transactionQueryFunction = `
async function tsRequest() {
//To begin using the Verge library, initialize the VergeServices class by providing the API base URL.
const service = await new VergeServices(baseUrl, apiResponse?.token);

//Create the request body for signature generation. This request body will be passed as an argument into the getSignature service.
const signatureRequest = {
merchantId: merchantId,
traceId: traceId,
key: apiResponse.key,
shaType: "SHA256",
};

//Initiate an API call to the getSignature service from verge and pass the signature request as an argument. The getSignature service returns an object with two values (timeStamp and signature).
const signatureResponse = await service.getSignature(signatureRequest);


const tsqRequestBody = {
requestHeader: {
merchantId: merchantId,
terminalId: "40016858822",
timeStamp: signatureResponse.timeStamp,
signature: await signatureResponse.signature,
},
traceId: traceId,
};

//initiate the transaction query API by passing in the request body.
service
.transactionQuery(tsqRequestBody)
.then((tsqResponse) => {
console.log('Transaction Query Response:', tsqResponse);
})
.catch((err) => {
console.error('Transaction Query Error:', err);
});
}
`;

export const tsqSampleResponsePayload = `
const response = {
"responseCode": "00",
"responseMessage": "Transaction successful",
"amount": 100.00,
"currency": "USD",
"traceId": "trace_id",
"transactionId": "transaction_id",
"refunds": []
}
`;

export const sampleRequestForGetAccountDetails = `
const accountRequest = {
requestHeader: {
merchantId: 'your_merchant_id',
terminalId: 'your_terminal_id',
timeStamp: 'timestamp',
signature: 'your_signature'
},
traceId: 'unique_trace_id'
};
`;

export const getAccountDetailsFunction = `
async function accountDetails() {
//To begin using the Verge library, initialize the VergeServices class by providing the API base URL.
const service = await new VergeServices(baseUrl, apiResponse?.token);

//Create the request body for signature generation. This request body will be passed as an argument into the getSignature service.
const signatureRequest = {
merchantId: merchantId,
traceId: traceId,
key: apiResponse.key,
shaType: "SHA256",
};

//Initiate an API call to the getSignature service from verge and pass the signature request as an argument. The getSignature service returns an object with two values (timeStamp and signature).
const signatureResponse = await service.getSignature(signatureRequest);


const accountRequest = {
requestHeader: {
merchantId: merchantId,
terminalId: "40016858822",
timeStamp: signatureResponse.timeStamp,
signature: await signatureResponse.signature,
},
traceId: traceId,
};

//initiate the account details API by passing in the request body.
service
.getAccountDetails(accountRequest)
.then((accountResponse) => {
console.log('Account Details Response:', accountResponse);
})
.catch((err) => {
console.error("Account Details Error:", err);
});
}
`;

export const accountDetailsResponse = `
const response = {
"accountDetails": [
{
    "bankCode": "123",
    "accountNumber": "1234567890",
    "accountId": 22
},
{
    "bankCode": "456",
    "accountNumber": "1000002001",
    "accountId": 33
}
],
"traceId": "trace_id",
"timeStamp": 1726751419,
"signature": "signature",
"responseCode": "00",
"responseMessage": "Successful"
}
`;

export const sampleRequestForSignatureGeneration = `
const signatureRequest = {
merchantId: 'your_merchant_id',
traceId: 'unique_trace_id',
key: 'your_key',
shaType: 'SHA256'
};
`;

export const signatureGenerationFunction = `
//The getSignature API is called after you must have initiated the vergeServices.
const signatureResponse = vergeService.getSignature(signatureRequest);
`;

export const sampleSignatureResponse = `
const response = {
"timeStamp": 1609459200,
"signature": "generated_signature"
}
`;

export const sampleErrorHandling = `
vergeService.invokePayment(paymentRequest)
.then(response => {
if (response.responseCode !== '00') {
    console.error('Error:', response.responseMessage);
} else {
    console.log('Success:', response);
}
})
.catch(err => console.error('Request failed:', err));
`;