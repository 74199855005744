import React from "react"
import guy from "../assets/guy.svg";

const TermsAndConditions = () => {
    return(
        <div>
            <div className="blog-header" style={{backgroundImage:`url(${guy})`}}>
                <div className="blog-wrapper-overlay blog">
                    <h3 className='small'>Terms & Conditions</h3>
                </div>
            </div>
            <div className="isms-policy">
          
            <div>
        <p style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black', fontWeight: 'bold'}}><span style={{fontFamily: 'Montserrat'}}>PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS&nbsp;</span><span style={{fontFamily: 'Montserrat'}}>SITE</span></p>
        <p style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black', fontWeight: 'bold'}}><span style={{fontFamily: 'Montserrat'}}>What's in these terms?</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>These terms tell you the framework for using our site.</span></p>
        <p style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black', fontWeight: 'bold'}}><span style={{fontFamily: 'Montserrat'}}>Who we are and how to contact us</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>CoralPay.com is a site (the “site”) operated by CoralPay Technology Nigeria Limited ("<strong>We", “us” “our</strong>”). We are registered in the Federal Republic of Nigeria, with RC No. 634364, duly licensed by the Central Bank of Nigeria and have our principal office at Plot 152A, Prince Ade Odedina Street, Victoria Island, Lagos State, Nigeria.&nbsp;</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>To contact us, please email&nbsp;</span><span style={{fontFamily: 'Montserrat', color: '#2E74B5'}}><a href="mailto:customersupport@coralpay.com"><em><span style={{color: '#2E74B5'}}>customersupport@coralpay.com</span></em></a></span><span style={{fontFamily: 'Montserrat'}}>&nbsp;or telephone our customer service line on&nbsp;</span><span style={{fontFamily: 'Montserrat', color: '#0070C0'}}>+234 913 9380 354</span></p>
        <p style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black', fontWeight: 'bold'}}><span style={{fontFamily: 'Montserrat'}}>By using our site you accept these terms</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>By using our site, you confirm that you accept these terms of use and that you agree to comply with them.</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>If you do not agree to these terms, you must not use our site.</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>We recommend that you print a copy of these terms for future reference.</span></p>
        <p style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black', fontWeight: 'bold'}}><span style={{fontFamily: 'Montserrat'}}>There are other terms that may apply to you</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>These terms of use refer to the following additional terms, which also apply to your use of our site:</span></p>
        <ul style={{listStyleType: 'disc'}}>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>Our Privacy Policy.</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>Our Acceptable Use Policy, which sets out the permitted uses and prohibited uses of our site. When using our site, you must comply with this Acceptable Use Policy.</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>Our Cookie Policy and as contained in the Privacy Policy, which sets out information about the cookies on our site.</span></li>
        </ul>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>If you purchase or subscribe to products or services or perform transactions or payment from/through our site, our terms and conditions of service delivery/provision will apply.</span></p>
        <p style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black', fontWeight: 'bold'}}><span style={{fontFamily: 'Montserrat'}}>We may make changes to these terms</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>We amend these terms from time to time. Every time you wish to use our site, please check these terms to ensure you understand the terms that apply at that time.&nbsp;</span></p>
        <p style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black', fontWeight: 'bold'}}><span style={{fontFamily: 'Montserrat'}}>We may make changes to our site</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>We may update and change our site from time to time to reflect changes to our products and services, our users' needs and our business priorities and or other stakeholders’ preference.<strong>&nbsp;</strong></span></p>
        <p style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black', fontWeight: 'bold'}}><span style={{fontFamily: 'Montserrat'}}>We may suspend or withdraw our site</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>Our site is made available and accessible “as is”, on limited, conditional, and non-exclusive basis, which can be revoked at anytime.</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>We do not guarantee, represent and or warrant that our site, or any content on it, will always be available or be uninterrupted. We may suspend or withdraw or restrict the availability of all or any part of our site for business and operational reasons. We will try to give you reasonable notice of any suspension or withdrawal.</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>You are also responsible for ensuring that all persons who access our site through you in are aware of these terms of use and other applicable terms and conditions, and that they comply with them.</span></p>
        <p style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black', fontWeight: 'bold'}}><span style={{fontFamily: 'Montserrat'}}>We may transfer this agreement to someone else</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>We may transfer our rights and obligations under these terms to another organisation. We will try to tell you in writing if this happens.</span></p>
        <p style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black', fontWeight: 'bold'}}><span style={{fontFamily: 'Montserrat'}}>You must keep your account details safe</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>If you choose, or you are provided with, a user identification code, password or any other piece of information as part of our security procedures, you must treat such information as confidential. You must not disclose it to any third party.</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>We have the right to disable any user identification code or password, whether chosen by you or allocated by us, at any time, if in our opinion you have failed to comply with any of the provisions of these terms of use.</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>If you know or suspect that anyone other than you knows your user identification code or password, you must promptly notify us at&nbsp;</span><span style={{fontFamily: 'Montserrat', color: '#2E74B5'}}><a href="mailto:customersupport@coralpay.com"><em><span style={{color: '#2E74B5'}}>customersupport@coralpay.com</span></em></a>.</span></p>
        <p style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black', fontWeight: 'bold'}}><span style={{fontFamily: 'Montserrat'}}>How you may use material on our site</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>We are the owner or the licensee of all intellectual property in our site, and in the material published on it. Those works are protected by intellectual property rights laws and treaties around the world. All such rights are reserved.</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>You may print off one copy, and may download extracts, of any page(s) from our site for your personal use and you may draw the attention of others within your organisation to content posted on our site.</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>Our status (and that of any identified contributors) as the authors of content on our site must always be acknowledged.</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>You must not use any part of the content on our site for commercial purposes without obtaining a licence to do so from us.</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>If you print off, copy, download, share or repost any part of our site in breach of these terms of use, your right to use our site will cease immediately and you must, at our option, return or destroy any copies of the materials you have made. This is without prejudice to any other rights or remedies available in law, contractually or in equity.</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>You undertake, warrant and covenant not to use the site in the following manner:</span></p>
        <ul style={{listStyleType: 'undefined', marginLeft: '62px'}}>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>to harass, abuse or threaten others or otherwise violate any person's legal rights;</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>to perpetrate fraud, financial or other financial/illicit crime;</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>breach any of our other policies;</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>to create or transmit unnecessary spam to any person or URL;</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>to post, transmit or cause to be posted or transmitted, any communication or solicitation designed to obtain password, account, or private information of other users or persons</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>(to post copyrighted content which does not belong to you and without obtaining the prior consent of the author;</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>to use robot, spider, scraper or other automated means to access this site without our prior and express written consent;</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>to engage in or create any unlawful gambling, sweepstakes, or scheme;</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>publishing or distributing any obscene or defamatory material;</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>using this site in any way that impacts user access to the site;</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>to engage in advertisement or solicit any user to buy or sell products or services without our prior and express written consent;</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>disseminating computer viruses/bugs or other software;</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>violating our intellectual property rights or any third party;</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>to reverse engineer or permit the reverse engineering or dissemble any code or software from or on the site or our services; and</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>In any way that is unlawful or fraudulent or has any unlawful or fraudulent purpose or effect.</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>transmit any data, send or upload any material that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer code designed to adversely affect the operation of any computer software or hardware.</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>not to violate the security of the site &nbsp;by or through any unauthorized access, circumvention of encryption or other security tools, data mining or interference with any host or user or network.</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>to use the site for illegal spam activities.</span></li>
        </ul>
        <p style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black', fontWeight: 'bold'}}><span style={{fontFamily: 'Montserrat'}}>No text or data mining, or web scraping</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>You shall not conduct, facilitate, authorise or permit any text or data mining or web scraping in relation to our site or any services provided via, or in relation to, our site. This includes using (or permitting, authorising or attempting the use of):</span></p>
        <ul style={{listStyleType: 'disc'}}>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>Any "robot", "bot", "spider", "scraper" or other automated device, program, tool, algorithm, code, process or methodology to access, obtain, copy, monitor or republish any portion of the site or any data, content, information or services accessed via the same.</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>Any automated analytical technique aimed at analysing text and data in digital form to generate information which includes but is not limited to patterns, trends and correlations.</span></li>
        </ul>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>The provisions in this clause should be treated as an express reservation of our rights in this regard.</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>This site its content and any services provided in relation to the same is only targeted to, and intended for use by, persons located in the Federal Republic of Nigeria and such other jurisdiction which we operate in (each, a Permitted Territory). By continuing to access, view or make use of this site and any related content and services, you hereby warrant and represent to us that you are located in a Permitted Territory. If you are not located in a Permitted Territory, you must immediately discontinue use of this site and any related content and services.</span></p>
        <p style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black', fontWeight: 'bold'}}><span style={{fontFamily: 'Montserrat'}}>Do not rely on information on this site</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>The content on our site is provided for general information only, or commercial purposes (such purposes as duly agreed with you in writing). It is not intended to amount to advice on which you should rely. You must obtain professional or specialist advice before taking, or refraining from, any action on the basis of the content on our site.</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>Although we make reasonable efforts to update the information on our site, we make no representations, warranties or guarantees, whether express or implied, that the content on our site is accurate, complete or up to date.</span></p>
        <p style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black', fontWeight: 'bold'}}><span style={{fontFamily: 'Montserrat'}}>We are not responsible for websites we link to</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>Where our site contains links to other sites and resources provided by third parties, these links are provided for your information only. Such links should not be interpreted as approval by us of those linked websites or information you may obtain from them.</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>We have no control over the contents of those sites or resources.</span></p>
        <p style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black', fontWeight: 'bold'}}><span style={{fontFamily: 'Montserrat'}}>User-generated content is not approved by us</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>This website may include information and materials uploaded by other users of the site, including to bulletin boards and or chat rooms. This information and these materials have not been verified or approved by us. The views expressed by other users on our site do not represent our views or values.</span></p>
        <p style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black', fontWeight: 'bold'}}><span style={{fontFamily: 'Montserrat'}}>How to complain about content uploaded by other users</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>If you wish to complain about content uploaded by other users, please contact us on <a href="mailto:customersupport@coralpay.com"><em>customersupport@coralpay.com</em></a>.</span></p>
        <p style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black', fontWeight: 'bold'}}><span style={{fontFamily: 'Montserrat'}}>Disclaimer</span></p>
        <ul style={{listStyleType: 'disc'}}>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>The content on our site are provided on an “as is” basis. We make no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>We do not warrant or make any representations concerning the accuracy, completeness, likely results, or reliability of the use of the content on the site or otherwise relating to such content or on any link or third party content to the site.</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>We disclaim all implied or express conditions, warranties, representations or other terms that may apply to our site or any content on it.</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>We will not be liable to you for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with:</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>Access, navigation or visit to our site;</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>use of, or inability to use, our site; and or</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>use of or reliance on any content displayed on our site.</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>In particular, we will not be liable for and in no event will we be liable for, including the following:</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>loss of profits, sales, business, or revenue;</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>business interruption;</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>loss of anticipated savings;</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>loss of business opportunity, goodwill or reputation; or</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>any indirect or consequential loss or damage.</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>You agree not to use our site for any commercial or business purposes, except otherwise designated by us, and we have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.</span></li>
        </ul>
        <p style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black', fontWeight: 'bold'}}><span style={{fontFamily: 'Montserrat'}}>Indemnity</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>You hereby agree to fully indemnify and hold us harmless from and against any and all claims brought by a third party resulting from the use of our site and or the Services or the provision of content to us by you and in respect of all costs, actions, losses, claims, proceedings, damages, expenses (including reasonable legal costs and expenses), or liabilities whatsoever suffered or incurred directly or indirectly by us in consequence of such use of this site and the services or provision of content or your breach or non-observance of any of these Terms and conditions.&nbsp;</span></p>
        <p style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black', fontWeight: 'bold'}}><span style={{fontFamily: 'Montserrat'}}>How we may use your personal information</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>We will only use your personal information as set out in our privacy policy or data privacy policy and related policies as issued from time to time.</span></p>
        <p style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black', fontWeight: 'bold'}}><span style={{fontFamily: 'Montserrat'}}>Uploading content to our site</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>Whenever you make use of a feature that allows you to upload content to our site, or to make contact with other users of our site, you must comply with the content standards set out in our Acceptable Use Policy&nbsp;</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>You warrant and represent that any such contribution which does comply with those standards, you will be liable to us and fully indemnify us for any breach of that warranty or representation. This means you will be responsible for any liability, claims, cost, loss and or damage we suffer or likely to suffer as a result of your breach of warranty, representation or breach of this policy.</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>Any content you upload to our site will be considered non-confidential and non-proprietary. You retain all of your ownership rights in your content, but you are required to grant us and other users of our site a non-limited, unconditional and exclusive licence to use, store and copy that content and to distribute and make it available to third parties.&nbsp;</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>We also have the right to disclose your identity to any third party who is claiming that any content posted or uploaded by you to our site constitutes a violation of their intellectual property rights, or of their right to privacy and or to regulators.</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>We have the right to remove any posting you make on our site if, in our opinion, your post does not comply with the content standards set out in our Acceptable Use Policy.</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>You are solely responsible for securing and backing up your content.</span></p>
        <p style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black', fontWeight: 'bold'}}><span style={{fontFamily: 'Montserrat'}}>Rights you are giving us to use material you upload</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>When you upload, insert or post content to our site, you grant us the following rights to use that content:</span></p>
        <ul style={{listStyleType: 'disc'}}>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>a worldwide, non-exclusive, non-revocable, unlimited, royalty-free, transferable licence to use, reproduce, distribute, prepare derivative works of, display, and perform that user-generated content in connection with the service provided by the site and across different media including to promote the site or the service for other users, partners or advertisers.</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>a worldwide, non-exclusive, non-revocable, unlimited, royalty-free, transferable licence for other users, partners or advertisers to use the content for their purposes or in accordance with the functionality of the site.</span></li>
        </ul>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', textIndent: '0cm', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>We are not responsible for viruses and you must not introduce them</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>We do not guarantee that our site will be secure or free from bugs or viruses.</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>You are responsible for configuring your information technology, computer programmes and platform to access our site. You should use your own virus protection software.</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>You must not misuse our site by introducing viruses, trojans, worms, logic bombs or other material that is malicious or technologically harmful. You must not attempt to gain unauthorised access to our site, the server on which our site is stored or any server, computer or database connected to our site. You must not attack our site via a denial-of-service attack or a distributed denial-of service attack. By breaching this provision, you would commit a criminal offence under the applicable laws. We will report any such breach to the relevant law enforcement authorities and we will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use our site will cease immediately.</span></p>
        <p style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black', fontWeight: 'bold'}}><span style={{fontFamily: 'Montserrat'}}>Rules about linking to our site</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>You may link to our home page, provided you do so in a way that is fair, legal and as agreed with us in writing, provided such does not damage our reputation or take advantage of it.</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>You must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists.</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>You must not establish a link to our site in any site that is not owned by you.</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>Our site must not be framed on any other site, nor may you create a link to any part of our site other than the home page.</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>We reserve the right to withdraw linking permission without notice.</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>The site/platform in which you are linking must comply in all respects with the content standards set out in our Acceptable Use Policy.</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>If you wish to link to or make any use of content on our site other than that set out above, please contact&nbsp;</span><span style={{fontFamily: 'Montserrat', color: '#0070C0'}}><a href="mailto:info@coralpay.com"><em><span style={{color: '#0070C0'}}>info@coralpay.com</span></em></a></span><span style={{fontFamily: 'Montserrat'}}>.</span></p>
        <p style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black', fontWeight: 'bold'}}><span style={{fontFamily: 'Montserrat'}}>Breach of this policy</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>When we consider that a breach of this policy has occurred, we may take such action as we deem appropriate. &nbsp;</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>Failure to comply with this policy constitutes a material breach, upon which you are permitted to use, navigate, access or visit our site, and may result in our taking all or any of the following actions:</span></p>
        <ul style={{listStyleType: 'disc', marginLeft: '43.85px'}}>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>Immediate, temporary or permanent withdrawal of your right to navigate visit, use or access our site.</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>Immediate, temporary or permanent removal of any content uploaded by you (directly or indirectly) to our site.</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>Issue of a warning to you.</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>Legal proceedings against you for reimbursement of all costs, liabilities, damages, and or claims on an indemnity basis (including, but not limited to, reasonable administrative and legal costs) resulting from the breach.</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>Further legal action against you.</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>Disclosure of such information to law enforcement authorities as we reasonably feel is necessary or as required by law.</span></li>
        </ul>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>We exclude our liability for all action we may take in response to breaches of this acceptable use policy. The actions we may take are not limited to those described above, and we may take any other action we reasonably deem appropriate.</span></p>
        <p style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black', fontWeight: 'bold'}}><span style={{fontFamily: 'Montserrat'}}>Transfer of this Contract</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black'}}><span style={{fontFamily: 'Montserrat'}}>We can transfer our rights and obligations under these terms to any third party.</span></p>
        <p style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '36.0pt', textAlign: 'justify', lineHeight: '15.0pt', fontSize: '15px', fontFamily: '"Arial",sans-serif', color: 'black', fontWeight: 'bold'}}><span style={{fontFamily: 'Montserrat'}}>Which country's laws apply to this Policy?</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0cm', marginBottom: '6.0pt', marginLeft: '36.0pt', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontFamily: 'Montserrat', color: 'black'}}>Please note that these terms of use, their subject matter and their formation (and any non-contractual disputes or claims), are governed by law</span><span style={{fontFamily: 'Montserrat'}}>s of the Federal Republic of <span style={{color: 'black'}}>Nigeria. You and we both agree to use all reasonable endeavors to settle any dispute or difference of opinion between them, arising from or in connection with this policy amicably through mutual discussion. However, if such cannot be amicably resolved between the Parties within ninety (90) business days after the occurrence of such dispute, we and or you may refer such dispute to mediation upon giving the other party 15 (fifteen) days written notice. The dispute will be submitted to and decided by Mediation at the Lagos Multidoor Courthouse.&nbsp;</span></span></p>
      </div>
            </div>
        </div>
    )
}

export default TermsAndConditions