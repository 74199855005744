import React from "react"

const PageNotFound = () => {
    return(
        <div>
            <div style={{width:"100%",height:"80vh", display:"flex", alignItems:"center", justifyContent:"center",background:"whitesmoke"}}>
                <h1 style={{fontSize:"40px", fontWeight:"800"}}>PAGE NOT FOUND</h1>
            </div>
        </div>
    )
}

export default PageNotFound