import React from "react";
import logo from "../assets/logo1-new.svg";
import Button from "./Button";
import search from "../assets/icons/search.svg";

const Header = () => {
  return (
    <></>
    // <div style={{ background: "#F9F9FB" }}>
    //   <nav className="border-gray-200 px-2 sm:px-4 py-2.5 rounded">
    //     <div className="container flex flex-wrap justify-between items-center mx-auto">
    //       <a href="https://flowbite.com/" className="flex items-center">
    //         <img src={logo} alt="logo" />
    //       </a>
    //       <div className="flex items-center md:order-2 btn-dev-header">
    //         <Button message="API" bgColor="#7B3F63"></Button>
    //         <Button message="Back to Main Home" bgColor="#7B3F63"></Button>
    //       </div>
    //       <div
    //         className="justify-between btn-dev-input items-center w-full md:flex md:w-auto md:order-1"
    //         id="mobile-menu-2"
    //         style={{ width: "50%" }}
    //       >
    //         <form className="form">
    //           <div
    //             style={{
    //               display: "flex",
    //               width: "100%",
    //               justifyContent: "center",
    //             }}
    //           >
    //             <img
    //               src={search}
    //               alt="email"
    //               style={{ transform: "translate(250%, 10%)" }}
    //             />
    //             <input
    //               type="text"
    //               className="subscribe-input"
    //               style={{ paddingLeft: "80px" }}
    //               placeholder="Search Documentation"
    //             />
    //           </div>
    //         </form>
    //       </div>
    //     </div>
    //   </nav>
    // </div>
  );
};

export default Header;
