import React from "react"
import DocumentationLink from "../../components/DocumentationLink";
import transQuery from "../../assets/tansquery.svg"

const TransactionQuery = () => {
    return(
        <div>
            <div style={{background:'#F9F9FB',width:'100%'}}>
                <div className="flex-section">
                    <DocumentationLink></DocumentationLink>
                    <div>
                        <div className="doc-content">
                            <h3>Transaction Query</h3>
                            <p>This operation is for merchants to query/check the status of a transaction. This enables the
                                merchant to be aware of payments made to the created accounts. This service is applicable
                                to both Dynamic and Static/Fixed Account Numbers.</p>
                            <div className="" style={{marginTop:"80px",marginBottom:"80px"}}>
                                <img src={transQuery} alt="responseCode"  className="doc-content-inner-img"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="doc-div-parent">
                    <div className="doc-div">
                        <table style={{border: '13px solid #7E3D64'}}>
                            <tr style={{border: '3px solid #7E3D64'}}>
                                <th>Description</th>
                                <th style={{border: '3px solid #7E3D64'}}>Field</th>
                                <th style={{border: '3px solid #7E3D64'}}>Description</th>
                                <th style={{border: '3px solid #7E3D64'}}>Optional/Mandatory</th>
                            </tr>
                            <tr style={{border: '3px solid #7E3D64'}}>
                                <td></td>
                                <td style={{border: '3px solid #7E3D64'}} >Client Id</td>
                                <td style={{border: '3px solid #7E3D64'}} >The Id of the merchant issued by  CoralPay after<br/> profiling</td>
                                <td style={{border: '3px solid #7E3D64'}} >Mandatory</td>
                            </tr>
                            <br />
                            <tr style={{border: '3px solid #7E3D64'}}>
                                <td></td>
                                <td style={{border: '3px solid #7E3D64'}} >Request Type</td>
                                <td style={{border: '3px solid #7E3D64'}}>The type of request the merchant/client is making. Bank <br/> Transfer by default</td>
                                <td style={{border: '3px solid #7E3D64'}}>Mandatory</td>
                            </tr>
                            <br />
                            <tr style={{border: '3px solid #7E3D64'}}>
                                <td></td>
                                <td style={{border: '3px solid #7E3D64'}} >Reference Number </td>
                                <td style={{border: '3px solid #7E3D64'}}>The transaction unique reference number</td>
                                <td style={{border: '3px solid #7E3D64'}}>Mandatory</td>
                            </tr>
                            <br />
                            <tr style={{border: '3px solid #7E3D64'}}>
                                <td></td>
                                <td style={{border: '3px solid #7E3D64'}} >Response Code</td>
                                <td style={{border: '3px solid #7E3D64'}}>The final response code of the operation</td>
                                <td style={{border: '3px solid #7E3D64'}}>Mandatory</td>
                            </tr>
                            <br />
                            <tr style={{border: '3px solid #7E3D64'}}>
                                <td></td>
                                <td style={{border: '3px solid #7E3D64'}} >Response Message</td>
                                <td style={{border: '3px solid #7E3D64'}}>The final response message of the operation</td>
                                <td style={{border: '3px solid #7E3D64'}}>Mandatory</td>
                            </tr>
                            <br />
                            <tr style={{border: '3px solid #7E3D64'}}>
                                <td style={{width:"116px"}}></td>
                                <td style={{border: '3px solid #7E3D64'}} >NipSessionID</td>
                                <td style={{border: '3px solid #7E3D64'}} >The session id for this transaction on NIP</td>
                                <td style={{border: '3px solid #7E3D64'}} ></td>
                            </tr>
                            <br />
                            <tr style={{border: '3px solid #7E3D64'}}>
                                <td></td>
                                <td style={{border: '3px solid #7E3D64'}} >TransDateTime</td>
                                <td style={{border: '3px solid #7E3D64'}}>The date and time for the transaction </td>
                                <td style={{border: '3px solid #7E3D64'}}></td>
                            </tr>
                            <br />
                            <tr style={{border: '3px solid #7E3D64'}}>
                                <td></td>
                                <td style={{border: '3px solid #7E3D64'}} >TransactionAmount </td>
                                <td style={{border: '3px solid #7E3D64'}}>The actual Amount that was paid into this account</td>
                                <td style={{border: '3px solid #7E3D64'}}></td>
                            </tr>
                            <br />
                            <tr style={{border: '3px solid #7E3D64'}}>
                                <td></td>
                                <td style={{border: '3px solid #7E3D64'}} >TransactionSource</td>
                                <td style={{border: '3px solid #7E3D64'}}>The Source Account Number</td>
                                <td style={{border: '3px solid #7E3D64'}}></td>
                            </tr>
                            <br />
                            <tr style={{border: '3px solid #7E3D64'}}>
                                <td></td>
                                <td style={{border: '3px solid #7E3D64'}} >SourceAccountName</td>
                                <td style={{border: '3px solid #7E3D64'}}>The Source Account Name</td>
                                <td style={{border: '3px solid #7E3D64'}}></td>
                            </tr>
                            <br />
                            <tr style={{border: '3px solid #7E3D64'}}>
                                <td></td>
                                <td style={{border: '3px solid #7E3D64'}} >SourceBank</td>
                                <td style={{border: '3px solid #7E3D64'}}>The Source Bank</td>
                                <td style={{border: '3px solid #7E3D64'}}></td>
                            </tr>
                        </table>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default TransactionQuery