import React from "react"
import guy from "../assets/guy.svg";

const PrivacyScreen = () => {
   return (
       <div>
           <div className="blog-header" style={{backgroundImage:`url(${guy})`}}>
               <div className="blog-wrapper-overlay blog">
                   <h3 className='small'>Privacy Statement</h3>
               </div>
           </div>
           <div className="isms-policy">
              
           <div>
        <h3 style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>Introduction</span></h3>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>Welcome to CoralPay Technology (Nigeria) Limited (“<strong>CoralPay</strong>”).&nbsp;</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>This Privacy policy between the CoralPay (RC No. 634364) of 152A, Prince Ade Odedina Street, Victoria Island, Lagos State, Nigeria (“<strong>Company</strong>, “<strong>our</strong>” “<strong>us</strong>” “<strong>we</strong>”) and you, constitutes our commitment to your privacy on our records, websites, platforms, and premises (“platform”).&nbsp;</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>We respects your privacy and is committed to protecting your personal data. This privacy policy will inform you as to how we look after your personal data when you navigate, visit or use our platform (regardless of where or how you navigate, visit or use it from) and tell you about your privacy rights and how the law protects you.&nbsp;</span></p>
        <h3 style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>Purpose of this privacy policy</span></h3>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>This privacy policy aims to give you information on how CoralPay collects and processes your personal data through your use of this platform, including any data you may provide through this&nbsp;</span><span style={{fontFamily: 'Montserrat'}}>platform.</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>This Privacy policy describes your privacy rights regarding our collection, processing, use, storage, sharing and protection of your personal data. It applies to our website and all database, applications, services, platforms, tools and physical contact with us, regardless of where, how you access or use them.&nbsp;</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>This platform is not intended for children and we do not knowingly collect data relating to children.</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>It is important that you read this privacy policy together with any other privacy policy or fair processing policy we may provide on specific occasions when we are collecting or processing personal data about you so that you are fully aware of how and why we are using your data. This privacy policy supplements other notices and privacy policies and is not intended to override them.&nbsp;</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>Consent</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat', fontWeight: 'normal'}}>Upon access to our platforms or use of our services, content, features, technologies or functions offered on our website, platforms or visit any of our offices for official or non-official purposes (collectively the “CoralPay Services”), or continuing to visit, navigate or use this platform,&nbsp;</span><span style={{fontFamily: 'Montserrat'}}>you acknowledge and confirm the acceptance and consent to this Privacy policy</span><span style={{fontFamily: 'Montserrat', fontWeight: 'normal'}}>. This privacy policy also governs the use of CoralPay Services and collaboration projects by our users and stakeholders, unless otherwise agreed through a valid written contract.&nbsp;</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat', fontWeight: 'normal'}}>We may amend this privacy policy at any time by posting a revised version on our platforms or placing such notice at conspicuous points at our office facilities. The revised version will be effective after publication.&nbsp;</span></p>
        <h3 style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>Controller</span></h3>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>On proactive measures, we have appointed a data protection officer (DPO) who is responsible for overseeing questions in relation to this privacy policy. If you have any questions about this privacy policy, privacy practices including any requests to exercise <em>your legal rights</em>, please contact the DPO by sending an email to&nbsp;</span><a href="mailto:Audit_compliance@coralpay.com"><em><span style={{fontFamily: 'Montserrat', color: '#2E74B5'}}>Audit_compliance@coralpay.com</span></em></a><span style={{fontFamily: 'Montserrat', color: '#2E74B5'}}>&nbsp;</span><span style={{fontFamily: 'Montserrat'}}>and&nbsp;</span><a href="mailto:Info@coralpay.com"><em><span style={{fontFamily: 'Montserrat', color: '#2E74B5'}}>Info@coralpay.com</span></em></a><span style={{fontFamily: 'Montserrat'}}>.&nbsp;</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>You have the right to make a complaint at any time to the National Information Technology Development Agency and Nigeria Data Protection Bureau- the regulators for data protection. We would, however, appreciate the chance to deal with your concerns before you approach the regulator, so kindly contact us in the first instance.&nbsp;</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>Changes to the privacy policy and your duty to inform us of changes</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>We keep our privacy policy under regular review.&nbsp;</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>It is important that the personal data we hold about you is accurate, up-to-date, and complete.&nbsp;</span><span style={{fontFamily: 'Montserrat'}}>Hence, we require that you ensure all your personal data, at all times, is accurate, up-to-date and complete to enable us comply with regulatory requirements and extant applicable laws.</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>Third-party links</span></p>
        <p style={{marginLeft: '0cm', textIndent: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>This platform may include links to third-party websites, plug-ins, platform and or applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party sites and are not responsible for their privacy statements and we are, therefore, not liable for how such third parties use or process your data. When you leave our platform, we encourage you to read the privacy policy of every website/platform you visit.</span></p>
        <ol style={{listStyleType: 'decimal'}}>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>The data we collect about you</span></li>
        </ol>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>Personal data, or personal information, means any information about an individual from which that person can be identified. It does not include data where the identity has been removed (anonymous data).</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>We may collect, use, process, store and or transfer different kinds of personal data about you which we have grouped together as follows:</span></p>
        <ul style={{listStyleType: 'disc'}}>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>Identity Data</span><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>&nbsp;includes first name, maiden name, last name, username or similar identifier, marital status, title, date of birth and gender.</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>Contact Data</span><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>&nbsp;includes billing address, delivery address, email address and telephone numbers.</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>Financial Data</span><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>&nbsp;includes bank account and payment details.</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>Transaction Data</span><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>&nbsp;includes details about payments to and from you and other details of products and services, or payments you have purchased/performed from/through us.</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>Technical Data</span><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>&nbsp;includes internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access this platform.&nbsp;</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>Profile Data</span><strong><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>&nbsp;</span></strong><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>includes&nbsp;your username and password, purchases or orders made by you, your interests, preferences, feedback and survey responses. If you know or suspect that anyone other than you knows your security details or profile data you must immediately notify us at&nbsp;</span><span style={{fontFamily: 'Montserrat', color: '#2E74B5', fontSize: '15px'}}><a href="mailto:Audit_compliance@coralpay.com"><span style={{color: '#2E74B5'}}>Audit_compliance@coralpay.com</span></a>&nbsp;</span><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>and&nbsp;</span><span style={{fontFamily: 'Montserrat', color: '#2E74B5', fontSize: '15px'}}><a href="mailto:Info@coralpay.com"><em><span style={{fontFamily: 'Montserrat', color: '#2E74B5'}}>Info@coralpay.com</span></em></a>.</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>Usage Data</span><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>&nbsp;includes information about how you use our platform, products and services.&nbsp;</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>Marketing, educational, sales and or Communications Data</span><strong><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>&nbsp;</span></strong><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>includes your preferences in receiving marketing from/through us and our third parties and your communication preferences.</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>Sensitive Data</span><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>&nbsp;includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health, and genetic and biometric data, criminal convictions and offences</span></li>
        </ul>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>We also collect, use and share&nbsp;</span><span style={{fontFamily: 'Montserrat'}}>aggregated data</span><span style={{fontFamily: 'Montserrat'}}>&nbsp;such as statistical or demographic data for any purpose (Aggregated Data). Aggregated Data could be derived from your personal data but is not considered personal data in law as this data will <strong>not</strong> directly or indirectly reveal your identity. For example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific website feature. However, if we combine or connect Aggregated Data with your personal data so that it can directly or indirectly identify you, we treat the combined data as personal data which will be used in accordance with this privacy policy.</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>If you fail to provide personal data</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>Where we need to collect personal data by law, or under the terms of a contract we have with you, and you fail to provide that data when requested, we may not be able to perform the contract we have or are trying to enter into with. In this case, we may have to cancel our service you have with us.&nbsp;</span></p>
        <ol style={{listStyleType: 'undefined'}}>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>How is your personal data collected?</span></li>
        </ol>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>We use different methods to collect data from and about you including through:</span></p>
        <ul style={{listStyleType: 'disc'}}>
          <li><strong><span style={{fontFamily: 'Montserrat'}}>Direct interactions</span></strong><strong><span style={{fontFamily: 'Montserrat'}}>.</span></strong><span style={{fontFamily: 'Montserrat'}}>&nbsp;You may give us your personal data by filling in forms/questionnaire or by corresponding with us by post, phone, email or otherwise. This includes personal data you provide or we collect when you:</span></li>
        </ul>
        <ul style={{listStyleType: 'disc', marginLeft: '43.85px'}}>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>subscribe for our products or services;</span></li>
        </ul>
        <ul style={{listStyleType: 'disc'}}>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>receive, use or enjoy our service (directly or indirectly);&nbsp;</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>use your computer, mobile phone or other electronic access device on our platforms;</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>create an account on our platforms;</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>subscribe to our service, products or publications;&nbsp;</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>request or accept marketing or promotions to be sent to you. This automatically applies for existing subscribers or customers;</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>enter a competition, promotion or survey; or</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>give us feedback or contact us.&nbsp;</span></li>
          <li><strong><span style={{fontFamily: 'Montserrat'}}>Automated technologies or interactions.</span></strong><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>&nbsp;As you interact with our platform, we will automatically collect Technical Data about your equipment, browsing actions and patterns. We collect this personal data by using cookies, server logs, and other similar technologies. We may also receive personal data about you if you visit other websites or use our platform or employing our cookies. Please see below for further details.</span></li>
          <li><strong><span style={{fontFamily: 'Montserrat'}}>Third parties or publicly available sources.</span></strong><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>&nbsp;We will receive personal data about you from various third parties and public sources, including those as set out below:&nbsp;</span>
            <ol style={{listStyleType: 'undefined'}}>
              <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>analytics providers</span><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>.</span></li>
              <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>advertising networks.</span></li>
              <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>Data, analytics, aggregation, or marketing companies.</span></li>
              <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>Open data source.</span></li>
              <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>Other third party or government sources.&nbsp;</span></li>
              <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>search information providers.</span></li>
              <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>providers of services, goods, utilities or products, including technical, or payment.</span></li>
              <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>data brokers or aggregators.</span></li>
              <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>publicly available sources.</span></li>
            </ol>
          </li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>How we use your personal data</span></li>
        </ul>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>We will collect, process and or use your personal data in the following circumstances:</span></p>
        <ul style={{listStyleType: 'disc'}}>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>Where we need to perform the contract we are about to enter into or have entered into with you.</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests.</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>Where we need to comply with a legal obligation.</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>Marketing communications. You have the right to withdraw consent to marketing at any time by contacting us.</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>Such other lawful basis that we will rely on to process your personal data.</span></li>
        </ul>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>Purposes for which we will use your personal data</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>We have set out below, in a table format, a description of all the ways we plan to use your personal data, and which of the legal bases we rely on to do so. We have also identified what our legitimate interests are where appropriate.</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>Note that we may process your personal data for more than one lawful ground depending on the specific purpose for which we are using your data. Please contact us if you need details about the specific legal ground we are relying on to process your personal data where more than one ground has been set out in the table below.&nbsp;</span></p>
        <table style={{borderCollapse: 'collapse', border: 'none'}}>
          <tbody>
            <tr>
              <td style={{width: '176.4pt', border: '1pt solid rgb(191, 191, 191)', padding: '0cm 5.4pt', verticalAlign: 'top'}}>
                <p><span style={{fontFamily: 'Montserrat'}}>Purpose/Activity</span></p>
              </td>
              <td style={{width: '94.5pt', borderColor: 'rgb(191, 191, 191) rgb(191, 191, 191) rgb(191, 191, 191) currentcolor', borderStyle: 'solid solid solid none', borderWidth: '1pt 1pt 1pt medium', borderImage: 'none 100% / 1 / 0 stretch', padding: '0cm 5.4pt', verticalAlign: 'top'}}>
                <p><strong><span style={{fontFamily: 'Montserrat'}}>Type of data</span></strong></p>
              </td>
              <td style={{width: '191.2pt', borderColor: 'rgb(191, 191, 191) rgb(191, 191, 191) rgb(191, 191, 191) currentcolor', borderStyle: 'solid solid solid none', borderWidth: '1pt 1pt 1pt medium', borderImage: 'none 100% / 1 / 0 stretch', padding: '0cm 5.4pt', verticalAlign: 'top'}}>
                <p><strong><span style={{fontFamily: 'Montserrat'}}>Lawful basis for processing, including basis of legitimate interest</span></strong></p>
              </td>
            </tr>
            <tr>
              <td style={{width: '176.4pt', borderColor: 'currentcolor rgb(191, 191, 191) rgb(191, 191, 191)', borderStyle: 'none solid solid', borderWidth: 'medium 1pt 1pt', borderImage: 'none 100% / 1 / 0 stretch', background: 'rgb(242, 242, 242)', padding: '0cm 5.4pt', verticalAlign: 'top'}}>
                <p><span style={{fontFamily: 'Montserrat'}}>To verify, register, or profile you as a visitor, user, subscriber or customer, and or conduct of appropriate due diligence, including know your customer requirements.</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>&nbsp;</span></p>
              </td>
              <td style={{width: '94.5pt', borderColor: 'currentcolor rgb(191, 191, 191) rgb(191, 191, 191) currentcolor', borderStyle: 'none solid solid none', borderWidth: 'medium 1pt 1pt medium', background: 'rgb(242, 242, 242)', padding: '0cm 5.4pt', verticalAlign: 'top'}}>
                <p><span style={{fontFamily: 'Montserrat'}}>(a) Identity&nbsp;</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(b) Contact</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(b) profile&nbsp;</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(c) Marketing</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(d) sensitive information</span></p>
              </td>
              <td style={{width: '191.2pt', borderColor: 'currentcolor rgb(191, 191, 191) rgb(191, 191, 191) currentcolor', borderStyle: 'none solid solid none', borderWidth: 'medium 1pt 1pt medium', background: 'rgb(242, 242, 242)', padding: '0cm 5.4pt', verticalAlign: 'top'}}>
                <p><span style={{fontFamily: 'Montserrat'}}>(a) Performance of a contract with you&nbsp;</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(b) for our legitimate interests&nbsp;</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(c) &nbsp;for compliance with a legal obligation of a contract with you</span></p>
              </td>
            </tr>
            <tr>
              <td style={{width: '176.4pt', borderColor: 'currentcolor rgb(191, 191, 191) rgb(191, 191, 191)', borderStyle: 'none solid solid', borderWidth: 'medium 1pt 1pt', borderImage: 'none 100% / 1 / 0 stretch', padding: '0cm 5.4pt', verticalAlign: 'top'}}>
                <p><span style={{fontFamily: 'Montserrat'}}>To process and provide our services, support and or products including:</span></p>
                <ol style={{listStyleType: 'undefined'}}>
                  <li><span style={{fontFamily: 'Montserrat'}}>Manage payments, fees and charges</span></li>
                  <li><span style={{fontFamily: 'Montserrat'}}>process or switch transactions or payment instructions, and send notices about your transactions or payments to requisite stakeholders</span></li>
                  <li><span style={{fontFamily: 'Montserrat'}}>Collect, set-off &nbsp;and or recover money owed to us</span></li>
                  <li><span style={{fontFamily: 'Montserrat'}}>resolve disputes, collect fees, settlement, chargeback or dispute management, and troubleshoot problems&nbsp;</span></li>
                  <li><span style={{fontFamily: 'Montserrat'}}>manage risk, or to detect, prevent, and/or remediate fraud or other potentially prohibited or illegal activities</span></li>
                </ol>
              </td>
              <td style={{width: '94.5pt', borderColor: 'currentcolor rgb(191, 191, 191) rgb(191, 191, 191) currentcolor', borderStyle: 'none solid solid none', borderWidth: 'medium 1pt 1pt medium', padding: '0cm 5.4pt', verticalAlign: 'top'}}>
                <p><span style={{fontFamily: 'Montserrat'}}>(a) Identity&nbsp;</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(b) Contact&nbsp;</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(c) Financial&nbsp;</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(d) Transaction&nbsp;</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(e) Marketing and Communications</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(f) profile</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(g) sensitive</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(h) Technical</span></p>
              </td>
              <td style={{width: '191.2pt', borderColor: 'currentcolor rgb(191, 191, 191) rgb(191, 191, 191) currentcolor', borderStyle: 'none solid solid none', borderWidth: 'medium 1pt 1pt medium', padding: '0cm 5.4pt', verticalAlign: 'top'}}>
                <p><span style={{fontFamily: 'Montserrat'}}>(a) Performance of a contract with you&nbsp;</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(b) for our legitimate interests&nbsp;</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(c) &nbsp;for compliance with a legal obligation</span></p>
              </td>
            </tr>
            <tr>
              <td style={{width: '176.4pt', borderColor: 'currentcolor rgb(191, 191, 191) rgb(191, 191, 191)', borderStyle: 'none solid solid', borderWidth: 'medium 1pt 1pt', borderImage: 'none 100% / 1 / 0 stretch', background: 'rgb(242, 242, 242)', padding: '0cm 5.4pt', verticalAlign: 'top'}}>
                <p><span style={{fontFamily: 'Montserrat'}}>To manage our relationship with you which will include:</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(a) Notifying you about changes to our terms or policies</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(b) Asking you to leave a review or take a survey</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(c) subscribing to our newsletter, marketing or communications</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(d)&nbsp;</span><span style={{fontFamily: 'Montserrat'}}>detect, prevent or remediate violation of laws, regulations, standards, guidelines and frameworks</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(e) improve our services/products by implementing aggregate customer or user preferences;&nbsp;</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(f) measure the performance of our service/products and improve content, technology and layout</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>&nbsp;</span></p>
              </td>
              <td style={{width: '94.5pt', borderColor: 'currentcolor rgb(191, 191, 191) rgb(191, 191, 191) currentcolor', borderStyle: 'none solid solid none', borderWidth: 'medium 1pt 1pt medium', background: 'rgb(242, 242, 242)', padding: '0cm 5.4pt', verticalAlign: 'top'}}>
                <p><span style={{fontFamily: 'Montserrat'}}>(a) Identity&nbsp;</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(b) Contact&nbsp;</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(c) Profile&nbsp;</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(d) Marketing and Communications</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(e) usage</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(f) Technical</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(g) Transaction</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(h) Financial</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(i) sensitive information</span></p>
              </td>
              <td style={{width: '191.2pt', borderColor: 'currentcolor rgb(191, 191, 191) rgb(191, 191, 191) currentcolor', borderStyle: 'none solid solid none', borderWidth: 'medium 1pt 1pt medium', background: 'rgb(242, 242, 242)', padding: '0cm 5.4pt', verticalAlign: 'top'}}>
                <p><span style={{fontFamily: 'Montserrat'}}>(a) Performance of a contract with you&nbsp;</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(b) to comply with a legal obligation</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(c) for our legitimate interests&nbsp;</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(d) to keep our records updated and to study how customers utilize or enjoy our products/services)</span></p>
              </td>
            </tr>
            <tr>
              <td style={{width: '176.4pt', borderColor: 'currentcolor rgb(191, 191, 191) rgb(191, 191, 191)', borderStyle: 'none solid solid', borderWidth: 'medium 1pt 1pt', borderImage: 'none 100% / 1 / 0 stretch', padding: '0cm 5.4pt', verticalAlign: 'top'}}>
                <ol style={{listStyleType: 'undefined', marginLeft: '0cmundefined'}}>
                  <li><span style={{fontFamily: 'Montserrat'}}>To enable you to partake in a prize draw, vouchers, discounts, competition or complete a survey.</span></li>
                  <li><span style={{fontFamily: 'Montserrat'}}>To contact you at any time through your provided telephone number, email address or other contact details</span></li>
                </ol>
                <p><span style={{fontFamily: 'Montserrat'}}>&nbsp;</span></p>
              </td>
              <td style={{width: '94.5pt', borderColor: 'currentcolor rgb(191, 191, 191) rgb(191, 191, 191) currentcolor', borderStyle: 'none solid solid none', borderWidth: 'medium 1pt 1pt medium', padding: '0cm 5.4pt', verticalAlign: 'top'}}>
                <p><span style={{fontFamily: 'Montserrat'}}>(a) Identity&nbsp;</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(b) Contact&nbsp;</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(c) Profile&nbsp;</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(d) Usage&nbsp;</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(e) Marketing and Communications</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(f) Technical</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(g) Financial</span></p>
              </td>
              <td style={{width: '191.2pt', borderColor: 'currentcolor rgb(191, 191, 191) rgb(191, 191, 191) currentcolor', borderStyle: 'none solid solid none', borderWidth: 'medium 1pt 1pt medium', padding: '0cm 5.4pt', verticalAlign: 'top'}}>
                <p><span style={{fontFamily: 'Montserrat'}}>(a) Performance of a contract with you&nbsp;</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(b) to comply with a legal obligation</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(c) for our legitimate interests&nbsp;</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(d) to study how customers use our products/services, to develop them, add value or value creation, and grow our business)</span></p>
              </td>
            </tr>
            <tr>
              <td style={{width: '176.4pt', borderColor: 'currentcolor rgb(191, 191, 191) rgb(191, 191, 191)', borderStyle: 'none solid solid', borderWidth: 'medium 1pt 1pt', borderImage: 'none 100% / 1 / 0 stretch', background: 'rgb(242, 242, 242)', padding: '0cm 5.4pt', verticalAlign: 'top'}}>
                <ol style={{listStyleType: 'undefined', marginLeft: '0cmundefined'}}>
                  <li><span style={{fontFamily: 'Montserrat'}}>To administer and protect our business and this platform (including troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data).&nbsp;</span></li>
                  <li><span style={{fontFamily: 'Montserrat'}}>track information breach and remediate such identified breaches</span></li>
                  <li><span style={{fontFamily: 'Montserrat'}}>&nbsp;manage and protect our information technology, system, platform, property and or infrastructure</span></li>
                </ol>
                <p><span style={{fontFamily: 'Montserrat'}}>&nbsp;</span></p>
              </td>
              <td style={{width: '94.5pt', borderColor: 'currentcolor rgb(191, 191, 191) rgb(191, 191, 191) currentcolor', borderStyle: 'none solid solid none', borderWidth: 'medium 1pt 1pt medium', background: 'rgb(242, 242, 242)', padding: '0cm 5.4pt', verticalAlign: 'top'}}>
                <p><span style={{fontFamily: 'Montserrat'}}>(a) Identity</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(b) Contact</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(c) Technical</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(d) financial</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(e) usage</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(f) transaction</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(g) sensitive</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(h) profile</span></p>
              </td>
              <td style={{width: '191.2pt', borderColor: 'currentcolor rgb(191, 191, 191) rgb(191, 191, 191) currentcolor', borderStyle: 'none solid solid none', borderWidth: 'medium 1pt 1pt medium', background: 'rgb(242, 242, 242)', padding: '0cm 5.4pt', verticalAlign: 'top'}}>
                <p><span style={{fontFamily: 'Montserrat'}}>(a) for our legitimate interests&nbsp;</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(b) for running our business, provision of administration and IT services, network security, to prevent fraud and in the context of a business re-organisation or group restructuring exercise</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(c) to comply with a legal obligation</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(d) Performance of a contract with you</span></p>
              </td>
            </tr>
            <tr>
              <td style={{width: '176.4pt', borderColor: 'currentcolor rgb(191, 191, 191) rgb(191, 191, 191)', borderStyle: 'none solid solid', borderWidth: 'medium 1pt 1pt', borderImage: 'none 100% / 1 / 0 stretch', padding: '0cm 5.4pt', verticalAlign: 'top'}}>
                <p><span style={{fontFamily: 'Montserrat'}}>To deliver relevant platform content, promotional, communications and advertisements to you and measure or understand the effectiveness of the promotional, communication and or marketing/advertisement we serve to you.</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>To contact you at any time through your provided telephone number, email address or other contact details</span></p>
              </td>
              <td style={{width: '94.5pt', borderColor: 'currentcolor rgb(191, 191, 191) rgb(191, 191, 191) currentcolor', borderStyle: 'none solid solid none', borderWidth: 'medium 1pt 1pt medium', padding: '0cm 5.4pt', verticalAlign: 'top'}}>
                <p><span style={{fontFamily: 'Montserrat'}}>(a) Identity&nbsp;</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(b) Contact&nbsp;</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(c) Profile&nbsp;</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(d) Usage&nbsp;</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(e) Marketing, educational, sales, and Communications&nbsp;</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(f) Technical&nbsp;</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(g) Sensitive</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(h) Financial</span></p>
              </td>
              <td style={{width: '191.2pt', borderColor: 'currentcolor rgb(191, 191, 191) rgb(191, 191, 191) currentcolor', borderStyle: 'none solid solid none', borderWidth: 'medium 1pt 1pt medium', padding: '0cm 5.4pt', verticalAlign: 'top'}}>
                <p><span style={{fontFamily: 'Montserrat'}}>(a) for our legitimate interests&nbsp;</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(b) to study how customers use our products/services, to develop them, to grow our business and to inform our marketing strategy</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(c) to comply with a legal obligation</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(d) Performance of a contract with you</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(e) to protect your vital interests<br />&nbsp;(f) performance of a task carried out in the public interest or in exercise of official public mandate</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>&nbsp;</span></p>
              </td>
            </tr>
            <tr>
              <td style={{width: '176.4pt', borderColor: 'currentcolor rgb(191, 191, 191) rgb(191, 191, 191)', borderStyle: 'none solid solid', borderWidth: 'medium 1pt 1pt', borderImage: 'none 100% / 1 / 0 stretch', background: 'rgb(242, 242, 242)', padding: '0cm 5.4pt', verticalAlign: 'top'}}>
                <p><span style={{fontFamily: 'Montserrat'}}>To use data analytics, mining or aggregation to improve our platform, products/services, marketing, customer relationships and experiences</span></p>
              </td>
              <td style={{width: '94.5pt', borderColor: 'currentcolor rgb(191, 191, 191) rgb(191, 191, 191) currentcolor', borderStyle: 'none solid solid none', borderWidth: 'medium 1pt 1pt medium', background: 'rgb(242, 242, 242)', padding: '0cm 5.4pt', verticalAlign: 'top'}}>
                <p><span style={{fontFamily: 'Montserrat'}}>(a) Technical&nbsp;</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(b) Usage&nbsp;</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(d) Contact</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(e) identity</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(f) transaction</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(g) Profile</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(h) Marketing, educational, sales, and Communications</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(i) Sensitive</span></p>
              </td>
              <td style={{width: '191.2pt', borderColor: 'currentcolor rgb(191, 191, 191) rgb(191, 191, 191) currentcolor', borderStyle: 'none solid solid none', borderWidth: 'medium 1pt 1pt medium', background: 'rgb(242, 242, 242)', padding: '0cm 5.4pt', verticalAlign: 'top'}}>
                <p><span style={{fontFamily: 'Montserrat'}}>(a) Necessary for our legitimate interests&nbsp;</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(b) to define types of customers for our products and services, to keep our platform updated and relevant, to develop our business, to inform/scale our marketing strategy and for value creation</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(c) to comply with a legal obligation</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(d) Performance of a contract with you</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(e) to protect your vital interests<br />&nbsp;(f) performance of a task carried out in the public interest or in exercise of official public mandate</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>&nbsp;</span></p>
              </td>
            </tr>
            <tr>
              <td style={{width: '176.4pt', borderColor: 'currentcolor rgb(191, 191, 191) rgb(191, 191, 191)', borderStyle: 'none solid solid', borderWidth: 'medium 1pt 1pt', borderImage: 'none 100% / 1 / 0 stretch', padding: '0cm 5.4pt', verticalAlign: 'top'}}>
                <p><span style={{fontFamily: 'Montserrat'}}>To make suggestions and recommendations to you about products or services that may be of interest to you</span></p>
              </td>
              <td style={{width: '94.5pt', borderColor: 'currentcolor rgb(191, 191, 191) rgb(191, 191, 191) currentcolor', borderStyle: 'none solid solid none', borderWidth: 'medium 1pt 1pt medium', padding: '0cm 5.4pt', verticalAlign: 'top'}}>
                <p><span style={{fontFamily: 'Montserrat'}}>(a) Identity&nbsp;</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(b) Contact&nbsp;</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(c) Technical&nbsp;</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(d) Usage&nbsp;</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(e) Profile&nbsp;</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(f) Marketing and Communications</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(g) Sensitive</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(h) Transaction</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(i) Financial</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>&nbsp;</span></p>
              </td>
              <td style={{width: '191.2pt', borderColor: 'currentcolor rgb(191, 191, 191) rgb(191, 191, 191) currentcolor', borderStyle: 'none solid solid none', borderWidth: 'medium 1pt 1pt medium', padding: '0cm 5.4pt', verticalAlign: 'top'}}>
                <p><span style={{fontFamily: 'Montserrat'}}>(a) Necessary for our legitimate interests&nbsp;</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(b) to develop our products/services, create value, and grow our business</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>(c) Performance of a contract with you</span></p>
                <p><span style={{fontFamily: 'Montserrat'}}>&nbsp;</span></p>
              </td>
            </tr>
          </tbody>
        </table>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>Marketing&nbsp;</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>We strive to provide you with choices regarding certain personal data uses, particularly around marketing, sales, promotions and or advertising.&nbsp;</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>Promotional offers from/through us&nbsp;</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>We may use your personal data to form a view on what we think you may want or need, or what may be of interest to you. This is how we decide which products, services and offers may be relevant for you (we call this marketing).&nbsp;</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>You will receive marketing communications from/through us if you have requested information from/through us or purchased, received/used our goods or services from /through us and you have not opted out of receiving that marketing.</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>Opting out</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>You can ask us to stop sending you marketing messages at any time by contacting us at any time.&nbsp;</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>Where you opt out of receiving these marketing messages, this will not apply to personal data provided to us as a result of a product purchase, service provision, product/service experience or other transactions.</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>Cookies</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>Our platform uses cookies to distinguish you from other persons, other than you. This helps us to provide you with a good experience when you browse, navigate or visit us and also allows us to improve our services or platform.&nbsp;</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>You can set your browser to refuse all or some browser cookies. If you disable or refuse cookies, please note that some parts of this platform may become inaccessible or not function properly.&nbsp;</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>Please note that third parties (including, for example, advertising networks and providers of external services like web traffic analysis services) may also use cookies, over which we have no control. These cookies are likely to be analytical/performance cookies or targeting cookies.</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>You block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our platform.</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>For more information on our cookie policy, kindly find details at url link</span><span style={{fontSize: '11px', fontFamily: 'Montserrat'}}><a href="#_msocom_1" id="_anchor_1" language="JavaScript" name="_msoanchor_1">[GO1]</a>&nbsp;</span><span style={{fontFamily: 'Montserrat'}}>.</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>Change of purpose&nbsp;</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>We will only use your personal data for the purposes for which we collected it, unless we consider that we need to use it for another reason and that reason is compatible or similar with the original purpose. If you wish to get an explanation as to how the processing for the new purpose is compatible with the original purpose, please contact us.&nbsp;</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>If we need to use your personal data for an unrelated purpose not contemplated or contained in this policy, we will notify you and we will explain the legal basis which allows us to do so.</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>Please note that we may process your personal data without your knowledge or consent, in compliance with the above rules, where this is required or permitted by law.</span></p>
        <ol style={{listStyleType: 'undefined'}}>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>Disclosures of your personal data</span></li>
        
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>We may share your personal data with the&nbsp;</span><span style={{fontFamily: 'Montserrat'}}>third parties.</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>Please contact us if you want further information on the specific mechanism used by us when transferring your personal data out of Nigeria.&nbsp;</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>Whenever we transfer your personal data out of Nigeria, we ensure a similar degree of protection is afforded to it by ensuring at least one of the following safeguards is implemented:&nbsp;</span></p>
        <ul style={{listStyleType: 'disc', marginLeft: '12.5px'}}>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>We may only transfer your personal data to countries that have been deemed to provide an adequate level of protection for personal data, as published by NITDA as “Whitelist Countries”, from time to time.&nbsp;</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>Where we use designated service providers, we may use specific contracts approved for use in Nigeria which give personal data the same protection it has in Nigeria.</span></li>
        </ul>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>Data security</span></li>
    
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality.</span></p>
        <p style={{marginLeft: '0cm', textIndent: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.</span></p>
   
        

          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>Data retention</span></li>

        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>How long will you use my personal data for?</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>We will only retain your personal data for period of seven years and as long as reasonably necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, regulatory, tax, accounting or reporting requirements. We may retain your personal data for a longer period in the event of a complaint or if we reasonably believe there is a prospect of litigation in respect to our relationship with you.</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>To determine the appropriate retention period for personal data, we consider the amount, nature and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal, regulatory, reporting, tax, accounting or other requirements.</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>Anonymized statistical data: When you use our services, we may create anonymized statistical data from your data and usage of our services, including through aggregation. Once anonymized, we may use it for our own purposes or for that of our partners/affiliates, such as to provide and improve our services, to develop new services or product offerings, to identify business trends, and for other uses further to our business and corporate interest.&nbsp;</span></p>

          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>Your legal rights</span></li>

        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>Under certain circumstances, you have rights under data protection laws in relation to your personal data.&nbsp;</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>If you wish to exercise any of the rights set out above, please contact us.&nbsp;</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>No fee usually required</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we could refuse to comply with your request in these circumstances.</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>What we may need from you</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>Time limit to respond</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>We try to respond to all legitimate requests within one month. Occasionally it could take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.&nbsp;</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>Transfer of this Contract&nbsp;</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>We can transfer our rights and obligations under these terms to any third party.</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>Indemnity</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>You hereby agree to fully indemnify and hold us harmless from and against any and all claims brought by a third party resulting from the collection, use and or processing (or otherwise) of your data and in respect of all costs, actions, losses, claims, proceedings, damages, expenses (including reasonable legal costs and expenses), or liabilities whatsoever suffered or incurred directly or indirectly by us in consequence of such collection, usage and or processing or your breach or non-observance of any of these policy (for example, but not limited to ensuring that your personal data is accurate, up-to-date and complete).</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>Which country's laws apply to this Policy?</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>Please note that the terms of this policy, its subject matter and its formation&nbsp;</span><span style={{fontFamily: 'Montserrat'}}>(and any non-contractual disputes or claims)&nbsp;</span><span style={{fontFamily: 'Montserrat'}}>are governed by the laws of the Federal Republic of Nigeria.&nbsp;</span><span style={{fontFamily: 'Montserrat'}}>You and we both agree to use all reasonable endeavors to settle any dispute or difference of opinion between them, arising from or in connection with this policy amicably through mutual discussion. However, if such cannot be amicably resolved between the Parties within ninety (90) business days after the occurrence of such dispute, we and or you may refer such dispute to mediation upon giving the other party 15 (fifteen) days written notice. The dispute will be submitted to and decided by Mediation at the Lagos Multidoor Courthouse.&nbsp;</span></p>

          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>Glossary</span></li>
        </ol>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>LAWFUL BASIS</span></p>
        <p style={{marginLeft: '0cm'}}><strong><span style={{fontFamily: 'Montserrat'}}>Data Portability</span></strong><span style={{fontFamily: 'Montserrat'}}>&nbsp;means the ability for data to be transferred easily from one IT system or computer to another through a safe and secured means in a standard format</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>Legitimate Interest</span><span style={{fontFamily: 'Montserrat'}}>&nbsp;means the interest of our business in conducting and managing our business to enable us to give you the best service/product and the best and most secure experience. We consider and balance any potential impact on you (both positive and negative) and your rights before we process your personal data for our legitimate interests. We do not use your personal data for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted to by law). You can obtain further information about how we assess our legitimate interests against any potential impact on you in respect of specific activities by contacting us.</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>Performance of Contract</span><span style={{fontFamily: 'Montserrat'}}>&nbsp;means processing your data where it is necessary or in contemplation for the performance of a contract to which you are a party or to take steps at or your request before/after entering into such a contract.</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>Comply with a legal obligation</span><span style={{fontFamily: 'Montserrat'}}>&nbsp;means processing your personal data where it is necessary for compliance with a legal obligation that we are subject to.</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>YOUR LEGAL RIGHTS</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>You have the right to:</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>Request access</span><strong><span style={{fontFamily: 'Montserrat'}}>&nbsp;</span></strong><span style={{fontFamily: 'Montserrat'}}>to your personal data (commonly known as a "data subject access request"). This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it.</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>Request correction</span><strong><span style={{fontFamily: 'Montserrat'}}>&nbsp;or right of rectification&nbsp;</span></strong><span style={{fontFamily: 'Montserrat'}}>of the personal data that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected or completed, though we may need to verify the accuracy of the new data you provide to us.</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>Right to be forgotten or Request erasure</span><strong><span style={{fontFamily: 'Montserrat'}}>&nbsp;</span></strong><span style={{fontFamily: 'Montserrat'}}>of your personal data. This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.&nbsp;</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>Object to processing</span><strong><span style={{fontFamily: 'Montserrat'}}>&nbsp;</span></strong><span style={{fontFamily: 'Montserrat'}}>of your personal data where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>Request restriction of processing</span><strong><span style={{fontFamily: 'Montserrat'}}>&nbsp;</span></strong><span style={{fontFamily: 'Montserrat'}}>of your personal data. This enables you to ask us to suspend the processing of your personal data in the following scenarios:&nbsp;</span></p>
        <ul style={{listStyleType: 'disc'}}>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>If you want us to establish the data's accuracy.</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>Where our use of the data is unlawful but you do not want us to erase it.</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>Where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims.&nbsp;</span></li>
          <li><span style={{fontFamily: 'Montserrat', fontSize: '15px'}}>You have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it.&nbsp;</span></li>
        </ul>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>Right to Data Portability or Request the transfer</span><strong><span style={{fontFamily: 'Montserrat'}}>&nbsp;</span></strong><span style={{fontFamily: 'Montserrat'}}>of your personal data to you or to a third party. We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format.&nbsp;</span><span style={{fontFamily: 'Montserrat'}}>Provided that this right shall not apply to processing necessary for the performance of a task carried out in the public interest or in the exercise of official authority vested in the Controller.</span></p>
        <p style={{marginLeft: '0cm'}}><span style={{fontFamily: 'Montserrat'}}>Withdraw consent at any time</span><strong><span style={{fontFamily: 'Montserrat'}}>&nbsp;</span></strong><span style={{fontFamily: 'Montserrat'}}>where we are relying on consent to process your personal data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you.&nbsp;</span></p>
        <div id="_com_1" language="JavaScript">
         
        </div>
      </div>
           </div>
       </div>
   )
}

export default PrivacyScreen