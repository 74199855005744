export const initializeVergClass = `
String baseUrl = "https://testdev.coralpay.com:5000/GwApi/api/v1"; // Replace with actual Verge API base URL
String token = "your_auth_token"; // You will receive this after authentication

VergeServices vergeService = new VergeServices(baseUrl, token);
`;
export const authenticationRequestStructure = `
public class AuthenticationRequest {

    public String username;
    public String password;

    public String terminalId;

}

`;
export const authenticationResponseStructure = `
public class AuthenticationResponse {

    public String token;
    public String key;
    public String status;
}

`;

export const baseUrlText = `
const baseUrl = "https://testdev.coralpay.com:5000/GwApi"; //replace with the actual verge base URL
`;

export const authenticateCode = `
AuthenticationRequest authenticationRequest = new AuthenticationRequest(){
    {
       username = "your_username";
       password = "your_password";
       terminalId = "your_terminal_id";
     }
 };

AuthenticationResponse authenticationResponse = vergeService.Authentication(authenticationRequest);

System.out.println("Key : " + authenticationResponse.key);
System.out.println("Token : " + authenticationResponse.token);
System.out.println("Status : " + authenticationResponse.status);
`;

export const invokePaymentRequest = `
public class PaymentRequest {
    public RequestHeader requestHeader;
    public Customer customer;
    public Customization customization;
    public MetaData metaData;
    public String traceId;
    public String productId;
    public Double amount;
    public String currency;
    public String feeBearer;
    public String returnUrl;
    public Settlement settlement;

}

`;

export const invokePaymentFunction = `
// First, generate the signature and timestamp
SignatureResponse signatureResponse = vergeService.GetSignature(
      new SignatureRequest()
       {
           {
              merchantId = "your_merchant_id";
              traceId = "unique_trace_id";
              key = "your_secret_key",  // You get this from the Verge dashboard;
              shaType = SHA.SHA256;
           }
       }
 );

List<Accounts> accountList = new ArrayList<>();

        // Add Accounts to the list
        accountList.add(
                new Accounts(){
                    {
                        AccountId = 101;
                        Amount = 75.50;
                    }
                }
        );
        accountList.add(
                new Accounts(){
                    {
                        AccountId = 102;
                        Amount = 75.250;
                    }
                }
        );

// Create a new PaymentRequest object

        PaymentRequest paymentRequest = new PaymentRequest(){
            {
               requestHeader = new RequestHeader() {
                    {
                        merchantId = "your_merchant_id";
                        terminalId = "";
                        timeStamp= signatureResponse.timeStamp;
                        signature = signatureResponse.signature;
                    }
                };

                customer = new Customer(){
                    {
                        email = "babavoss@see.com";
                        name = "Baba Voss";
                        phone = "080300000001";
                        tokenUserId = "080300000001";
                    }
                };


                customization = new Customization(){
                    {
                        logoUrl = "http://sampleurl.com";
                        title = "Watermarks Limited";
                        description = "Service Payment";
                    }
                };

                metaData = new MetaData(){
                    {
                        data1 = "sample data";
                        data2 = "another sample data";
                        data3 = "sample info";
                    }
                };

                traceId = "1234567894";
                productId = "aa12443d";
                amount = 2000.00;
                currency = "NGN";
                feeBearer= "M";
                returnUrl = "http://samplereturnurl.com/status";
                settlement = new Settlement(){
                    {
                        settlementType = "DYNAMIC";
                        splitCode = "SPLIT_CODE_001";
                        settlementInstruction = accountList;
                    }
                };
            }
        };

PaymentResponse paymentResponse = vergeService.InvokePayment(paymentRequest);
System.out.println("Response Code: " + paymentResponse.responseHeader.responseCode);
System.out.println("Response Message: " + paymentResponse.responseHeader.responseMessage);
System.out.println("Signature: " + paymentResponse.responseHeader.signature);
System.out.println("TransactionId: " + paymentResponse.transactionId);
System.out.println("TraceId: " + paymentResponse.traceId);
System.out.println("Pay Page Link: " + paymentResponse.payPageLink);
System.out.println("Meta Data: " + paymentResponse.metaData);
`;

export const sampleResponse = `
const response= {
"token": "your_auth_token",
"key": "your_encryption_key",
"status": "Success"
}
`;

export const sampleInvokePaymentResponse = `
const response = {
"responseHeader": {
"responseCode": "00",
"responseMessage": "Success",
"timeStamp": 1609459200,
"signature": "response_signature"
},
"transactionId": "transaction_id",
"traceId": "trace_id",
"payPageLink": "https://pay.verge.com/payment-page",
"metaData": {}
}
`;

export const sampleRequestForTransactionQuery = `
public class TransactionQueryRequest {
    public RequestHeader requestHeader;
    public String traceId;
}
`;

export const transactionQueryFunction = `
SignatureResponse signatureResponse = vergeService.GetSignature(
      new SignatureRequest()
       {
           {
              merchantId = "your_merchant_id";
              traceId = "unique_trace_id";
              key = "your_secret_key",  // You get this from the Verge dashboard;
              shaType = SHA.SHA256;
           }
       }
 );

TransactionQueryRequest transactionQueryRequest = new TransactionQueryRequest(){
    {
      requestHeader = new RequestHeader() {
           {
             merchantId = "your_merchant_id";
             terminalId = "your_terminal_id";
             timeStamp= signatureResponse.timeStamp; // Use generated timestamp
             signature = signatureResponse.signature; // Use generated signature
            }
      };
      traceId = "your_trace_id";
};


TransactionQueryResponse queryResponse = vergeService.TransactionQuery(transactionQueryRequest);
if (queryResponse.responseCode.equals("00"))
   {
     System.out.println("Transaction successful");
   }
   else
   {
     System.out.println("Error: " + queryResponse.responseMessage);
   }
`;

export const tsqSampleResponsePayload = `
const response = {
"responseCode": "00",
"responseMessage": "Transaction successful",
"amount": 100.00,
"currency": "USD",
"traceId": "trace_id",
"transactionId": "transaction_id",
"refunds": []
}
`;

export const sampleRequestForGetAccountDetails = `
// Create an instance of GetAccountRequest

GetAccountRequest getAccountRequest = new GetAccountRequest(){
  {
     requestHeader = new RequestHeader() {
       {
          merchantId = "your_merchant_id";
          terminalId = "your_terminal_id";
          timeStamp= signatureResponse.timeStamp;
          signature = signatureResponse.signature;
        }
     };
     traceId = "unique_trace_id";
   }
};
`;

export const getAccountDetailsFunction = `
SignatureResponse signatureResponse = vergeService.GetSignature(
      new SignatureRequest()
       {
           {
              merchantId = "your_merchant_id";
              traceId = "unique_trace_id";
              key = "your_secret_key";
              shaType = SHA.SHA256;
           }
       }
 );

GetAccountRequest accountRequest = new GetAccountRequest(){
  {
     requestHeader = new RequestHeader() {
       {
          merchantId = "your_merchant_id";
          terminalId = "your_terminal_id";
          timeStamp= signatureResponse.timeStamp; // Use generated timestamp
          signature = signatureResponse.signature; // Use generated signature
        }
     };
     traceId = "unique_trace_id";
   }
};

GetAccountResponse accountResponse = vergeService.GetAccountDetails(accountRequest);

System.out.println("accountDetails: ");
for (AccountDetail account : accountResponse.accountDetails.accounts) {
    System.out.printf("{ bankCode : %s, accountNumber : %s, accountId : %s}%n", account.bankCode, account.accountNumber, account.accountId);
}
System.out.println("traceId : " + accountResponse.traceId);
System.out.println("timeStamp : " + accountResponse.timeStamp);
System.out.println("signature : " + accountResponse.signature);
System.out.println("responseCode : " + accountResponse.responseCode);
System.out.println("responseMessage : " + accountResponse.responseMessage);
`;

export const accountDetailsResponse = `
const response = {
"accountDetails": [
{
    "bankCode": "123",
    "accountNumber": "1234567890",
    "accountId": 22
},
{
    "bankCode": "456",
    "accountNumber": "1000002001",
    "accountId": 33
}
],
"traceId": "trace_id",
"timeStamp": 1726751419,
"signature": "signature",
"responseCode": "00",
"responseMessage": "Successful"
}
`;

export const sampleRequestForSignatureGeneration = `
public class SignatureRequest {

    public String merchantId;
    public String traceId;
    public String key;
    public SHA shaType; // SHA256 or SHA512
}

`;

export const sampleResponseForSignatureGeneration = `
public class SignatureResponse {

    public long timeStamp;
    public String signature;
}

`;

export const signatureGenerationFunction = `
SignatureRequest signatureRequest = new SignatureRequest(){
   {
       merchantId = "your_merchant_id";
       traceId = "unique_trace_id";
       key = "your_secret_key",  // You get this from the Verge dashboard;
       shaType = SHA.SHA256;
   }
 };

SignatureResponse signatureResponse = vergeService.GetSignature(signatureRequest);

long timeStamp = signatureResponse.timeStamp;
String signature = signatureResponse.signature;

System.out.println("Generated Signature: " + signature);
System.out.println("Timestamp : " + timeStamp);
`;

export const sampleSignatureResponse = `
const response = {
"timeStamp": 1609459200,
"signature": "generated_signature"
}
`;

export const sampleErrorHandling = `
PaymentResponse paymentResponse = vergeService.InvokePayment(paymentRequest);
if (!(paymentResponse.responseHeader.responseCode.equals("00")))
{
  System.out.println("Error: " + paymentResponse.responseHeader.responseMessage);
} 
else
{
  System.out.println("Payment initiated successfully");
}

`;
