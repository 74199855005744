import React, { useEffect } from "react";
import "../../App.css";
import Button from "../../components/Button";
import Card3 from "../../components/Card3";
import padd1 from "../../assets/Paddi1.png";
import Api from "../../assets/API1.png";
import HeroImg from "../../assets/download_004.png";
import HeroImg2 from "../../assets/download_002.png";
import HeroVector from "../../assets/HeroVector.png";
import DeveloperImg from "../../assets/HeroImage_002.jpg";
import HeroImg3 from "../../assets/download_004.png";
import circle_dot2 from "../../assets/circle_dot2.svg";
import styles from "./developer.module.css";
import { Box, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useLocation } from "react-router-dom";

const DeveloperHomeScreen = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" }); // Scroll to the element
      }
    }
  }, [location]);
  
  return (
    <div>
      <img src={circle_dot2} className="circle_dot2" alt="#" />
      <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center",
            height: "96.5vh",
            justifyContent: "space-between",
            padding: "3rem",
            background:
              "linear-gradient(105.99deg, rgba(252, 221, 236, 0.06) 1.24%, rgba(36, 69, 118, 0.06) 99.79%)",
            zIndex: "1",
            width: "50%",
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            lg={5}
            sx={{ display: "flex", justifyContent: "flex-start" }}
          >
            <Stack>
              <Typography>CORALPAY FOR DEVELOPERS</Typography>
              <Typography
                sx={{ fontSize: "2.8em", color: "#083857", fontWeight: "800" }}
              >
                A Complete API
              </Typography>
              <Typography
                sx={{ fontSize: "2.8em", color: "#083857", fontWeight: "800" }}
              >
                Suite To Build Seamless
              </Typography>
              <Typography
                sx={{ fontSize: "2.8em", fontWeight: "800", width: "100%" }}
                className={styles.customizeText}
              >
                Payment Infrastructure
              </Typography>
              <Typography>EXPLORE OUR VARIOUS APIS</Typography>
            </Stack>
          </Grid>
          {/* <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            sx={{
              display: "flex",
              height: "60vh",
              width: "40%",
              justifyContent: "flex-start",
              backgroundImage: `url(${DeveloperImg})`,
              backgroundSize: "50vw 50vh",
              backgroundRepeat: "no-repeat",
            }}
          >
            <img src={HeroImg2} height="500px" width="500px" />
          </Grid> */}
        </Grid>

        <Grid
          container
          sx={{
            width: "50%",
            height: "96.5vh",
            backgroundImage: `url(${HeroImg})`,
            backgroundSize: "60vw 100vh",
            backgroundRepeat: "no-repeat",
          }}
        ></Grid>
      </Box>
      {/* <div
        className="developer-wrapper"
        style={{ backgroundImage: `url(${HeroImg2})` }}
      >
        <div className="developer-wrapper-overlay">
          <div className="developer-wrapper-overlay-inner">
            <p className="mb-1 small">CORALPAY FOR DEVELOPERS</p>
            <h3>
              A Complete API <br /> Suite To Build Seamless{" "}
              <span className={styles.customizeText}>
                {" "}
                Payment Infrastructure
              </span>
            </h3>
            <p className="mt-2 small">EXPLORE OUR VARIOUS APIS</p>
            <br />
          </div>
        </div>
      </div> */}

      <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
        <Grid
          container
          sx={{
            width: "50%",
            height: "96.5vh",
            backgroundImage: `url(${HeroImg2})`,
            backgroundSize: "60vw 100vh",
            backgroundRepeat: "no-repeat",
          }}
        ></Grid>
        <Grid
          container
          sx={{
            width: "40%",
            height: "96.5vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Stack>
            <Typography
              sx={{
                fontSize: "3.5em",
                fontWeight: "800",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              className={styles.customizeText}
            >
              Intro to Usage
            </Typography>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: "4rem",
              }}
            >
              This page provides a collection of API specifications. We are
              committed to enhancing open API implementations, and giving the
              needed support to all licensed entities.
            </Typography>
          </Stack>
        </Grid>
      </Box>
      <div className="developer-wrapper-mobile">
        <div className="developer-wrapper-overlay">
          <div className="developer-wrapper-overlay-inner">
            <p className="mb-1 small">CORALPAY FOR DEVELOPERS</p>
            <h3>
              A Complete API <br /> Suite To Build Seamless{" "}
              <span style={{ color: "#DCD35D" }}> Payment Infrastructure</span>
            </h3>
            <p className="mt-2 small">EXPLORE OUR VARIOUS APIS</p>
            {/*<div style={{display:'flex', flexDirection:"column",justifyContent:"center",gap:"20px"}}>*/}
            {/*    <Button message="API Dashboard" bgColor="#7B3F63" bgText="#FFFFFF" />*/}
            {/*    <Button  message="Download Document" bgColor="#EFEFEF" bgText="#3A3A3A"/>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
      {/* <div>
        <div
          className="developer-wrapper"
          style={{ backgroundImage: `url(${HeroImg2})` }}
        >
          <div className="developer-wrapper-overlay2">
            <div className="developer-wrapper-overlay-inner">
              <h3 style={{ color: "#7B3F63" }}>Intro to Usage </h3>
              <p className="mt-2">
                This page provides a collection of API specifications. We are
                committed to enhancing open API implementations, and giving the
                needed support to all licensed entities.
              </p>
            </div>
          </div>
        </div>
        <div
          className="developer-wrapper-mobile2"
          style={{ backgroundImage: `url(${HeroImg2})` }}
        >
          <div className="developer-wrapper-overlay2">
            <div className="developer-wrapper-overlay-inner2">
              <h3 style={{ color: "#7B3F63" }}>Intro to Usage </h3>
              <p>
                This page provides a collection of API specifications. We are
                committed to enhancing open API implementations, and giving the
                needed support to all licensed entities.
              </p>
            </div>
          </div>
        </div>
      </div> */}
      <div className="section-card" id="services">
        <div className="section-card-inner">
          <Card3 />
        </div>
      </div>
    </div>
  );
};

export default DeveloperHomeScreen;
