export const initializeVergClass = `
private readonly HttpClient _httpClient;
     public readonly string _token;
     public readonly string _baseUrl;

     public BankLinkService(string baseUrl, string authToken = null)
     {
         _httpClient = new HttpClient();
         _baseUrl = baseUrl;

         _httpClient.DefaultRequestHeaders.Clear();
         _httpClient.DefaultRequestHeaders.Authorization = new System.Net.Http.Headers.AuthenticationHeaderValue("Bearer", authToken);
         //_httpClient.DefaultRequestHeaders.Add("Authorization", $"Bearer {authToken}");
     }
`;
export const authenticationRequestStructure = `
public class AuthenticationRequest
{
    public string username { get; set; }
    public string password { get; set; }
    public string terminalId { get; set; }
}

`;
export const authenticationResponseStructure = `
public class AuthenticationResponse
{
    public string token { get; set; }
    public string key { get; set; }
    public string status { get; set; }
}

`;

export const baseUrlText = `
const baseUrl = "https://testdev.coralpay.com:5000/GwApi"; //replace with the actual verge base URL
`;

export const authenticateCode = `
     public async Task<AuthResponse> Auth(AuthRequest Request)
     {
         var JsonRequest = JsonSerializer.Serialize(Request);
         var Content = new StringContent(JsonRequest, Encoding.UTF8, "application/json");

         var HttpResponse = await _httpClient.PostAsync($"{_baseUrl}/api/Auth", Content);

         if (HttpResponse.IsSuccessStatusCode)
         {
             var stringResponse = await HttpResponse.Content.ReadAsStringAsync();
             var ResponseObj = JsonSerializer.Deserialize<AuthResponse>(stringResponse);

             return ResponseObj;
         }
         else
         {
             return new AuthResponse
             {
                 responseHeader = new Models.ResponseHeader
                 {
                     responseCode = HttpResponse.StatusCode.ToString(),
                     responseMessage = HttpResponse.ReasonPhrase
                 }
             };
         }

     }
`;

export const bankLinkCode = `
     public async Task<GetBanksResponse> GetBanks()
     {
         var HttpResponse = await _httpClient.GetAsync($"{_baseUrl}/api/GetBanks");

         if (HttpResponse.IsSuccessStatusCode)
         {
             var stringResponse = await HttpResponse.Content.ReadAsStringAsync();
             var ResponseObj = JsonSerializer.Deserialize<GetBanksResponse>(stringResponse);

             return ResponseObj;
         }
         else
         {
             return new GetBanksResponse
             {
                 responseHeader = new Models.ResponseHeader
                 {
                     responseCode = "96",
                     responseMessage = HttpResponse.ReasonPhrase
                 },
                 banks = new()
             };

         }
     }

`;

export const invokePaymentFunction = `
     public async Task<InvokePaymentResponse> InvokePayment(InvokePaymentRequest Request)
     {
         var JsonRequest = JsonSerializer.Serialize(Request);

         var Content = new StringContent(JsonRequest, Encoding.UTF8, "application/json");

         var HttpResponse = await _httpClient.PostAsync($"{_baseUrl}/api/InvokePayment", Content);

         if (HttpResponse.IsSuccessStatusCode)
         {
             var stringResponse = await HttpResponse.Content.ReadAsStringAsync();
             var ResponseObj = JsonSerializer.Deserialize<InvokePaymentResponse>(stringResponse);

             return ResponseObj;
         }
         else
         {
             return new InvokePaymentResponse
             {
                 responseHeader = new Responseheader
                 {
                     responseCode = "96",
                     responseMessage = !string.IsNullOrEmpty(HttpResponse.ReasonPhrase) ? HttpResponse.ReasonPhrase : "System Challenge"
                 }
             };
         }

     }
`;

export const createStaticAccountFunction = `
     public async Task<CreateStaticAcctResponse> CreateStaticAccount(CreateStaticAcctRequest Request)
     {
         var JsonRequest = JsonSerializer.Serialize(Request);
         var Content = new StringContent(JsonRequest, Encoding.UTF8, "application/json");

         var HttpResponse = await _httpClient.PostAsync($"{_baseUrl}/api/CreateStaticAccount", Content);

         if (HttpResponse.IsSuccessStatusCode)
         {
             var stringResponse = await HttpResponse.Content.ReadAsStringAsync();
             var ResponseObj = JsonSerializer.Deserialize<CreateStaticAcctResponse>(stringResponse);

             return ResponseObj;
         }
         else
         {
             return new CreateStaticAcctResponse
             {
                 responseHeader = new Responseheader
                 {
                     responseCode = "96",
                     responseMessage = HttpResponse.ReasonPhrase
                 },
             };
         }

     }
`;

export const sampleInvokePaymentResponse = `
const response = {
"responseHeader": {
"responseCode": "00",
"responseMessage": "Success",
"timeStamp": 1609459200,
"signature": "response_signature"
},
"transactionId": "transaction_id",
"traceId": "trace_id",
"payPageLink": "https://pay.verge.com/payment-page",
"metaData": {}
}
`;

export const sampleRequestForTransactionQuery = `
public class TransactionQueryRequest
{
    public RequestHeader requestHeader { get; set; }
    public string traceId { get; set; }
}
`;

export const transactionQueryFunction = `
     public async Task<TranQueryResponse> TransactionQuery(TranQueryRequest Request)
     {
         var JsonRequest = JsonSerializer.Serialize(Request);

         var Content = new StringContent(JsonRequest, Encoding.UTF8, "application/json");

         var HttpResponse = await _httpClient.PostAsync($"{_baseUrl}/api/TransactionQuery", Content);

         if (HttpResponse.IsSuccessStatusCode)
         {
             var stringResponse = await HttpResponse.Content.ReadAsStringAsync();
             var ResponseObj = JsonSerializer.Deserialize<TranQueryResponse>(stringResponse);

             return ResponseObj;
         }
         else
         {
             return new TranQueryResponse
             {
                 responseHeader = new Models.ResponseHeader
                 {
                     responseCode = "96",
                     responseMessage = !string.IsNullOrEmpty(HttpResponse.ReasonPhrase) ? HttpResponse.ReasonPhrase : "System Challenge"
                 }
             };
         }

     }
`;

export const staticTransactionQuery = `
     public async Task<StaticTranQueryResponse> StaticTranQuery(StaticTranQueryRequest Request)
     {
         var JsonRequest = JsonSerializer.Serialize(Request);

         var Content = new StringContent(JsonRequest, Encoding.UTF8, "application/json");

         var HttpResponse = await _httpClient.PostAsync($"{_baseUrl}/api/StaticTransactionQuery", Content);

         if (HttpResponse.IsSuccessStatusCode)
         {
             var stringResponse = await HttpResponse.Content.ReadAsStringAsync();
             var ResponseObj = JsonSerializer.Deserialize<StaticTranQueryResponse>(stringResponse);

             return ResponseObj;
         }
         else
         {
             return new StaticTranQueryResponse
             {
                 responseHeader = new Models.ResponseHeader
                 {
                     responseCode = "96",
                     responseMessage = !string.IsNullOrEmpty(HttpResponse.ReasonPhrase) ? HttpResponse.ReasonPhrase : "System Challenge"
                 }
             };
         }

     }
`;

export const sampleRequestForGetAccountDetails = `
        // Create an instance of GetAccountRequest
        var getAccountRequest = new GetAccountRequest
        {
            requestHeader = new RequestHeader
            {
            merchantId = "123456",
            terminalId = "78910",
            timeStamp = signatureResponse.timeStamp,
            signature = signatureResponse.signature 
           }
           traceId = Guid.NewGuid().ToString() // Generates a unique trace ID
        };
`;

export const getAccountDetailsFunction = `
SignatureResponse signatureResponse = vergeService.GetSignature(new SignatureRequest
{
    merchantId = "your_merchant_id",
    traceId = "unique_trace_id",
    key = "your_secret_key",
    shaType = SHA.SHA256
});

GetAccountRequest accountRequest = new GetAccountRequest
{
    requestHeader = new RequestHeader
    {
        merchantId = "your_merchant_id",
        terminalId = "your_terminal_id",
        timeStamp = signatureResponse.timeStamp,  // Use generated timestamp
        signature = signatureResponse.signature   // Use generated signature
    },
    traceId = "unique_trace_id"
};

GetAccountResponse accountResponse = await vergeService.GetAccountDetails(accountRequest);

if (accountResponse.responseCode == "00")
{
    Console.WriteLine("Account details retrieved successfully");
}
else
{
    Console.WriteLine($"Error: {accountResponse.responseMessage}");
}
`;

export const accountDetailsResponse = `
const response = {
"accountDetails": [
{
    "bankCode": "123",
    "accountNumber": "1234567890",
    "accountId": 22
},
{
    "bankCode": "456",
    "accountNumber": "1000002001",
    "accountId": 33
}
],
"traceId": "trace_id",
"timeStamp": 1726751419,
"signature": "signature",
"responseCode": "00",
"responseMessage": "Successful"
}
`;

export const sampleRequestForSignatureGeneration = `
public class SignatureRequest
{
    public string merchantId { get; set; }
    public string traceId { get; set; }
    public string key { get; set; }
    public SHA shaType { get; set; } // SHA256 or SHA512
}

`;

export const signatureGenerationFunction = `
SignatureRequest signatureRequest = new SignatureRequest
{
    merchantId = "your_merchant_id",
    traceId = "unique_trace_id",
    key = "your_secret_key",  // You get this from the Verge dashboard
    shaType = SHA.SHA256
};

SignatureResponse signatureResponse = vergeService.GetSignature(signatureRequest);

long timeStamp = signatureResponse.timeStamp;
string signature = signatureResponse.signature;

Console.WriteLine($"Generated Signature: {signature}");
Console.WriteLine($"Timestamp: {timeStamp}");
`;

export const sampleSignatureResponse = `
const response = {
"timeStamp": 1609459200,
"signature": "generated_signature"
}
`;

export const sampleErrorHandling = `
PaymentResponse paymentResponse = await vergeService.InvokePayment(paymentRequest);

if (paymentResponse.responseHeader.responseCode != "00")
{
    Console.WriteLine($"Error: {paymentResponse.responseHeader.responseMessage}");
}
else
{
    Console.WriteLine("Payment initiated successfully");
}

`;
