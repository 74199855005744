import React from 'react'
import guy from "../assets/guy.svg";

const ITsmsPolicyStatement = () => {
    const headingStyle = {
        textDecoration: 'underline'
    };
    return (
        <div>
            <div className="blog-header" style={{ backgroundImage: `url(${guy})` }}>
                <div className="blog-wrapper-overlay blog">
                    <h3 className='small'>ITSMS Policy Statement</h3>
                </div>
            </div>
            <div className="isms-policy">
                <div>
                    <h3 style={headingStyle}>Objectives</h3>
                    <p>
                        Coralpay is committed to delivering high-quality, reliable, and cost-effective payment centric services
                        and solutions that meet or exceed the needs and expectations of our customers. We achieve this
                        through a comprehensive Service Management System (SMS) that aligns with industry best practices
                        and relevant standards.
                    </p>
                    <p>To achieve this Coralpay will ensure the following;</p>
                    <ul>
                        <li>
                            <strong>Customer Focus:</strong> We understand that our customers are at the heart of what we do. We
                            actively engage with them to understand their needs, build strong relationships, and ensure
                            their satisfaction.
                        </li>
                        <li>
                            <strong>Continuous Improvement:</strong> We are committed to continuous improvement in all aspects of our
                            service delivery. We regularly review and update our processes, tools, and technologies to
                            ensure optimal performance.
                        </li>
                        <li>
                            <strong>Value Creation:</strong> We strive to deliver value to our customers through efficient and effective
                            service management. We focus on optimizing resources and maximizing cost-effectiveness.
                        </li>
                        <li>
                            <strong>Collaboration:</strong> We foster a collaborative culture within our organization and with our
                            partners. We believe that working together as a team leads to better outcomes for everyone.
                        </li>
                        <li>
                            <strong>Innovation:</strong> We embrace innovation and new technologies to enhance our service offerings
                            and stay ahead of the curve.
                        </li>
                        <li>
                            <strong>Commitment:</strong> Commitment of the delivery of quality IT services extends to the Board and
                            Management of CoralPay Technology Limited and will be demonstrated through the Service
                            Management policy and the provision of appropriate resource to establish and develop the
                            Service Management System. Top management will also ensure that a systematic review of
                            performance of the program is conducted on a regular basis to ensure that quality objectives
                            are being met and quality issues are identified through the audit program and management
                            processes.
                        </li>
                    </ul>
                </div>
                <div>
                    <p>
                        This service management policy statement will be reviewed and updated periodically to reflect
                        changes in our business, services, and the applicable regulatory landscape.
                    </p>
                    <p>
                        By adhering to this service management policy statement, we strive to consistently deliver exceptional
                        value to our customers and maintain our position as a leading provider of high-quality first choice
                        provider of payment services in Nigeria and the West African sub-region.
                    </p>
                </div>
            </div>
        </div>

    )
}

export default ITsmsPolicyStatement