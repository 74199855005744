import {
  Box,
  Button,
  Grid2,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import {
  darcula,
  a11yDark,
} from "react-syntax-highlighter/dist/esm/styles/prism";
import DotNetContent from "./dotnet/DotnetContent";
//   import DotNetContent from "./dotnet/DotNetContent";
// import JavascriptContent from "./javascript/JavascriptContent";
// import Dotnetcontent from "./dotnet/Dotnetcontent";
// import JavaContent from "./java";

const CIPDocumentation = () => {
  const [javascript, setJavascript] = useState(true);
  const [dotnet, setDotnet] = useState(false);
  const [ceeSharp, setCeeSharp] = useState(false);
  const navigate = useNavigate();

  const handleJavascriptContent = () => {
    setJavascript((prev) => !prev);
    setDotnet(false);
    setCeeSharp(false);
  };
  const handleDotnetContent = () => {
    setJavascript(false);
    setDotnet((prev) => !prev);
    setCeeSharp(false);
  };
  const handleCeeSharpContent = () => {
    setJavascript(false);
    setDotnet(false);
    setCeeSharp((prev) => !prev);
  };
  const outgoingNameEnquiry = `
{
  "sessionId": "000966720915078958283981330700",
  "destinationInstitutionId": "000703",
  "accountId": "1000211487"
}
    `;

  const outgoingNameEnquiryResponsePayload = `
{
  "sessionId": "000966720915078958283981330700",
  "destinationInstitutionId": "000703",
  "accountId": "1000211487",
  "accountName": "Ify Obi",
  "status": "01",
  "responseCode": "00",
  "responseMessage": "Successful",
  "bvn": "11022117237",
  "kycLevel": "2",
  "accountType": "Savings Account",
  "nameEnquiryRef": "20240529051006228290"
}
    `;
  const incomingNameEnquiry = `
{
  "sessionId": "000966720915078958283981330700",
  "destinationInstitutionId": "000703",
  "accountId": "1000211487"
}
    `;

  const incomingNameEnquiryResponsePayload = `
{
 "sessionId": "000966720915078958283981330700",
 "destinationInstitutionId": "000703",
 "accountId": "1000211487",
 "accountName": "Ify Obi",
 "status": "01",
 "responseCode": "00",
 "responseMessage": "Successful",
 "bvn": "11022117237",
 "kycLevel": "2",
"accountType": "Savings Account"
}
    `;
  const outgoingCreditOperationRequest = `
{
  "sessionId": " 000966444324566658283981389895",
  "paymentRef": "2023012123345211290009765",
  "destinationInstitutionId": "000703",
  "creditAccount": "1000211487",
  "creditAccountName": "Sola Badmus",
  "sourceAccountId": "2001223145",
  "sourceAccountName": "Ify Obi",
  "narration": "Payment for food",
  "channel": "USSD",
  "group": "Corporate",
  "sector": "Banking",
  "amount": 5000.0,
  "nameEnquiryRef": " 20230323011254478394"
}

    `;

  const outgoingCreditOperationResponse = `
{
  "sessionId": "000966444324566658283981389895",
  "paymentRef": "2023012123345211290009765",
  "destinationInstitutionId": "000703",
  "creditAccount": "1000211487",
  "creditAccountName": "Sola Badmus",
  "sourceAccountId": "2001223145",
  "sourceAccountName": "Ify Obi",
  "narration": "Payment for food",
  "channel": "USSD",
  "group": "Corporate",
  "sector": "Banking",
  "amount": 5000.0,
  "nameEnquiryRef": " 20230323011254478394",
  "transactionDate": "2023-03-23 10:28:25.007",
  "responseCode": "00",
  "responseMessage": "Successful"
}

    `;
  const incomingCreditOperationRequest = `
{
  "sessionId": " 000966444324566658283981389895",
  "paymentRef": "2023012123345211290009765",
  "destinationInstitutionId": "000703",
  "creditAccount": "1000211487",
  "creditAccountName": "Sola Badmus",
  "sourceAccountId": "2001223145",
  "sourceAccountName": "Ify Obi",
  "narration": "Payment for food",
  "channel": "USSD",
  "group": "Corporate",
  "sector": "Banking",
  "amount": 5000.0,
  "nameEnquiryRef": " 20230323011254478394"
}


    `;

  const incomingCreditOperationResponse = `
{
  "sessionId": " 000966444324566658283981389895",
  "paymentRef": "2023012123345211290009765",
  "destinationInstitutionId": "000703",
  "creditAccount": "1000211487",
  "creditAccountName": "Sola Badmus",
  "sourceAccountId": "2001223145",
  "sourceAccountName": "Ify Obi",
  "narration": "Payment for food",
  "channel": "USSD",
  "group": "Corporate",
  "sector": "Banking",
  "amount": 5000.0,
  "nameEnquiryRef": " 20230323011254478394",
  "transactionDate": "2023-03-23 10:28:25.007",
  "responseCode": "00",
  "responseMessage": "Successful"
}


    `;
  const authResponse = `
  {
    "responseHeader": {
      "responseCode": "00",
      "responseMessage": "Successful"
    },
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdG",
    "key": "2092ba6b-742b-4df1-af34-7def3bc004c1",
    "expiryDate": "2024-03-10T10:15:06.8791956+01:00"
  }
    `;
  const singlePostUrl = `
    https://testdev.coralpay.com:5000/FastChannel/api/SinglePost
    `;

  const singlePostRequestPayload = `
  {
    "traceId": "100992921107213",
    "signature": "81a657c554b654f54441a776c",
    "timestamp": "1602211123",
    "timeStamp": 1694660027,
    "transactionDetails": {
      "creditAccount": "1780004070",
      "creditAccountName": "Test Name",
      "creditBankCode": "999998",
      "narration": "Test narration",
      "amount": 3000.0
    }
  }
  `;
  const singlePostResponsePayload = `
  {
    "responseHeader": {
      "responseCode": "00",
      "responseMessage": "Success"
    },
    "traceId": "1002222222222220008",
    "batchId": "20230914035415758080",
    "amountDebited": 3010.75,
    "transactionAmount": 3000,
    "transactionCharge": 10.0,
    "vat": 0.75
  }
  `;

  const batchPostUrl = `
  https://testdev.coralpay.com:5000/FastChannel/api/BatchPost
  `;

  const batchPostRequestPayload = `
  {
    "traceId": "1009929211072",
    "totalAmount": 6000.0,
    "signature": "0928uymd0dkq445553222pl9",
    "timestamp": "1602211123",
    "transactionList": [
      {
        "creditAccount": "1234567890",
        "creditAccountName": "Test Name",
        "creditBankCode": "737",
        "narration": "Monthly payment",
        "amount": 2000.0,
        "itemTraceId": "10102929291"
      },
      {
        "creditAccount": "1234567891",
        "creditAccountName": "Test Name",
        "creditBankCode": "966",
        "narration": "Monthly payment",
        "amount": 3000.0,
        "itemTraceId": "10102929292"
      },
      {
        "creditAccount": "1234567898",
        "creditAccountName": "Test Name",
        "creditBankCode": "737",
        "narration": "Monthly payment",
        "amount": 1000.0,
        "itemTraceId": "10102929299"
      }
    ]
  }
  
  `;

  const batchPostResponsePayload = `
  {
    "responseHeader": {
      "responseCode": "00",
      "responseMessage": "Successful"
    },
    "batchId": "BCT112234553",
    "traceId": "1009929211072",
    "amountDebited": 6060.0,
    "transactionAmount": 6000.0,
    "transactionCharge": 60.0
  }
  `;

  const nameEnquiryUrl = `
  https://testdev.coralpay.com:5000/FastChannel/api/NameEnquiry
  `;

  const nameEnquiryRequestPayload = `
  {
    "traceId": "2024041134453533211",
    "signature": "1ac221676bc2a33837ac33baa22313",
    "timeStamp": 1689798324,
    "enquiryDetails": {
      "bankCode": "737",
      "accountNumber": "0114547784"
    }
  }
  
  `;

  const nameEnquiryResponsePayload = `
  {
    "responseHeader": {
      "responseCode": "00",
      "responseMessage": "Successful"
    },
    "traceId": "2024041134453533211",
    "accountName": "Chinedu Musa Ayo",
    "accountNumber": "0114547784",
    "bankCode": "737"
  }
  `;

  const callBackUrl = `
  https://testdev.coralpay.com:5000/FastChannel/api/BatchPost
  `;

  const callBackRequestPayload = `
  {
    "ResponseCode": "00",
    "ResponseMessage": "Successful",
    "CreditAccount": "1780004070",
    "CreditAccountName": "Nathan Sola",
    "CreditBankCode": "999998",
    "Narration": "Payment for cable",
    "Amount": 8000.0,
    "Charge": 10.0,
    "ItemBatchId": "202201160550030256707",
    "ItemTraceId": "10102929298",
    "TraceId": "1000000100003",
    "MerchantId": "MS1000001"
  }
  
  `;

  const callBackResponsePayload = `
  {
    "responseCode": "00",
    "responseMessage": "Successful"
  }
  `;

  const invokeUrl = `
    https://testdev.coralpay.com/VergeTest/api/v1/Invokepayment
    `;
  const invokePaymentRequestPayload = `
      { 
        "RequestHeader": 
        { "MerchantId": "10990MTE0992", 
          "TimeStamp": "0123456789", 
          "Signature": "0928uymd0dkq445553222pl9"
        }, 
        "Customer": 
       { 
          "Email": "babavoss@see.com", 
          "Name": "Baba Voss", 
          "Phone": "002200302092" 
        }, 
        "Customization": 
        { 
        "LogoUrl": "http://sampleurl.com", 
         "Title": "Watermarks Limited", 
         "Description": "Service Payment"
        }, 
        "MetaData": 
          { 
            "Data1": "sample string 1", 
            "Data2": "sample string 2", 
            "Data3": "sample string 3" 
          }, 
           "TraceId": "9900990285", 
           "Amount": 2000.00, 
           "Currency": "NGN", 
           "FeeBearer": "M", 
           "ReturnUrl": "http://samplereturnurl.com/status" 
    
          }
    `;

  const invokePaymentResponsePayload = `{
        "ResponseHeader": {
        "ResponseCode": "00",
        "ResponseMessage": "Successful",
        "TimeStamp": 0123456789,
        "Signature": "120dksl3l3lld303e3k3mjmf36k6j3h"
        },
        "TransactionId": "029918891988381",
        "TraceId": "9900990285",
        "PayPageLink": "https://coralpay.ng/payment/00998833",
        "MetaData": {
        "Data1": "sample string 1",
        "Data2": "sample string 2",
        "Data3": "sample string 3"
        
        }
    }
        `;

  const transactionQueryUrl = `
        https://testdev.coralpay.com:5000/FastChannel/api/TransactionQuery
        `;

  const transactionQueryRequestPayload = `
{
  "sessionId": " 000966367432476535828398145678"
}
        `;
  const transactionQueryResponsePayload = `
{
  "sessionId": "000966367432476535828398145678",
  "paymentRef": "2023012123345211290009765",
  "destinationInstitutionId": "000703",
  "creditAccount": "1000211487",
  "creditAccountName": "Sola Badmus",
  "sourceAccountId": "2001223145",
  "sourceAccountName": "Ify Obi",
  "narration": "Payment for food",
  "channel": "USSD",
  "group": "Corporate",
  "sector": "Banking",
  "amount": 5000.0,
  "transactionDate": "2022-02-28T07:05:50.1994153+01:00",
  "responseCode": "00",
  "responseMessage": "Successful"
}

        `;

  return (
    <div>
      <div className="developer-wrapper">
        <div className="developer-wrapper-overlay">
          <div className="developer-wrapper-overlay-inner">
            <h3>CoralPay InterBank Service (CIP)</h3>
            <p>
              CIP is a payment service used by banks and other financial
              institutions to carryout real-time money transfer for customers
              and businesses.
            </p>
            <br />
            <br />
            {/*<div style={{display:'flex'}} >*/}
            {/*    <Button message="API Dashboard" bgColor="#7B3F63" bgText="#FFFFFF" />*/}
            {/*    <Button  message="Download Document" bgColor="#EFEFEF" bgText="#3A3A3A"/>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
      <div className="doc-div-parent-doc">
        <div
          className="docNav"
          style={{ paddingTop: "2rem", position: "sticky", top: "0" }}
        >
          {/* <h3>Documentation</h3> */}
          <Box sx={{ paddingBottom: "2rem" }}>
            <Button
              sx={{
                backgroundColor: "#083857",
                fontWeight: "550",
                color: "#fff",
              }}
              onClick={() => navigate("/developer")}
            >
              BACK TO DEVELOPER'S HOMEPAGE
            </Button>
          </Box>
          <nav>
            <List
              sx={{
                border: "1px solid #083857",
                borderRadius: "5px",
              }}
            >
              <ListItem disablePadding>
                <a href="#apiSpec">
                  <ListItemButton>
                    <ListItemIcon>{/* <InboxIcon /> */}</ListItemIcon>
                    <ListItemText primary="API Specifications" />
                  </ListItemButton>
                </a>
              </ListItem>
              <ListItem disablePadding>
                <a href="#snippets">
                  <ListItemButton>
                    <ListItemIcon>{/* <DraftsIcon /> */}</ListItemIcon>
                    <ListItemText primary="Libraries & Code Snippets" />
                  </ListItemButton>
                </a>
              </ListItem>
            </List>
          </nav>
          {/* <ul>
                <a href={"/developer/docs/cgateussd"}>
                  {" "}
                  <li>C’Gate USSD</li>
                </a>
                <a href={"/developer/docs/pay-with-bank-transfer"}>
                  {" "}
                  <li>Pay With Bank Transfer</li>
                </a>
                <a href={"/developer/docs/payment-gateway"}>
                  {" "}
                  <li>CoralPay Payment Gateway</li>
                </a>
                <a href={"/developer/docs/vas"}>
                  {" "}
                  <li>CoralPay VAS</li>
                </a>
                <a href={"/developer/docs/card-api"}>
                  {" "}
                  <li>CoralPay Card APIs</li>
                </a>
              </ul> */}
        </div>

        <div className="doc-div-doc">
          <React.Fragment id="apiSpec">
            <h3>Introduction</h3>

            <p className="mt-2 small">
              This document is a technical document for the integration into
              CoralPay’s Interbank Payment Service (CIP). It provides guidance,
              and it is a template resource to enable the relevant stakeholders
              seamlessly integrate with the CIP solution.
            </p>
            <p className="mt-2 small">
              This service rides on CoralPay’s connection already established
              with banks and financial institutions in the industry. The CIP
              service leverages on that connection to power bank transfers in
              real time.
            </p>
            <p className="mt-2 small">
              <b>CoralPay Interbank Payment (CIP)</b> has the capabilities to
              carry out the following operations:
            </p>
            <ul>
              <li>
                <span style={{ fontSize: "2em", fontWeight: "550" }}>. </span>
                Name Enquiry
              </li>
              <li>
                <span style={{ fontSize: "2em", fontWeight: "550" }}>. </span>
                Fund Transfer
              </li>
              <li>
                <span style={{ fontSize: "2em", fontWeight: "550" }}>. </span>
                Transaction Query
              </li>
            </ul>
            <p>
              The transmission of funds is done securely, as the CIP service
              uses PGP, an end-to-end encryption, to communicate with the
              Financial Institutions.
            </p>
            <p>
              <b>
                NB: Destination institution is expected to call our transaction
                query endpoint to confirm the transaction status is successful
                before crediting their customer
              </b>
            </p>
            <br />
            <h3>Encryption</h3>
            <p>
              <b>PGP</b> stands for "Pretty Good Privacy". It works by
              encrypting a message using a public key that's tied to a specific
              user; when that user receives the message, they use a private key
              that's known only to them to decrypt it. You can generate your
              keys at https://pgptool.org/
            </p>
            <p>
              Save both your private and public keys (simply copy & paste the
              keys individually to a text editor such as Notepad and save the
              file). If you lose either key, you will be unable to send
              encrypted messages nor decrypt any received message.
            </p>
            <p>
              Please note, you are required to share your public key for
              profiling on our system.
            </p>
            <p>
              You are required to pass into every API request the unique
              SecretKey value provided to you by CoralPay.
            </p>
            <p>The endpoints for each operation will also be provided also.</p>
            <br />
            <h3>API Specification</h3>
            <p>
              Merchants are required to be registered on CoralPay’s platform and
              the credentials to call the API operations will be shared.
            </p>

            <h3>Name Enquiry</h3>

            <p>
              Name Enquiry Operation is used to confirm the name tied to an
              account in a financial institution.
            </p>
            <h3>Outgoing Name Enquiry</h3>

            <p>
              This is the outgoing name enquiry request from the source bank to
              the CIP service.
            </p>
            <br />

            <table style={{ width: "910px" }}>
              <tr>
                <td>Verb</td>
                <td>Post</td>
              </tr>
              <tr>
                <td>Content Type</td>
                <td>Text/Plain</td>
              </tr>
              <tr>
                <td>Request JSON Object</td>
                <td>
                  <SyntaxHighlighter language="javascript" style={a11yDark}>
                    {outgoingNameEnquiry}
                  </SyntaxHighlighter>
                </td>
              </tr>
              <tr>
                <td>Request Payload</td>
                <td>
                  <b>ENCRYPTED REQUEST PAYLOAD</b>
                </td>
              </tr>
              <tr>
                <td>RESPONSE JSON Object</td>
                <td>
                  <SyntaxHighlighter language="javascript" style={a11yDark}>
                    {outgoingNameEnquiryResponsePayload}
                  </SyntaxHighlighter>
                </td>
              </tr>
              <tr>
                <td>Response Payload</td>
                <td>
                  <b>ENCRYPTED RESPONSE PAYLOAD</b>
                </td>
              </tr>
            </table>
            <br />
            <span>
              <b>Properties Description</b>
            </span>
            <table style={{ width: "910px" }}>
              <tr>
                <th>Properties</th>
                <th>Description</th>
                <th>Mandatory</th>
              </tr>
              <tr>
                <td>sessionId </td>
                <td>
                  This is a unique ID for the session. It must be 30 digits. The
                  Bank’s Institution Id + 24 digits. The 24 digits must be
                  unique for each session. Datatype: <i>String</i>
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <td>destinationInstitutionId</td>
                <td>
                  This is the unique ID of the destination institution. See
                  below for list of institution codes. Datatype: <i>String</i>
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <td>accountId</td>
                <td>
                  The is the unique ID of the account. Datatype: <i>String</i>
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <td>accountName</td>
                <td>
                  This is the name assigned to the account ID. Datatype:{" "}
                  <i>String</i>
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <td>status</td>
                <td>
                  This is the status of the account. Value can be any of 01 (For
                  Active account) or 02 (for Closed/Locked/Dormant Account).
                  Datatype: <i>String</i>
                </td>
                <td>YES</td>
              </tr>
              {/* <tr>
                <th>ResponseHeader</th>
              </tr> */}
              <tr>
                <td>ResponseCode</td>
                <td>
                  This is the response code for the transaction. Datatype:{" "}
                  <i>String</i>
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <td>ResponseMessage</td>
                <td>
                  This is the status code message for the transaction Datatype:{" "}
                  <i>String</i>
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <td>BVN</td>
                <td>
                  This is the Bank Verification Number linked to the account.
                  Datatype: <i>String</i>
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <td>KYCLevel</td>
                <td>
                  This is the KYC Level of the account. Value can be any of 1, 2
                  or 3. Datatype: <i>String</i>
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <td>AccountType</td>
                <td>
                  This is the type of account. Datatype: <i>String</i>
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <td>NameEnquiryRef</td>
                <td>
                  This is the unique reference that is returned when the name
                  enquiry is called.
                </td>
                <td>YES</td>
              </tr>
            </table>
            <br />

            <h3>Incoming Name Enquiry</h3>
            <p>
              This is the incoming name enquiry request from the CIP service to
              the destination bank.
            </p>
            <table style={{ width: "910px" }}>
              <tr>
                <td>Verb</td>
                <td>Post</td>
              </tr>
              <tr>
                <td>Content Type</td>
                <td>Text/Plain</td>
              </tr>
              <tr>
                <td>Request JSON Object</td>
                <td>
                  {" "}
                  <SyntaxHighlighter language="javascript" style={a11yDark}>
                    {incomingNameEnquiry}
                  </SyntaxHighlighter>
                </td>
              </tr>
              <tr>
                <td>Request Payload</td>
                <td>
                  {" "}
                  <b>ENCRYPTED REQUEST PAYLOAD</b>
                </td>
              </tr>
              <tr>
                <td>Response JSON Object</td>
                <td>
                  <SyntaxHighlighter language="javascript" style={a11yDark}>
                    {incomingNameEnquiryResponsePayload}
                  </SyntaxHighlighter>
                </td>
              </tr>
              <tr>
                <td>Response Payload</td>
                <td>
                  <b>ENCRYPTED RESPONSE PAYLOAD</b>
                </td>
              </tr>
            </table>
            <br />
            <span>
              <b>Properties Description</b>
            </span>
            <table style={{ width: "910px" }}>
              <tr>
                <th>Properties</th>
                <th>Description</th>
                <th>Mandatory</th>
              </tr>
              <tr>
                <td>sessionId </td>
                <td>
                  This is a unique ID for the session. It must be 30 digits. The
                  Bank’s Institution Id + 24 digits. The 24 digits must be
                  unique for each session. Datatype: <i>String</i>
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <td>destinationInstitutionId</td>
                <td>
                  This is the unique ID of the destination institution. See
                  below for list of institution codes. Datatype: <i>String</i>
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <td>accountId</td>
                <td>
                  The is the unique ID of the account. Datatype: <i>String</i>
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <td>accountName</td>
                <td>
                  This is the name assigned to the account ID. Datatype:{" "}
                  <i>String</i>
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <td>status</td>
                <td>
                  This is the status of the account. Value can be any of 01 (For
                  Active account) or 02 (for Closed/Locked/Dormant Account).
                  Datatype: <i>String</i>
                </td>
                <td>YES</td>
              </tr>
              {/* <tr>
                <th>ResponseHeader</th>
              </tr> */}
              <tr>
                <td>ResponseCode</td>
                <td>
                  This is the response code for the transaction. Datatype:{" "}
                  <i>String</i>
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <td>ResponseMessage</td>
                <td>
                  This is the status code message for the transaction Datatype:{" "}
                  <i>String</i>
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <td>BVN</td>
                <td>
                  This is the Bank Verification Number linked to the account.
                  Datatype: <i>String</i>
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <td>KYCLevel</td>
                <td>
                  This is the KYC Level of the account. Value can be any of 1, 2
                  or 3. Datatype: <i>String</i>
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <td>AccountType</td>
                <td>
                  This is the type of account. Datatype: <i>String</i>
                </td>
                <td>YES</td>
              </tr>
            </table>
            <br />
            <h3>Post Credit</h3>
            <p>
              This is the operation to initiate transfer of funds between
              financial institutions using CIP.
            </p>
            <h3>Outgoing Credit Operation</h3>
            <p>
              This is the outgoing credit request from the source bank to the
              CIP service.
            </p>
            <table style={{ width: "910px" }}>
              <tr>
                <td>Verb</td>
                <td>POST</td>
              </tr>
              <tr>
                <td>Header</td>
                <td>
                  <b>CONTENT-TYPE</b>-Text/plain
                  <br />
                  {`SecretKey – {{Key provided by CoralPay }}`}
                </td>
              </tr>
              <tr>
                <td>Request JSON Object</td>
                <td>
                  <SyntaxHighlighter language="javascript" style={a11yDark}>
                    {outgoingCreditOperationRequest}
                  </SyntaxHighlighter>
                </td>
              </tr>
              <tr>
                <td>Request Payload</td>
                <td>
                  <b>ENCRYPTED REQUEST PAYLOAD</b>
                </td>
              </tr>
              <tr>
                <td>Response JSON Object</td>
                <td>
                  <SyntaxHighlighter language="javascript" style={a11yDark}>
                    {outgoingCreditOperationResponse}
                  </SyntaxHighlighter>
                </td>
              </tr>
              <tr>
                <td>Response Payload</td>
                <td>
                  <b>ENCRYPTED RESPONSE PAYLOAD</b>
                </td>
              </tr>
            </table>
            <br />
            <span>
              <b>Properties Description</b>
            </span>
            <table style={{ width: "910px" }}>
              <tr>
                <th>Properties</th>
                <th>Description</th>
                <th>Type</th>
                <th>Mandatory</th>
              </tr>
              <tr>
                <td>sessionId </td>
                <td>
                  This is a unique ID for the session. It must be 30 digits. The
                  Bank’s Institution Id + 24 digits. The 24 digits must be
                  unique for each session.
                </td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>paymentRef </td>
                <td>
                  This is the unique reference number for the payment generated
                  by the source institution.
                </td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>destinationInstitutionId</td>
                <td>
                  This is the unique ID of the destination institution. See
                  below for list of institution codes.
                </td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>creditAccount</td>
                <td>
                  This is the account number to be credited with the payment.
                </td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>creditAccountName</td>
                <td>
                  This is name of the account that is to be credited with the
                  payment. Max length = 200 characters
                </td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>sourceAccountId</td>
                <td>
                  This is the account number of the sender that initiates the
                  transaction
                </td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>sourceAccountName</td>
                <td>
                  This is the account name of the sender that initiates the
                  transaction. Max length = 200 characters
                </td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>narration</td>
                <td>
                  This is the narration of the transaction. Max length = 300
                  characters
                </td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>channel</td>
                <td>
                  This is the channel where the transaction is initiated from.
                  See below for list of channels.
                </td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>group</td>
                <td>
                  This is the classification of the account doing the
                  transaction.
                </td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>amount</td>
                <td>
                  This is the amount to credit the customer. The amount must be
                  greater than 0, with a maximum of 16 digits.
                </td>
                <td>Decimal</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>sector</td>
                <td>This is the sector of the institution. e.g Banking</td>
                <td>String</td>
                <td>YES</td>
              </tr>
              {/* <tr>
                <th>ResponseHeader</th>
              </tr> */}
              <tr>
                <td>ResponseCode</td>
                <td>This is the response code for the transaction.</td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>ResponseMessage</td>
                <td>This is the status code message for the transaction.</td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>transactionDate</td>
                <td>This is the date of the transaction</td>
                <td>DateTime</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>nameEnquiryRef</td>
                <td>
                  This is the unique reference that is returned when the name
                  enquiry is called.
                </td>
                <td>String</td>
                <td>No</td>
              </tr>
            </table>
            <br />
            <h3>Incoming Credit Operation</h3>
            <p>
              This is the incoming credit request from CIP to the destination
              bank.
            </p>
            <table style={{ width: "910px" }}>
              <tr>
                <td>Verb</td>
                <td>POST</td>
              </tr>
              <tr>
                <td>CONTENT-TYPE</td>
                <td>Text/plain</td>
              </tr>
              <tr>
                <td>Request JSON Object</td>
                <td>
                  <SyntaxHighlighter language="javascript" style={a11yDark}>
                    {incomingCreditOperationRequest}
                  </SyntaxHighlighter>
                </td>
              </tr>
              <tr>
                <td>Request Payload</td>
                <td>
                  <b>ENCRYPTED REQUEST PAYLOAD</b>
                </td>
              </tr>
              <tr>
                <td>Response JSON Object</td>
                <td>
                  <SyntaxHighlighter language="javascript" style={a11yDark}>
                    {incomingCreditOperationResponse}
                  </SyntaxHighlighter>
                </td>
              </tr>
              <tr>
                <td>Response Payload</td>
                <td>
                  <b>ENCRYPTED RESPONSE PAYLOAD</b>
                </td>
              </tr>
            </table>
            <br />
            <span>
              <b>Properties Description</b>
            </span>
            <table style={{ width: "910px" }}>
              <tr>
                <th>Properties</th>
                <th>Description</th>
                <th>Type</th>
                <th>Mandatory</th>
              </tr>
              <tr>
                <td>sessionId </td>
                <td>
                  This is a unique ID for the session. It must be 30 digits. The
                  Bank’s Institution Id + 24 digits. The 24 digits must be
                  unique for each session.
                </td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>paymentRef </td>
                <td>
                  This is the unique reference number for the payment generated
                  by the source institution.
                </td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>destinationInstitutionId</td>
                <td>
                  This is the unique ID of the destination institution. See
                  below for list of institution codes.
                </td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>creditAccount</td>
                <td>
                  This is the account number to be credited with the payment.
                </td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>creditAccountName</td>
                <td>
                  This is name of the account that is to be credited with the
                  payment. Max length = 200 characters
                </td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>sourceAccountId</td>
                <td>
                  This is the account number of the sender that initiates the
                  transaction
                </td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>sourceAccountName</td>
                <td>
                  This is the account name of the sender that initiates the
                  transaction. Max length = 200 characters
                </td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>narration</td>
                <td>
                  This is the narration of the transaction. Max length = 300
                  characters
                </td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>channel</td>
                <td>
                  This is the channel where the transaction is initiated from.
                  See below for list of channels.
                </td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>group</td>
                <td>
                  This is the classification of the account doing the
                  transaction.
                </td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>amount</td>
                <td>
                  This is the amount to credit the customer. The amount must be
                  greater than 0, with a maximum of 16 digits.
                </td>
                <td>Decimal</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>sector</td>
                <td>This is the sector of the institution. e.g Banking</td>
                <td>String</td>
                <td>YES</td>
              </tr>
              {/* <tr>
                <th>ResponseHeader</th>
              </tr> */}
              <tr>
                <td>ResponseCode</td>
                <td>This is the response code for the transaction.</td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>ResponseMessage</td>
                <td>This is the status code message for the transaction.</td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>transactionDate</td>
                <td>This is the date of the transaction</td>
                <td>DateTime</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>nameEnquiryRef</td>
                <td>
                  This is the unique reference that is returned when the name
                  enquiry is called.
                </td>
                <td>String</td>
                <td>No</td>
              </tr>
            </table>
            <br />
            <h3>Transaction Query</h3>
            <p>
              This operation can be called to get transaction details of a
              particular transaction of not more than 3 months.
            </p>
            <table style={{ width: "910px" }}>
              <tr>
                <td>Verb</td>
                <td>POST</td>
              </tr>
              <tr>
                <td>
                  <b>CONTENT-TYPE</b>: Text/Plain
                  <br />
                  {`SecretKey – {{Key provided by CoralPay}}`}
                </td>
              </tr>
              <tr>
                <td>Request JSON Object</td>
                <td>
                  <SyntaxHighlighter language="javascript" style={a11yDark}>
                    {transactionQueryRequestPayload}
                  </SyntaxHighlighter>
                </td>
              </tr>
              <tr>
                <td>Request Payload</td>
                <td>
                  <b>ENCRYPTED REQUEST PAYLOAD</b>
                </td>
              </tr>
              <tr>
                <td>Response JSON Object</td>
                <td>
                  <SyntaxHighlighter language="javascript" style={a11yDark}>
                    {transactionQueryResponsePayload}
                  </SyntaxHighlighter>
                </td>
              </tr>
              <tr>
                <td>Response Payload</td>
                <td>
                  <b>ENCRYPTED RESPONSE PAYLOAD</b>
                </td>
              </tr>
            </table>

            <br />
            <h3>Incoming Transaction Query Operation</h3>
            <table style={{ width: "910px" }}>
              <tr>
                <td>Verb</td>
                <td>POST</td>
              </tr>
              <tr>
                <td>CONTENT-TYPE</td>
                <td>Text/Plain</td>
              </tr>
              <tr>
                <td>Request JSON Object</td>
                <td>
                  <SyntaxHighlighter language="javascript" style={a11yDark}>
                    {transactionQueryRequestPayload}
                  </SyntaxHighlighter>
                </td>
              </tr>
              <tr>
                <td>Request Payload</td>
                <td>
                  <b>ENCRYPTED REQUEST PAYLOAD</b>
                </td>
              </tr>
              <tr>
                <td>Response JSON Object</td>
                <td>
                  <SyntaxHighlighter language="javascript" style={a11yDark}>
                    {transactionQueryResponsePayload}
                  </SyntaxHighlighter>
                </td>
              </tr>
              <tr>
                <td>Response Payload</td>
                <td>
                  <b>ENCRYPTED RESPONSE PAYLOAD</b>
                </td>
              </tr>
            </table>

            <br />
            <span>
              <b>Properties Description</b>
            </span>

            <table style={{ width: "910px" }}>
              <tr>
                <th>Properties</th>
                <th>Description</th>
                <th>Type</th>
                <th>Mandatory</th>
              </tr>
              <tr>
                <td>sessionId </td>
                <td>
                  This is a unique ID for the session. It must be 30 digits. The
                  Bank’s Institution Id + 24 digits. The 24 digits must be
                  unique for each session.
                </td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>paymentRef </td>
                <td>
                  This is the unique reference number for the payment generated
                  by the source institution.
                </td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>destinationInstitutionId</td>
                <td>
                  This is the unique ID of the destination institution. See
                  below for list of institution codes.
                </td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>creditAccount</td>
                <td>
                  This is the account number to be credited with the payment.
                </td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>creditAccountName</td>
                <td>
                  This is name of the account that is to be credited with the
                  payment. Max length = 200 characters
                </td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>sourceAccountId</td>
                <td>
                  This is the account number of the sender that initiates the
                  transaction
                </td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>sourceAccountName</td>
                <td>
                  This is the account name of the sender that initiates the
                  transaction. Max length = 200 characters
                </td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>narration</td>
                <td>
                  This is the narration of the transaction. Max length = 300
                  characters
                </td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>channel</td>
                <td>
                  This is the channel where the transaction is initiated from.
                  See below for list of channels.
                </td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>group</td>
                <td>
                  This is the classification of the account doing the
                  transaction.
                </td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>amount</td>
                <td>
                  This is the amount to credit the customer. The amount must be
                  greater than 0, with a maximum of 16 digits.
                </td>
                <td>Decimal</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>sector</td>
                <td>This is the sector of the institution. e.g Banking</td>
                <td>String</td>
                <td>YES</td>
              </tr>
              {/* <tr>
                <th>ResponseHeader</th>
              </tr> */}
              <tr>
                <td>ResponseCode</td>
                <td>This is the response code for the transaction.</td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>ResponseMessage</td>
                <td>This is the status code message for the transaction.</td>
                <td>String</td>
                <td>YES</td>
              </tr>
              <tr>
                <td>transactionDate</td>
                <td>This is the date of the transaction</td>
                <td>DateTime</td>
                <td>YES</td>
              </tr>
            </table>

            <br />

            <h3>Channels</h3>
            <table>
              <th>Channel Names</th>
              <tr>
                <td>ATM</td>
              </tr>
              <tr>
                <td>POS</td>
              </tr>
              <tr>
                <td>WEB</td>
              </tr>
              <tr>
                <td>MOBILE</td>
              </tr>
              <tr>
                <td>USSD</td>
              </tr>
              <tr>
                <td>KIOSK</td>
              </tr>
              <tr>
                <td>Internet Banking</td>
              </tr>
              <tr>
                <td>Corporate Bank</td>
              </tr>
              <tr>
                <td>Branch</td>
              </tr>
              <tr>
                <td>Orders</td>
              </tr>
            </table>
            <br />
            <h3>Response Codes</h3>
            <p>
              Please note: When Null returned as API call response, check Reason
              Phrase for the following error description
            </p>
            <ul>
              <li>Invalid SessionId</li>
              <li>SessionId not digits</li>
              <li>Invalid Source InstitutionId</li>
              <li>System challenge</li>
              <li>Invalid Secret Key</li>
            </ul>
            <br />

            <table>
              <tr>
                <td>
                  Outgoing: Expected ResponseCode/ResponseMessage from CoralPay
                  for outgoing requests by Source Bank
                </td>
              </tr>
              <tr>
                <th>ResponseCode</th>
                <th>ResponseMessage</th>
                <th>Category</th>
              </tr>
              <tr>
                <th>Name Enquiry</th>
              </tr>
              <tr>
                <td>00</td>
                <td>Successful</td>
                <td>Successful</td>
              </tr>
              <tr>
                <td>02</td>
                <td>Invalid account </td>
                <td>Failed</td>
              </tr>
              <tr>
                <td>03</td>
                <td>Account cannot be resolved</td>
                <td>Failed</td>
              </tr>
              <tr>
                <th>Post Credit</th>
              </tr>
              <tr>
                <td>00</td>
                <td>Successful</td>
                <td>Successful</td>
              </tr>
              <tr>
                <td>00</td>
                <td>Successful</td>
                <td>Successful</td>
              </tr>
              <tr>
                <td>07</td>
                <td>Pending</td>
                <td>Pending</td>
              </tr>
              <tr>
                <td>01</td>
                <td>{`{add response message}`}</td>
                <td>Failed</td>
              </tr>
              <tr>
                <td>02</td>
                <td>Invalid account </td>
                <td>Failed</td>
              </tr>
              <tr>
                <td>03</td>
                <td>Account cannot be resolved</td>
                <td>Failed</td>
              </tr>
              <tr>
                <td>05</td>
                <td>Bank account restricted/Dormant account</td>
                <td>Failed</td>
              </tr>
              <tr>
                <td>12</td>
                <td>Invalid Transaction</td>
                <td>Failed</td>
              </tr>
              <tr>
                <td>13</td>
                <td>Invalid amount</td>
                <td>Failed</td>
              </tr>
              <tr>
                <td>14</td>
                <td>Account name mismatch</td>
                <td>Failed</td>
              </tr>
              <tr>
                <td>39</td>
                <td>Invalid NameEnquiryRef</td>
                <td>Failed</td>
              </tr>
              <tr>
                <td>51</td>
                <td>Insufficient funds</td>
                <td>Failed</td>
              </tr>
              <tr>
                <td>61</td>
                <td>Credit limit exceeded </td>
                <td>Failed</td>
              </tr>
              <tr>
                <td>94</td>
                <td>Duplicate transaction</td>
                <td>Failed</td>
              </tr>
              <tr>
                <td>96</td>
                <td>System malfunction</td>
                <td>Failed</td>
              </tr>
              <tr>
                <th>Transaction Query</th>
              </tr>
              <tr>
                <td>00</td>
                <td>Successful</td>
                <td>Successful</td>
              </tr>
              <tr>
                <td>07</td>
                <td>Pending</td>
                <td>Pending</td>
              </tr>
              <tr>
                <td>01</td>
                <td>{`{add response message}`}</td>
                <td>Failed</td>
              </tr>
              <tr>
                <td>02</td>
                <td>Invalid account </td>
                <td>Failed</td>
              </tr>
              <tr>
                <td>03</td>
                <td>Account cannot be resolved</td>
                <td>Failed</td>
              </tr>
              <tr>
                <td>05</td>
                <td>Bank account restricted/Dormant account</td>
                <td>Failed</td>
              </tr>
              <tr>
                <td>12</td>
                <td>Invalid Transaction</td>
                <td>Failed</td>
              </tr>
              <tr>
                <td>13</td>
                <td>Invalid amount</td>
                <td>Failed</td>
              </tr>
              <tr>
                <td>14</td>
                <td>Account name mismatch</td>
                <td>Failed</td>
              </tr>
              <tr>
                <td>39</td>
                <td>Invalid NameEnquiryRef</td>
                <td>Failed</td>
              </tr>
              <tr>
                <td>51</td>
                <td>Insufficient funds</td>
                <td>Failed</td>
              </tr>
              <tr>
                <td>61</td>
                <td>Credit limit exceeded </td>
                <td>Failed</td>
              </tr>
              <tr>
                <td>94</td>
                <td>Duplicate transaction</td>
                <td>Failed</td>
              </tr>
              <tr>
                <td>96</td>
                <td>System malfunction</td>
                <td>Failed</td>
              </tr>
            </table>
            <br/>
            <table>
              <tr>
                <td>
                  Incoming: Expected ResponseCode/ResponseMessage from CoralPay
                  for outgoing requests by Source Bank
                </td>
              </tr>
              <tr>
                <th>ResponseCode</th>
                <th>ResponseMessage</th>
                <th>Category</th>
              </tr>
              <tr>
                <th>Name Enquiry</th>
              </tr>
              <tr>
                <td>00</td>
                <td>Successful</td>
                <td>Successful</td>
              </tr>
              <tr>
                <td>02</td>
                <td>Invalid account </td>
                <td>Failed</td>
              </tr>
              <tr>
                <td>03</td>
                <td>Account cannot be resolved</td>
                <td>Failed</td>
              </tr>
              <tr>
                <th>Post Credit</th>
              </tr>
              <tr>
                <td>00</td>
                <td>Successful</td>
                <td>Successful</td>
              </tr>
              <tr>
                <td>00</td>
                <td>Successful</td>
                <td>Successful</td>
              </tr>
              <tr>
                <td>07</td>
                <td>Pending</td>
                <td>Pending</td>
              </tr>
              <tr>
                <td>01</td>
                <td>{`{add response message}`}</td>
                <td>Failed</td>
              </tr>
              <tr>
                <td>02</td>
                <td>Invalid account </td>
                <td>Failed</td>
              </tr>
              <tr>
                <td>03</td>
                <td>Account cannot be resolved</td>
                <td>Failed</td>
              </tr>
              <tr>
                <td>05</td>
                <td>Bank account restricted/Dormant account</td>
                <td>Failed</td>
              </tr>
              <tr>
                <td>12</td>
                <td>Invalid Transaction</td>
                <td>Failed</td>
              </tr>
              <tr>
                <td>13</td>
                <td>Invalid amount</td>
                <td>Failed</td>
              </tr>
              <tr>
                <td>14</td>
                <td>Account name mismatch</td>
                <td>Failed</td>
              </tr>
              <tr>
                <td>39</td>
                <td>Invalid NameEnquiryRef</td>
                <td>Failed</td>
              </tr>
              <tr>
                <td>51</td>
                <td>Insufficient funds</td>
                <td>Failed</td>
              </tr>
              <tr>
                <td>61</td>
                <td>Credit limit exceeded </td>
                <td>Failed</td>
              </tr>
              <tr>
                <td>94</td>
                <td>Duplicate transaction</td>
                <td>Failed</td>
              </tr>
              <tr>
                <td>96</td>
                <td>System malfunction</td>
                <td>Failed</td>
              </tr>
              <tr>
                <th>Transaction Query</th>
              </tr>
              <tr>
                <td>00</td>
                <td>Successful</td>
                <td>Successful</td>
              </tr>
              <tr>
                <td>07</td>
                <td>Pending</td>
                <td>Pending</td>
              </tr>
              <tr>
                <td>01</td>
                <td>{`{add response message}`}</td>
                <td>Failed</td>
              </tr>
              <tr>
                <td>02</td>
                <td>Invalid account </td>
                <td>Failed</td>
              </tr>
              <tr>
                <td>03</td>
                <td>Account cannot be resolved</td>
                <td>Failed</td>
              </tr>
              <tr>
                <td>05</td>
                <td>Bank account restricted/Dormant account</td>
                <td>Failed</td>
              </tr>
              <tr>
                <td>12</td>
                <td>Invalid Transaction</td>
                <td>Failed</td>
              </tr>
              <tr>
                <td>13</td>
                <td>Invalid amount</td>
                <td>Failed</td>
              </tr>
              <tr>
                <td>14</td>
                <td>Account name mismatch</td>
                <td>Failed</td>
              </tr>
              <tr>
                <td>39</td>
                <td>Invalid NameEnquiryRef</td>
                <td>Failed</td>
              </tr>
              <tr>
                <td>51</td>
                <td>Insufficient funds</td>
                <td>Failed</td>
              </tr>
              <tr>
                <td>61</td>
                <td>Credit limit exceeded </td>
                <td>Failed</td>
              </tr>
              <tr>
                <td>94</td>
                <td>Duplicate transaction</td>
                <td>Failed</td>
              </tr>
              <tr>
                <td>96</td>
                <td>System malfunction</td>
                <td>Failed</td>
              </tr>
            </table>
            <br />

          </React.Fragment>

          <br />

          <hr />

          <Box sx={{ width: "100%", paddingTop: "2rem" }} id="snippets">
            <Grid2 container>
              <Typography
                sx={{ color: "#7f3d64", fontSize: "1.2em", fontWeight: "800" }}
              >
                Libraries & Code Snippets
              </Typography>
            </Grid2>

            <Grid2
              container
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "1.5rem 0 1.5rem 0",
              }}
            >
              <div style={{ marginRight: "1rem" }}>
                <button
                  style={{
                    border: javascript ? "none" : "1px solid #242424",
                    borderRadius: "10px",
                    fontWeight: 550,
                    padding: "1rem",
                    color: javascript ? "#fff" : "#242424",
                    backgroundColor: javascript ? "#242424" : "#fff",
                  }}
                  onClick={handleJavascriptContent}
                >
                  JAVASCRIPT
                </button>
              </div>
              <div style={{ marginRight: "1rem" }}>
                <button
                  style={{
                    border: dotnet ? "none" : "1px solid #242424",
                    borderRadius: "10px",
                    fontWeight: 550,
                    padding: "1rem",
                    color: dotnet ? "#fff" : "#242424",
                    backgroundColor: dotnet ? "#242424" : "#fff",
                  }}
                  onClick={handleDotnetContent}
                >
                  .NET
                </button>
              </div>
              <div>
                <button
                  style={{
                    border: ceeSharp ? "none" : "1px solid #242424",
                    borderRadius: "10px",
                    fontWeight: 550,
                    padding: "1rem",
                    color: ceeSharp ? "#fff" : "#242424",
                    backgroundColor: ceeSharp ? "#242424" : "#fff",
                  }}
                  onClick={handleCeeSharpContent}
                >
                  JAVA
                </button>
              </div>
            </Grid2>

            <div>
              {/* {javascript && <JavascriptContent />} */}
              {dotnet && <DotNetContent />}
              {/* {ceeSharp && <JavaContent />} */}
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default CIPDocumentation;
