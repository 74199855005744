import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import {
  darcula,
  a11yDark,
} from "react-syntax-highlighter/dist/esm/styles/prism";
import { authenticationResponseStructure, initializeVergClass } from "./syntax";
import {
  scriptTag,
  authenticationRequestStructure,
  baseUrlText,
  authenticateCode,
  invokePaymentRequest,
  invokePaymentFunction,
  sampleResponse,
  sampleInvokePaymentResponse,
  sampleRequestForTransactionQuery,
  transactionQueryFunction,
  tsqSampleResponsePayload,
  sampleRequestForGetAccountDetails,
  getAccountDetailsFunction,
  accountDetailsResponse,
  sampleRequestForSignatureGeneration,
  signatureGenerationFunction,
  sampleSignatureResponse,
  sampleErrorHandling,
} from "./syntax";

const Dotnetcontent = () => {
  const newHeaderClass = {
    fontWeight: "600",
    fontSize: "1.2em",
    color: "#7F3D64",
  };
  const newHeaderClass2 = {
    fontWeight: "600",
    // fontSize: "1.2em",
    color: "#7F3D64",
  };
  return (
    <>
      <h3 style={newHeaderClass}>Prerequisites</h3>
      <ul>
        <li>1. .NET environment (e.g., .NET Core or .NET Framework).</li>
        <li>
          2. Access to Verge API credentials, including merchantId, terminalId,
          username, and password.
        </li>
        <li>3. Familiarity with HTTP requests and C#.</li>
      </ul>
      <div style={{ padding: "1rem 0 1rem 0" }}>
        <hr />
      </div>
      <div style={{ padding: "1rem 0 1rem 0" }}>
        <h3
          style={newHeaderClass}
        >
          1. Setting Up Verge Services
        </h3>
      </div>
      <div style={{ padding: "1rem 0 1rem 0" }}>
        <h5
          style={newHeaderClass2}
        >
          1.1 Installation
        </h5>
        <p style={{ padding: "1rem 0 1rem 0" }}>
          The VergeServices class provides the core functionality for
          interacting with the Verge API.
        </p>

        <h5
          style={newHeaderClass2}
        >
          1.2 Initializing VergeServices Class
        </h5>
        <p style={{ padding: "1rem 0 1rem 0" }}>
          You can initialize the VergeServices class by passing the base URL of
          the Verge API and an authentication token.
        </p>
        <SyntaxHighlighter language="javascript" style={a11yDark}>
          {initializeVergClass}
        </SyntaxHighlighter>
      </div>
      <h3 style={newHeaderClass}>2. Authentication</h3>
      <br />
      {/* <span>
        <b>Properties Description</b>
      </span>
      <table style={{ width: "910px" }}>
        <tr>
          <th>Properties</th>
          <th>Description</th>
          <th>Optional/Mandatory</th>
        </tr>
        <tr>
          <td>Username</td>
          <td>
            This is a unique username assigned to the merchant. Datatype:
            String.
          </td>
          <td>YES</td>
        </tr>
        <tr>
          <td>Password</td>
          <td>
            The is the Corresponding password to the Username. Datatype: String
          </td>
          <td>YES</td>
        </tr>
        <tr>
          <td>Token</td>
          <td>
            The is the generated token used for the bearer token authentication
            type. Datatype: String
          </td>
          <td>YES</td>
        </tr>
        <tr>
          <td>Key</td>
          <td>
            This is used to generate the signature value on operation call.
            Datatype: String
          </td>
          <td>YES</td>
        </tr>
        <tr>
          <td>Status</td>
          <td>
            This is the status of the call which is either Success or Failed.
            Datatype: String.
          </td>
          <td>YES</td>
        </tr>
      </table> */}
      <h5
        style={newHeaderClass2}
      >
        2.1 Authentication Request Structure.
      </h5>
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {authenticationRequestStructure}
      </SyntaxHighlighter>
      <br/>
      <h5
        style={newHeaderClass2}
      >
        2.2 Authentication Response Structure
      </h5>
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {authenticationResponseStructure}
      </SyntaxHighlighter>
      <br/>
      <ul style={{ padding: "1rem 0 1rem 0" }}>
        <li>
          <span style={{ fontSize: "2em", fontWeight: "550" }}>. </span>
          <code>username: </code> Your Verge API username.
        </li>
        <li>
          <span style={{ fontSize: "2em", fontWeight: "550" }}>. </span>
          <code>password: </code>password: Your Verge API password.
        </li>
        <li>
          <span style={{ fontSize: "2em", fontWeight: "550" }}>. </span>
          <code>terminalId: </code> Your terminal ID (optional).
        </li>
      </ul>
      <h5
        style={newHeaderClass2}
      >
        2.3 Calling the Authentication Service.
      </h5>
      <p>
        Merchants are required to call the Authentication operation using the
        credentials provided by CoralPay during registration. The Response from
        this operation is to be used for authentication for other operations
        using bearer token authentication type.
      </p>
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {authenticateCode}
      </SyntaxHighlighter>
      <br />

      <h3 style={newHeaderClass}>3. Generating Signatures</h3>
      <p style={{ padding: "1rem 0 1rem 0" }}>
        You may need to generate a signature for certain requests, depending on
        the security requirements.
      </p>
      <h5
        style={newHeaderClass2}
      >
        3.1 Request Parameters.
      </h5>
      <ul style={{ padding: "1rem 0 1rem 0" }}>
        <li>
          <span style={{ fontSize: "2em", fontWeight: "550" }}>. </span>
          <code>merchantId: </code> Merchant ID.
        </li>
        <li>
          <span style={{ fontSize: "2em", fontWeight: "550" }}>. </span>
          <code>traceId: </code> A unique identifier for the transaction.
        </li>
        <li>
          <span style={{ fontSize: "2em", fontWeight: "550" }}>. </span>
          <code>key: </code> Encryption key for generating the signature.
        </li>
        <li>
          <span style={{ fontSize: "2em", fontWeight: "550" }}>. </span>
          <code>shaType: </code> The hashing algorithm to use (SHA256).
        </li>
      </ul>
      <h5
        style={newHeaderClass2}
      >
        3.2 Sample Request Payload.
      </h5>
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {sampleRequestForSignatureGeneration}
      </SyntaxHighlighter>
      <h5
        style={newHeaderClass2}
      >
        3.3 Example of Generating a Signature.
      </h5>
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {signatureGenerationFunction}
      </SyntaxHighlighter>
      <h5
        style={newHeaderClass2}
      >
        3.4 Sample Response.
      </h5>
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {sampleSignatureResponse}
      </SyntaxHighlighter>
      <br />
      <h3 style={newHeaderClass}>4. Initiating a payment request</h3>
      {/* <span>
        <b>Properties Description</b>
      </span>
      <table style={{ width: "910px" }}>
        <tr>
          <th>Properties</th>
          <th>Description</th>
          <th>Type</th>
          <th>Optional/Mandatory</th>
        </tr>
        <tr>
          <th>RequestHeader</th>
        </tr>
        <tr>
          <td>MerchantId</td>
          <td>This is the Merchant unique identifier</td>
          <td>String</td>
          <td>YES</td>
        </tr>
        <tr>
          <td>TimeStamp</td>
          <td>Timestamp: UnixTimeSeconds Datatype: long</td>
          <td>Long</td>
          <td>YES</td>
        </tr>
        <tr>
          <td>Signature</td>
          <td>
            This value is generated to maintain the integrity of the payload.
            Note that a new Timestamp must be generated to calculate the
            signature every API call. Sha256(MerchantId +TraceId+ TimeStamp +
            Key).
          </td>
          <td>String</td>
          <td>YES</td>
        </tr>
        <tr>
          <th>Customer</th>
        </tr>
        <tr>
          <td>Email</td>
          <td>This is a customer property, which is the customer email</td>
          <td>String</td>
          <td>NO</td>
        </tr>
        <tr>
          <td>Phone</td>
          <td>This is a customer property, which is the customer Phone No.</td>
          <td>String</td>
          <td>NO</td>
        </tr>
        <tr>
          <td>Name</td>
          <td>This is a customer property, which is the customer Name.</td>
          <td>String</td>
          <td>NO</td>
        </tr>

        <tr>
          <th>Customization</th>
        </tr>
        <tr>
          <td>MetaData</td>
          <td>This object can be used for additional information.</td>
          <td>Object</td>
          <td>NO</td>
        </tr>
        <tr>
          <td>TraceId</td>
          <td>This is the unique identifier from the merchant</td>
          <td>String</td>
          <td>YES</td>
        </tr>
        <tr>
          <td>Amount</td>
          <td>This is the amount of the transaction.</td>
          <td>Decimal</td>
          <td>YES</td>
        </tr>
        <tr>
          <td>FeeBearer</td>
          <td>
            This specifies who bears the fee/charges of the transaction. The
            value for the property is either M or C M=Merchant, C=Customer
          </td>
          <td>String</td>
          <td>NO</td>
        </tr>
        <tr>
          <td>Currency</td>
          <td>This is the currency of the transaction. e.g., NGN</td>
          <td>String</td>
          <td>YES</td>
        </tr>
        <tr>
          <td>ReturnUrl</td>
          <td>
            This is the URL to return to when payment is completed. We’ll
            include Id and Status as query strings in the URL while routing to
            it. Also note that a real-time notification will be pushed via the
            call-back.
          </td>
          <td>String</td>
          <td>YES</td>
        </tr>
      </table> */}
      <br />
      <h5
        style={newHeaderClass2}
      >
        4.1 Payment Request.
      </h5>
      <p style={{ padding: "1rem 0 1rem 0" }}>
        To initiate a payment, you must provide several required parameters such
        as merchant ID, trace ID, product ID, amount, currency, and more.
      </p>
      <h5
        style={newHeaderClass2}
      >
        4.2 Request Parameters.
      </h5>
      <ul style={{ padding: "1rem 0 1rem 0" }}>
        <li>
          <span style={{ fontSize: "2em", fontWeight: "550" }}>. </span>
          <code>requestHeader: </code> Contains merchantId, terminalId,
          timeStamp, and signature.
        </li>
        <li>
          <span style={{ fontSize: "2em", fontWeight: "550" }}>. </span>
          timeStamp, and signature should be gotten from 'Generating Signatures'
        </li>
        <li>
          <span style={{ fontSize: "2em", fontWeight: "550" }}>. </span>
          <code>traceId: </code> A unique identifier for the transaction.
        </li>
        <li>
          <span style={{ fontSize: "2em", fontWeight: "550" }}>. </span>
          <code>productId: </code> Product or service identifier.
        </li>
        <li>
          <span style={{ fontSize: "2em", fontWeight: "550" }}>. </span>
          <code>amount: </code> The transaction amount.
        </li>
        <li>
          <span style={{ fontSize: "2em", fontWeight: "550" }}>. </span>
          <code>currency: </code> Currency code (e.g., USD, NGN).
        </li>
        <li>
          <span style={{ fontSize: "2em", fontWeight: "550" }}>. </span>
          <code>feeBearer: </code> Specifies who will bear the transaction fee
          (e.g., Customer, Merchant).
        </li>
        <li>
          <span style={{ fontSize: "2em", fontWeight: "550" }}>. </span>
          <code>returnUrl: </code> URL to redirect the user after payment.
        </li>
      </ul>
      <h5
        style={newHeaderClass2}
      >
        4.3 Payment Request Structure
      </h5>
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {invokePaymentRequest}
      </SyntaxHighlighter>
      <h5
        style={newHeaderClass2}
      >
        4.4 Example of Making a Payment Request.
      </h5>
      <p style={{ padding: "1rem 0 1rem 0" }}>
        Use the signature and timestamp from the GetSignature method to complete
        the payment request.
      </p>
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {invokePaymentFunction}
      </SyntaxHighlighter>
      <h5
        style={newHeaderClass2}
      >
        4.5 Sample Response.
      </h5>
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {sampleInvokePaymentResponse}
      </SyntaxHighlighter>
      <br />
      <h3 style={newHeaderClass}>5. Querying Transactions</h3>
      {/* <br />
      <p>Properties description</p>
      <table style={{ width: "910px" }}>
        <tr>
          <th>Properties</th>
          <th>Description</th>
          <th>Type</th>
          <th>Mandatory</th>
        </tr>
        <tr>
          <th>RequestHeader</th>
        </tr>
        <tr>
          <td>MerchantId</td>
          <td>This is the Merchant unique identifier</td>
          <td>String</td>
          <td>YES</td>
        </tr>
        <tr>
          <td>TimeStamp</td>
          <td>Timestamp: UnixTimeSeconds Datatype: long</td>
          <td>Long</td>
          <td>YES</td>
        </tr>
        <tr>
          <td>Signature</td>
          <td>
            This value is generated to maintain the integrity of the payload.
            Note that a new Timestamp must be generated to calculate the
            signature every API call. Sha256(MerchantId +TraceId+ TimeStamp +
            Key).
          </td>
          <td>String</td>
          <td>YES</td>
        </tr>
        <tr>
          <td>Title</td>
          <td>Transaction title as in the payment request</td>
          <td>String</td>
          <td></td>
        </tr>
        <tr>
          <td>Description</td>
          <td>Transaction description as in the payment request</td>
          <td>String</td>
          <td></td>
        </tr>
        <tr>
          <td>TraceId</td>
          <td>This is the unique identifier from the merchant</td>
          <td>String</td>
          <td>NO</td>
        </tr>
        <tr>
          <td>Amount</td>
          <td>This is the amount of the transaction.</td>
          <td>Decimal</td>
          <td>YES</td>
        </tr>

        <tr>
          <td>Fee</td>
          <td>This is the transaction fee</td>
          <td>Decimal</td>
          <td>YES</td>
        </tr>
        <tr>
          <td>FeeBearer</td>
          <td>
            This specifies who bears the fee/charges of the transaction. The
            value for the property is either M or C M=Merchant, C=Customer
          </td>
          <td>String</td>
          <td>NO</td>
        </tr>
        <tr>
          <td>Currency</td>
          <td>This is the currency of the transaction. e.g., NGN</td>
          <td>String</td>
          <td>YES</td>
        </tr>
        <tr>
          <td>ResponseCode</td>
          <td>This is the status code for the transaction.</td>
          <td>String</td>
          <td>YES</td>
        </tr>
        <tr>
          <td>ResponseMessage</td>
          <td>The is the status code message for the transaction.</td>
          <td>String</td>
          <td>YES</td>
        </tr>
      </table> */}
      <br />
      <h5
        style={newHeaderClass2}
      >
        5.1 Request Parameters.
      </h5>
      <ul style={{ padding: "1rem 0 1rem 0" }}>
        <li>
          <span style={{ fontSize: "2em", fontWeight: "550" }}>. </span>
          <code>requestHeader: </code> Contains merchantId, terminalId,
          timeStamp, and signature.
        </li>
        <li>
          <span style={{ fontSize: "2em", fontWeight: "550" }}>. </span>
          <code>traceId: </code> A unique identifier for the transaction.
        </li>
      </ul>
      <h5
        style={newHeaderClass2}
      >
        5.2 Transaction Query Request Structure
      </h5>
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {sampleRequestForTransactionQuery}
      </SyntaxHighlighter>
      <h5
        style={newHeaderClass2}
      >
        5.3 Example of Making a Transaction Query.
      </h5>
      <p style={{ padding: "1rem 0 1rem 0" }}>
        Use the GetSignature method to generate the signature and timestamp for
        the request.
      </p>
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {transactionQueryFunction}
      </SyntaxHighlighter>
      <h5
        style={newHeaderClass2}
      >
        5.4 Sample Response.
      </h5>
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {tsqSampleResponsePayload}
      </SyntaxHighlighter>
      <br />
      <h3 style={newHeaderClass}>6. Retrieving Account Details.</h3>
      <p style={{ padding: "1rem 0 1rem 0" }}>
        You can retrieve account details and get the account ID using the
        getAccountDetails and getAccountId methods.
      </p>
      <h5
        style={newHeaderClass2}
      >
        6.1 Request Parameters.
      </h5>
      <ul style={{ padding: "1rem 0 1rem 0" }}>
        <li>
          <span style={{ fontSize: "2em", fontWeight: "550" }}>. </span>
          <code>requestHeader: </code> Contains merchantId, terminalId,
          timeStamp, and signature.
        </li>
        <li>
          <span style={{ fontSize: "2em", fontWeight: "550" }}>. </span>
          <code>traceId: </code> A unique identifier for the transaction.
        </li>
      </ul>
      <h5
        style={newHeaderClass2}
      >
        6.2 Sample Request Payload.
      </h5>
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {sampleRequestForGetAccountDetails}
      </SyntaxHighlighter>
      <h5
        style={newHeaderClass2}
      >
        6.3 Example of Making an Account Details Request
      </h5>

      <p style={{ padding: "1rem 0 1rem 0" }}>
        Similar to other requests, you need to use the GetSignature method to
        generate a signature and timestamp.
      </p>
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {getAccountDetailsFunction}
      </SyntaxHighlighter>
      <h5
        style={newHeaderClass2}
      >
        6.4 Sample Response.
      </h5>
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {accountDetailsResponse}
      </SyntaxHighlighter>
      <br />

      <h3 style={newHeaderClass}>7. Error Handling</h3>
      <p style={{ padding: "1rem 0 1rem 0" }}>
        For all methods, it's essential to handle errors appropriately. The
        Verge Services library returns responses with status codes and messages
        to help identify issues.
      </p>
      <h5
        style={newHeaderClass2}
      >
        7.1 Example Of Error Handling.
      </h5>
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {sampleErrorHandling}
      </SyntaxHighlighter>
      <br />
      <span>
        <b>Error Codes</b>
      </span>
      <table style={{ width: "910px" }}>
        <tr>
          <td>Codes</td>
          <td>Description</td>
        </tr>
        <tr>
          <td>00</td>
          <td>Successful</td>
        </tr>
        <tr>
          <td>01</td>
          <td>
            Failed Transaction: Description in the ResponseMessage, e.g.
            Insufficient fund,
          </td>
        </tr>
        <tr>
          <td>96</td>
          <td>General Error or System Challenge</td>
        </tr>
        <tr>
          <td>06</td>
          <td>Invalid or Missing Parameter</td>
        </tr>
        <tr>
          <td>25</td>
          <td>Not Found</td>
        </tr>
        <tr>
          <td>12</td>
          <td>Invalid Transaction</td>
        </tr>
        <tr>
          <td>09</td>
          <td>Pending Transaction</td>
        </tr>
        <tr>
          <td>34</td>
          <td>Suspected Fraud</td>
        </tr>
      </table>
      <br />
      <h3 style={newHeaderClass}>8. Additional Information</h3>
      <ul>
        <li>
          <span style={{ fontSize: "2em", fontWeight: "550" }}>. </span> Always
          ensure that your API calls are secured with valid signatures and that
          sensitive information is encrypted.
        </li>
        <li>
          <span style={{ fontSize: "2em", fontWeight: "550" }}>. </span>
          Make sure to replace placeholders like your_merchant_id,
          your_terminal_id, and others with actual values from your Verge
          account.
        </li>
        <li>
          <span style={{ fontSize: "2em", fontWeight: "550" }}>. </span>
          Refer to the official Verge API documentation for additional details
          on optional parameters and advanced configuration.
        </li>
      </ul>

      <br />
      <hr />
      <br />
      <h3 style={newHeaderClass}>9. Conclusion</h3>
      <p style={{ padding: "1rem 0 1rem 0" }}>
        The Verge Payment Gateway provides a flexible and secure way to manage
        payments. This guide helps you integrate basic operations, but you can
        expand it by incorporating more advanced features as needed.
      </p>
      <p style={{ padding: "0 0 1rem 0" }}>
        For more details, please contact Verge support or refer to the official
        API documentation.
      </p>

      <hr />

      <p style={{ padding: "1rem 0 1rem 0" }}>
        This document serves as a general integration guide for Verge Payment
        Gateway and can be customized to fit your specific implementation needs.
      </p>
    </>
  );
};

export default Dotnetcontent;
