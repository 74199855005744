import React, { useEffect, useRef, useState } from "react";
// import Table from "../../components/Table";
// import payload from "../../assets/payloadsvg.svg";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import {
  darcula,
  a11yDark,
} from "react-syntax-highlighter/dist/esm/styles/prism";
import { useNavigate } from "react-router-dom";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Button,
  Grid2,
  Typography,
} from "@mui/material";
import DotnetContent from "./dotnet/DotnetContent";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import { scrollToTop } from "../../../components/helper";

const CGateUssdDocumentationScreen = () => {
  const [javascript, setJavascript] = useState(true);
  const [dotnet, setDotnet] = useState(true);
  const [ceeSharp, setCeeSharp] = useState(false);
  const navigate = useNavigate();

  const apiSpec = useRef(null);
  const snippets = useRef(null);

  const scrollToSection = (ref) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleJavascriptContent = () => {
    setJavascript((prev) => !prev);
    setDotnet(false);
    setCeeSharp(false);
  };
  const handleDotnetContent = () => {
    setJavascript(false);
    setDotnet((prev) => !prev);
    setCeeSharp(false);
  };
  const handleCeeSharpContent = () => {
    setJavascript(false);
    setDotnet(false);
    setCeeSharp((prev) => !prev);
  };

  useEffect(() => {
    scrollToTop();
  },[]);



  const authRequest = `
{
  "Username": "SkyKru",
  "Password": "#1ooGr@0ndar$"
}
  `;
  const authResponse = `
{
  "Token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdG",
  "Key": "2092ba6b-742b-4df1-af34-7def3bc004c1",
  "Status": "Success"
}
  `;

  const invokePaymentRequestPayload = `
{
  "MerchantId": "23457M6620011300",
  "TerminalId": "17019513",
  "SubMerchantName": "Mount Weather",
  "Amount": 100.0,
  "TraceId": "100023023023",
  "BankCode": "737",
  "TimeStamp": 2021230123,
  "Signature": "dfb94dd 459061b2e331c37ed17fe75bc0770607a2"
}
  `;

  const invokePaymentResponsePayload = `
{
    "ResponseHeader": {
    "ResponseCode": "00",
    "ResponseMessage": "Success"
  },
    "ResponseDetails": {
    "Reference": "1234",
    "Amount": "100.0",
    "TransactionId": "21090799711470344266",
    "TraceId": "100023023023",
    "UssdString": "*737*000*4736#",
    "TimeStamp": 1621230125,
    "Signature":"yhk94dd 4590612e331c37ed17fe75bc077060tf0”
    }
}
  `;

  const callBackRequestPayload = `
  {
  "MerchantId": "2057M6620011300",
  "TerminalId": "17019513",
  "SubMerchantName": "Mount Weather",
  "TransactionId": "20102020029299002999",
  "TraceId": "100023023023",
  "CustomerId": "29039290",
  "Reference": "1234",
  "Amount": 100.0,
  "BankCode": "000966",
  "BankName": "Arkadia Bank",
  "CustomerMobile": "0703****684",
  "ResponseCode": "00",
  "ResponseMessage": "Success",
  "TimeStamp": 20000393884,
  "Signature": "9993kwdkklrj5n2neek5k6nnb53mnbhn"
}

  `;
  const getBankResponsePayload = `
[
  {
    "BankName": "First Bank of Nigeria",
    "BankCode": "894",
    "UssdString": "*894*000*RefCode#"
  },
  {
    "BankName": "Guaranty Trust Bank",
    "BankCode": "737",
    "UssdString": "*737*000*RefCode#"
  },
  {
    "BankName": "Access Bank",
    "BankCode": "901",
    "UssdString": "*901*000*RefCode#"
  }
]


  `;

  const responseJsonObject = `
  {
  "ResponseHeader": { "ResponseCode": "00", "ResponseMessage": "Success" },
  "ResponseDetails": {
    "Reference": "1856",
    "Amount": "2000.0",
    "TransactionID": "19012301000000001357",
    "TraceID": "09283474728"
  }
}
  `;

  const transactionStatusFromPOS = `
    {
    "responseCode": "00",
    "responsemessage": "Success",
    "reference": "1466",
    "amount": 2000.0,
    "terminalId": "1057P010",
    "merchantId": "1057P0100000001",
    "retrievalReference": "19010811000000000967",
    "institutionCode": "777",
    "shortName": "Sink Node",
    "customer_mobile": "080",
    "SubMerchantName": "Contoso Limited",
    "TransactionID": "19010811000000000967",
    "UserID": "66141919074003",
    "TraceID": "xyz123abc"
}
  `;

  const transactionQueryResponse = `
  {
  "RequestHeader": {
    "Username": "Citi_Serve",
    "Password": "PosP@ssw3824652"
  },
  "RequestDetails": {
    "TerminalId": "1057P010",
    "Amount": 2000.0,
    "MerchantId": "1057P0100000001",
    "TransactionID": "19012307000000001388"
  }
}
  `;

  const transactionQueryResponsePayload = `
{
  "MerchantId": "2057M6620011300",
  "TerminalId": "17019513",
  "SubMerchantName": "Mount Weather",
  "TransactionId": "20102020029299002999",
  "TraceId": "100023023023",
  "CustomerId": "29039290",
  "Reference": "1234",
  "Amount": 100.0,
  "BankCode": "000966",
  "BankName": "Arkadia Bank",
  "CustomerMobile": "0703****684",
  "ResponseCode": "00",
  "ResponseMessage": "Success",
  "TimeStamp": 40439939933,
  "Signature": "9993kwdkklrj5n2neek5k6nnb53mnbhn"
}

  `;

  const transactionRefundPayload = `
  {
  "RequestHeader": {
    "Username": "City_Loans",
    "Password": "Kos$ssw3824652"
  },
  "ReversalDetails": {
    "TerminalId": "1057P010",
    "Amount": 2000.0,
    "MerchantId": "1057P0100000001",
    "TransactionID": "19012307000000001388"
  }
}
  `;
  return (
    <div>
      <div className="developer-wrapper">
        <div className="developer-wrapper-overlay">
          <div className="developer-wrapper-overlay-inner">
            <h3>C’Gate USSD Documentation</h3>
            <p className="mt-2 small">
              This feature is the first in the industry. It will enable
              customers to use their USSD PIN to authorize POS or WEB
              transaction.
            </p>
            <br />
            <br />
            {/*<div style={{display:'flex'}} >*/}
            {/*    <Button message="API Dashboard" bgColor="#7B3F63" bgText="#FFFFFF" />*/}
            {/*    <Button  message="Download Document" bgColor="#EFEFEF" bgText="#3A3A3A"/>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
      <div className="developer-wrapper-mobile">
        <div className="developer-wrapper-overlay">
          <div className="developer-wrapper-overlay-inner">
            <h3>C’Gate USSD Documentation</h3>
            <p className="small">
              This feature is the first in the industry. It will enable
              customers to use their USSD PIN to authorize POS or WEB
              transaction.
            </p>
            {/*<div style={{display:'flex', flexDirection:"column",justifyContent:"center",gap:"20px"}}>*/}
            {/*    <Button message="API Dashboard" bgColor="#7B3F63" bgText="#FFFFFF" />*/}
            {/*    <Button  message="Download Document" bgColor="#EFEFEF" bgText="#3A3A3A"/>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>

      <div className="doc-div-parent-doc">
        <div className="docNav">
          <Box sx={{ padding: "2rem 0 2rem 0" }}>
            <Button
              sx={{
                backgroundColor: "#083857",
                fontWeight: "550",
                color: "#fff",
              }}
              onClick={() => navigate("/developer#services")}
            >
              BACK TO DEVELOPER'S HOMEPAGE
            </Button>
          </Box>
          <nav>
            <List
              sx={{
                border: "1px solid #083857",
                borderRadius: "5px",
              }}
            >
              <ListItem disablePadding>
                {/* <a href="#apiSpec"> */}
                  <ListItemButton onClick={() => scrollToSection(apiSpec)}>
                    <ListItemIcon>{/* <InboxIcon /> */}</ListItemIcon>
                    <ListItemText primary="API Specifications" />
                  </ListItemButton>
                {/* </a> */}
              </ListItem>
              <ListItem disablePadding>
                {/* <a href="#snippets"> */}
                  <ListItemButton onClick={() => scrollToSection(snippets)}>
                    <ListItemIcon>{/* <DraftsIcon /> */}</ListItemIcon>
                    <ListItemText primary="Libraries & Code Snippets" />
                  </ListItemButton>
                {/* </a> */}
              </ListItem>
            </List>
          </nav>
          {/* <h3>Documentation</h3> */}
          {/* <ul>
            <a href={"/developer/docs/cgateussd"}>
              {" "}
              <li>C’Gate USSD</li>
            </a>
            <a href={"/developer/docs/pay-with-bank-transfer"}>
              {" "}
              <li>Pay With Bank Transfer</li>
            </a>
            <a href={"/developer/docs/payment-gateway"}>
              {" "}
              <li>CoralPay Payment Gateway</li>
            </a>
            <a href={"/developer/docs/vas"}>
              {" "}
              <li>CoralPay VAS</li>
            </a>
            <a href={"/developer/docs/card-api"}>
              {" "}
              <li>CoralPay Card APIs</li>
            </a>
          </ul> */}
        </div>
        <div className="doc-div-doc" ref={apiSpec}>
          <h3>INTRODUCTION</h3>
          <p>
            This document is a technical and integration document for C’Gate
            Ussd payment for web and POS transactions. It provides guidance and
            template material which is intended to assist the relevant
            stakeholders to easily integrate to the payment interface for
            collection and processing of transactions using the Banks’ USSD
            channel.
          </p>
        </div>
        <div className="doc-div-doc">
          <h3>ASSUMPTIONS</h3>
          <ol>
            <li>
              <b>1.</b> Aggregator/Merchant integrating the gateways has a
              platform such as a web interface or POS channel to integrate the
              payment gateways.
            </li>
            <br />
            <li>
              <b>2.</b> Customers using the USSD as preferred option of payment
              already has a bank account with USSD enabled.
            </li>
          </ol>
        </div>
        <div className="doc-div-doc">
          <h3>PAYMENT SCENARIO</h3>
          <ol>
            <li>
              <b>1.</b> Aggregator /Merchant computes the total amount to be
              paid on the website or POS.
            </li>
            <br />
            <li>
              <b>2.</b> Customer selects the bank he wishes to use to
              complete/authorize the payment.
            </li>
            <li>
              <b>3.</b> Merchant makes an API call to C’Gate to generate
              reference code/USSD string to make payment.
            </li>
            <li>
              <b>4.</b> Merchant get’s the Ussd string from C’Gate and displays
              to the customer to dial
            </li>
            <li>
              <b>5.</b> The customer dials the Ussd string and put pin to
              authorize the payment
            </li>
            <li>
              <b>6.</b> Cgate then notifies the Merchant via a call-back
              endpoint on the status of the transaction in real time. (Note, if
              Cgate does not get Http 200 on the call-back, C’Gate retries 10
              times in 1hour. Merchant can also call the Status Query endpoint
              for transaction status.)
            </li>
          </ol>
        </div>
        <div className="doc-div-doc">
          <h3>API SPECIFICATION</h3>
          <p>
            Merchants are required to be registered on CoralPay Cgate platform
            and the credentials to call the API operations will be shared.
          </p>
          <p>
            Cgate USSD uses the uses the JWT authentication method to
            authenticate users calling the API, and a Key to generate the
            Signature value for every call
          </p>
        </div>
        <div className="doc-div-doc">
          <h3>AUTHENTICATION</h3>
          <p>
            Merchant is required to call the Authentication operation using the
            credentials provided by CoralPay during registration. The Response
            from this operation is to be used for authentication for other
            operations using bearer token authentication type.
          </p>

          <table>
            <tr>
              <td>URL</td>
              <td>
                <SyntaxHighlighter language="javascript" style={a11yDark}>
                  {`https://testdev.coralpay.com/cgateproxy/api/v2/authentication`}
                </SyntaxHighlighter>
              </td>
            </tr>
            <tr>
              <td>VERB</td>
              <td>POST</td>
            </tr>
            <tr>
              <td>CONTENT-TYPE</td>
              <td>application/json</td>
            </tr>
            <tr>
              <td>REQUEST-PAYLOAD</td>
              <td>
                <SyntaxHighlighter language="javascript" style={a11yDark}>
                  {authRequest}
                </SyntaxHighlighter>
              </td>
            </tr>
            <tr>
              <td>RESPONSE-PAYLOAD</td>
              <td>
                <SyntaxHighlighter language="javascript" style={a11yDark}>
                  {authResponse}
                </SyntaxHighlighter>
              </td>
            </tr>
          </table>
        </div>
        <br />
        <div className="doc-div-doc">
          <h3>Properties Descritpion</h3>
          <table>
            <tr>
              <th>Properties</th>
              <th>Description</th>
              <th>Mandatory</th>
            </tr>
            <tr>
              <td>Username</td>
              <td>
                This is a unique username assigned to the merchant. Datatype:
                String
              </td>
              <td>YES</td>
            </tr>
            <tr>
              <td>Password</td>
              <td>
                The is the Corresponding password to the Username. Datatype:
                String
              </td>
              <td>YES</td>
            </tr>
            <tr>
              <td>Token</td>
              <td>
                The is the generated token used for the bearer token
                authentication type. Datatype: String
              </td>
              <td>YES</td>
            </tr>
            <tr>
              <td>Key</td>
              <td>
                This is used to generate the signature value on operation call.
                Datatype: String Sha256(MerchantId+TerminalId+TimeStamp+Key).
                Timestamp: UnixTimeSeconds (int)
              </td>
              <td>YES</td>
            </tr>
            <tr>
              <td>Status</td>
              <td>
                This is the status of the call which is either Success or
                Failed. Datatype: String
              </td>
              <td>YES</td>
            </tr>

            <tr>
              <td>REQUEST-PAYLOAD</td>
              <td>
                <SyntaxHighlighter language="javascript" style={a11yDark}>
                  {authRequest}
                </SyntaxHighlighter>
              </td>
            </tr>
            <tr>
              <td>RESPONSE-PAYLOAD</td>
              <td>
                <SyntaxHighlighter language="javascript" style={a11yDark}>
                  {authResponse}
                </SyntaxHighlighter>
              </td>
            </tr>
          </table>
        </div>

        <div className="doc-div-doc">
          <h3>Invoke-Reference</h3>
          <p>
            This operation generates the payment reference code to be used to
            complete or authorize the payment.
          </p>

          <table>
            <tr>
              <td>URL</td>
              <td>
                <SyntaxHighlighter language="javascript" style={a11yDark}>
                  {`https://testdev.coralpay.com/cgateproxy/api/v2/invokereference`}
                </SyntaxHighlighter>
              </td>
            </tr>
            <tr>
              <td>VERB</td>
              <td>Post</td>
            </tr>
            <tr>
              <td>CONTENT-TYPE</td>
              <td>application/json</td>
            </tr>
            <tr>
              <td>AUTHENTICATION</td>
              <td>Bearer token authentication type</td>
            </tr>
            <tr>
              <td>REQUEST PAYLOAD</td>
              <td>
                <SyntaxHighlighter language="javascript" style={a11yDark}>
                  {invokePaymentRequestPayload}
                </SyntaxHighlighter>
              </td>
            </tr>
            <tr>
              <td>RESPONSE PAYLOAD</td>
              <td>
                <SyntaxHighlighter language="javascript" style={a11yDark}>
                  {invokePaymentResponsePayload}
                </SyntaxHighlighter>
              </td>
            </tr>
          </table>
          <br />

          <h3>Properties Description</h3>

          <table>
            <tr>
              <th>Properties</th>
              <th>Description</th>
              <th>Mandatory</th>
            </tr>
            <tr>
              <td>MerchantId</td>
              <td>
                This is a unique id assigned to the merchant which is provided
                by CoralPay. Datatype: String
              </td>
              <td>YES</td>
            </tr>
            <tr>
              <td>TerminalId</td>
              <td>
                The is the Id for the Terminal/Channel making the call to Cgate.
                Datatype: String
              </td>
              <td>YES</td>
            </tr>
            <tr>
              <td>SubMerchantName</td>
              <td>
                This is the Name to be displayed on the customers’ mobile while
                authorizing the payment via the banks USSD.
              </td>
              <td>NO</td>
            </tr>
            <tr>
              <td>Amount</td>
              <td>This is transaction amount. Datatype: decimal</td>
              <td>YES</td>
            </tr>
            <tr>
              <td>TraceId</td>
              <td>
                This is the Id attached to the transaction by the Merchant. If
                this is not supplied, Cgate generates it
              </td>
              <td>NO</td>
            </tr>
            <tr>
              <td>BankCode</td>
              <td>
                This is the bank USSD code the user intends to use. See here for
                list of banks’ Codes
              </td>
              <td>NO</td>
            </tr>
            <tr>
              <td>TimeStamp</td>
              <td>Timestamp: UnixTimeSeconds Datatype: long</td>
              <td>YES</td>
            </tr>
            <tr>
              <td>Signature</td>
              <td>
                This value is generated to maintain the integrity of the
                payload. Note that a new Timestamp must be generated for
                signature calculation in every API call.
                Sha256(MerchantId+TerminalId+TimeStamp+Key). Datatype: String
              </td>
              <td>YES</td>
            </tr>
            <tr>
              <td>ResponseCode</td>
              <td>
                This is the ResponseCode for the call to generate the payment
                Reference code.
              </td>
              <td>YES</td>
            </tr>
            <tr>
              <td>ResposneMessage</td>
              <td>This is the translation of the response code</td>
              <td>YES</td>
            </tr>
            <tr>
              <td>Reference</td>
              <td>
                This is the Reference code combined with the bank USSD to
                complete the USSD string for the customers. Usually valid for 5
                minutes. E.g. *737*000*1234#
              </td>
              <td>YES</td>
            </tr>
            <tr>
              <td>UssdString</td>
              <td>
                This will the populated with the string to be dialled if the
                BankCode is supplied. Datatype: String
              </td>
              <td>YES</td>
            </tr>
            <tr>
              <td>TransactionId</td>
              <td>
                This the TransactionId attached to the transaction by CoralPay,
                and this is unique across board.
              </td>
              <td>YES</td>
            </tr>
          </table>
        </div>
        <br />

        <div className="doc-div-doc">
          <h3>CALL-BACK-OPERATION</h3>
          <p>
            C’Gate always send payment status notification in real time as soon
            as the payment is authorized, Cgate will retry to push the
            notification 10 times within an hour until we get Http response 200.
          </p>
          <p>
            <i>
              Note: There is a Transaction query to get status if you eventually
              didn’t get the real-time notification
            </i>
          </p>

          <table>
            <tr>
              <td>URL</td>
              <td>To be provided by the merchant</td>
            </tr>
            <tr>
              <td>VERB</td>
              <td>Post</td>
            </tr>
            <tr>
              <td>CONTENT-TYPE</td>
              <td>application/json</td>
            </tr>
            <tr>
              <td>AUTHENTICATION</td>
              <td>
                Basic Auth: using username and password provided by CoralPay
              </td>
            </tr>
            <tr>
              <td>RESPONSE-PAYLOAD </td>
              <td>
                <SyntaxHighlighter language="javascript" style={a11yDark}>
                  {callBackRequestPayload}
                </SyntaxHighlighter>
              </td>
            </tr>
            <tr>
              <td>RESPONSE-PAYLOAD </td>
              <td>
                <SyntaxHighlighter language="javascript" style={a11yDark}>
                  {callBackRequestPayload}
                </SyntaxHighlighter>
              </td>
            </tr>
          </table>
          <br />

          <h3>Properties Description</h3>
          <table>
            <tr>
              <th>Properties</th>
              <th>Description</th>
              <th>Mandatory</th>
            </tr>
            <tr>
              <td>MerchantId</td>
              <td>
                This is a unique id assigned to the merchant which is provided
                by CoralPay. Datatype: String
              </td>
              <td>YES</td>
            </tr>
            <tr>
              <td>TerminalId</td>
              <td>
                The is the Id for the Terminal/Channel making the call to Cgate.
                Datatype: String
              </td>
              <td>YES</td>
            </tr>
            <tr>
              <td>SubMerchantName</td>
              <td>
                This is the Name to be displayed on the customers’ mobile while
                authorizing the payment via the banks USSD.
              </td>
              <td>NO</td>
            </tr>
            <tr>
              <td>Amount</td>
              <td>This is transaction amount. Datatype: decimal</td>
              <td>YES</td>
            </tr>
            <tr>
              <td>TraceId</td>
              <td>
                This is the Id attached to the transaction by the Merchant. If
                this is not supplied, Cgate generates it
              </td>
              <td>NO</td>
            </tr>
            <tr>
              <td>BankCode</td>
              <td>
                This is the bank USSD code the user intends to use. See here for
                list of banks’ Codes
              </td>
              <td>NO</td>
            </tr>
            <tr>
              <td>TimeStamp</td>
              <td>Timestamp: UnixTimeSeconds Datatype: long</td>
              <td>YES</td>
            </tr>
            <tr>
              <td>Signature</td>
              <td>
                This value is generated to maintain the integrity of the
                payload. Note that a new Timestamp must be generated for
                signature calculation in every API call.
                Sha256(MerchantId+TerminalId+TimeStamp+Key). Datatype: String
              </td>
              <td>YES</td>
            </tr>
            <tr>
              <td>ResponseCode</td>
              <td>
                This is the ResponseCode for the call to generate the payment
                Reference code.
              </td>
              <td>YES</td>
            </tr>
            <tr>
              <td>ResposneMessage</td>
              <td>This is the translation of the response code</td>
              <td>YES</td>
            </tr>
            <tr>
              <td>Reference</td>
              <td>
                This is the Reference code combined with the bank USSD to
                complete the USSD string for the customers. Usually valid for 5
                minutes. E.g. *737*000*1234#
              </td>
              <td>YES</td>
            </tr>
            <tr>
              <td>TransactionId</td>
              <td>
                This the TransactionId attached to the transaction by CoralPay,
                and this is unique across board.
              </td>
              <td>YES</td>
            </tr>
            <tr>
              <td>BankName</td>
              <td>The bank used by the customer.</td>
              <td>YES</td>
            </tr>
            <tr>
              <td>CustomerMobile</td>
              <td>This is the customer phone number</td>
              <td>YES</td>
            </tr>
            <tr>
              <td>CustomerId</td>
              <td>This is the unique Id for the customer dialling.</td>
              <td>YES</td>
            </tr>
          </table>
          <br />
          <h3>TRANSACTION STATUS QUERY</h3>
          <p>
            This is the operation to get details of a particular transaction.
          </p>

          <br />
          <table>
            <tr>
              <td>URL</td>
              <td>
                <SyntaxHighlighter language="javascript" style={a11yDark}>
                  {`https://testdev.coralpay.com/cgateproxy/api/v2/statusquery`}
                </SyntaxHighlighter>
              </td>
            </tr>
            <tr>
              <td>VERB</td>
              <td>Post</td>
            </tr>
            <tr>
              <td>CONTENT-TYPE</td>
              <td>application/json</td>
            </tr>
            <tr>
              <td>AUTHENTICATION</td>
              <td>Bearer Token Authentication type.</td>
            </tr>
            <tr>
              <td>RESPONSE-PAYLOAD</td>
              <td>
                {" "}
                <SyntaxHighlighter language="javascript" style={a11yDark}>
                  {transactionQueryResponsePayload}
                </SyntaxHighlighter>
              </td>
            </tr>
          </table>
          <br />

          <h3>Properties Description</h3>
          <br />

          <table>
            <tr>
              <th>Properties</th>
              <th>Description</th>
              <th>Mandatory</th>
            </tr>
            <tr>
              <td>MerchantId</td>
              <td>
                This is a unique id assigned to the merchant which is provided
                by CoralPay. Datatype: String
              </td>
              <td>YES</td>
            </tr>
            <tr>
              <td>TerminalId</td>
              <td>
                The is the Id for the Terminal/Channel making the call to Cgate.
                Datatype: String
              </td>
              <td>YES</td>
            </tr>
            <tr>
              <td>SubMerchantName</td>
              <td>
                This is the Name to be displayed on the customers’ mobile while
                authorizing the payment via the banks USSD.
              </td>
              <td>NO</td>
            </tr>
            <tr>
              <td>Amount</td>
              <td>This is transaction amount. Datatype: decimal</td>
              <td>YES</td>
            </tr>
            <tr>
              <td>TraceId</td>
              <td>
                This is the Id attached to the transaction by the Merchant. If
                this is not supplied, Cgate generates it
              </td>
              <td>NO</td>
            </tr>
            <tr>
              <td>BankCode</td>
              <td>
                This is the bank USSD code the user intends to use. See here for
                list of banks’ Codes
              </td>
              <td>NO</td>
            </tr>
            <tr>
              <td>TimeStamp</td>
              <td>Timestamp: UnixTimeSeconds Datatype: long</td>
              <td>YES</td>
            </tr>
            <tr>
              <td>Signature</td>
              <td>
                This value is generated to maintain the integrity of the
                payload. Note that a new Timestamp must be generated for
                signature calculation in every API call.
                Sha256(MerchantId+TerminalId+TimeStamp+Key). Datatype: String
              </td>
              <td>YES</td>
            </tr>
            <tr>
              <td>ResponseCode</td>
              <td>
                This is the ResponseCode for the call to generate the payment
                Reference code.
              </td>
              <td>YES</td>
            </tr>
            <tr>
              <td>ResposneMessage</td>
              <td>This is the translation of the response code</td>
              <td>YES</td>
            </tr>
            <tr>
              <td>Reference</td>
              <td>
                This is the Reference code combined with the bank USSD to
                complete the USSD string for the customers. Usually valid for 5
                minutes. E.g. *737*000*1234#
              </td>
              <td>YES</td>
            </tr>
            <tr>
              <td>TransactionId</td>
              <td>
                This the TransactionId attached to the transaction by CoralPay,
                and this is unique across board.
              </td>
              <td>YES</td>
            </tr>
            <tr>
              <td>BankName</td>
              <td>The bank used by the customer.</td>
              <td>YES</td>
            </tr>
            <tr>
              <td>CustomerMobile</td>
              <td>This is the customer phone number</td>
              <td>YES</td>
            </tr>
            <tr>
              <td>CustomerId</td>
              <td>This is the unique Id for the customer dialling.</td>
              <td>YES</td>
            </tr>
          </table>

          <br />

          <h3>GET BANK LIST</h3>
          <p>This is to get the lists of banks</p>
          <br />

          <table>
            <tr>
              <td>URL</td>
              <td>
                <SyntaxHighlighter language="javascript" style={a11yDark}>
                  {`https://testdev.coralpay.com/cgateproxy/api/v2/getbanks`}
                </SyntaxHighlighter>
              </td>
            </tr>
            <tr>
              <td>VERB</td>
              <td>POST</td>
            </tr>
            <tr>
              <td>CONTENT-TYPE</td>
              <td>application/json</td>
            </tr>
            <tr>
              <td>AUTHENTICATION</td>
              <td>Bearer Token Authentication type</td>
            </tr>
            <tr>
              <td>RESPONSE PAYLOAD</td>
              <td>
                <SyntaxHighlighter language="javascript" style={a11yDark}>
                  {getBankResponsePayload}
                </SyntaxHighlighter>
              </td>
            </tr>
          </table>
        </div>

        <div className="doc-div-doc">
          <Box sx={{ width: "100%", paddingTop: "2rem" }} ref={snippets}>
            <Grid2
              container
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Grid2>
                <Typography
                  sx={{
                    color: "#7f3d64",
                    fontSize: "1.2em",
                    fontWeight: "800",
                  }}
                >
                  Libraries & Code Snippets
                </Typography>
              </Grid2>
              <Grid2 sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button onClick={scrollToTop} sx={{ color: "#7f3d64" }}>
                  Back to the top{" "}
                  <KeyboardDoubleArrowUpIcon
                    sx={{ fontSize: 30, color: "#7f3d64" }}
                  />
                </Button>
              </Grid2>
            </Grid2>

            <Grid2
              container
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "1.5rem 0 1.5rem 0",
              }}
            >
              {/* <div style={{ marginRight: "1rem" }}>
                <button
                  style={{
                    border: javascript ? "none" : "1px solid #242424",
                    borderRadius: "10px",
                    fontWeight: 550,
                    padding: "1rem",
                    color: javascript ? "#fff" : "#242424",
                    backgroundColor: javascript ? "#242424" : "#fff",
                  }}
                  onClick={handleJavascriptContent}
                >
                  JAVASCRIPT
                </button>
              </div> */}
              <div style={{ marginRight: "1rem" }}>
                <button
                  style={{
                    border: dotnet ? "none" : "1px solid #242424",
                    borderRadius: "10px",
                    fontWeight: 550,
                    padding: "1rem",
                    color: dotnet ? "#fff" : "#242424",
                    backgroundColor: dotnet ? "#242424" : "#fff",
                  }}
                  onClick={handleDotnetContent}
                >
                  .NET
                </button>
              </div>
              {/* <div>
                <button
                  style={{
                    border: ceeSharp ? "none" : "1px solid #242424",
                    borderRadius: "10px",
                    fontWeight: 550,
                    padding: "1rem",
                    color: ceeSharp ? "#fff" : "#242424",
                    backgroundColor: ceeSharp ? "#242424" : "#fff",
                  }}
                  onClick={handleCeeSharpContent}
                >
                  JAVA
                </button>
              </div> */}
            </Grid2>

            <div>
              {/* {javascript && <JavascriptContent />} */}
              {dotnet && <DotnetContent />}
              {/* {ceeSharp && <JavaContent />} */}
            </div>
          </Box>
          <br />
          <h3>RESPONSE CODES</h3>
          <table style={{ width: "910px" }}>
            <tr>
              <th>Response Code</th>
              <th>Description</th>
            </tr>

            <tr>
              <td>00</td>
              <td>Successful approval/completion</td>
            </tr>
            <tr>
              <td>03</td>
              <td>
                Invalid service provider or Institution not properly setup
              </td>
            </tr>
            <tr>
              <td>05</td>
              <td>Do not honor</td>
            </tr>
            <tr>
              <td>06</td>
              <td>General Error or Fatal Error</td>
            </tr>
            <tr>
              <td>12</td>
              <td>Invalid transaction</td>
            </tr>
            <tr>
              <td>13</td>
              <td>Invalid Amount</td>
            </tr>
            <tr>
              <td>14</td>
              <td>Credit account number is invalid</td>
            </tr>
            <tr>
              <td>15</td>
              <td>Forwarding Institution has not been setup</td>
            </tr>
            <tr>
              <td>16</td>
              <td>Insufficient Fund</td>
            </tr>
            <tr>
              <td>25</td>
              <td>Unable to locate the account or transaction</td>
            </tr>
            <tr>
              <td>30</td>
              <td>Format Error. System cannot decrypt message</td>
            </tr>
            <tr>
              <td>51</td>
              <td>Insufficient funds</td>
            </tr>
            <tr>
              <td>52</td>
              <td>Account does not exist</td>
            </tr>
            <tr>
              <td>53</td>
              <td>Account number does not exist</td>
            </tr>
            <tr>
              <td>55</td>
              <td>Incorrect PIN(USSD or Mobile or One time token)</td>
            </tr>
            <tr>
              <td>57</td>
              <td>Transaction type is not permitted to account</td>
            </tr>
            <tr>
              <td>58</td>
              <td>This terminal has not been setup for this transaction</td>
            </tr>
            <tr>
              <td>61</td>
              <td>Amount Limit exceeded</td>
            </tr>
            <tr>
              <td>63</td>
              <td>Security Violation</td>
            </tr>
            <tr>
              <td>78</td>
              <td>This account has been blacklisted</td>
            </tr>
            <tr>
              <td>92</td>
              <td>Destination transaction route not found</td>
            </tr>
            <tr>
              <td>96</td>
              <td>System Malfunction</td>
            </tr>
          </table>

          <p>
            <b>
              The Cgate Response codes are based on ISO 8583 financial
              transaction codes. You can find the full list of the codes and
              their meaning here:
            </b>
            <br />
            <a href="https://en.wikipedia.org/wiki/ISO_8583">
              https://en.wikipedia.org/wiki/ISO_8583
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default CGateUssdDocumentationScreen;
