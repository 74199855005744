import { useEffect } from "react";
import { useLocation } from "react-router-dom";
const ScrollToHash = () => {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.substring(1)); // Remove the '#' from hash
      if (element) {
        element.scrollIntoView({ behavior: "smooth" }); // Smooth scrolling to the section
      }
    }
  }, [hash]); // Re-run this effect when the hash changes

  return null;
};

export default ScrollToHash;
