export const initializeVergClass = `
     private readonly HttpClient _client;
     public readonly string _secretKey;
     public readonly string _baseUrl;
     public readonly string _clientPublicKey;
     public readonly string _clientPrivateKey;
     public readonly string _clientKeyPassword;
     public readonly string _providerPubKey;
`;
export const authenticationRequestStructure = `
public class AuthenticationRequest
{
    public string username { get; set; }
    public string password { get; set; }
    public string terminalId { get; set; }
}

`;
export const authenticationResponseStructure = `
public class AuthenticationResponse
{
    public string token { get; set; }
    public string key { get; set; }
    public string status { get; set; }
}

`;

export const baseUrlText = `
const baseUrl = "https://testdev.coralpay.com:5000/GwApi"; //replace with the actual verge base URL
`;

export const authenticateCode = `
     public CipServices(string baseUrl, string providerPubKey, string clientPrivateKey, string clientKeyPassword, string secretKey, string clientPublicKey)
     {
         var httpClientHandler = new HttpClientHandler();
         httpClientHandler.ServerCertificateCustomValidationCallback = (sender, cert, chain, sslPolicyErrors) =>
         {
             return true;
         };
         _client = new HttpClient();
         _baseUrl = baseUrl;
         _providerPubKey = providerPubKey;
         _clientPublicKey = clientPublicKey;
         _clientPrivateKey = clientPrivateKey;
         _clientKeyPassword = clientKeyPassword;
         _secretKey = secretKey;
         _client.DefaultRequestHeaders.Clear();
         _client.DefaultRequestHeaders.Add("SecretKey", secretKey);
         _client.Timeout = TimeSpan.FromSeconds(45);
     }
`;

export const nameEnquiry = `
     public async Task<NameEnquiryResult> NameEnquiry(NameEnquiryRequest request)
     {
         try
         {
             var resquestToJson = JsonConvert.SerializeObject(request);

             var encRequest = Encrypt(resquestToJson);
             if (encRequest == null)
             {
                 return new NameEnquiryResult
                 {
                     responseHeader = new Models.ResponseHeader
                     {
                         responseCode = "01",
                         responseMessage = "Encryption Failed"
                     },
                 };
             }

             var response = await _client.PostAsJsonAsync($"{_baseUrl}/api/NameEnquiry", encRequest);

             if (response.IsSuccessStatusCode)
             {
                 var responseContent = await response.Content.ReadAsStringAsync();

                 var decResponse = Decrypt(responseContent);
                 if (decResponse != null)
                 {
                     var result = JsonConvert.DeserializeObject<NameEnquiryResponse>(decResponse);
                     return new NameEnquiryResult
                     {
                         responseHeader = new Models.ResponseHeader
                         {
                             responseCode = "00",
                             responseMessage = "Successful"
                         },
                         encryptedRequest = encRequest,
                         reasonPharse = response.ReasonPhrase,
                         encryptedResponse = responseContent,
                         decryptedResponse = result
                     };
                 }
                 else
                 {
                     return new NameEnquiryResult
                     {
                         responseHeader = new Models.ResponseHeader
                         {
                             responseCode = "01",
                             responseMessage = "Decryption Failed"
                         },
                         encryptedRequest = encRequest,
                         reasonPharse = response.ReasonPhrase,
                         encryptedResponse = responseContent,
                         decryptedResponse = null
                     };
                 }

             }
             else
             {
                 return new NameEnquiryResult
                 {
                     responseHeader = new Models.ResponseHeader
                     {
                         responseCode = "01",
                         responseMessage = "Failed"
                     },
                     encryptedRequest = encRequest,
                     reasonPharse = response.ReasonPhrase,
                     decryptedResponse = null
                 };
             }
         }
         catch (TimeoutException ExTmo)
         {
             return new NameEnquiryResult
             {
                 responseHeader = new Models.ResponseHeader
                 {
                     responseCode = "97",
                     responseMessage = ExTmo.Message
                 },
                 decryptedResponse = null
             };
         }
         catch (Exception ex)
         {
             return new NameEnquiryResult
             {
                 responseHeader = new Models.ResponseHeader
                 {
                     responseCode = "01",
                     responseMessage = ex.Message
                 },
                 decryptedResponse = null
             };
         }
     }

`;

export const postCredit = `
     public async Task<PostCreditResult> PostCredit(PostCreditRequest request)
     {
         try
         {
             var resquestToJson = JsonConvert.SerializeObject(request);
             var encRequest = Encrypt(resquestToJson);
             if (encRequest == null)
             {
                 return new PostCreditResult
                 {
                     responseHeader = new Models.ResponseHeader
                     {
                         responseCode = "01",
                         responseMessage = "Encryption Failed"
                     },
                 };
             }
             var response = await _client.PostAsJsonAsync($"{_baseUrl}/api/PostCredit", encRequest);

             if (response.IsSuccessStatusCode)
             {
                 var responseContent = await response.Content.ReadAsStringAsync();
                 var decResponse = Decrypt(responseContent);
                 if (decResponse != null)
                 {
                     var result = JsonConvert.DeserializeObject<PostCreditResponse>(decResponse);
                     return new PostCreditResult
                     {
                         responseHeader = new Models.ResponseHeader
                         {
                             responseCode = "00",
                             responseMessage = "Successful"
                         },
                         encryptedRequest = encRequest,
                         reasonPharse = response.ReasonPhrase,
                         encryptedResponse = responseContent,
                         decryptedResponse = result
                     };
                 }
                 else
                 {
                     return new PostCreditResult
                     {
                         responseHeader = new Models.ResponseHeader
                         {
                             responseCode = "01",
                             responseMessage = "Decryption Failed"
                         },
                         encryptedRequest = encRequest,
                         reasonPharse = response.ReasonPhrase,
                         encryptedResponse = responseContent,
                         decryptedResponse = null
                     };
                 }
             }
             else
             {
                 return new PostCreditResult
                 {
                     responseHeader = new Models.ResponseHeader
                     {
                         responseCode = "01",
                         responseMessage = "Failed"
                     },
                     encryptedRequest = encRequest,
                     reasonPharse = response.ReasonPhrase,
                     decryptedResponse = null
                 };
             }
         }
         catch (TimeoutException ExTmo)
         {
             return new PostCreditResult
             {
                 responseHeader = new Models.ResponseHeader
                 {
                     responseCode = "97",
                     responseMessage = ExTmo.Message
                 },
                 decryptedResponse = null
             };
         }
         catch (Exception ex)
         {
             return new PostCreditResult
             {
                 responseHeader = new Models.ResponseHeader
                 {
                     responseCode = "01",
                     responseMessage = ex.Message
                 },
                 decryptedResponse = null
             };
         }
     }
`;

export const createStaticAccountFunction = `
     public async Task<CreateStaticAcctResponse> CreateStaticAccount(CreateStaticAcctRequest Request)
     {
         var JsonRequest = JsonSerializer.Serialize(Request);
         var Content = new StringContent(JsonRequest, Encoding.UTF8, "application/json");

         var HttpResponse = await _httpClient.PostAsync($"{_baseUrl}/api/CreateStaticAccount", Content);

         if (HttpResponse.IsSuccessStatusCode)
         {
             var stringResponse = await HttpResponse.Content.ReadAsStringAsync();
             var ResponseObj = JsonSerializer.Deserialize<CreateStaticAcctResponse>(stringResponse);

             return ResponseObj;
         }
         else
         {
             return new CreateStaticAcctResponse
             {
                 responseHeader = new Responseheader
                 {
                     responseCode = "96",
                     responseMessage = HttpResponse.ReasonPhrase
                 },
             };
         }

     }
`;

export const sampleInvokePaymentResponse = `
const response = {
"responseHeader": {
"responseCode": "00",
"responseMessage": "Success",
"timeStamp": 1609459200,
"signature": "response_signature"
},
"transactionId": "transaction_id",
"traceId": "trace_id",
"payPageLink": "https://pay.verge.com/payment-page",
"metaData": {}
}
`;

export const sampleRequestForTransactionQuery = `
public class TransactionQueryRequest
{
    public RequestHeader requestHeader { get; set; }
    public string traceId { get; set; }
}
`;

export const transactionQueryFunction = `
     public async Task<TsqResult> TransactionQuery(TransactionQueryRequest request)
     {
         try
         {
             var resquestToJson = JsonConvert.SerializeObject(request);
             var encRequest = Encrypt(resquestToJson);
             if (encRequest == null)
             {
                 return new TsqResult
                 {
                     responseHeader = new Models.ResponseHeader
                     {
                         responseCode = "01",
                         responseMessage = "Encryption Failed"
                     },
                 };
             }
             var response = await _client.PostAsJsonAsync($"{_baseUrl}/api/TransactionQuery", encRequest);

             if (response.IsSuccessStatusCode)
             {
                 var responseContent = await response.Content.ReadAsStringAsync();
                 var decResponse = Decrypt(responseContent);
                 if (decResponse != null)
                 {
                     var result = JsonConvert.DeserializeObject<TransactionQueryResponse>(decResponse);
                     return new TsqResult
                     {
                         responseHeader = new Models.ResponseHeader
                         {
                             responseCode = "00",
                             responseMessage = "Successful"
                         },
                         encryptedRequest = encRequest,
                         reasonPharse = response.ReasonPhrase,
                         encryptedResponse = responseContent,
                         decryptedResponse = result
                     };
                 }
                 else
                 {
                     return new TsqResult
                     {
                         responseHeader = new Models.ResponseHeader
                         {
                             responseCode = "01",
                             responseMessage = "Decryption Failed"
                         },
                         encryptedRequest = encRequest,
                         reasonPharse = response.ReasonPhrase,
                         encryptedResponse = responseContent,
                         decryptedResponse = null
                     };
                 }
             }
             else
             {
                 return new TsqResult
                 {
                     responseHeader = new Models.ResponseHeader
                     {
                         responseCode = "01",
                         responseMessage = "Failed"
                     },
                     encryptedRequest = encRequest,
                     reasonPharse = response.ReasonPhrase,
                     decryptedResponse = null
                 };
             }

         }
         catch (TimeoutException ExTmo)
         {
             return new TsqResult
             {
                 responseHeader = new Models.ResponseHeader
                 {
                     responseCode = "97",
                     responseMessage = ExTmo.Message
                 },
                 decryptedResponse = null
             };
         }
         catch (Exception ex)
         {
             return new TsqResult
             {
                 responseHeader = new Models.ResponseHeader
                 {
                     responseCode = "01",
                     responseMessage = ex.Message
                 },
                 decryptedResponse = null
             };
         }
     }
`;

export const encryptFunction = `
     public string Encrypt(string PayLoad)
     {
         try
         {
             var Enc = CoralPay.Cryptography.Pgp.Invoke.Encrypt(new CoralPay.Cryptography.Pgp.Models.EncryptionParam
             {
                 ExternalPublicKeyStream = new MemoryStream(Encoding.ASCII.GetBytes(_providerPubKey)),
                 ToEncryptText = PayLoad

             }).Result;

             if (Enc.Header.ResponseCode != "00")
             {
                 return null;
             }

             return Enc.Encryption;
         }
         catch (Exception)
         {
             return null;
         }
     }
`;

export const decryptionFunction = `
     public string Decrypt(string PayLoad)
     {
         try
         {

             var dec = CoralPay.Cryptography.Pgp.Invoke.Decrypt(new CoralPay.Cryptography.Pgp.Models.DecryptionParam
             {
                 EncryptedData = PayLoad,
                 InternalKeyPassword = _clientKeyPassword,
                 InternalPublicKeyStream = new MemoryStream(Encoding.ASCII.GetBytes(_clientPublicKey)),
                 InternalPrivateKeyStream = new MemoryStream(Encoding.ASCII.GetBytes(_clientPrivateKey))
             }).Result;
             if (dec.Header.ResponseCode != "00")
             {
                 return null;
             }


             return dec.Decryption;
         }
         catch (Exception ex)
         {
             return null;
         }
     }
`;

export const getAccountDetailsFunction = `
SignatureResponse signatureResponse = vergeService.GetSignature(new SignatureRequest
{
    merchantId = "your_merchant_id",
    traceId = "unique_trace_id",
    key = "your_secret_key",
    shaType = SHA.SHA256
});

GetAccountRequest accountRequest = new GetAccountRequest
{
    requestHeader = new RequestHeader
    {
        merchantId = "your_merchant_id",
        terminalId = "your_terminal_id",
        timeStamp = signatureResponse.timeStamp,  // Use generated timestamp
        signature = signatureResponse.signature   // Use generated signature
    },
    traceId = "unique_trace_id"
};

GetAccountResponse accountResponse = await vergeService.GetAccountDetails(accountRequest);

if (accountResponse.responseCode == "00")
{
    Console.WriteLine("Account details retrieved successfully");
}
else
{
    Console.WriteLine($"Error: {accountResponse.responseMessage}");
}
`;

export const accountDetailsResponse = `
const response = {
"accountDetails": [
{
    "bankCode": "123",
    "accountNumber": "1234567890",
    "accountId": 22
},
{
    "bankCode": "456",
    "accountNumber": "1000002001",
    "accountId": 33
}
],
"traceId": "trace_id",
"timeStamp": 1726751419,
"signature": "signature",
"responseCode": "00",
"responseMessage": "Successful"
}
`;

export const sampleRequestForSignatureGeneration = `
public class SignatureRequest
{
    public string merchantId { get; set; }
    public string traceId { get; set; }
    public string key { get; set; }
    public SHA shaType { get; set; } // SHA256 or SHA512
}

`;

export const signatureGenerationFunction = `
SignatureRequest signatureRequest = new SignatureRequest
{
    merchantId = "your_merchant_id",
    traceId = "unique_trace_id",
    key = "your_secret_key",  // You get this from the Verge dashboard
    shaType = SHA.SHA256
};

SignatureResponse signatureResponse = vergeService.GetSignature(signatureRequest);

long timeStamp = signatureResponse.timeStamp;
string signature = signatureResponse.signature;

Console.WriteLine($"Generated Signature: {signature}");
Console.WriteLine($"Timestamp: {timeStamp}");
`;

export const sampleSignatureResponse = `
const response = {
"timeStamp": 1609459200,
"signature": "generated_signature"
}
`;

export const sampleErrorHandling = `
PaymentResponse paymentResponse = await vergeService.InvokePayment(paymentRequest);

if (paymentResponse.responseHeader.responseCode != "00")
{
    Console.WriteLine($"Error: {paymentResponse.responseHeader.responseMessage}");
}
else
{
    Console.WriteLine("Payment initiated successfully");
}

`;
