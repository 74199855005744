import React from "react"
import "../../App.css"
import Button from "../../components/Button";
// import Table from "../../components/Table";
import responseCode from "../../assets/icons/response Code.svg"
import DocumentationLink from "../../components/DocumentationLink";

const ResponseCode = () => {

    return(
        <div style={{background:'#F9F9FB',width:'100%'}}>
                <div className="flex-section">
                    <DocumentationLink></DocumentationLink>
                    <div>
                        <div className="doc-content response-code">
                             <h3>Response Code</h3>
                            <div className="doc-content-inner">
                                   <img src={responseCode} alt="responseCode"  className="doc-content-inner-img"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="doc-div-parent">
                    <div className="doc-div">
                        <h3>Generate Account Numbers</h3>
                        <p>This operation is for merchants to generate a Dynamic Account Number for their customers
                            to make payments. These payments can either be to fund wallets or pay for services.
                            Below is the Specification for the API to submit the customer’s details. </p>

                        <Button message="See Documentation" bgColor="#7F3D64" />
                    </div>
                    <div className="doc-div">
                        <h3>Transaction Payment Notifications</h3>
                        <p>This operation is for merchants to receive instant payment notification from CoralPay. This
                            enables the merchant to be aware of payments made to the created accounts. This service is
                            only applicable to the Dynamic Account Numbers. Merchants are to provide this endpoint
                            which is to be maintained on the CoralPay system. The merchant is to provide the Basic
                            Username and Password for Authentication.</p>
                           <p> Below is the Specification for the API to submit the customer’s details.  </p>

                        <Button message="See Documentation" bgColor="#7F3D64" />
                    </div>
                    <div className="doc-div">
                        <h3>Transaction Query</h3>
                        <p>
                            This operation is for merchants to query/check the status of a transaction. This enables the
                            merchant to be aware of payments made to the created accounts. This service is applicable
                            to both Dynamic and Static/Fixed Account Numbers.
                            Below is the Specification for the API to submit the customer’s details.  </p>

                        <Button message="See Documentation" bgColor="#7F3D64" />
                    </div>
                </div>
            </div>

    )

}

export default  ResponseCode