import React from "react";
import ProductLinks from "../../components/ProductLinks";
import Subscribe from "../../components/Subscribe";
import paymentGateway from "../../assets/paymentGateway.jpg"
import learnMore from "../../assets/learnmore.svg"
import "../../App.css"
import blank from "../../assets/secondgif.gif"
import coralpay_animation from "../../assets/CoralPayPaymentGateway.mp4"


const PaymentGatewayScreen = () => {
    return(
        <div>
            <div className="about-header" style={{backgroundImage:`url(${paymentGateway})`}}>
                <h3>CoralPay Payment  <span style={{color:'#F7EF8A'}}>Gateway</span></h3>
            </div>
            <div className="product">
                <div className="product-one">
                    <p>Our Payment Gateway aggregates all our payment solutions in one place,
                        enabling customers to have various payment options at merchant’s checkout point.</p>
                    <span> Our Payment Gateway offers: </span>
                    <ul style={{listStyle:'disc',marginTop:'5px',marginLeft:'10%',marginBottom:'3%'}}>
                        <li>C'Gate USSD</li>
                        <li>Cards</li>
                        <li>Pay with Bank Transfer</li>
                        <li> NQR</li>
                    </ul>
                    <a href="https://blog.coralpay.com/the-payment-options-that-will-keep-your-business-ahead/"><img style={{marginBottom:'10%'}} src={learnMore} alt='learn more btn'/></a>
                    <div>
                        <img src={blank} className="biller-one-option-p" style={{borderRadius:40}} alt="f"/>
                    </div>
                    <ProductLinks />
            </div>
                <div className="biller-one-option-desk" >
                    <video src={coralpay_animation} loop={'true'} autoplay={'true'} style={{borderRadius:40}}> </video>
                    {/*<img src={blank}  alt="f"/>*/}
                </div>

            </div>
            <Subscribe />
        </div>
    )
}

export default PaymentGatewayScreen