import { Box } from "@mui/material";
import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import {
  darcula,
  a11yDark,
} from "react-syntax-highlighter/dist/esm/styles/prism";
import {
  authenticateCode,
  authenticationRequestStructure,
  authenticationResponseStructure,
  bankLinkCode,
  batchPostRequestStructure,
  batchPostResponseStructure,
  createStaticAccountFunction,
  initializeVergClass,
  invokePaymentFunction,
  nameEnquiryRequestStructure,
  nameEnquiryResponseStructure,
  responseHeader,
  sampleRequestForSignatureGeneration,
  sampleRequestForTransactionQuery,
  sampleResponseForSignatureGeneration,
  sampleResponseForTransactionQuery,
  singlePostRequestStructure,
  singlePostResponseStructure,
  staticTransactionQuery,
  transactionQueryFunction,
} from "./syntax";
import { singlePost } from "./syntax";
import { batchPost } from "./syntax";
import { nameEnquiry } from "./syntax";
import { signatureGenerationFunction } from "./syntax";

const DotNetContent = () => {
  const customTextStyle = {
    fontFamily: "monospace",
    background: "#efefef",
    padding: "0.3rem",
    fontSize: "0.8em",
  };
  return (
    <>
      <h3>Overview</h3>
      <p>
        This document provides step-by-step guidance to easily integrate
        FastChannel using the C# library. The guide covers authentication,
        single post, batch post, transaction query, name enquiry and generating
        signatures using the GetSignature function.
      </p>
      <br />
      <h3>Prerequisites</h3>
      <ul>
        <li>1. .NET environment (e.g., .NET Core or .NET Framework).</li>
        <li>
          2. Access to FastChannel API credentials, including{" "}
          <span style={customTextStyle}>merchantId</span>,{" "}
          <span style={customTextStyle}>username</span>, and{" "}
          <span style={customTextStyle}>password</span>.
        </li>
        <li>3. Familiarity with HTTP requests and C#.</li>
      </ul>
      <div style={{ padding: "1rem 0 1rem 0" }}>
        <hr />
      </div>

      <div style={{ padding: "1rem 0 1rem 0" }}>
        <h3
          style={{
            fontWeight: "600",
            fontSize: "1.2em",
            color: "#7F3D64",
          }}
        >
          1. Setting Up FastChannelServices Class
        </h3>
      </div>
      <div style={{ padding: "1rem 0 1rem 0" }}>
        <h5
          style={{
            fontWeight: "600",
            // fontSize: "1em",
            color: "#7F3D64",
          }}
        >
          1.1 Installation
        </h5>
        <p style={{ padding: "1rem 0 1rem 0" }}>
          <span style={customTextStyle}>FastChannelServices</span> class
          provides the core functionality for interacting with FastChannel API.
        </p>

        <h5
          style={{
            fontWeight: "600",
            fontSize: "1em",
            color: "#7F3D64",
          }}
        >
          1.2 Initializing FastChannel Services Class
        </h5>
        <p style={{ padding: "1rem 0 1rem 0" }}>
          You can initialize the{" "}
          <span style={customTextStyle}>FastChannelServices</span> class by
          passing the base URL of the FastChannel API and an authentication
          token.
        </p>
        <SyntaxHighlighter language="javascript" style={a11yDark}>
          {initializeVergClass}
        </SyntaxHighlighter>

        <h5
          style={{
            fontWeight: "600",
            fontSize: "1em",
            color: "#7F3D64",
            padding: "1rem 0 1rem 0",
          }}
        >
          1.3 Response Header Class
        </h5>
        <p style={{ padding: "1rem 0 1rem 0" }}>
          This class captures the status of the action performed by various
          methods in this library. It holds the status and corresponding message
          of the operation called.
        </p>
        <SyntaxHighlighter language="javascript" style={a11yDark}>
          {responseHeader}
        </SyntaxHighlighter>
      </div>

      <h3
        style={{
          fontWeight: "600",
          fontSize: "1.2em",
          color: "#7F3D64",
        }}
      >
        2. Authentication
      </h3>
      <br />
      <h5
        style={{
          fontWeight: "600",
          // fontSize: "1em",
          color: "#7F3D64",
        }}
      >
        1.1 Authentication Request Structure
      </h5>
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {authenticationRequestStructure}
      </SyntaxHighlighter>
      <br />
      <h5
        style={{
          fontWeight: "600",
          // fontSize: "1em",
          color: "#7F3D64",
        }}
      >
        1.2 Authentication Response Structure
      </h5>
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {authenticationResponseStructure}
      </SyntaxHighlighter>
      <br />
      <h3
        style={{
          fontWeight: "600",
          fontSize: "1.2em",
          color: "#7F3D64",
        }}
      >
        3. Generating Signatures with{" "}
        <span style={customTextStyle}>GetSignature</span>
      </h3>
      <p style={{ padding: "1rem 0 1rem 0" }}>
        All API requests to FastChannel that require a{" "}
        <span style={customTextStyle}>signature</span> and{" "}
        <span style={customTextStyle}>timestamp</span>
        should use the <span style={customTextStyle}>GetSignature</span> method.
        This function ensures that your request is properly signed.
      </p>
      <h5
        style={{
          fontWeight: "600",
          // fontSize: "1em",
          color: "#7F3D64",
        }}
      >
        3.1 Signature Request Structure
      </h5>
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {sampleRequestForSignatureGeneration}
      </SyntaxHighlighter>
      <br />

      <h5
        style={{
          fontWeight: "600",
          // fontSize: "1em",
          color: "#7F3D64",
        }}
      >
        3.2 Signature Response Structure
      </h5>
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {sampleResponseForSignatureGeneration}
      </SyntaxHighlighter>
      <br />

      <h5
        style={{
          fontWeight: "600",
          // fontSize: "1em",
          color: "#7F3D64",
        }}
      >
        3.3 Example of Generating a Signature
      </h5>
      <p style={{ padding: "1rem 0 1rem 0" }}>
        Before making any request (like pushing transfer or transaction query),
        generate a signature and a timestamp.
      </p>
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {signatureGenerationFunction}
      </SyntaxHighlighter>
      <br />
      <h3
        style={{
          fontWeight: "600",
          fontSize: "1.2em",
          color: "#7F3D64",
        }}
      >
        4. Single Post
      </h3>
      <br />
      <p>
        Note: No callback will be sent for this operation. Please requery if the
        response code is not definite.
      </p>
      <br />
      <h5
        style={{
          fontWeight: "600",
          // fontSize: "1em",
          color: "#7F3D64",
        }}
      >
        4.1 SinglePost Request Structure
      </h5>
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {singlePostRequestStructure}
      </SyntaxHighlighter>
      <br />

      <h5
        style={{
          fontWeight: "600",
          // fontSize: "1em",
          color: "#7F3D64",
        }}
      >
        4.2 SinglePost Response Structure
      </h5>
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {singlePostResponseStructure}
      </SyntaxHighlighter>
      <br />
      <h5
        style={{
          fontWeight: "600",
          // fontSize: "1em",
          color: "#7F3D64",
        }}
      >
        4.3 Example of Making a Single Post Request
      </h5>
      <p style={{ padding: "1rem 0 1rem 0" }}>
        Use the signature and timestamp from the{" "}
        <span style={customTextStyle}>GetSignature</span> method to complete
        this request.
      </p>
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {singlePost}
      </SyntaxHighlighter>
      <br />
      <h3
        style={{
          fontWeight: "600",
          fontSize: "1.2em",
          color: "#7F3D64",
        }}
      >
        5. Batch Post
      </h3>
      <br />
      <h5
        style={{
          fontWeight: "600",
          // fontSize: "1em",
          color: "#7F3D64",
        }}
      >
        5.1 BatchPost Request Structure
      </h5>
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {batchPostRequestStructure}
      </SyntaxHighlighter>
      <br />
      <h5
        style={{
          fontWeight: "600",
          // fontSize: "1em",
          color: "#7F3D64",
        }}
      >
        5.2 BatchPost Response Structure
      </h5>
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {batchPostResponseStructure}
      </SyntaxHighlighter>
      <br />
      <h5
        style={{
          fontWeight: "600",
          // fontSize: "1em",
          color: "#7F3D64",
        }}
      >
        5.3 Example of Making a BatchPost Request
      </h5>
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {batchPost}
      </SyntaxHighlighter>
      <br />
      <h3
        style={{
          fontWeight: "600",
          fontSize: "1.2em",
          color: "#7F3D64",
        }}
      >
        6. Querying Transactions
      </h3>
      <br />
      <h5
        style={{
          fontWeight: "600",
          // fontSize: "1em",
          color: "#7F3D64",
        }}
      >
        6.1 TransactionQuery Request Structure
      </h5>
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {sampleRequestForTransactionQuery}
      </SyntaxHighlighter>
      <br />
      <h5
        style={{
          fontWeight: "600",
          // fontSize: "1em",
          color: "#7F3D64",
        }}
      >
        6.2 TransactionQuery Response Structure
      </h5>
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {sampleResponseForTransactionQuery}
      </SyntaxHighlighter>
      <br />
      <h5
        style={{
          fontWeight: "600",
          // fontSize: "1em",
          color: "#7F3D64",
        }}
      >
        6.3 Example of Making a Transaction Query
      </h5>
      <p style={{ padding: "1rem 0 1rem 0" }}>
        Use the <span style={customTextStyle}>GetSignature</span> method to
        generate the signature and timestamp for the request.
      </p>
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {transactionQueryFunction}
      </SyntaxHighlighter>
      <br />
      <h3
        style={{
          fontWeight: "600",
          fontSize: "1.2em",
          color: "#7F3D64",
        }}
      >
        7. Name Enquiry{" "}
      </h3>
      <br />
      <h5
        style={{
          fontWeight: "600",
          // fontSize: "1em",
          color: "#7F3D64",
        }}
      >
        7.1 Name Enquiry Request Structure
      </h5>
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {nameEnquiryRequestStructure}
      </SyntaxHighlighter>
      <br />
      <h5
        style={{
          fontWeight: "600",
          // fontSize: "1em",
          color: "#7F3D64",
        }}
      >
        7.2 Name Enquiry Response Structure
      </h5>
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {nameEnquiryResponseStructure}
      </SyntaxHighlighter>
      <br />
      <h5
        style={{
          fontWeight: "600",
          // fontSize: "1em",
          color: "#7F3D64",
        }}
      >
        7.3 Example of Making a Name Enquiry Request
      </h5>
      <p style={{ padding: "1rem 0 1rem 0" }}>
        {" "}
        Use the <span style={customTextStyle}>GetSignature</span> method to
        generate the signature and timestamp for the request.
      </p>
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {nameEnquiry}
      </SyntaxHighlighter>
      <br />
    </>
  );
};

export default DotNetContent;
