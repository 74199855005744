import React from "react"
import Subscribe from "../../components/Subscribe";
import digipos from "../../assets/digipos.svg"
import receipt from "../../assets/receipt.svg"
import "../../App.css"
import learnMore from "../../assets/learnmore.svg"
import {Link} from "react-router-dom";

const DigiPos = () => {
    return(
        <div>
            <div className="blog-header" style={{backgroundImage:`url(${digipos})`}}>
                <div className="blog-wrapper-overlay">
                    <h3>Digi<span style={{color:'#F7EF8A',width:'629px'}}>POS</span></h3>
                </div>
            </div>
            <br /><br /><br /><br />
            <div className="product">
                <div className="product-one">
                    <div className="accordion" id="accordionExample">
                        <Link to="/coralpay-payment-gateway" >
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingOne">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Coralpay Payment Gateway
                                    </button>
                                </h2>
                            </div>
                        </Link>

                        <Link to="/cgateussd" >
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingOne">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        C'Gate USSD
                                    </button>
                                </h2>
                            </div>
                        </Link>
                        <Link to="/transaction-switching-and-processing">
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingTwo">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo(null)" aria-expanded="false" aria-controls="collapseTwo">
                                        Transaction Switching & Processing
                                    </button>
                                </h2>
                            </div>
                        </Link>

                        <Link to="/payment-with-transfer">
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree(null)" aria-expanded="false"
                                            aria-controls="collapseThree">
                                        Pay With Bank Transfer
                                    </button>
                                </h2>
                            </div></Link>
                        <Link to="/nqr" >
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree(null)" aria-expanded="false"
                                            aria-controls="collapseThree">
                                        NQR
                                    </button>
                                </h2>
                            </div></Link>
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingFour">
                                    <button className="accordion-button dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseFour" aria-expanded="false"
                                            aria-controls="collapseFour">
                                        DigiPOS
                                    </button>
                                </h2>
                                <div id="collapseFour" className="accordion-collapse show" aria-labelledby="headingFour"
                                     data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <div className="biller-one-option-mobile">
                                            <div className="biller-one-app-option" style={{backgroundImage:`url(${receipt})`}}></div>
                                        </div>
                                        <p>
                                            DigiPOS is a software-based solution which transforms a regular smartphone or other Commercial Off-The-Shelf (COTS) devices,
                                            into a contactless payment terminal. With DigiPOS, merchants can accept digital payments in a cost-effective and simple way,
                                            driving digitalization, financial inclusion and boosting their offering for consumers.
                                            Merchants can save cost by using any compatible mobile device to simply download CoralPay’s DigiPOS app and start accepting payments.
                                        </p>
                                        <br />
                                        <br />
                                        <a href="https://blog.coralpay.com/the-payment-options-that-will-keep-your-business-ahead/"><img src={learnMore} alt='learn more btn'/></a>
                                    </div>
                                </div>
                            </div>
                        <Link to="/billers-aggregation-platform">
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header dropdown-btn" id="headingThree">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree">
                                        Billers Aggregation Platform
                                    </button>
                                </h2>
                            </div>
                        </Link>
                        <Link to="/finpay">
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed  dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree">
                                        FinPay
                                    </button>
                                </h2>
                            </div>
                        </Link>
                        <Link to="/payment-link">
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header dropdown-btn" id="headingThree">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree">
                                        Payment Link
                                    </button>
                                </h2>
                            </div>
                        </Link>
                        <Link to="/instant-payment">
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header dropdown-btn " id="headingThree">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree">
                                        Coralpay Instant Payment
                                    </button>
                                </h2>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="biller-one">
                    <div className="biller-one-app" style={{backgroundImage:`url(${receipt})`}}></div>
                </div>
            </div>
            <Subscribe />
        </div>
    )
}


export default DigiPos