import React from "react"
import Subscribe from "../../components/Subscribe";
import "../../App.css"
import photo2 from "../../assets/photo2.png"
import manholdinglaptop from "../../assets/manholdinglaptop.png"
import learnMore from "../../assets/learnmore.svg";
import {Link} from "react-router-dom";
import Route from "../../ReRoute";


const PaymentLinkScreen = () => {
    return(
        <div>
            <div className="blog-header" style={{backgroundImage:`url(${photo2})`}}>
                <div className="blog-wrapper-overlay">
                    <h3>Payment  <span style={{color:'#F7EF8A',width:'629px'}}>Link</span></h3>
                </div>
            </div>
            <br /><br /><br /><br />
            <div className="product">
                <div className="product-one">
                    <div className="accordion" id="accordionExample">
                        <Link to="/coralpay-payment-gateway" >
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingOne">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Coralpay Payment Gateway
                                    </button>
                                </h2>
                            </div>
                        </Link>
                        <Link to="/cgateussd" >
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingOne">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        C'Gate USSD
                                    </button>
                                </h2>
                            </div>
                        </Link>
                        <Link to="/transaction-switching-and-processing">
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingTwo">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo(null)" aria-expanded="false" aria-controls="collapseTwo">
                                        Transaction Switching & Processing
                                    </button>
                                </h2>
                            </div>
                        </Link>

                        <Link to="/payment-with-transfer">
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree(null)" aria-expanded="false"
                                            aria-controls="collapseThree">
                                        Pay With Bank Transfer
                                    </button>
                                </h2>
                            </div></Link>
                        <Link to="/nqr" >
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree(null)" aria-expanded="false"
                                            aria-controls="collapseThree">
                                        NQR
                                    </button>
                                </h2>
                            </div></Link>
                        <Link to="/digipos">
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingFour">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseFour" aria-expanded="false"
                                            aria-controls="collapseFour">
                                        DigiPOS
                                    </button>
                                </h2>
                            </div>
                        </Link>
                        <Link to="/billers-aggregation-platform">
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree">
                                        Billers Aggregation Platform
                                    </button>
                                </h2>
                            </div>
                        </Link>
                        <Link to="/finpay">
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingSix">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseSix" aria-expanded="false"
                                            aria-controls="collapseSix">
                                        FinPay
                                    </button>
                                </h2>
                            </div>
                        </Link>
                        <Link to="/payment-link">
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingSeven">
                                    <button className="accordion-button dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseSeven" aria-expanded="false"
                                            aria-controls="collapseSeven">
                                        Payment Link
                                    </button>
                                </h2>
                                <div id="collapseSeven" className="accordion-collapse show" aria-labelledby="headingSeven"
                                     data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <div className="biller-one-option-mobile">
                                            <div className="biller-one-app-option" style={{backgroundImage:`url(${manholdinglaptop})`}}></div>
                                        </div>
                                        <p>
                                            With Payment Link, CoralPay empowers social media merchants and other online merchants without a website, to take advantage of CoralPay’s Payment Gateway by generating a payment link which is made available to the customer, to enable them make payment to the merchant.
                                            Merchants do not need to worry about forged credit alerts, while offering the customer a variety of payment channels to ease payments.
                                        </p>
                                        <br />
                                        <span  onClick={() => Route("https://blog.coralpay.com/the-payment-options-that-will-keep-your-business-ahead/")}><img src={learnMore} alt='learn more btn'/></span>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <Link to="/instant-payment">
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree">
                                        Coralpay Instant Payment
                                    </button>
                                </h2>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="biller-one">
                    <div className="biller-one-app" style={{backgroundImage:`url(${manholdinglaptop})`}}></div>
                </div>
            </div>
            <Subscribe />
        </div>
    )
}


export default PaymentLinkScreen