import React from "react"
import billers from "../assets/faqs.jpeg";
import faq from "../assets/faq-1-1.png"
import Accordion from "../../src/components/Accordion";

const FAQs = () => {

    const accordionData = [
        {
            title: 'How does CoralPay Payment Gateway work?',
            content: `CoralPay Payment Gateway enables your business to accept online payments by sending encrypted transaction data from your website to our payment gateway. These data are decrypted, processed, and sent directly to your acquiring bank. The payment response from your bank,
             which could be either “approved” or “declined”, is sent back to your website through the payment gateway`
        },
        {
            title: 'Who is CoralPay suitable for?',
            content: `CoralPay is well suited to businesses of any type: financial institutions—commercial banks, micro-finance banks, and other financial Institutions (OFIs)—and any institution involved in payments for goods and services online, 
            offline, or on mobile`
        },
        {
            title: 'Who is a merchant?',
            content: `A merchant is an organisation accepting payment card or other alternate payment methods 
            for the goods or services provided to customers.`
        },
        {
            title: 'What is a Merchant Account?',
            content: `A Merchant Account allows a business to accept payments from transactions made by customers. Merchant Accounts are set up between a retail business and a financial institution. However, 
            you will need to contact your bank for more information on the requirements for a Merchant Account..`
        },
        {
            title: 'Do I require a Merchant Account to use CoralPay?',
            content: `Yes. Funds received from sales will be settled into your Merchant Account.`
        },
        {
            title: 'What payment methods does CoralPay support?',
            content: `CoralPay supports card payment using both local or international Visa Card and MasterCard. CoralPay supports the following payment methods: \n` +
                    `1. Card payments: using local and international Visa Card and MasterCard \n` +
                    `2.  USSD payment method,  QR code etc.`
        },
        {
            title: 'Do I require a Merchant Account to use CoralPay?',
            content: `Yes. Funds received from sales will be settled into your Merchant Account.`
        }
    ]

    return(
        <div>
            <div className="blog-header" style={{backgroundImage:`url(${billers})`,backgroundSize:'cover'}}>
                <div className="blog-wrapper-overlay">
                    <h3>FAQ's</h3>
                </div>
            </div>
            <div style={styles.faqWrapper}>
                <div style={styles.section1}>
                    <h3 style={styles.faqH1}>Frequently asked questions</h3>
                    <p style={styles.faqP}>Have questions? We have answers.</p>
                    <img src={faq} style={styles.elementorImg}/>
                </div>
                <div style={styles.section2}>
                    <div className="accordion">
                        {accordionData.map(({ title, content }) => (
                            <Accordion title={title} content={content} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

const styles = ({
        faqWrapper:{
            width:'100%',
            display:'flex',
            padding:'3%',
            flexDirection:window.screen.width > 1000 ? 'row' : 'column',
        },
        faqH1:{
            color: '#6E374C',
            fontSize: window.screen.width > 999 ? '38px' : '20px',
            fontWeight: '800',
        },

        section1:{
            display: 'flex',
            width:window.screen.width > 999 ? '50%' : '100%',
            flexDirection:'column',
            alignItems:'center'
        },
        section2:{
            display: 'flex',
            width: window.screen.width > 999 ? '50%' : '100%',
            flexDirection:'column',
            alignItems:'center'
        },

        faqP:{
            lineHeight: 1.7,
            marginBottom: '15px',
            fontWeight: "300",
            fontSize: '16px'
        },
        elementorImg: {
            height: 'auto',
            maxWidth: window.screen.width > 999 ? '700px' : '400px',
            borderRadius: 0,
            display:'flex'

        }
})

export default  FAQs
