import React from "react";
import "../App.css"
import email from "../assets/icons/email.svg"
// import halfton2 from "../assets/Halftone2.svg"

const Subscribe = () => {
    return(
        <div className="subscribe">
            {/*<img src={halfton2}  className="halfton2"/>*/}
            <div className="subscribe-inner">
                <div style={{marginBottom:60}}>
                    <h3>Let’s Keep You Updated</h3>
                    <p>Enter your email to get the lastest news and <br />
                        knowledge you need to build a profitable business</p>
                </div>

                 <div>
                 <form className="form" method="post" action="https://blog.coralpay.com/?na=s">
                    <div style={{display:'flex'}}>
                        <img src={email} alt='email' style={{transform:'translate(180%, -10%)'}}/>
                        <input type="email"  className="subscribe-input"  style={{paddingLeft:'60px'}}  required name="ne" id="tnp-1"  />
                    </div>
                    <input type="hidden" name="nlang" value=""></input>
                    <div className="b">
                        <button className="btn">Subscribe</button>
                    </div>
                </form>
            </div>
            </div>
        </div>
    )
}

export default Subscribe