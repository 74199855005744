import React from "react"
import guy from "../assets/guy.svg";

const AcceptableUsePolicy = () => {
    return (
        <div>
            <div className="blog-header" style={{backgroundImage:`url(${guy})`}}>
                <div className="blog-wrapper-overlay blog">
                    <h3>Acceptable Use Policy</h3>
                </div>
            </div>
            <div className="isms-policy">
                <div>
                    <h4 style={{textAlign:'center'}}><b>PLEASE READ THE TERMS OF THIS POLICY CAREFULLY BEFORE USING THE SITE</b></h4>
                    <h3>What&#39;s in these terms?</h3>
                    <p>This acceptable use policy sets out the content standards that apply when you upload content to our site,
                        make contact with other users on our site, link to our site, or interact with our site in any other way.</p>
                    <p>[DOMAIN ADDRESS] is a site (the “site”) operated by CoralPay Technology Nigeria Limited (&quot;We&quot;, “us”
                        “our”). We are registered in the Federal Republic of Nigeria, with RC No. 634364, duly licensed by the
                        Central Bank of Nigeria and have our principal office at Plot 152A, Prince Ade Odedina Street, Victoria
                        Island, Lagos State, Nigeria.</p>

                    <p>To contact us, please email customersupport@coralpay.com or telephone our customer service line on
                        +234 913 9380 354</p>

                    <h3>By using our site you accept these terms</h3>
                    <p>By accessing or using our site, you confirm that you accept the terms of this policy and that you agree to
                        comply with them.</p>

                    <p>If you do not agree to these terms, you must not access or use our site.</p>

                    <p>We recommend that you print a copy of these terms for future reference.</p>

                    <h3>There are other terms that may apply to you</h3>

                    <p>Our Terms and conditions [INSERT AS LINK] also apply to your use of our site.</p>

                    <h3>We may make changes to the terms of this policy</h3>

                    <p>We amend these terms from time to time. Every time you wish to use our site, please check these terms to
                        ensure you understand the terms that apply at that time.</p>

                    <h3>Prohibited uses</h3>
                    <p>You shall not use our site:</p>
                    <ul>
                        <li>In any way that breaches any applicable local, national or international law or regulation.</li>
                        <li>In any way that is unlawful or fraudulent or has any unlawful or fraudulent purpose or effect.</li>
                        <li>For the purpose of harming or attempting to harm minors in any way.</li>
                        <li>To bully, insult, intimidate or humiliate any person.</li>
                        <li>To send, knowingly receive, upload, download, use or re-use any material which does not comply with our
                            content standards.</li>
                        <li>To transmit, or procure the sending of, any unsolicited or unauthorised material or any other form of similar
                            solicitation (spam).</li>
                        <li>To knowingly transmit any data, send or upload any material that contains viruses, trojan horses, worms,
                            time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer code
                            designed to adversely affect the operation of any computer software or hardware.</li>
                        <li>Facilitate or perform any illicit, illegal or fraudulent transaction.</li>
                        <li>To upload or transmit any terrorist content.</li>
                    </ul>


                    <p>You also agree:</p>
                    <ul>
                        <li>Not to reproduce, duplicate, copy or re-sell any part of our site in contravention of the provisions of this
                            policy or our terms and conditions.</li>

                        <li>Not to access without authority, interfere with, damage or disrupt:</li>
                        <dd>- any part of our site;</dd>
                        <dd>- any equipment or network on which our site is stored;</dd>
                        <dd>- any software used in the provision of our site; or</dd>
                        <dd>- any equipment or network or software owned or used by any third party.</dd>
                    </ul>

                    <h3>Interactive services</h3>

                    <p>We may from time to time provide interactive services on our site (interactive services.)</p>

                    <p>Where we do provide any interactive service, we will provide clear information to you about the kind of
                        service offered, if it is moderated and what form of moderation is used (including whether it is human or
                        technical).</p>

                    <p>We will do our best to assess any possible risks for users from third parties when they use any interactive
                        service provided on our site, and we will decide in each case whether it is appropriate to use moderation of
                        the relevant service (including what kind of moderation to use) in the light of those risks. However, we are
                        under no obligation to oversee, monitor or moderate any interactive service we provide on our site, and we
                        expressly exclude our liability for any loss or damage arising from the use of any interactive service by a
                        user in contravention of our content standards, whether the service is moderated or not.</p>

                    <p>The use of any of our interactive services by a minor is subject to the consent of their parent or guardian.
                        We advise parents who permit their children to use an interactive service that it is important that they
                        communicate with their children about their safety online, as moderation is not fool proof. Minors who are
                        using any interactive service should be made aware of the potential risks to them.</p>

                    <p>Where we do moderate an interactive service, we will normally provide you with a means of contacting the
                        moderator, should a concern or difficulty arise.</p>

                    <h3>Content standards</h3>

                    <p>These content standards apply to any and all material which you contribute, upload or type-in to our site
                        (Contribution), and to any interactive services associated with it.</p>

                    <p>The Content Standards must be complied with in spirit as well as to the letter. The standards apply to each
                        part of any Contribution as well as to its whole.</p>

                    <p>We will determine, in its discretion, whether a Contribution breaches the Content Standards.</p>

                    <p>A Contribution must:</p>

                    <ul>
                        <li>Be accurate.</li>
                        <li>Be complete and up-to-date;</li>
                        <li>Be genuinely held.;</li>
                        <li>Comply with applicable laws and regulations.</li>
                    </ul>

                    <p>A Contribution must not:</p>

                    <ul>
                        <li>Infringe any intellectual property rights, including copyright, database right or trade mark of any other
                            person.</li>
                        <li>Advocate, promote, incite any party to commit, or assist any unlawful or criminal act such as (by way of
                            example only) fraud, money laundering, copyright infringement or computer misuse.</li>
                        <li>Be likely to deceive.</li>
                        <li>Breach any legal duty owed to a third party, such as a contractual duty or a duty of confidence.</li>
                        <li>  Promote any illegal or illicit content or activity.</li>
                        <li> Be in contempt of court.</li>
                        <li>  is harmful to a child;</li>
                        <li>  could cause any physical or psychological harm to another user directly or indirectly;</li>
                        <li> is defamatory, libellous, pornographic, revenge porn, bullying, harassing,</li>
                        <li>  obscene, encouraging money laundering, exploiting a child, fraud, violence,</li>
                        <li>  or inconsistent with Nigeria&#39;s laws and public order;</li>
                        <li> Be threatening, abuse or invade another&#39;s privacy, or cause annoyance, inconvenience or needless anxiety.</li>
                        <li>  Be likely to harass, upset, embarrass, alarm or annoy a third party.</li>
                        <li>  Impersonate any person or misrepresent your identity or affiliation with any person.</li>
                        <li> Give the impression that the Contribution emanates from us or authorised by us, except independently and validly authorised by us in express writing.</li>
                        <li>  Be defamatory of any person.</li>
                        <li>  Be obscene, offensive, hateful or inflammatory.</li>
                        <li>  Bully, insult, intimidate or humiliate.</li>
                        <li>  Promote or facilitate violence, sexually explicit or child sexual abuse.</li>
                        <li>  Promote discrimination.</li>
                        <li>  Contain a statement which you know or believe, or have reasonable grounds for believing, that if published,
                        are likely to understand as a direct or indirect encouragement or other inducement to the commission,
                        preparation or instigation of acts of terrorism.</li>
                        <li> You are not the lawful owner and to which no authorisation was secured from the lawful owner.</li>
                        <li> is false or misleading.</li>
                        <li> compromises the security or undermines the unity, integrity or sovereignty of Nigeria or promotes act of
                        terrorism.</li>
                        <li>  instigates public disorder or interfere with an ongoing investigation.
                        For the avoidance of doubt, for any Contribution</li>
                        <li> You must tell us immediately of any content containing any of the following: criminal material (relating to
                        terrorism, money laundering, and or other material that might impair the physical, mental or moral
                        development of persons under the age of 18 (restricted material) or breach any applicable law or regulatory
                        framework.</li>
                        <li> You must not upload a content containing harmful material.</li>
                    </ul>

                    <p>Any material you upload must not:</p>
                    <ul>
                        <li> prejudice respect for human dignity;</li>
                        <li> include or promote discrimination;</li>
                        <li> encourage activities prejudicial to law, health or safety;</li>
                        <li> encourage activities grossly prejudicial to our interest or the protection of the environment;</li>
                        <li> cause physical, mental or moral detriment to persons under the age of 18;</li>
                    </ul>
                    <h3>Indemnity</h3>
                    <p>You hereby agree to fully indemnify and hold us harmless from and against any and all claims brought by a
                    third party resulting from the use of or access to our site and or our services or the provision of content to us
                    by you and in respect of all costs, actions, losses, claims, proceedings, damages, expenses (including
                    reasonable legal costs and expenses), or liabilities whatsoever suffered or incurred directly or indirectly by
                    us in consequence of such use of or access to this site and the services or provision of content or your
                        breach or non-observance of any of these Terms and conditions.</p>

                   <h3> Breach of this policy</h3>
                    <p>When we consider that a breach of this policy and our other policies has occurred, we may take such action
                        as we deem appropriate.</p>

                    <p>Failure to comply with this policy constitutes a material breach, upon which you are permitted to use,
                        navigate, access or visit our site, and may result in our taking all or any of the following actions:</p>
                    <ul>
                    <li> Immediate, temporary or permanent withdrawal of your right to navigate visit, use or access our site.</li>
                        <li> Immediate, temporary or permanent removal of any Contribution uploaded by you (directly or indirectly) to
                    our site.</li>
                            <li>  Issue of a warning to you.</li>
                                <li> Legal proceedings against you for reimbursement of all costs, liabilities, damages, and or claims on an
                    indemnity basis (including, but not limited to, reasonable administrative and legal costs) resulting from the
                    breach.</li>
                                    <li> Further legal action against you.</li>
                                        <li> Disclosure of such information to law enforcement authorities as we reasonably feel is necessary or as
                    required by law.</li>
                    </ul>
                   <p>x We exclude our liability for all action we may take in response to breaches of this acceptable use policy. The
                    actions we may take are not limited to those described above, and we may take any other action we
                    reasonably deem appropriate.</p>

                    <h3>Transfer of this Contract</h3>
                    <p>We can transfer our rights and obligations under these terms to any third party.</p>

                   <h3> Which country&#39;s laws apply to this Policy?</h3>
                   <p> Please note that the terms of this policy, its subject matter and its formation (and any non-contractual
                    disputes or claims) are governed by the laws of the Federal Republic of Nigeria. You and we both agree to
                    use all reasonable endeavors to settle any dispute or difference of opinion between them, arising from or in
                    connection with this policy amicably through mutual discussion. However, if such cannot be amicably
                    resolved between the Parties within ninety (90) business days after the occurrence of such dispute, we and
                    or you may refer such dispute to mediation upon giving the other party 15 (fifteen) days written notice. The
                    dispute will be submitted to and decided by Mediation at the Lagos Multidoor Courthouse.</p>
                </div>
            </div>
        </div>
    )
}

export default AcceptableUsePolicy