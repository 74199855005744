import React, { useEffect, useRef, useState } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import {
  darcula,
  a11yDark,
} from "react-syntax-highlighter/dist/esm/styles/prism";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Grid2,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import DotNetContent from "./dotnet/DotNetContent";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import { scrollToTop } from "../../../components/helper";

const PaywithBankTransferDocumentation = () => {
  const [javascript, setJavascript] = useState(true);
  const [dotnet, setDotnet] = useState(true);
  const [ceeSharp, setCeeSharp] = useState(false);
  const navigate = useNavigate();

  const apiSpec = useRef(null);
  const snippets = useRef(null);

  const scrollToSection = (ref) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleJavascriptContent = () => {
    setJavascript((prev) => !prev);
    setDotnet(false);
    setCeeSharp(false);
  };
  const handleDotnetContent = () => {
    setJavascript(false);
    setDotnet((prev) => !prev);
    setCeeSharp(false);
  };
  const handleCeeSharpContent = () => {
    setJavascript(false);
    setDotnet(false);
    setCeeSharp((prev) => !prev);
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  const authRequest = `
{
  "Username": "Skaikru",
  "Password": "#1ooGr@0ndar$",
  "TerminalId": "TT100025"
}

  `;
  const authResponse = `
{
  "ResponseHeader": {
    "ResponseCode": "00",
    "ResponseMessage": "Successful"
  },
  "Key": "99df5451-6f36-46ac-9ebc-f23ad55f7c0d",
  "Token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdG",
  "ExpiryDate": "2024-03-10T10:15:06.8791956+01:00"
}

  `;

  const getbanksResponsePayload = `
{
 "ResponseHeader": {
 "ResponseCode": "00",
 "ResponseMessage": "Successful"
 },
 "Banks": [
 {
 "BankName": "Test Bank",
 "BankCode": "100101",
 "BankLogo": " https://testbank.ng/logo"
 }
 ]

  
  `;

  const createStaticAccountRequestPayload = `
{
  "RequestHeader": {
    "MerchantId": "TT10002500001",
    "TerminalId": "TT100025",
    "TraceId": "20240326122115458"
  },
  "BankCode": "100101",
  "Description": "User Account Creation",
  "AccountName": "Michael Sola"
}
  `;

  const createStaticAccountResponsePayload = `
{
  "ResponseHeader": {
    "ResponseCode": "00",
    "ResponseMessage": "Successful",
    "traceId": "20240326122115458"
  },
  "DestinationBankCode": "100101",
  "DestinationBankName": "Test Bank",
  "Description": " User Account Creation",
  "DestinationAccountName": "CPY-Michael Sola",
  "DestinationAccountNumber": "1011110012",
  "TransactionId": "2024326145554554478",
  "SessionId": "100101202403265454787456554775",
  "DestinationBankLogo": "https://testbank.ng/logo"
}

  `;

  const invokePaymentRequestPayload = `
  {
  "RequestHeader": {
    "MerchantId": "TT10002500001",
    "TerminalId": "TT100025",
    "TraceId": "20240326122115458"
  },
  "Amount": 5000.0,
  "BankCode": "100101",
  "Description": "User Account Creation",
  "AccountName": "Michael Sola"
}
  `;

  const invokePaymentResponsePayload = `
{
 "ResponseHeader": {
 "ResponseCode": "00",
 "ResponseMessage": "Successful",
 "traceId": "4b908a9d-aa0f-4ff6-bd5d-eb724e6a72fe",
 },
 "DestinationBankCode": "100101",
 "DestinationBankName": "Test Bank",
 "Description": "Wallet Account Funding",
 "DestinationAccountName": "CPY-Michael Sola",
 "DestinationAccountNumber": "1011110012",
 "TransactionId": "2024326145554554478",
 "SessionId": "100101202403265454787456554775",
 "DestinationBankLogo": "https://testbank.ng/logo",
 "ExpiryTime": "2024-05-02T13:50:00"
}
  `;

  const generateDynamicAccountRequestPayload = `
  {
    "RequestHeader": {
      "clientId": "a@a.com",
      "requestType": "Bank Transfer"
    },
    "customerName": "Shehu Chidi & Sons",
    "referenceNumber": "xsd4pe0t008952",
    "transactionAmount": "6754"
  }
  `;
  const generateDynamicAccountRsponsePayload = `
 {
      "responseDetails": {
        "clientId": "a@a.com",
        "referenceNumber": "xsd4pe0t008952",
        "responseCode": "00",
        "responseMessage": "OPERATION SUCCESSFUL"
      },
      "accountName": "MERCHANT(Shehu Chidi & Sons)",
      "accountNumber": "9977573777",
      "operationReference": "merchant2201250331078455",
      "transactionAmount": "6754"
}
  `;

  const transactionNotificationRequestPayload = `
{
    "responseDetails": {
    "clientId": "a@a.com",
    "referenceNumber": "xsd4pe0t008952",
    "responseCode": "00",
    "responseMessage": "OPERATION SUCCESSFUL"
    },
    "accountName": "MERCHANT(Shehu Chidi & Sons)",
    "accountNumber": "9977573777",
    "operationReference": "merchant2201250331078455",
    "transactionAmount": "6754"
}
  `;

  const transactionQueryRequestPayload = `
{
 "RequestHeader": {
 "MerchantId": "Kelvin1",
 "TerminalId": "kelvin123",
 "TraceId": "20240502095332087865"
 }
}

  
  `;

  const transactionQueryResponsePayload = `
{
 "ResponseHeader": {
 "ResponseCode": "00",
 "ResponseMessage": "Successful"
 },
 "TransactionResponseCode": "00",
 "TransactionResponseMessage": "Success",
 "SourceBankCode": "100004",
 "SourceAccountName": "ADE JIMMY SUNDAY",
 "SourceBankName": "Test Source Bank",
 "SourceBankAccountNumber": "8074649190",
 "Amount": 5000.00,
 "DestinationBankCode": "000058",
 "DestinationBankName": "Test Bank",
 "DestinationAccountName": "Test Account Name",
 "DestinationAccountNumber": "4560983413",
 "TraceId": "20240502095332087865",
 "TransactionId": "2ff63963-b7fb-4651-bdea-740dea71551d",
 "SessionId": "840e666a-9108-46a1-bb2c-9b5d373e8148",
 "Terminal": "kelvin123",
 "Description": "Payment",
 "PaidAmount": 5000.00,
 "PaymentDate": "2024-05-02T09:53:32.1692279"
}
  `;

  const staticTransactionQueryRequestPayload = `
{
  "RequestHeader": {
    "MerchantId": "kelvin1",
    "TerminalId": "kelvin123"
  },
  "AccountNumber": "4560951068",
  "StartDate": "2024-05-01",
  "EndDate": "2024-05-01"
}
  
  `;
  const staticTransactionQueryResponsePayload = `
{
  "responseHeader": {
    "responseCode": "00",
    "responseMessage": "Success"
  },
  "transactions": [
    {
      "TransactionResponseCode": "00",
      "TransactionResponseMessage": "Success",
      "SourceBankCode": "100004",
      "SourceAccountName": "ADE JIMMY SUNDAY",
      "SourceBankName": "Test Source Bank",
      "SourceBankAccountNumber": "8074649190",
      "Amount": 5000.0,
      "DestinationBankCode": "000058",
      "DestinationBankName": "Test Bank",
      "DestinationAccountName": "Test Account Name",
      "DestinationAccountNumber": "4560983413",
      "TraceId": "20240502095332087865",
      "TransactionId": "2ff63963-b7fb-4651-bdea-740dea71551d",
      "SessionId": "840e666a-9108-46a1-bb2c-9b5d373e8148",
      "Terminal": "kelvin123",
      "Description": "Payment",
      "PaymentAmount": 5000.0,
      "PaymentDate": "2024-05-02T09:53:32.1692279"
    }
  ]
}

  
  `;

  const callBackResponsePayload = `
  {
  "TransactionResponseCode": "00",
  "TransactionResponseMessage": "Successful",

  "SourceBankCode": "string",
  "SourceAccountName": "Michael Sola",
  "SourceBankName": " Rave Bank",
  "SourceBankAccountNumber": "1001245778",
  "Amount": 600.0,
  "DestinationBankCode": "100101",
  "DestinationBankName": "Test Bank",
  "DestinationAccountName": " CPY-Michael Sola ",
  "DestinationAccountNumber": "string",
  "TraceId": "20240326122115458",
  "TransactionId": "2024326145554554478",
  "SessionId": "100101202403265454787456554775",
  "Terminal": " TT100025",
  "Description": " Wallet Account Funding",
  "MerchantId": "TT10002500001"
}

  `;
  return (
    <div>
      <div className="developer-wrapper">
        <div className="developer-wrapper-overlay">
          <div className="developer-wrapper-overlay-inner">
            <h3>BANK LINK</h3>
            <p className="mt-2 small">
              This service facilitates online payments via bank fund transfers.
              Customers are required to choose from a list of available banks.
              They must then transfer the exact amount of transaction displayed
              to the generated bank account number. This API enables the
              seamless integration into the Bank Link virtual account services.
            </p>
            <br />
            <br />
            {/*<div style={{display:'flex'}} >*/}
            {/*    <Button message="API Dashboard" bgColor="#7B3F63" bgText="#FFFFFF" />*/}
            {/*    <Button  message="Download Document" bgColor="#EFEFEF" bgText="#3A3A3A"/>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
      <div className="doc-div-parent-doc">
        <div className="docNav">
          <Box sx={{ padding: "2rem 0 2rem 0" }}>
            <Button
              sx={{
                backgroundColor: "#083857",
                fontWeight: "550",
                color: "#fff",
              }}
              onClick={() => navigate("/developer#services")}
            >
              BACK TO DEVELOPER'S HOMEPAGE
            </Button>
          </Box>
          <nav>
            <List
              sx={{
                border: "1px solid #083857",
                borderRadius: "5px",
              }}
            >
              <ListItem disablePadding>
                {/* <a href="#apiSpec"> */}
                  <ListItemButton onClick={() => scrollToSection(apiSpec)}>
                    <ListItemIcon>{/* <InboxIcon /> */}</ListItemIcon>
                    <ListItemText primary="API Specifications" />
                  </ListItemButton>
                {/* </a> */}
              </ListItem>
              <ListItem disablePadding>
                {/* <a href="#snippets"> */}
                  <ListItemButton onClick={() => scrollToSection(snippets)}>
                    <ListItemIcon>{/* <DraftsIcon /> */}</ListItemIcon>
                    <ListItemText primary="Libraries & Code Snippets" />
                  </ListItemButton>
                {/* </a> */}
              </ListItem>
            </List>
          </nav>
          {/* <h3>Documentation</h3> */}
          {/* <ul>
            <a href={"/developer/docs/cgateussd"}>
              {" "}
              <li>C’Gate USSD</li>
            </a>
            <a href={"/developer/docs/pay-with-bank-transfer"}>
              {" "}
              <li>Pay With Bank Transfer</li>
            </a>
            <a href={"/developer/docs/payment-gateway"}>
              {" "}
              <li>CoralPay Payment Gateway</li>
            </a>
            <a href={"/developer/docs/vas"}>
              {" "}
              <li>CoralPay VAS</li>
            </a>
            <a href={"/developer/docs/card-api"}>
              {" "}
              <li>CoralPay Card APIs</li>
            </a>
          </ul> */}
        </div>
        <div className="doc-div-doc" ref={apiSpec}>
          <h3>Introduction</h3>

          <p>
            This document serves as a technical and integration guide for the
            BankLink service. Its purpose is to offer guidance and serve as a
            template to facilitate smooth integration processes.
          </p>
          <p>
            This service employs both static and dynamically generated account
            numbers to facilitate payments.
          </p>
          <p>
            For dynamic accounts, invoking payment with the transaction amount
            triggers instant payment. Customers are required to transfer the
            exact amount to the generated account to complete the payment.
          </p>
          <p>
            Static account numbers are generated for specific purposes and
            ongoing use. When a transfer is made to a static account, a payment
            notification is sent to the merchant to fulfill the payment's
            intended purpose.
          </p>

          <h3>API Specifications</h3>
          <p>
            The Bank Link API is a RESTful service which uses the JWT
            authentication method to authenticate users calling the API.
            Credentials to call the API would be shared also.
          </p>
          <br />
          <h3>Authentication</h3>
          <p>
            The Authentication operation is to be called using the credentials
            provided during registration. The Response from this operation
            includes the Token, which is to be used as bearer token
            authentication for other operations.
          </p>
          <br />
          <table>
            <tr>
              <td>URL</td>
              <td>
                <SyntaxHighlighter language="javascript" style={a11yDark}>
                  {`https://testdev.coralpay.com:5000/BankLinkService/api/Auth`}
                </SyntaxHighlighter>
              </td>
            </tr>
            <tr>
              <td>VERB</td>
              <td>POST</td>
            </tr>
            <tr>
              <td>CONTENT-TYPE</td>
              <td>application/json</td>
            </tr>
            <tr>
              <td>REQUEST-PAYLOAD </td>
              <td>
                <SyntaxHighlighter language="javascript" style={a11yDark}>
                  {authRequest}
                </SyntaxHighlighter>
              </td>
            </tr>
            <tr>
              <td>RESPONSE-PAYLOAD </td>
              <td>
                <SyntaxHighlighter language="javascript" style={a11yDark}>
                  {authResponse}
                </SyntaxHighlighter>
              </td>
            </tr>
          </table>
          <br />

          <h3>Properties Description</h3>
          <br />

          <table>
            <tr>
              <th>Properties</th>
              <th>Description</th>
              <th>Mandatory</th>
            </tr>

            <tr>
              <td>Username</td>
              <td>
                This is a unique username assigned to the merchant. Datatype:
                String
              </td>
              <td>YES</td>
            </tr>
            <tr>
              <td>Password</td>
              <td>
                The is the Corresponding password to the Username. Datatype:
                String
              </td>
              <td>YES</td>
            </tr>
            <tr>
              <td>TerminalId</td>
              <td>This is the unique id of the terminal</td>
              <td>YES</td>
            </tr>
            <th>ResponseHeader</th>
            <tr>
              <td>ResponseCode</td>
              <td>This is the status code for the transaction</td>
              <td>YES</td>
            </tr>
            <tr>
              <td>ResponseMessage</td>
              <td>This is the status code message for the transaction </td>
              <td>YES</td>
            </tr>
            <tr>
              <td>Token</td>
              <td>
                The is the generated token used for the bearer token
                authentication type. Datatype: String
              </td>
              <td>YES</td>
            </tr>
            <tr>
              <td>ExpiryDate</td>
              <td>
                This is the expiry date of the token generated. Datatype:
                DateTime
              </td>
              <td>YES</td>
            </tr>
          </table>

          <br />

          <h3>Get Banks</h3>
          <p>
            This operation retrieves the list of available banks. We recommend
            displaying this list to users for instant online payments, enabling
            them to select their preferred bank.
          </p>

          <table>
            <tr>
              <td>URL</td>
              <td>
                <SyntaxHighlighter language="javascript" style={a11yDark}>
                  {`https://testdev.coralpay.com:5000/banklinkservice/api/GetBanks`}
                </SyntaxHighlighter>
              </td>
            </tr>
            <tr>
              <td>VERB</td>
              <td>GET</td>
            </tr>
            <tr>
              <td>CONTENT-TYPE</td>
              <td>application/json</td>
            </tr>
            <tr>
              <td>AUTHENTICATION</td>
              <td>Bearer Token Authentication type.</td>
            </tr>
            <tr>
              <td>RESPONSE-PAYLOAD </td>
              <td>
                <SyntaxHighlighter language="javascript" style={a11yDark}>
                  {getbanksResponsePayload}
                </SyntaxHighlighter>
              </td>
            </tr>
          </table>

          <br />
          <h3>Properties Description</h3>
          <table>
            <tr>
              <th>Properties</th>
              <th>Description</th>
              <th>Type</th>
              <th>Mandatory</th>
            </tr>

            <tr>
              <th>ResponseHeader</th>
            </tr>

            <tr>
              <td>ResponseCode</td>
              <td>This is the status code for the request.</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>ResponseMessage</td>
              <td>This is the status code message for the request.</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <th>Banks [List]</th>
            </tr>

            <tr>
              <td>BankName</td>
              <td>This is the name of the bank </td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>BankCode</td>
              <td>This is the NIP institution code of the bank. </td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>BankLogo</td>
              <td>This is the URL of the bank’s logo. </td>
              <td>String</td>
              <td>Yes</td>
            </tr>
          </table>
          <br />

          <h3>Create Static Account</h3>
          <p>
            This operation is used to create a static account for the customer.
            The static account created does not expire and the customer can
            reuse it to make payments on multiple occasions.
          </p>
          <p>
            The inclusion of a bank code is optional; providing it means an
            account will be generated from the specified bank. If no bank code
            is supplied, the default bank associated with the merchant profile
            will be used for the transaction.{" "}
          </p>
          <p>
            Once payment is made to the account, the Merchant is sent a
            notification to their callback URL.
          </p>
          <table>
            <tr>
              <td>URL</td>
              <td>
                <SyntaxHighlighter language="javascript" style={a11yDark}>
                  {`https://testdev.coralpay.com:5000/banklinkservice/api/CreateStaticAccount`}
                </SyntaxHighlighter>
              </td>
            </tr>
            <tr>
              <td>VERB</td>
              <td>POST</td>
            </tr>
            <tr>
              <td>CONTENT-TYPE</td>
              <td>application/json</td>
            </tr>
            <tr>
              <td>AUTHENTICATION</td>
              <td>Bearer Token Authentication type.</td>
            </tr>
            <tr>
              <td>REQUEST-PAYLOAD </td>
              <td>
                <SyntaxHighlighter language="javascript" style={a11yDark}>
                  {createStaticAccountRequestPayload}
                </SyntaxHighlighter>
              </td>
            </tr>
            <tr>
              <td>RESPONSE-PAYLOAD </td>
              <td>
                <SyntaxHighlighter language="javascript" style={a11yDark}>
                  {createStaticAccountResponsePayload}
                </SyntaxHighlighter>
              </td>
            </tr>
          </table>

          <br />
          <h3>Properties Description</h3>
          <table>
            <tr>
              <th>Properties</th>
              <th>Description</th>
              <th>Type</th>
              <th>Mandatory</th>
            </tr>

            <tr>
              <th>RequestHeader</th>
            </tr>
            <tr>
              <td>MerchantId</td>
              <td>This is the Merchant’s unique identifier.</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>TerminalId</td>
              <td>This is the unique identifier of the terminal.</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>TraceId</td>
              <td>
                This is the unique identifier of the request from the merchant’s
                end.
              </td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>BankCode</td>
              <td>
                This is the NIP institution code of the bank, this can be gotten
                by calling GetBanks operation.
              </td>
              <td>String</td>
              <td>No</td>
            </tr>
            <tr>
              <td>Description</td>
              <td>This is the request description</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>AccountName</td>
              <td>The is the name to be assigned to the account</td>
              <td>String</td>
              <td>Yes</td>
            </tr>

            <tr>
              <th>ResponseHeader</th>
            </tr>

            <tr>
              <td>ResponseCode</td>
              <td>This is the status code for the request.</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>ResponseMessage</td>
              <td>This is the status code message for the request.</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>DestinationBankName</td>
              <td>This is the name of the bank.</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>DestinationBankCode</td>
              <td>This is the NIP institution code of the bank.</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>DestinationAccountName</td>
              <td>This is the name of the account generated by the bank.</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>DestinationAccountNumber</td>
              <td>
                This is the account number generated and where fund is to be
                transferred to.
              </td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>DestinationBankLogo</td>
              <td>This is the Url of the bank’s logo</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>TransactionId</td>
              <td>
                This is the unique identifier of the request generated by
                CoralPay.
              </td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>SessionId</td>
              <td>
                This is the unique identifier of the request generated by the
                payment source Bank.
              </td>
              <td>String</td>
              <td>Yes</td>
            </tr>
          </table>
          <br />

          <h3>Invoke Payment</h3>
          <p>
            This operation is responsible for generating a dynamic bank account
            number for instant online payment, which expires in some minutes.{" "}
          </p>
          <p>
            The inclusion of a bank code is optional; providing it means an
            account will be generated from the specified bank. If no bank code
            is supplied, the default bank associated with the merchant profile
            will be used for the transaction.
          </p>
          <p>
            In either case, the customer is required to transfer the exact
            amount displayed to the generated account number.
          </p>
          {/* invokePayment */}
          <table>
            <tr>
              <td>URL</td>
              <td>
                <SyntaxHighlighter language="javascript" style={a11yDark}>
                  {`https://testdev.coralpay.com:5000/banklinkservice/api/InvokePayment`}
                </SyntaxHighlighter>
              </td>
            </tr>
            <tr>
              <td>VERB</td>
              <td>POST</td>
            </tr>
            <tr>
              <td>CONTENT-TYPE</td>
              <td>application/json</td>
            </tr>
            <tr>
              <td>AUTHENTICATION</td>
              <td>Bearer Token Authentication type.</td>
            </tr>
            <tr>
              <td>REQUEST-PAYLOAD </td>
              <td>
                <SyntaxHighlighter language="javascript" style={a11yDark}>
                  {invokePaymentRequestPayload}
                </SyntaxHighlighter>
              </td>
            </tr>
            <tr>
              <td>RESPONSE-PAYLOAD </td>
              <td>
                <SyntaxHighlighter language="javascript" style={a11yDark}>
                  {invokePaymentResponsePayload}
                </SyntaxHighlighter>
              </td>
            </tr>
          </table>

          <br />

          <h3>Properties Description</h3>
          <table>
            <tr>
              <th>Properties</th>
              <th>Description</th>
              <th>Type</th>
              <th>Mandatory</th>
            </tr>

            <tr>
              <th>RequestHeader</th>
            </tr>
            <tr>
              <td>MerchantId</td>
              <td>This is the Merchant’s unique identifier.</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>TerminalId</td>
              <td>This is the unique identifier of the terminal.</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>TraceId</td>
              <td>
                This is the unique identifier of the request from the merchant’s
                end.
              </td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>BankCode</td>
              <td>
                This is the NIP institution code of the bank, this can be gotten
                by calling GetBanks operation.
              </td>
              <td>String</td>
              <td>No</td>
            </tr>
            <tr>
              <td>Description</td>
              <td>This is the request description</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>AccountName</td>
              <td>The is the name to be assigned to the account</td>
              <td>String</td>
              <td>Yes</td>
            </tr>

            <tr>
              <th>ResponseHeader</th>
            </tr>

            <tr>
              <td>ResponseCode</td>
              <td>This is the status code for the request.</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>ResponseMessage</td>
              <td>This is the status code message for the request.</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>DestinationBankName</td>
              <td>This is the name of the bank.</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>DestinationBankCode</td>
              <td>This is the NIP institution code of the bank.</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>DestinationAccountName</td>
              <td>This is the name of the account generated by the bank.</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>DestinationAccountNumber</td>
              <td>
                This is the account number generated and where fund is to be
                transferred to.
              </td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>DestinationBankLogo</td>
              <td>This is the Url of the bank’s logo</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>TransactionId</td>
              <td>
                This is the unique identifier of the request generated by
                CoralPay.
              </td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>SessionId</td>
              <td>
                This is the unique identifier of the request generated by the
                payment source Bank.
              </td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>ExpiryTime</td>
              <td>This is the DateTime when the account expires</td>
              <td>Datetime</td>
              <td>Yes</td>
            </tr>
          </table>
          <br />

          <h3>Transaction Query</h3>

          <p>
            This operation allows the merchant to query for a particular
            transaction.
          </p>
          <br />
          <table>
            <tr>
              <td>URL</td>
              <td>
                <SyntaxHighlighter language="javascript" style={a11yDark}>
                  {`https://testdev.coralpay.com:5000/banklinkservice/api/TransactionQuery`}
                </SyntaxHighlighter>
              </td>
            </tr>
            <tr>
              <td>VERB</td>
              <td>POST</td>
            </tr>
            <tr>
              <td>CONTENT-TYPE</td>
              <td>application/json</td>
            </tr>
            <tr>
              <td>AUTHENTICATION</td>
              <td>Bearer Token Authentication type.</td>
            </tr>
            <tr>
              <td>REQUEST-PAYLOAD </td>
              <td>
                <SyntaxHighlighter language="javascript" style={a11yDark}>
                  {transactionQueryRequestPayload}
                </SyntaxHighlighter>
              </td>
            </tr>
            <tr>
              <td>RESPONSE-PAYLOAD </td>
              <td>
                <SyntaxHighlighter language="javascript" style={a11yDark}>
                  {transactionQueryResponsePayload}
                </SyntaxHighlighter>
              </td>
            </tr>
          </table>

          <br />

          <h3>Properties Description</h3>
          <table>
            <tr>
              <th>Properties</th>
              <th>Description</th>
              <th>Type</th>
              <th>Mandatory</th>
            </tr>

            <tr>
              <th>RequestHeader</th>
            </tr>
            <tr>
              <td>MerchantId</td>
              <td>This is the Merchant’s unique identifier.</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>TerminalId</td>
              <td>This is the unique identifier of the terminal.</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>TraceId</td>
              <td>
                This is the unique identifier of the request from the merchant’s
                end.
              </td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>BankCode</td>
              <td>
                This is the NIP institution code of the bank, this can be gotten
                by calling GetBanks operation.
              </td>
              <td>String</td>
              <td>No</td>
            </tr>
            <tr>
              <td>Description</td>
              <td>This is the request description</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>AccountName</td>
              <td>The is the name to be assigned to the account</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>Amount</td>
              <td>This is the amount of the Transaction </td>
              <td>Decimal</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>TransactionResponseCode</td>
              <td>This is the status code of the transaction </td>
              <td>Decimal</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>TransactionResponseMessage</td>
              <td>This is the status code message for the transaction </td>
              <td>Decimal</td>
              <td>Yes</td>
            </tr>

            <tr>
              <th>ResponseHeader</th>
            </tr>

            <tr>
              <td>ResponseCode</td>
              <td>This is the status code for the request.</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>ResponseMessage</td>
              <td>This is the status code message for the request.</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>DestinationBankName</td>
              <td>This is the name of the bank.</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>DestinationBankCode</td>
              <td>This is the NIP institution code of the bank.</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>DestinationAccountName</td>
              <td>This is the name of the account generated by the bank.</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>DestinationAccountNumber</td>
              <td>
                This is the account number generated and where fund is to be
                transferred to.
              </td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>DestinationBankLogo</td>
              <td>This is the Url of the bank’s logo</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>TransactionId</td>
              <td>
                This is the unique identifier of the request generated by
                CoralPay.
              </td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>SessionId</td>
              <td>
                This is the unique identifier of the request generated by the
                payment source Bank.
              </td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>SourceBankCode</td>
              <td>
                This is the NIP institution code of the payment source bank.
              </td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>SourceBankName</td>
              <td>This is the name of the payment source bank.</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>SourceBankAccountNumber</td>
              <td>This is the payment source bank account</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>SourceAccountName</td>
              <td>This is the name of the payment source bank account</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>PaidAmount</td>
              <td>This is the amount</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
          </table>

          <br />

          <h3>Static Transaction Query</h3>

          <p>
            This operation allows the merchant to query for all transactions
            made to a particular static account.
          </p>

          <br />

          <table>
            <tr>
              <td>URL</td>
              <td>
                <SyntaxHighlighter language="javascript" style={a11yDark}>
                  {`https://testdev.coralpay.com:5000/banklinkservice/api/StaticTransactionQuery`}
                </SyntaxHighlighter>
              </td>
            </tr>
            <tr>
              <td>VERB</td>
              <td>POST</td>
            </tr>
            <tr>
              <td>CONTENT-TYPE</td>
              <td>application/json</td>
            </tr>
            <tr>
              <td>AUTHENTICATION</td>
              <td>Bearer Token Authentication type.</td>
            </tr>
            <tr>
              <td>REQUEST-PAYLOAD </td>
              <td>
                <SyntaxHighlighter language="javascript" style={a11yDark}>
                  {staticTransactionQueryRequestPayload}
                </SyntaxHighlighter>
              </td>
            </tr>
            <tr>
              <td>RESPONSE-PAYLOAD </td>
              <td>
                <SyntaxHighlighter language="javascript" style={a11yDark}>
                  {staticTransactionQueryResponsePayload}
                </SyntaxHighlighter>
              </td>
            </tr>
          </table>

          <br />

          <h3>Properties Description</h3>
          <table>
            <tr>
              <th>Properties</th>
              <th>Description</th>
              <th>Type</th>
              <th>Mandatory</th>
            </tr>

            <tr>
              <th>RequestHeader</th>
            </tr>
            <tr>
              <td>MerchantId</td>
              <td>This is the Merchant’s unique identifier.</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>TerminalId</td>
              <td>This is the unique identifier of the terminal.</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>TraceId</td>
              <td>
                This is the unique identifier of the request from the merchant’s
                end.
              </td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>AccountNumber</td>
              <td>This is the static account number.</td>
              <td>String</td>
              <td>No</td>
            </tr>
            <tr>
              <td>StartDate</td>
              <td>Filter transactions starting from this date.</td>
              <td>DateTime</td>
              <td>No</td>
            </tr>
            <tr>
              <td>EndDate</td>
              <td>Filter transactions ending on this date.</td>
              <td>DateTime</td>
              <td>No</td>
            </tr>
            <tr>
              <th>Transactions</th>
            </tr>
            <tr>
              <td>Description</td>
              <td>This is the request description</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>AccountName</td>
              <td>The is the name to be assigned to the account</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>Amount</td>
              <td>This is the amount of the Transaction </td>
              <td>Decimal</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>TransactionResponseCode</td>
              <td>This is the status code of the transaction </td>
              <td>Decimal</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>TransactionResponseMessage</td>
              <td>This is the status code message for the transaction </td>
              <td>Decimal</td>
              <td>Yes</td>
            </tr>

            <tr>
              <th>ResponseHeader</th>
            </tr>

            <tr>
              <td>ResponseCode</td>
              <td>This is the status code for the request.</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>ResponseMessage</td>
              <td>This is the status code message for the request.</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>DestinationBankName</td>
              <td>This is the name of the bank.</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>DestinationBankCode</td>
              <td>This is the NIP institution code of the bank.</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>DestinationAccountName</td>
              <td>This is the name of the account generated by the bank.</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>DestinationAccountNumber</td>
              <td>
                This is the account number generated and where fund is to be
                transferred to.
              </td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>DestinationBankLogo</td>
              <td>This is the Url of the bank’s logo</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>TransactionId</td>
              <td>
                This is the unique identifier of the request generated by
                CoralPay.
              </td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>SessionId</td>
              <td>
                This is the unique identifier of the request generated by the
                payment source Bank.
              </td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>SourceBankCode</td>
              <td>
                This is the NIP institution code of the payment source bank.
              </td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>SourceBankName</td>
              <td>This is the name of the payment source bank.</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>SourceBankAccountNumber</td>
              <td>This is the payment source bank account</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>SourceAccountName</td>
              <td>This is the name of the payment source bank account</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
          </table>
          <br />

          <h3>Callback</h3>

          <p>
            This is the specification of the notification that is to be sent to
            the merchant on the completion of the payment, and the merchant is
            expected to build the endpoint accordingly.
          </p>
          <p>
            The endpoint should use these two Parameters, to be passed in the
            header:
          </p>
          <p>- “key” – this is a unique key to be shared by CoralPay.</p>
          <p>
            An Http 200 response is expected to be gotten once notification is
            sent. There would be a repush of up to 6 times within an hour, until
            http 200 response is received.
          </p>

          <br />

          <table>
            <tr>
              <td>URL</td>
              <td>
                <b>To be provided by the merchant</b>
              </td>
            </tr>
            <tr>
              <td>VERB</td>
              <td>POST</td>
            </tr>
            <tr>
              <td>CONTENT-TYPE</td>
              <td>application/json</td>
            </tr>
            <tr>
              <td>AUTHENTICATION</td>
              <td>Api Key. Header name: Secret</td>
            </tr>
            <tr>
              <td>RESPONSE-PAYLOAD </td>
              <td>
                <SyntaxHighlighter language="javascript" style={a11yDark}>
                  {callBackResponsePayload}
                </SyntaxHighlighter>
              </td>
            </tr>
          </table>

          <h3>Properties Description</h3>

          <table>
            <tr>
              <th>Properties</th>
              <th>Description</th>
              <th>Type</th>
              <th>Mandatory</th>
            </tr>

            <tr>
              <th>RequestHeader</th>
            </tr>
            <tr>
              <td>MerchantId</td>
              <td>This is the Merchant’s unique identifier.</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>TerminalId</td>
              <td>This is the unique identifier of the terminal.</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>TraceId</td>
              <td>
                This is the unique identifier of the request from the merchant’s
                end.
              </td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>BankCode</td>
              <td>
                This is the NIP institution code of the bank, this can be gotten
                by calling GetBanks operation.
              </td>
              <td>String</td>
              <td>No</td>
            </tr>
            <tr>
              <td>Description</td>
              <td>This is the request description</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>AccountName</td>
              <td>The is the name to be assigned to the account</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>Amount</td>
              <td>This is the amount of the Transaction </td>
              <td>Decimal</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>DestinationBankName</td>
              <td>This is the name of the bank.</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>DestinationBankCode</td>
              <td>This is the NIP institution code of the bank.</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>DestinationAccountName</td>
              <td>This is the name of the account generated by the bank.</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>DestinationAccountNumber</td>
              <td>
                This is the account number generated and where fund is to be
                transferred to.
              </td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>DestinationBankLogo</td>
              <td>This is the Url of the bank’s logo</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>TransactionId</td>
              <td>
                This is the unique identifier of the request generated by
                CoralPay.
              </td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>SessionId</td>
              <td>
                This is the unique identifier of the request generated by the
                payment source Bank.
              </td>
              <td>String</td>
              <td>Yes</td>
            </tr>

            <tr>
              <td>PaymentDate</td>
              <td>This is the amount</td>
              <td>DateTime</td>
              <td>Yes</td>
            </tr>
          </table>

          <br />

          <h3>Error codes</h3>

          <table>
            <th>Codes</th>
            <th>Description</th>
            <tr>
              <td>00</td>
              <td>Successful</td>
            </tr>
            <tr>
              <td>01</td>
              <td>
                Failed Transaction/Request: Description in the Response Message
              </td>
            </tr>
            <tr>
              <td>09</td>
              <td>Pending Transaction</td>
            </tr>
            <tr>
              <td>76</td>
              <td>Mismatch Transaction</td>
            </tr>
            <tr>
              <td>25</td>
              <td>Unable to locate record</td>
            </tr>
            <tr>
              <td>54</td>
              <td>Expired Account Number</td>
            </tr>
          </table>

          {/* enddddd */}

          <Box sx={{ width: "100%", paddingTop: "2rem" }} ref={snippets}>
            <Grid2
              container
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Grid2>
                <Typography
                  sx={{
                    color: "#7f3d64",
                    fontSize: "1.2em",
                    fontWeight: "800",
                  }}
                >
                  Libraries & Code Snippets
                </Typography>
              </Grid2>
              <Grid2 sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button onClick={scrollToTop} sx={{ color: "#7f3d64" }}>
                  Back to the top{" "}
                  <KeyboardDoubleArrowUpIcon
                    sx={{ fontSize: 30, color: "#7f3d64" }}
                  />
                </Button>
              </Grid2>
            </Grid2>

            <Grid2
              container
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "1.5rem 0 1.5rem 0",
              }}
            >
              {/* <div style={{ marginRight: "1rem" }}>
                <button
                  style={{
                    border: javascript ? "none" : "1px solid #242424",
                    borderRadius: "10px",
                    fontWeight: 550,
                    padding: "1rem",
                    color: javascript ? "#fff" : "#242424",
                    backgroundColor: javascript ? "#242424" : "#fff",
                  }}
                  onClick={handleJavascriptContent}
                >
                  JAVASCRIPT
                </button>
              </div> */}
              <div style={{ marginRight: "1rem" }}>
                <button
                  style={{
                    border: dotnet ? "none" : "1px solid #242424",
                    borderRadius: "10px",
                    fontWeight: 550,
                    padding: "1rem",
                    color: dotnet ? "#fff" : "#242424",
                    backgroundColor: dotnet ? "#242424" : "#fff",
                  }}
                  onClick={handleDotnetContent}
                >
                  .NET
                </button>
              </div>
              {/* <div>
                <button
                  style={{
                    border: ceeSharp ? "none" : "1px solid #242424",
                    borderRadius: "10px",
                    fontWeight: 550,
                    padding: "1rem",
                    color: ceeSharp ? "#fff" : "#242424",
                    backgroundColor: ceeSharp ? "#242424" : "#fff",
                  }}
                  onClick={handleCeeSharpContent}
                >
                  JAVA
                </button>
              </div> */}
            </Grid2>

            <div>
              {/* {javascript && <JavascriptContent />} */}
              <DotNetContent />
              {/* {ceeSharp && <JavaContent />} */}
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default PaywithBankTransferDocumentation;
