import React from "react"
import Subscribe from "../../components/Subscribe";
import billers from "../../assets/billerAggPlat.svg"
import coralpay from "../../assets/Coralpaypaymentgateway.svg"
import playstoreimg from "../../assets/play-store-download-icon-4 1.svg"
import "../../App.css"
import {Link} from "react-router-dom";
import learnMore from "../../assets/learnmore.svg";

const BillersAggePlat = () => {
    return(
        <div>
            <div className="blog-header" style={{backgroundImage:`url(${billers})`}}>
                <div className="blog-wrapper-overlay">
                    <h3 style={{width:'60%',lineHeight:1.1}}>Billers Aggregation <span style={{color:'#F7EF8A',marginTop:30}}>Platform</span></h3>
                </div>
            </div>
            <br /><br /><br /><br />
            <div className="product">
                <div className="product-one">
                    <div className="accordion" id="accordionExample">
                        <Link to="/coralpay-payment-gateway" >
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingOne">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Coralpay Payment Gateway
                                    </button>
                                </h2>
                            </div>
                        </Link>
                        <Link to="/cgateussd" >
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingOne">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        C'Gate USSD
                                    </button>
                                </h2>
                            </div>
                        </Link>
                        {/*<Link to="/cgateussd" >*/}
                        {/*    <div class0ame="accordion-item mb-3">*/}
                        {/*        <h2 className="accordion-header" id="headingOne">*/}
                        {/*            <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"*/}
                        {/*                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">*/}
                        {/*                C'Gate USSD*/}
                        {/*            </button>*/}
                        {/*        </h2>*/}
                        {/*    </div>*/}
                        {/*</Link>*/}

                        <Link to="/transaction-switching-and-processing">
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingTwo">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo(null)" aria-expanded="false" aria-controls="collapseTwo">
                                        Transaction Switching & Processing
                                    </button>
                                </h2>
                            </div>
                        </Link>

                        <Link to="/payment-with-transfer">
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree(null)" aria-expanded="false"
                                            aria-controls="collapseThree">
                                        Pay With Bank Transfer
                                    </button>
                                </h2>
                            </div></Link>
                        <Link to="/nqr" >
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree(null)" aria-expanded="false"
                                            aria-controls="collapseThree">
                                        NQR
                                    </button>
                                </h2>
                            </div></Link>
                        <Link to="/digipos">
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingFour">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseFour" aria-expanded="false"
                                            aria-controls="collapseFour">
                                        DigiPOS
                                    </button>
                                </h2>
                            </div>
                        </Link>

                        <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingFive">
                                    <button className="accordion-button dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseFive" aria-expanded="false"
                                            aria-controls="collapseThree">
                                        Billers Aggregation Platform
                                    </button>
                                </h2>
                                <div id="collapseFive" className="accordion-collapse show" aria-labelledby="headingFive"
                                     data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <div className="biller-one-option-mobile">
                                            <div className="biller-one-app-option" style={{backgroundImage:`url(${coralpay})`}}></div>
                                        </div>
                                        <p>Make bills payments more convenient for your customers. Bring the billers
                                            right into their devices  with our Billers Aggregation Platform.
                                            Our Billers Aggregation Platform enables businesses to facilitate bills payments via internet banking,
                                            mobile banking apps, USSD, Agency network locations and POS terminals,
                                            guaranteeing cost reduction for our clients and a sustainable growth in revenue portfolio.
                                        </p>
                                        <br />
                                        {/*<ul>*/}
                                        {/*    <li> Electricity Distribution Companies</li>*/}
                                        {/*    <li> Cable TV Providers</li>*/}
                                        {/*    <li>  Internet Service Providers</li>*/}
                                        {/*    <li> Telecommunication Companies</li>*/}
                                        {/*    <li> Betting and Lottery companies</li>*/}
                                        {/*    <li>  Solar Energy providers</li>*/}
                                        {/*    <li> Insurance companies</li>*/}
                                        {/*    <li> Schools</li>*/}
                                        {/*    <li> Government</li>*/}
                                        {/*    <li> Tolls (LCC)</li>*/}
                                        {/*</ul>*/}
                                        {/*<br />*/}
                                        <a href="https://blog.coralpay.com/the-payment-options-that-will-keep-your-business-ahead/"><img src={learnMore} alt='learn more btn'/></a>
                                        <br />
                                        <span style={{color:'#141414',fontSize: '20px',marginTop:'10px'}}>Download Biller One App Here</span>
                                        <div>
                                            <div style={{display:'flex'}}>
                                            <a className="flex" target="blank" href="https://play.google.com/store/apps/details?id=com.coralpay.billerone&hl=en&gl=US">
                                                <img style={{ maxWidth: 140 }} src={playstoreimg} alt="someimage" />
                                            </a>
                                            <a className="flex" target="blank" href="https://apps.apple.com/ng/app/billerone-bills-payments/id1633836689">
                                                <img style={{ maxWidth: 165 }} src="https://www.svgrepo.com/show/303128/download-on-the-app-store-apple-logo.svg" alt="someimage" />
                                            </a>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        <Link to="/finpay">
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree">
                                        FinPay
                                    </button>
                                </h2>
                            </div>
                        </Link>
                        <Link to="/payment-link">
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree">
                                        Payment Link
                                    </button>
                                </h2>
                            </div>
                        </Link>
                        <Link to="/instant-payment">
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree">
`                                        Coralpay Instant Payment
`                                    </button>
                                </h2>
                            </div>
                        </Link>
                    </div>

                </div>
                <div className="biller-one">
                    <div className="biller-one-app" style={{backgroundImage:`url(${coralpay})`}}></div>
                </div>
            </div>
            <Subscribe />
        </div>
    )
}


export default BillersAggePlat