import React, { useEffect, useRef, useState } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import {
  darcula,
  a11yDark,
} from "react-syntax-highlighter/dist/esm/styles/prism";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Grid2,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import DotNetContent from "./dotnet/DotNetContent";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import { scrollToTop } from "../../../components/helper";

const AccountConnectDocumentation = () => {
  const [javascript, setJavascript] = useState(true);
  const [dotnet, setDotnet] = useState(true);
  const [ceeSharp, setCeeSharp] = useState(false);
  const navigate = useNavigate();

  const apiSpec = useRef(null);
  const snippets = useRef(null);

  const scrollToSection = (ref) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleJavascriptContent = () => {
    setJavascript((prev) => !prev);
    setDotnet(false);
    setCeeSharp(false);
  };
  const handleDotnetContent = () => {
    setJavascript(false);
    setDotnet((prev) => !prev);
    setCeeSharp(false);
  };
  const handleCeeSharpContent = () => {
    setJavascript(false);
    setDotnet(false);
    setCeeSharp((prev) => !prev);
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  const authRequest = `
{
  "Username": "Skaikru",
  "Password": "#1ooGr@0ndar$",
  "TerminalId": "TT100025"
}

  `;
  const authResponse = `
{
  "ResponseHeader": {
    "ResponseCode": "00",
    "ResponseMessage": "Successful"
  },
  "Key": "99df5451-6f36-46ac-9ebc-f23ad55f7c0d",
  "Token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdG",
  "ExpiryDate": "2024-03-10T10:15:06.8791956+01:00"
}

  `;

  const getProcessorsResponsePayload = `
{
  "responseHeader": {
    "responseCode": "00",
    "responseMessage": "Success"
  },
  "processors": [
    {
      "processorName": "kuda",
      "processorCode": "kuda_pay",
      "processorLogo": "http://kuda.com/logo.png",
      "isActive": true
    }
  ]
}


  
  `;

  const createStaticAccountRequestPayload = `
{
  "RequestHeader": {
    "MerchantId": "TT10002500001",
    "TerminalId": "TT100025",
    "TraceId": "20240326122115458"
  },
  "BankCode": "100101",
  "Description": "User Account Creation",
  "AccountName": "Michael Sola"
}
  `;

  const createStaticAccountResponsePayload = `
{
  "ResponseHeader": {
    "ResponseCode": "00",
    "ResponseMessage": "Successful",
    "traceId": "20240326122115458"
  },
  "DestinationBankCode": "100101",
  "DestinationBankName": "Test Bank",
  "Description": " User Account Creation",
  "DestinationAccountName": "CPY-Michael Sola",
  "DestinationAccountNumber": "1011110012",
  "TransactionId": "2024326145554554478",
  "SessionId": "100101202403265454787456554775",
  "DestinationBankLogo": "https://testbank.ng/logo"
}

  `;

  const invokePaymentRequestPayload = `
{
  "amount": 1000.0,
  "processorCode": "mono",
  "phoneNumber": "",
  "payId": "",
  "description": "Rent Payment",
  "requestHeader": {
    "merchantId": "niyistore",
    "traceId": "20342016726997"
  }
}

  `;

  const invokePaymentResponsePayload = `
{
  "responseHeader": {
    "traceId": "20342016726997",
    "merchantId": "niyistore ",
    "responseCode": "00",
    "responseMessage": "Success"
  },
  "expiryDate": "2024-06-27T18:22:32.283",
  "transactionId": "20240627181232283 ",
  "channelTranId": " 240627145661220308778",
  "processorCode": "mono",
  "processorName": "mono",
  "amount": 1000.0,
  "paymentLink": " https://sandboxcashier.opaycheckout.com",
  "description": "Rent Payment"
}

  `;

  const generateDynamicAccountRequestPayload = `
  {
    "RequestHeader": {
      "clientId": "a@a.com",
      "requestType": "Bank Transfer"
    },
    "customerName": "Shehu Chidi & Sons",
    "referenceNumber": "xsd4pe0t008952",
    "transactionAmount": "6754"
  }
  `;
  const generateDynamicAccountRsponsePayload = `
 {
      "responseDetails": {
        "clientId": "a@a.com",
        "referenceNumber": "xsd4pe0t008952",
        "responseCode": "00",
        "responseMessage": "OPERATION SUCCESSFUL"
      },
      "accountName": "MERCHANT(Shehu Chidi & Sons)",
      "accountNumber": "9977573777",
      "operationReference": "merchant2201250331078455",
      "transactionAmount": "6754"
}
  `;

  const transactionNotificationRequestPayload = `
{
    "responseDetails": {
    "clientId": "a@a.com",
    "referenceNumber": "xsd4pe0t008952",
    "responseCode": "00",
    "responseMessage": "OPERATION SUCCESSFUL"
    },
    "accountName": "MERCHANT(Shehu Chidi & Sons)",
    "accountNumber": "9977573777",
    "operationReference": "merchant2201250331078455",
    "transactionAmount": "6754"
}
  `;

  const transactionQueryRequestPayload = `
{
  "merchantId": " TTfire",
  "traceId": "20342016726997" //Same traceId used for invokepayment request
}

  
  `;

  const transactionQueryResponsePayload = `
{
  "transactionId": "20240627120538538",
  "amount": 200.0,
  "responseHeader": {
    "responseCode": "00",
    "responseMessage": "Success"
  },
  "createdDate": "2024-06-27T12:05:39",
  "traceId": "146532220",
  "merchantId": "TTfire",
  "paymentDate": "2024-06-27T12:25:39",
  "processorCode": "OPAY",
  "description": "Test Payment"
}

  `;

  const staticTransactionQueryRequestPayload = `
{
  "RequestHeader": {
    "MerchantId": "kelvin1",
    "TerminalId": "kelvin123"
  },
  "AccountNumber": "4560951068",
  "StartDate": "2024-05-01",
  "EndDate": "2024-05-01"
}
  
  `;
  const staticTransactionQueryResponsePayload = `
{
  "responseHeader": {
    "responseCode": "00",
    "responseMessage": "Success"
  },
  "transactions": [
    {
      "TransactionResponseCode": "00",
      "TransactionResponseMessage": "Success",
      "SourceBankCode": "100004",
      "SourceAccountName": "ADE JIMMY SUNDAY",
      "SourceBankName": "Test Source Bank",
      "SourceBankAccountNumber": "8074649190",
      "Amount": 5000.0,
      "DestinationBankCode": "000058",
      "DestinationBankName": "Test Bank",
      "DestinationAccountName": "Test Account Name",
      "DestinationAccountNumber": "4560983413",
      "TraceId": "20240502095332087865",
      "TransactionId": "2ff63963-b7fb-4651-bdea-740dea71551d",
      "SessionId": "840e666a-9108-46a1-bb2c-9b5d373e8148",
      "Terminal": "kelvin123",
      "Description": "Payment",
      "PaymentAmount": 5000.0,
      "PaymentDate": "2024-05-02T09:53:32.1692279"
    }
  ]
}

  
  `;

  const callBackResponsePayload = `
{
  "merchantId": "TTfire",
  "transactionId": "20240627112808748",
  "channelTranId ": "202402808748",
  "traceId": "11387890669109095729279",
  "transactionResponseCode": "00",
  "transactionResponseMessage": "Transaction Successful",
  "processorCode": "OPAY",
  "processorName": "OPAY",
  "sourceAccountName": "OpayWalletNg",
  "sourceAccountNumber": "2341259257649",
  "description": "Payment for 11387890669109095729279",
  "transactionAmount": 200.0
}


  `;
  return (
    <div>
      <div className="developer-wrapper">
        <div className="developer-wrapper-overlay">
          <div className="developer-wrapper-overlay-inner">
            <h3>ACCOUNT CONNECT</h3>
            <p className="mt-2 small">
              Account Connect facilitates online payments via one-time payment
              links. Merchants select a processor from a processor list and
              complete the transaction using the exact amount displayed on the
              generated payment link for the chosen processor. This API enables
              seamless integration with AccountConnect services by utilizing
              dynamically generated payment links.
            </p>
            <br />
            <br />
            {/*<div style={{display:'flex'}} >*/}
            {/*    <Button message="API Dashboard" bgColor="#7B3F63" bgText="#FFFFFF" />*/}
            {/*    <Button  message="Download Document" bgColor="#EFEFEF" bgText="#3A3A3A"/>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
      <div className="doc-div-parent-doc">
        <div className="docNav">
          <Box sx={{ padding: "2rem 0 2rem 0" }}>
            <Button
              sx={{
                backgroundColor: "#083857",
                fontWeight: "550",
                color: "#fff",
              }}
              onClick={() => navigate("/developer#services")}
            >
              BACK TO DEVELOPER'S HOMEPAGE
            </Button>
          </Box>
          <nav>
            <List
              sx={{
                border: "1px solid #083857",
                borderRadius: "5px",
              }}
            >
              <ListItem disablePadding>
                {/* <a href="#apiSpec"> */}
                  <ListItemButton onClick={() => scrollToSection(apiSpec)}>
                    <ListItemIcon>{/* <InboxIcon /> */}</ListItemIcon>
                    <ListItemText primary="API Specifications" />
                  </ListItemButton>
                {/* </a> */}
              </ListItem>
              <ListItem disablePadding>
                {/* <a href="#snippets"> */}
                  <ListItemButton onClick={() => scrollToSection(snippets)}>
                    <ListItemIcon>{/* <DraftsIcon /> */}</ListItemIcon>
                    <ListItemText primary="Libraries & Code Snippets" />
                  </ListItemButton>
                {/* </a> */}
              </ListItem>
            </List>
          </nav>
        </div>
        <div className="doc-div-doc" ref={apiSpec}>
          <h3>Introduction</h3>

          <p>
            This document serves as a technical and integration guide for the
            AccountConnect service, providing guidance and a template for smooth
            integration.
          </p>

          <h3>API Specifications</h3>
          <p>
            The Account Connect API is a RESTful service which uses the basic
            authentication method to authenticate users calling the API.
            Credentials to call the API would be shared also.
          </p>
          <br />
          <h3>Authentication</h3>
          <p>
            The authentication method used is Basic Authentication. For
            merchants, the token is generated using the merchant ID and secret
            key.
          </p>
          <br />

          <h3>Get Processors</h3>
          <p>
            This operation retrieves the list of available active processors. We
            recommend displaying this list to users for instant online payments,
            enabling them to select their preferred processor.
          </p>
          <br />
          <table>
            <tr>
              <td>URL</td>
              <td>
                <SyntaxHighlighter language="javascript" style={a11yDark}>
                  {`http://sandbox1.coralpay.com:8080/account-connect/api/payment/getprocessors`}
                </SyntaxHighlighter>
              </td>
            </tr>
            <tr>
              <td>VERB</td>
              <td>GET</td>
            </tr>
            <tr>
              <td>CONTENT-TYPE</td>
              <td>application/json</td>
            </tr>
            <tr>
              <td>AUTHENTICATION</td>
              <td>Basic Authentication type.</td>
            </tr>
            <tr>
              <td>RESPONSE-PAYLOAD </td>
              <td>
                <SyntaxHighlighter language="javascript" style={a11yDark}>
                  {getProcessorsResponsePayload}
                </SyntaxHighlighter>
              </td>
            </tr>
          </table>
          <br />

          <h3>Properties Description</h3>
          <br />

          <table>
            <tr>
              <th>Properties</th>
              <th>Description</th>
              <th>Type</th>
            </tr>
            <tr>
              <td>ProcessorName</td>
              <td>This is the name of the processor</td>
            </tr>
            <tr>
              <td>ProcessorCode</td>
              <td>This is the code of the processor.</td>
            </tr>
            <tr>
              <td>ProcessorLogo</td>
              <td>This is the logo of the processor.</td>
            </tr>
            <tr>
              <td>isActive</td>
              <td>This is the current status of the processor.</td>
            </tr>
          </table>

          <br />

          <h3>Invoke Payment Link</h3>
          <p>
            This operation is responsible for generating a payment link for
            instant online payment, which expires in minutes set by the
            processor.
          </p>
          <p>
            The inclusion of a processor is optional; providing it means an
            account will be generated from the specified processor. If no
            processor is supplied, the default processor associated with the
            merchant profile will be used for the transaction.
          </p>

          <p>
            In either case, the customer is required to transfer the exact
            amount displayed to the generated account number.
          </p>

          <table>
            <tr>
              <td>URL</td>
              <td>
                <SyntaxHighlighter language="javascript" style={a11yDark}>
                  {`http://sandbox1.coralpay.com:8080/account-connect/api/payment/invokepayment`}
                </SyntaxHighlighter>
              </td>
            </tr>
            <tr>
              <td>VERB</td>
              <td>POST</td>
            </tr>
            <tr>
              <td>CONTENT-TYPE</td>
              <td>application/json</td>
            </tr>
            <tr>
              <td>AUTHENTICATION</td>
              <td>Basic authentication type.</td>
            </tr>
            <tr>
              <td>REQUEST-PAYLOAD </td>
              <td>
                <SyntaxHighlighter language="javascript" style={a11yDark}>
                  {invokePaymentRequestPayload}
                </SyntaxHighlighter>
              </td>
            </tr>
            <tr>
              <td>RESPONSE-PAYLOAD </td>
              <td>
                <SyntaxHighlighter language="javascript" style={a11yDark}>
                  {invokePaymentResponsePayload}
                </SyntaxHighlighter>
              </td>
            </tr>
          </table>

          <br />
          <h3>Properties Description</h3>
          <table>
            <tr>
              <th>Properties</th>
              <th>Description</th>
              <th>Type</th>
              <th>Mandatory</th>
            </tr>

            <tr>
              <th>RequestHeader</th>
            </tr>
            <tr>
              <td>MerchantId</td>
              <td>This is the Merchant’s unique identifier.</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>TraceId</td>
              <td>
                This is the unique identifier of the request from the merchant’s
                end.
              </td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>ProcessorCode</td>
              <td>
                This is the institution code processing the transaction request
              </td>
              <td>String</td>
              <td>No</td>
            </tr>
            <tr>
              <td>Description</td>
              <td>This is the request description</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>Amount</td>
              <td>This is the amount of the Transaction </td>
              <td>Decimal</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>phoneNumber</td>
              <td>This is the phone number of the merchant for kuda payment</td>
              <td>String</td>
              <td>Only For Kuda Payment</td>
            </tr>
            <tr>
              <td>payId</td>
              <td>
                This is a unique code generated on kuda application for kuda
                payment
              </td>
              <td>String</td>
              <td>Only For Kuda Payment</td>
            </tr>

            <tr>
              <th>ResponseHeader</th>
            </tr>

            <tr>
              <td>ResponseCode</td>
              <td>This is the status code for the request.</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>ResponseMessage</td>
              <td>This is the status code message for the request.</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>ProcessorCode</td>
              <td>This is the code processing the processor</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>ProcessorName</td>
              <td>This is the name of the processor</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>TransactionId</td>
              <td>
                This is the unique identifier of the request generated by
                CoralPay. .
              </td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>ChannelTranId</td>
              <td>
                This is the unique identifier of the request generated by the
                Processor.
              </td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>ExpiryDate</td>
              <td>This is the Date when the payment link expires</td>
              <td>DateTime</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>PaymentLink</td>
              <td>This is the payment link generated for the transaction</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
          </table>
          <br />

          <h3>Transaction Query</h3>
          <p>
            This operation allows the merchant to query for a particular
            transaction.
          </p>
          <table>
            <tr>
              <td>URL</td>
              <td>
                <SyntaxHighlighter language="javascript" style={a11yDark}>
                  {`http://sandbox1.coralpay.com:8080/account-connect/api/payment/status`}
                </SyntaxHighlighter>
              </td>
            </tr>
            <tr>
              <td>VERB</td>
              <td>POST</td>
            </tr>
            <tr>
              <td>CONTENT-TYPE</td>
              <td>application/json</td>
            </tr>
            <tr>
              <td>AUTHENTICATION</td>
              <td>Basic authentication type.</td>
            </tr>
            <tr>
              <td>REQUEST-PAYLOAD </td>
              <td>
                <SyntaxHighlighter language="javascript" style={a11yDark}>
                  {transactionQueryRequestPayload}
                </SyntaxHighlighter>
              </td>
            </tr>
            <tr>
              <td>RESPONSE-PAYLOAD </td>
              <td>
                <SyntaxHighlighter language="javascript" style={a11yDark}>
                  {transactionQueryResponsePayload}
                </SyntaxHighlighter>
              </td>
            </tr>
          </table>

          <br />
          <h3>Properties Description</h3>
          <table>
            <tr>
              <th>Properties</th>
              <th>Description</th>
              <th>Type</th>
              <th>Mandatory</th>
            </tr>

            <tr>
              <th>RequestHeader</th>
            </tr>
            <tr>
              <td>MerchantId</td>
              <td>This is the Merchant’s unique identifier.</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>TraceId</td>
              <td>
                This is the unique identifier of the request from the merchant’s
                end.
              </td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>TransactionId</td>
              <td>
                This is the unique identifier of the request generated by
                CoralPay.
              </td>
              <td>String</td>
              <td>No</td>
            </tr>
            <tr>
              <td>Amount</td>
              <td>This is the amount of the Transaction </td>
              <td>Decimal</td>
              <td>Yes</td>
            </tr>

            <tr>
              <th>ResponseHeader</th>
            </tr>

            <tr>
              <td>ResponseCode</td>
              <td>This is the status code for the request.</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>ResponseMessage</td>
              <td>This is the status code message for the request.</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>MerchantId</td>
              <td>This is the Merchant’s unique identifier.</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>createdDate</td>
              <td>This is the date the transaction was created</td>
              <td>DateTime</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>PaymentDate</td>
              <td>This is the date the transaction was completed.</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>ProcessorCode</td>
              <td>The code of the processor</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>Description</td>
              <td>This is the request description</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
          </table>
          <br />

          <h3>Callback Payment Notification</h3>
          <p>
            This is the specification of the notification that is to be sent to
            the merchant on the completion of the payment, and the merchant is
            expected to build the endpoint accordingly.
          </p>
          <p>The endpoint would be whitelisted</p>

          {/* call back payment notification */}
          <table>
            <tr>
              <td>URL</td>
              <td>
                <SyntaxHighlighter language="javascript" style={a11yDark}>
                  {`To be provided by the merchant`}
                </SyntaxHighlighter>
              </td>
            </tr>
            <tr>
              <td>VERB</td>
              <td>POST</td>
            </tr>
            <tr>
              <td>CONTENT-TYPE</td>
              <td>application/json</td>
            </tr>
            {/* <tr>
              <td>AUTHENTICATION</td>
              <td>Bearer Token Authentication type.</td>
            </tr> */}
            <tr>
              <td>RESPONSE-PAYLOAD </td>
              <td>
                <SyntaxHighlighter language="javascript" style={a11yDark}>
                  {callBackResponsePayload}
                </SyntaxHighlighter>
              </td>
            </tr>
          </table>

          <br />

          <h3>Properties Description</h3>

          <br />

          <table>
            <tr>
              <th>Properties</th>
              <th>Description</th>
              <th>Type</th>
              <th>Mandatory</th>
            </tr>

            <tr>
              <th>RequestHeader</th>
            </tr>
            <tr>
              <td>MerchantId</td>
              <td>This is the Merchant’s unique identifier.</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>ChannelTranId</td>
              <td>
                This is the unique identifier of the transaction generated by
                the Processor.
              </td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>TraceId</td>
              <td>
                This is the unique identifier of the request from the merchant’s
                end.
              </td>
              <td>String</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>TransactionId</td>
              <td>
                This is the unique identifier of the request generated by
                CoralPay.
              </td>
              <td>String</td>
              <td>No</td>
            </tr>
            <tr>
              <td>TransactionAmount</td>
              <td>This is the amount of the Transaction.</td>
              <td>String</td>
              <td>No</td>
            </tr>
            <tr>
              <td>TransactionResponseCode</td>
              <td>This is the status code for the transaction.</td>
              <td>String</td>
              <td>No</td>
            </tr>
            <tr>
              <td>TransactionResponseMessage</td>
              <td>This is the status code message for the transaction.</td>
              <td>String</td>
              <td>No</td>
            </tr>
            <tr>
              <td>ProcessorCode</td>
              <td>The is the code of the processor.</td>
              <td>String</td>
              <td>No</td>
            </tr>
            <tr>
              <td>ProcessorName</td>
              <td>The name of the processor.</td>
              <td>String</td>
              <td>No</td>
            </tr>
            <tr>
              <td>SourceAccountName</td>
              <td>This is the name of the account of the customer</td>
              <td>String</td>
              <td>No</td>
            </tr>
            <tr>
              <td>SourceAccountNumber</td>
              <td>The is the account number of the customer</td>
              <td>String</td>
              <td>No</td>
            </tr>
            <tr>
              <td>Description</td>
              <td>This is the request description</td>
              <td>String</td>
              <td>Yes</td>
            </tr>
          </table>

          <br />

          <h3>Error codes</h3>

          <table>
            <th>Codes</th>
            <th>Description</th>
            <tr>
              <td>00</td>
              <td>Successful</td>
            </tr>
            <tr>
              <td>01</td>
              <td>
                Failed Transaction/Request: Description in the Response Message
              </td>
            </tr>
            <tr>
              <td>09</td>
              <td>Pending Transaction</td>
            </tr>
            <tr>
              <td>76</td>
              <td>Mismatch Transaction</td>
            </tr>
            <tr>
              <td>25</td>
              <td>Unable to locate record</td>
            </tr>
            {/* <tr>
              <td>54</td>
              <td>Expired Account Number</td>
            </tr> */}
          </table>

          {/* enddddd */}

          <Box sx={{ width: "100%", paddingTop: "2rem" }} ref={snippets}>
            <Grid2
              container
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Grid2>
                <Typography
                  sx={{
                    color: "#7f3d64",
                    fontSize: "1.2em",
                    fontWeight: "800",
                  }}
                >
                  Libraries & Code Snippets
                </Typography>
              </Grid2>
              <Grid2 sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button onClick={scrollToTop} sx={{ color: "#7f3d64" }}>
                  Back to the top{" "}
                  <KeyboardDoubleArrowUpIcon
                    sx={{ fontSize: 30, color: "#7f3d64" }}
                  />
                </Button>
              </Grid2>
            </Grid2>

            <Grid2
              container
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "1.5rem 0 1.5rem 0",
              }}
            >
              {/* <div style={{ marginRight: "1rem" }}>
                <button
                  style={{
                    border: javascript ? "none" : "1px solid #242424",
                    borderRadius: "10px",
                    fontWeight: 550,
                    padding: "1rem",
                    color: javascript ? "#fff" : "#242424",
                    backgroundColor: javascript ? "#242424" : "#fff",
                  }}
                  onClick={handleJavascriptContent}
                >
                  JAVASCRIPT
                </button>
              </div> */}
              <div style={{ marginRight: "1rem" }}>
                <button
                  style={{
                    border: dotnet ? "none" : "1px solid #242424",
                    borderRadius: "10px",
                    fontWeight: 550,
                    padding: "1rem",
                    color: dotnet ? "#fff" : "#242424",
                    backgroundColor: dotnet ? "#242424" : "#fff",
                  }}
                  onClick={handleDotnetContent}
                >
                  .NET
                </button>
              </div>
              {/* <div>
                <button
                  style={{
                    border: ceeSharp ? "none" : "1px solid #242424",
                    borderRadius: "10px",
                    fontWeight: 550,
                    padding: "1rem",
                    color: ceeSharp ? "#fff" : "#242424",
                    backgroundColor: ceeSharp ? "#242424" : "#fff",
                  }}
                  onClick={handleCeeSharpContent}
                >
                  JAVA
                </button>
              </div> */}
            </Grid2>

            <div>
              {/* {javascript && <JavascriptContent />} */}
              <DotNetContent />
              {/* {ceeSharp && <JavaContent />} */}
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default AccountConnectDocumentation;
