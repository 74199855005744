import React from "react";
import two from "../../assets/two.png"
import Subscribe from "../../components/Subscribe";
import {Link} from "react-router-dom"
import manwithphone from "../../assets/manwithphone.png"
import learnMore from "../../assets/learnmore.svg";

const PayWithTransfer = () => {
    return (
        <div>
            <div className="about-header" style={{backgroundImage:`url(${two})`}}>
                <h3>Pay With Bank <span style={{color:'#F7EF8A'}}>Transfer</span></h3>
            </div>
            <br /><br /><br />
            <div className="product">
                <div className="product-one">
                    <div className="accordion" id="accordionExample">
                        <Link to="/coralpay-payment-gateway" >
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingOne">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Coralpay Payment Gateway
                                    </button>
                                </h2>
                            </div>
                        </Link>
                        <Link to="/cgateussd">
                           <div className="accordion-item mb-3">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    C'Gate USSD
                                </button>
                            </h2>
                        </div></Link>
                    <Link to="/transaction-switching-and-processing">
                        <div className="accordion-item mb-3">
                            <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseTwo(null)" aria-expanded="false" aria-controls="collapseTwo">
                                    Transaction Switching & Processing
                                </button>
                            </h2>
                        </div></Link>
                        <div className="accordion-item mb-3">
                            <h2 className="accordion-header" id="headingThree">
                                <button className="accordion-button dropdown-btn" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree" aria-expanded="false"
                                        aria-controls="collapseThree">
                                    Pay With Bank Transfer
                                </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse show"
                                 aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className="biller-one-option-mobile">
                                        <div className="biller-one-app-option" style={{backgroundImage:`url(${manwithphone})`}}></div>
                                    </div>
                                    Pay with Bank Transfer is a secure way to make payment with account number,
                                    which ensures that best practices are observed while making payments through bank transfers.
                                    Merchant generates a unique dynamic or Static Nigerian Uniform Account Number (Nuban),
                                    which the customer pays into with internet banking, USSD or mobile app.
                                    CoralPay updates the merchant real-time on status of transfer, and the merchant responds to the customer accordingly.
                                    <br /><br />
                                    <br /><br />
                                    <a href="https://blog.coralpay.com/the-payment-options-that-will-keep-your-business-ahead/"><img src={learnMore} alt='learn more btn'/></a>
                                </div>
                            </div>
                        </div>
                    <Link to="/nqr">
                        <div className="accordion-item mb-3">
                            <h2 className="accordion-header" id="headingThree">
                                <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree(null)" aria-expanded="false"
                                        aria-controls="collapseThree">
                                    NQR
                                </button>
                            </h2>
                        </div>
                    </Link>
                        <Link to="/digipos">
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree">
                                        DigiPOS
                                    </button>
                                </h2>
                            </div>
                        </Link>
                        <Link to="/billers-aggregation-platform">
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree">
                                        Billers Aggregation Platform
                                    </button>
                                </h2>
                            </div>
                        </Link>
                        <Link to="/finpay">
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree">
                                        FinPay
                                    </button>
                                </h2>
                            </div>
                        </Link>
                        <Link to="/payment-link">
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree">
                                        Payment Link
                                    </button>
                                </h2>
                            </div>
                        </Link>
                        <Link to="/instant-payment">
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree">
                                        Coralpay Instant Payment
                                    </button>
                                </h2>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="biller-one">
                    <div className="biller-one-app" style={{backgroundImage:`url(${manwithphone})`}}></div>
                </div>
            </div>
            <br /><br />
            <Subscribe />
        </div>
    )
}

export default PayWithTransfer