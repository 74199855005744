import React from "react";
import paymentcard from "../../assets/cardpayment.png"
import cardpaymentposter from "../../assets/coralpaypayment2.png"
import Subscribe from "../../components/Subscribe";
import {Link} from "react-router-dom"
import learnMore from "../../assets/learnmore.svg";

const TransactionSwitching = () => {
    return (
        <div>
            <div className="about-header" style={{backgroundImage:`url(${paymentcard})`}}>
                <h3>Transaction Switching <span style={{color:'#F7EF8A'}}>& Processing</span></h3>
            </div>
            <br /><br /><br />
            <div className="product">
                <div className="product-one">
                    <div className="accordion" id="accordionExample">
                        <Link to="/coralpay-payment-gateway" >
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingOne">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Coralpay Payment Gateway
                                    </button>
                                </h2>
                            </div>
                        </Link>
                        <Link to="/cgateussd">
                          <div className="accordion-item mb-3">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    C'Gate USSD
                                </button>
                            </h2>
                        </div></Link>
                        <div className="accordion-item mb-3">
                            <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button dropdown-btn" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    Transaction Switching & Processing
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse show" aria-labelledby="headingTwo"
                                 data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className="biller-one-option-mobile">
                                        <div className="biller-one-app-option" style={{backgroundImage:`url(${cardpaymentposter})`}}></div>
                                    </div>
                                    We offer transaction switching and processing services to financial institutions,
                                    mobile money operators and other relevant partners. Our switching and processing services cut across various channels including Cards, USSD etc.
                                    We are a certified Third-party processor (TPP) for MasterCard and Visa in Nigeria and Africa.
                                    Our status as a switch and a PSSP ensures a more enhanced transaction life cycle,
                                    by reducing the number of participants involved in a transaction, thereby improving efficiency, revenue growth, and reducing transaction cost.
                                    <br /><br />
                                    <br /><br />
                                    <a href="https://blog.coralpay.com/the-payment-options-that-will-keep-your-business-ahead/"><img src={learnMore} alt='learn more btn'/></a>
                                </div>
                            </div>
                        </div>
                       <Link to="/payment-with-transfer">
                           <div className="accordion-item mb-3">
                            <h2 className="accordion-header" id="headingThree">
                                <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree(null)" aria-expanded="false"
                                        aria-controls="collapseThree">
                                    Pay With Bank Transfer
                                </button>
                            </h2>
                        </div></Link>
                       <Link to="/nqr">
                           <div className="accordion-item mb-3">
                            <h2 className="accordion-header" id="headingThree">
                                <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree(null)" aria-expanded="false"
                                        aria-controls="collapseThree">
                                    NQR
                                </button>
                            </h2>
                        </div>
                       </Link>
                        <Link to="/digipos">
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree">
                                        DigiPOS
                                    </button>
                                </h2>
                            </div>
                        </Link>
                        <Link to="/billers-aggregation-platform">
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree">
                                        Billers Aggregation Platform
                                    </button>
                                </h2>
                            </div>
                        </Link>
                        <Link to="/finpay">
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree">
                                        FinPay
                                    </button>
                                </h2>
                            </div>
                        </Link>
                        <Link to="/payment-link">
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree">
                                        Payment Link
                                    </button>
                                </h2>
                            </div>
                        </Link>
                        <Link to="/instant-payment">
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree">
                                        Coralpay Instant Payment
                                    </button>
                                </h2>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="biller-one-option-desk">
                    <div className="biller-one-app-option" style={{backgroundImage:`url(${cardpaymentposter})`}}></div>
                </div>
            </div>
            <br /><br />
            <Subscribe />
        </div>
    )
}

export default TransactionSwitching