export const initializeConnectLiteClass = `
    private readonly HttpClient _client;
    public readonly string _baseUrl;
    public readonly string _token;

    public CconnectLiteService (string baseUrl, string token = null)
    {
        _client = new HttpClient();
        _baseUrl = baseUrl;
        _client.DefaultRequestHeaders.Clear();
        _client.DefaultRequestHeaders.Authorization = new System.Net.Http.Headers.AuthenticationHeaderValue("Bearer", token);
    }
`;
export const authenticationRequestStructure = `
public class AuthenticationRequest
{
    public string username { get; set; }
    public string password { get; set; }
    public string terminalId { get; set; }
}

`;
export const authenticationResponseStructure = `
public class AuthenticationResponse
{
    public string token { get; set; }
    public string key { get; set; }
    public string status { get; set; }
}

`;

export const baseUrlText = `
const baseUrl = "https://testdev.coralpay.com:5000/GwApi"; //replace with the actual verge base URL
`;

export const authenticateCode = `
    public async Task<AuthenticationResponse> Authentication(AuthenticationRequest request)
    {

        var jsonPayload = JsonConvert.SerializeObject(request);

        var HttpResponse = await _client.PostAsync($"{_baseUrl}/api/v2/authentication", new StringContent(jsonPayload, Encoding.UTF8, "application/json"));

        if (HttpResponse.IsSuccessStatusCode)
        {

            return JsonConvert.DeserializeObject<AuthenticationResponse>(await HttpResponse.Content.ReadAsStringAsync());
          
        }

        else
        {
            return new AuthenticationResponse
            {
                status = $"Http response:{HttpResponse.StatusCode} | {HttpResponse.ReasonPhrase}"
            };
        }

    }
`;

export const invokePaymentRequest = `
public class PaymentRequest
{
    public RequestHeader requestHeader { get; set; }
    public Customer customer { get; set; }
    public Customization customization { get; set; }
    public object metaData { get; set; }
    public string traceId { get; set; }
    public string productId { get; set; }
    public decimal amount { get; set; }
    public string currency { get; set; }
    public string feeBearer { get; set; }
    public string returnUrl { get; set; }
    public Settlement settlement { get; set; }
}

`;

export const invokePaymentFunction = `
    public async Task<PaymentResponse> InvokeReference(PaymentRequest request)
    {
        var jsonPayload = JsonConvert.SerializeObject(request);

        var HttpResponse = await _client.PostAsync($"{_baseUrl}/api/v2/invokereference", new StringContent(jsonPayload, Encoding.UTF8, "application/json"));

        if (HttpResponse.IsSuccessStatusCode)
        {
            return JsonConvert.DeserializeObject<PaymentResponse>(await HttpResponse.Content.ReadAsStringAsync());
        }

        else
        {
            return new PaymentResponse
            {
                responseHeader = new ResponseHeader { responseCode = "96", responseMessage = HttpResponse.ReasonPhrase }
            };
        }

    }
`;

export const sampleResponse = `
const response= {
"token": "your_auth_token",
"key": "your_encryption_key",
"status": "Success"
}
`;

export const sampleInvokePaymentResponse = `
const response = {
"responseHeader": {
"responseCode": "00",
"responseMessage": "Success",
"timeStamp": 1609459200,
"signature": "response_signature"
},
"transactionId": "transaction_id",
"traceId": "trace_id",
"payPageLink": "https://pay.verge.com/payment-page",
"metaData": {}
}
`;

export const sampleRequestForTransactionQuery = `
public class TransactionQueryRequest
{
    public RequestHeader requestHeader { get; set; }
    public string traceId { get; set; }
}
`;

export const transactionQueryFunction = `
    public async Task<TransactionQueryResponse> TransactionQuery(TransactionQueryRequest request)
    {
        var jsonPayload = JsonConvert.SerializeObject(request);

        var HttpResponse = await _client.PostAsync($"{_baseUrl}/api/v2/statusquery", new StringContent(jsonPayload, Encoding.UTF8, "application/json"));

        if (HttpResponse.IsSuccessStatusCode)
        {
            return JsonConvert.DeserializeObject<TransactionQueryResponse>(await HttpResponse.Content.ReadAsStringAsync());
        }

        else
        {
            return new TransactionQueryResponse
            {
               responseCode = $"{HttpResponse.StatusCode}", responseMessage= HttpResponse.ReasonPhrase
            };
        }
    }
`;

export const tsqSampleResponsePayload = `
const response = {
"responseCode": "00",
"responseMessage": "Transaction successful",
"amount": 100.00,
"currency": "USD",
"traceId": "trace_id",
"transactionId": "transaction_id",
"refunds": []
}
`;

export const sampleRequestForGetAccountDetails = `
        // Create an instance of GetAccountRequest
        var getAccountRequest = new GetAccountRequest
        {
            requestHeader = new RequestHeader
            {
            merchantId = "123456",
            terminalId = "78910",
            timeStamp = signatureResponse.timeStamp,
            signature = signatureResponse.signature 
           }
           traceId = Guid.NewGuid().ToString() // Generates a unique trace ID
        };
`;

export const getAccountDetailsFunction = `
SignatureResponse signatureResponse = vergeService.GetSignature(new SignatureRequest
{
    merchantId = "your_merchant_id",
    traceId = "unique_trace_id",
    key = "your_secret_key",
    shaType = SHA.SHA256
});

GetAccountRequest accountRequest = new GetAccountRequest
{
    requestHeader = new RequestHeader
    {
        merchantId = "your_merchant_id",
        terminalId = "your_terminal_id",
        timeStamp = signatureResponse.timeStamp,  // Use generated timestamp
        signature = signatureResponse.signature   // Use generated signature
    },
    traceId = "unique_trace_id"
};

GetAccountResponse accountResponse = await vergeService.GetAccountDetails(accountRequest);

if (accountResponse.responseCode == "00")
{
    Console.WriteLine("Account details retrieved successfully");
}
else
{
    Console.WriteLine($"Error: {accountResponse.responseMessage}");
}
`;

export const accountDetailsResponse = `
const response = {
"accountDetails": [
{
    "bankCode": "123",
    "accountNumber": "1234567890",
    "accountId": 22
},
{
    "bankCode": "456",
    "accountNumber": "1000002001",
    "accountId": 33
}
],
"traceId": "trace_id",
"timeStamp": 1726751419,
"signature": "signature",
"responseCode": "00",
"responseMessage": "Successful"
}
`;

export const sampleRequestForSignatureGeneration = `
public class SignatureRequest
{
    public string merchantId { get; set; }
    public string traceId { get; set; }
    public string key { get; set; }
    public SHA shaType { get; set; } // SHA256
}

`;

export const signatureGenerationFunction = `
    public GetSignatureResponse GetSignature(GetSignatureRequest request)
    {
        long timestamp = ((DateTimeOffset)DateTime.UtcNow).ToUnixTimeSeconds();

        string value = request.MerchantId + request.TerminalId + timestamp + request.Key;

        using HashAlgorithm hashAlgorithm = request.shaType == SHA.SHA512 ? SHA512.Create() : SHA256.Create();

        string signature = string.Concat(hashAlgorithm.ComputeHash(Encoding.UTF8.GetBytes(value)).Select(item => item.ToString("x2")));

        return new GetSignatureResponse { timestamp = timestamp, signature = signature };

    }
`;

export const sampleSignatureResponse = `
const response = {
"timeStamp": 1609459200,
"signature": "generated_signature"
}
`;

export const sampleErrorHandling = `
PaymentResponse paymentResponse = await vergeService.InvokePayment(paymentRequest);

if (paymentResponse.responseHeader.responseCode != "00")
{
    Console.WriteLine($"Error: {paymentResponse.responseHeader.responseMessage}");
}
else
{
    Console.WriteLine("Payment initiated successfully");
}

`;

export const refundFunction = `

    public async Task<RefundResponse> Refund(RefundRequest request)
    {
        var jsonPayload = JsonConvert.SerializeObject(request);

        var HttpResponse = await _client.PostAsync($"{_baseUrl}/api/v2/refund", new StringContent(jsonPayload, Encoding.UTF8, "application/json"));

        if (HttpResponse.IsSuccessStatusCode)
        {
            return JsonConvert.DeserializeObject<RefundResponse>(await HttpResponse.Content.ReadAsStringAsync());
        }

        else
        {
            return new RefundResponse
            {
                responseHeader = new ResponseHeader { responseCode = "96", responseMessage = HttpResponse.ReasonPhrase }
            };
        }
    }
`;

export const getBanksFunction = `
    public async Task<List<GetBanksResponse>> GetBanks()
    {

        var HttpResponse = await _client.GetAsync($"{_baseUrl}/api/v2/getbanks");

        if (HttpResponse.IsSuccessStatusCode)
        {
            return JsonConvert.DeserializeObject<List<GetBanksResponse>>(await HttpResponse.Content.ReadAsStringAsync());
        }

        else
        {
            return new List<GetBanksResponse>
            { };
        }
    }

`;
