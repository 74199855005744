import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import {
  darcula,
  a11yDark,
} from "react-syntax-highlighter/dist/esm/styles/prism";
import {
  authenticateCode,
  getBanksFunction,
  initializeConnectLiteClass,
  invokePaymentFunction,
  refundFunction,
  signatureGenerationFunction,
  transactionQueryFunction,
} from "./snytax";

const DotnetContent = () => {
  const customTextStyle = {
    fontFamily: "monospace",
    background: "#efefef",
    padding: "0.3rem",
    fontSize: "0.8em",
  };

  const newHeaderClass = {
    fontWeight: "600",
    fontSize: "1.2em",
    color: "#7F3D64",
  };
  const newHeaderClass2 = {
    fontWeight: "600",
    // fontSize: "1.2em",
    color: "#7F3D64",
  };
  return (
    <>
      <h3 style={newHeaderClass}>Summary</h3>

      <p>
        This is a dotnet library for C'Gate USSD (CconnectLite) and it contains
        all required models. This library would assist to integrate in CoralPay
        CconnectLite service
      </p>

      <ul>
        <li>
          1. <b>baserUrl: </b>This is the baseUrl for CoralPay CconnectLite
          service.
        </li>
        <li>
          2. <b>token:</b> This is a bearer token recieved from the
          authentication endpoint
        </li>
      </ul>

      <div style={{ padding: "1rem 0 1rem 0" }}>
        <h3 style={newHeaderClass}>1. Setting Up ConnectLite Services</h3>
      </div>

      <div style={{ padding: "1rem 0 1rem 0" }}>
        <h5 style={newHeaderClass2}>1.1 Installation</h5>
        <p style={{ padding: "1rem 0 1rem 0" }}>
          The ConnectLite Services class provides the core functionality for
          interacting with the ConnectLite API.
        </p>

        <h5 style={newHeaderClass2}>1.2 Initializing ConnectLite Class</h5>
        <p style={{ padding: "1rem 0 1rem 0" }}>
          You can initialize the ConnectLite services class by passing the base
          URL of the ConnectLite API and an authentication token.
        </p>
        <SyntaxHighlighter language="javascript" style={a11yDark}>
          {initializeConnectLiteClass}
        </SyntaxHighlighter>
      </div>

      <h3 style={newHeaderClass}>2. Authentication</h3>
      <br />
      <p>
        This operation is called using the credentials provided by CORALPAY. It
        is adviced to call this operation less often (maybe once per day)
      </p>
      <br />

      <h5 style={newHeaderClass2}>2.1 Calling the Authentication Service.</h5>
      <br />
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {authenticateCode}
      </SyntaxHighlighter>
      <br />

      <h3 style={newHeaderClass}>3. InvokeReference</h3>
      <br />

      <p>This operation is used to generate the payment reference code</p>
      <br />

      <h5 style={newHeaderClass2}>3.1 Calling the InvokeReference Service.</h5>
      <br />
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {invokePaymentFunction}
      </SyntaxHighlighter>
      <br />

      <h3 style={newHeaderClass}>4. TransactionQuery</h3>
      <br />

      <p>This operation is used to get details of a particular transaction.</p>
      <br />
      <h5 style={newHeaderClass2}>4.1 Calling the TransactionQuery Service.</h5>
      <br />
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {transactionQueryFunction}
      </SyntaxHighlighter>
      <br />

      <h3 style={newHeaderClass}>5. Refund</h3>
      <br />

      <p>This operation is used to refund a previous successful transaction.</p>
      <br />
      <h5 style={newHeaderClass2}>5.1 Calling the Refund Service.</h5>
      <br />
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {refundFunction}
      </SyntaxHighlighter>
      <br />

      <h3 style={newHeaderClass}>6. GetBanks</h3>
      <br/>

      <p>This operation is used to get the list of banks.</p>
      <br/>

      <h5 style={newHeaderClass2}>6.1 Calling the GetBanks Service.</h5>
      <br />
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {getBanksFunction}
      </SyntaxHighlighter>
      <br />

      <h3 style={newHeaderClass}>7. GetSignature</h3>
      <br/>

      <p>This is a method to calculate signature.</p>
      <br/>

      <h5 style={newHeaderClass2}>7.1 Calling the GetBanks Service.</h5>
      <br />
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {signatureGenerationFunction}
      </SyntaxHighlighter>
      <br />
    </>
  );
};

export default DotnetContent;
