import React from "react"
import {Link, NavLink} from "react-router-dom";

const DocumentationLink = () => {
    return(
        <div className="menu">
            <div className="menu-nav">
                <ul>
                    <li><a href="/developer">Home</a></li>
                    <li><NavLink to="/developer/docs/account-number" activeClassName="active">Account Number</NavLink></li>
                    <li><NavLink to="/developer/docs/payment-notification" activeClassName="active">Payment Notification</NavLink></li>
                    <li><NavLink to="/developer/docs/transaction-query" activeClassName="active">Transaction Query</NavLink></li>
                    <li><NavLink to="/developer/docs/response-code" activeClassName="active">Response Codes</NavLink></li>
                </ul>
            </div>
        </div>
    )
}

export default DocumentationLink