import { Box } from "@mui/material";
import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import {
  darcula,
  a11yDark,
} from "react-syntax-highlighter/dist/esm/styles/prism";
import {
  authenticateCode,
  bankLinkCode,
  createStaticAccountFunction,
  decryption,
  decryptionFunction,
  encryptFunction,
  initializeVergClass,
  invokePaymentFunction,
  nameEnquiry,
  postCredit,
  staticTransactionQuery,
  transactionQueryFunction,
} from "./syntax";

const DotNetContent = () => {
  return (
    <>
      <h3>Prerequisites</h3>
      <ul>
        <li>1. .NET environment (e.g., .NET Core or .NET Framework).</li>
        <li>2. Access to CIP API credentials. username, and password.</li>
        <li>3. Familiarity with HTTP requests and C#.</li>
      </ul>
      <div style={{ padding: "1rem 0 1rem 0" }}>
        <hr />
      </div>

      <div style={{ padding: "1rem 0 1rem 0" }}>
        <h3
          style={{
            fontWeight: "600",
            fontSize: "1.2em",
            color: "#7F3D64",
          }}
        >
          1. Setting Up CIP Services
        </h3>
      </div>
      <div style={{ padding: "1rem 0 1rem 0" }}>
        <h3
          style={{
            fontWeight: "600",
            fontSize: "1.2em",
            color: "#7F3D64",
          }}
        >
          1.1 Installation
        </h3>
        <p style={{ padding: "1rem 0 1rem 0" }}>
          The CIP class provides the core functionality for interacting with the
          CIP API.
        </p>

        <h3
          style={{
            fontWeight: "600",
            fontSize: "1.2em",
            color: "#7F3D64",
          }}
        >
          1.2 Initializing CIP Services Class
        </h3>
        <p style={{ padding: "1rem 0 1rem 0" }}>
          You can initialize the CIP Services class by passing the base URL of
          the CIP API and an authentication token.
        </p>
        <p>
          This is a Library for CoralPay InterBank Service. This library helps
          with the encryption of request, sending of request the encrypted
          request and also decrypting the encrypted response gotten back from
          Cip.
        </p>
        <ul>
          <li>
            <b>baseUrl:</b> This is CIP baseUrl either for test or production
          </li>
          <li>
            <b>providerPubKey:</b> This is CIP public key
          </li>
          <li>
            <b>clientPrivateKey:</b> This is the private key of the institution
            integrating the service
          </li>
          <li>
            <b>clientKeyPassword:</b> This is the key password (Inputed during
            key generation) of the institution integrating the service
          </li>
          <li>
            <b>secretKey:</b> This is the secretKey provided by CoralPay to the
            institution integrating the service
          </li>
          <li>
            <b>clientPublicKey:</b> This is the public key of the institution
            integrating the service
          </li>
        </ul>
        <SyntaxHighlighter language="javascript" style={a11yDark}>
          {initializeVergClass}
        </SyntaxHighlighter>
      </div>

      <h3
        style={{
          fontWeight: "600",
          fontSize: "1.2em",
          color: "#7F3D64",
        }}
      >
        2. Authentication
      </h3>
      <br />
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {authenticateCode}
      </SyntaxHighlighter>
      <br />
      <h3
        style={{
          fontWeight: "600",
          fontSize: "1.2em",
          color: "#7F3D64",
        }}
      >
        3. Name Enquiry
      </h3>
      <br />
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {nameEnquiry}
      </SyntaxHighlighter>
      <br />
      <h3
        style={{
          fontWeight: "600",
          fontSize: "1.2em",
          color: "#7F3D64",
        }}
      >
        4. Post Credit
      </h3>
      <br />
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {postCredit}
      </SyntaxHighlighter>
      <br />
      <h3
        style={{
          fontWeight: "600",
          fontSize: "1.2em",
          color: "#7F3D64",
        }}
      >
        4. Transaction Query
      </h3>
      <br />
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {transactionQueryFunction}
      </SyntaxHighlighter>
      <br />
      <h3
        style={{
          fontWeight: "600",
          fontSize: "1.2em",
          color: "#7F3D64",
        }}
      >
        4. Encryption{" "}
      </h3>
      <br />
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {encryptFunction}
      </SyntaxHighlighter>

      <h3
        style={{
          fontWeight: "600",
          fontSize: "1.2em",
          color: "#7F3D64",
        }}
      >
        4. Decryption{" "}
      </h3>
      <br />
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {decryptionFunction}
      </SyntaxHighlighter>
    </>
  );
};

export default DotNetContent;
