import React from "react";
import Subscribe from "../components/Subscribe";
import global from "../assets/global.png"
import people from  "../assets/people.png"
import whychoosecoral from "../assets/whychoosecoral.svg"


const AboutScreen = () => {
    return (
        <div>
            <div className="about-header" style={{backgroundImage:`url(${global})`}}>
                <h3>A Global Reach With Local <span style={{color:'#F7EF8A'}}>Perspective</span></h3>
            </div>

            <div className="about-inner">
                <br/>
                <h3>What We Do At CoralPay</h3>
                <br />
                <p>
                CoralPay was incorporated in 2005 but licensed as a Payment Switching and Processing company in 2018. As part of our renewed service focus, we are poised to offer a broad range of market-reliable and secure proprietary technology platforms that provide a range of payment-centric services and solutions across
                    <br />
                    multiple channels.
                    <br />
                    Our Vision is to become Africa's Gold Standard for Payment and Processing.
                    <br /><br />
                    Our Mission is to build a forward-looking, customer-centred company committed to providing payment services and solutions to our customers through excellence, innovation, strategic partnerships, and the recruitment and retention of exceptional people.
                    <br /><br />
                    Our goal is to become the preferred payment processing partner of choice for consumers, merchants and institutions of all sizes and sectors with our cost-effective, secure payment services and solutions.
                    CoralPay continues to be driven by an experienced team of Nigerian professionals with combined experience of over 100 years in the Nigeria digital payments space.
                    <br></br>
                </p>
                <h6> <br></br></h6>
                
                <div className="about-inner-img" style={{backgroundImage:`url(${people})`}}></div><br />
                <h3>Why Choose CoralPay</h3><br ></br>
                <div style={{display:'flex', justifyContent:'center'}}>
                    <div className="why-choose-coralpay">
                        <img src={whychoosecoral} alt="learn more"/>
                    </div>
                </div>
                <br/>
            </div>

        <Subscribe />
        </div>
    )
}

export default AboutScreen