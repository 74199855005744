import React from "react";
import Subscribe from "../components/Subscribe";
import guy from "../assets/guy.svg";
import "../App.css"
import Card2 from "../components/Card2";
import search from "../assets/icons/search.svg";


const BlogScreen = () => {
    return(
        <div>
            <div className="blog-header" style={{backgroundImage:`url(${guy})`}}>
                <div className="blog-wrapper-overlay blog">
                    <h3>Get The <span style={{color:'#F7EF8A'}}>Best</span> From The <span style={{color:'#F7EF8A'}}>Blog</span></h3>
                </div>
            </div>
            <br /><br />

            <form className="form">
                <div style={{display:'flex', width:'100%',justifyContent:'center'}}>
                    <img src={search} alt='email' style={{transform:'translate(250%, 10%)'}}/>
                    <input type="text" className="subscribe-input"  style={{paddingLeft:'80px'}}  placeholder="Type something to search"/>
                </div>
            </form>
            <br /><br />
            <h3 className="recentpost">Recent Posts</h3>
            <div className="section-card2">
                <div className="section-card-inner2">
                     <Card2 />
                </div>
            </div>
            <br />
            <Subscribe />
        </div>
    )
}

export default BlogScreen