import { Box } from "@mui/material";
import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import {
  darcula,
  a11yDark,
} from "react-syntax-highlighter/dist/esm/styles/prism";
import {
  authenticateCode,
  authenticationRequestStructure,
  authenticationResponseStructure,
  bankLinkCode,
  batchPostRequestStructure,
  batchPostResponseStructure,
  callGetProcessorServices,
  createStaticAccountFunction,
  generatePaymentLink,
  getProcessorResponseStructure,
  initializeVergClass,
  invokePaymentFunction,
  nameEnquiryRequestStructure,
  nameEnquiryResponseStructure,
  paymentRequestStructure,
  paymentResponseStructure,
  paymentStructure,
  responseHeader,
  sampleRequestForSignatureGeneration,
  sampleRequestForTransactionQuery,
  sampleResponseForSignatureGeneration,
  sampleResponseForTransactionQuery,
  // singlePostRequestStructure,
  singlePostResponseStructure,
  staticTransactionQuery,
  transactionQuery,
  transactionQueryFunction,
  transactionQueryRequestStructure,
  transactionQueryResponseStructure,
} from "./syntax";
// import { singlePost } from "./syntax";
import { batchPost } from "./syntax";
import { nameEnquiry } from "./syntax";
import { signatureGenerationFunction } from "./syntax";

const DotNetContent = () => {
  const customTextStyle = {
    fontFamily: "monospace",
    background: "#efefef",
    padding: "0.3rem",
    fontSize: "0.8em",
  };
  return (
    <>
      <h3
        style={{
          fontWeight: "600",
          fontSize: "1.2em",
          color: "#7F3D64",
        }}
      >
        Overview
      </h3>
      <p>
        This document provides step-by-step guidance to easily integrate
        AccountConnect service using the C# library. The guide covers get
        processors, generating PaymentLink and TransactionQuery.
      </p>
      <br />
      <h3
        style={{
          fontWeight: "600",
          fontSize: "1.2em",
          color: "#7F3D64",
        }}
      >
        Prerequisites
      </h3>
      <ul>
        <li>1. .NET environment (e.g., .NET Core or .NET Framework).</li>
        <li>
          2. Access to AccountConnect API credentials, including{" "}
          <span style={customTextStyle}>merchantId</span> and{" "}
          <span style={customTextStyle}>secretKey</span> for basic
          authentication scheme.
        </li>
        <li>3. Familiarity with HTTP requests and C#.</li>
      </ul>
      <div style={{ padding: "1rem 0 1rem 0" }}>
        <hr />
      </div>

      <div style={{ padding: "1rem 0 1rem 0" }}>
        <h3
          style={{
            fontWeight: "600",
            fontSize: "1.2em",
            color: "#7F3D64",
          }}
        >
          1. Setting Up AccountConnect Services Class
        </h3>
      </div>
      <div style={{ padding: "1rem 0 1rem 0" }}>
        <h5
          style={{
            fontWeight: "600",
            // fontSize: "1em",
            color: "#7F3D64",
          }}
        >
          1.1 Installation
        </h5>
        <p style={{ padding: "1rem 0 1rem 0" }}>
          <span style={customTextStyle}>AccountConnectServices</span> class
          provides the core functionality for interacting with AccountConnect
          API.
        </p>

        <h5
          style={{
            fontWeight: "600",
            fontSize: "1em",
            color: "#7F3D64",
          }}
        >
          1.2 Initializing{" "}
          <span style={customTextStyle}>AccountConnectServices</span> Class
        </h5>
        <p style={{ padding: "1rem 0 1rem 0" }}>
          You can initialize the{" "}
          <span style={customTextStyle}>AccountConnectServices</span> class by
          passing the base URL of the AccountConnect API and and credentials
          provided by CoralPay.
        </p>
        <SyntaxHighlighter language="javascript" style={a11yDark}>
          {initializeVergClass}
        </SyntaxHighlighter>

        {/* <h5
          style={{
            fontWeight: "600",
            fontSize: "1em",
            color: "#7F3D64",
            padding: "1rem 0 1rem 0",
          }}
        >
          1.3 Response Header Class
        </h5>
        <p style={{ padding: "1rem 0 1rem 0" }}>
          This class captures the status of the action performed by various
          methods in this library. It is used to maintain a consistent
          representation of statuses and their corresponding messages.
        </p>
        <SyntaxHighlighter language="javascript" style={a11yDark}>
          {responseHeader}
        </SyntaxHighlighter> */}
      </div>

      <h3
        style={{
          fontWeight: "600",
          fontSize: "1.2em",
          color: "#7F3D64",
        }}
      >
        2. Get Processors
      </h3>
      <br />
      <p style={{ padding: "1rem 0 1rem 0" }}>
        This operation retrieves the list of available active processors.
      </p>
      <h5
        style={{
          fontWeight: "600",
          // fontSize: "1em",
          color: "#7F3D64",
        }}
      >
        2.1 GetProcessorResponse Structure
      </h5>
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {getProcessorResponseStructure}
      </SyntaxHighlighter>
      <br />
      <h5
        style={{
          fontWeight: "600",
          // fontSize: "1em",
          color: "#7F3D64",
        }}
      >
        2.2 Calling the GetProcessor Service
      </h5>
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {callGetProcessorServices}
      </SyntaxHighlighter>
      <br />
      <h3
        style={{
          fontWeight: "600",
          fontSize: "1.2em",
          color: "#7F3D64",
        }}
      >
        3. Invoke Payment Link
      </h3>
      <p style={{ padding: "1rem 0 1rem 0" }}>
        This operation is responsible for generating a payment link for instant
        online payment.
      </p>
      <h5
        style={{
          fontWeight: "600",
          // fontSize: "1em",
          color: "#7F3D64",
        }}
      >
        3.1 PaymentRequest Structure
      </h5>
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {paymentRequestStructure}
      </SyntaxHighlighter>
      <br />

      <h5
        style={{
          fontWeight: "600",
          // fontSize: "1em",
          color: "#7F3D64",
        }}
      >
        3.2 PaymentResponse Structure
      </h5>
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {paymentResponseStructure}
      </SyntaxHighlighter>
      <br />

      <h5
        style={{
          fontWeight: "600",
          // fontSize: "1em",
          color: "#7F3D64",
        }}
      >
        3.3 Example of Generating a Payment Link
      </h5>
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {generatePaymentLink}
      </SyntaxHighlighter>
      <br />
      <h3
        style={{
          fontWeight: "600",
          fontSize: "1.2em",
          color: "#7F3D64",
        }}
      >
        4. Querying Transactions
      </h3>
      <br />
      <p>
        This operation allows the merchant to query for a particular
        transaction.
      </p>
      <br />
      <h5
        style={{
          fontWeight: "600",
          // fontSize: "1em",
          color: "#7F3D64",
        }}
      >
        4.1 Transaction Query Request Structure
      </h5>
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {transactionQueryRequestStructure}
      </SyntaxHighlighter>
      <br />

      <h5
        style={{
          fontWeight: "600",
          // fontSize: "1em",
          color: "#7F3D64",
        }}
      >
        4.2 Transaction Query Response Structure
      </h5>
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {transactionQueryResponseStructure}
      </SyntaxHighlighter>
      <br />
      <h5
        style={{
          fontWeight: "600",
          // fontSize: "1em",
          color: "#7F3D64",
        }}
      >
        4.3 Example of Making a Transaction Query
      </h5>
      <SyntaxHighlighter language="javascript" style={a11yDark}>
        {transactionQuery}
      </SyntaxHighlighter>
      <br />
    </>
  );
};

export default DotNetContent;
