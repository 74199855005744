import React from "react";
import qr from "../../assets/NQRAmination.gif"
import qrWall from "../../assets/qrwall.png"
import Subscribe from "../../components/Subscribe";
import {Link} from "react-router-dom"
import learnMore from "../../assets/learnmore.svg";

const Nqr = () => {
    return (
        <div>
            <div className="about-header" style={{backgroundImage:`url(${qrWall})`}}>
                <h3>NQR</h3>
            </div>
            <br /><br /><br />
            <div className="product">
                <div className="product-one">
                    <div className="accordion" id="accordionExample">
                        <Link to="/coralpay-payment-gateway" >
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingOne">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Coralpay Payment Gateway
                                    </button>
                                </h2>
                            </div>
                        </Link>
                        <Link to="/cgateussd" >
                            <div className="accordion-item mb-3">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    C'Gate USSD
                                </button>
                            </h2>
                        </div></Link>

                        <Link to="/transaction-switching-and-processing" >
                            <div className="accordion-item mb-3">
                            <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseTwo(null)" aria-expanded="false" aria-controls="collapseTwo">
                                    Transaction Switching & Processing
                                </button>
                            </h2>
                        </div></Link>

                        <Link to="/payment-with-transfer" >
                            <div className="accordion-item mb-3">
                            <h2 className="accordion-header" id="headingThree">
                                <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree(null)" aria-expanded="false"
                                        aria-controls="collapseThree">
                                    Pay With Bank Transfer
                                </button>
                            </h2>
                        </div></Link>
                        <div className="accordion-item mb-3">
                            <h2 className="accordion-header" id="headingFour">
                                <button className="accordion-button dropdown-btn" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseFour" aria-expanded="false"
                                        aria-controls="collapseThree">
                                    NQR
                                </button>
                            </h2>
                            <div id="collapseFour" className="accordion-collapse collapse show"
                                 aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className="biller-one-option-mobile">
                                        <div className="biller-one-app-option" style={{backgroundImage:`url(${qr})`}}></div>
                                    </div>
                                    CoralPay has incorporated NQR as a channel for businesses to receive payments.
                                    With NQR, customers can scan a QR code on merchant’s check out page, the customer is debited, and the merchant is credited.
                                    NQR can be used in places of worship, schools, transportation, event locations etc, to facilitate collections and ticket payments.
                                    <br /><br />
                                    <br /><br />
                                    <a href="https://blog.coralpay.com/the-payment-options-that-will-keep-your-business-ahead/"><img src={learnMore} alt='learn more btn'/></a>
                                </div>
                            </div>
                        </div>
                        <Link to="/digipos">
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree">
                                        DigiPOS
                                    </button>
                                </h2>
                            </div>
                        </Link>
                        <Link to="/billers-aggregation-platform">
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree">
                                        Billers Aggregation Platform
                                    </button>
                                </h2>
                            </div>
                        </Link>
                        <Link to="/finpay">
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree">
                                        FinPay
                                    </button>
                                </h2>
                            </div>
                        </Link>
                        <Link to="/payment-link">
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree">
                                        Payment Link
                                    </button>
                                </h2>
                            </div>
                        </Link>
                        <Link to="/instant-payment">
                            <div className="accordion-item mb-3">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed dropdown-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree">
                                        Coralpay Instant Payment
                                    </button>
                                </h2>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="biller-one">
                    <div className="biller-one-app" style={{backgroundImage:`url(${qr})`}}></div>
                </div>
            </div>
            <br /><br />
            <Subscribe />
        </div>
    )
}

export default Nqr